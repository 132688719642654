import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { getAnalytics } from '../../common/firebase';

const withTracking = (WrappedComponent, options = {}) => {
  const trackPageView = (pathname) => {
    const analytics = getAnalytics();
    analytics.logEvent('page_view', { ...options, page_path: pathname });
  };

  const HOC = (props) => {
    const location = useLocation();
    useEffect(() => trackPageView(location.pathname), [location.pathname]);

    return <WrappedComponent {...props} />;
  };

  return HOC;
};

export default withTracking;
