import produce from 'immer';
import _ from 'lodash';

import {
  LIST_AUDIENCE_SUCCESS,
  LIST_AUDIENCE_REQUEST,
  LIST_AUDIENCE_ERROR,
  GET_AUDIENCE_SUCCESS,
  GET_AUDIENCE_ERROR,
  GET_AUDIENCE_REQUEST,
  SAVE_AUDIENCE_REQUEST,
  SAVE_AUDIENCE_SUCCESS,
  SAVE_AUDIENCE_ERROR,
} from './types';

// The initial state of the Audiences.
export const initialState = {
  loading: false,
  updating: false,
  loadError: null,
  updateError: null,
  lastListRequestTs: {},
  lastEntityRequestTs: {},
  entities: {},
};

function audiencesReducer(state = initialState, action) {
  // eslint-disable-next-line consistent-return
  return produce(state, (draft) => {
    // eslint-disable-next-line default-case
    switch (action.type) {
      case LIST_AUDIENCE_REQUEST: {
        const { advertiserId } = action.payload;

        draft.loading = true;
        draft.loadError = null;
        _.set(draft.lastListRequestTs, [advertiserId], Date.now());
        break;
      }
      case LIST_AUDIENCE_SUCCESS: {
        const advertiserId = action.payload.advertiserId;
        const audiences = action.payload.audiences;
        draft.loading = false;
        draft.entities[advertiserId] = _.keyBy(audiences, (audience) => audience.id);
        break;
      }
      case LIST_AUDIENCE_ERROR: {
        const { error } = action.payload;

        draft.loading = false;
        draft.loadError = error;
        break;
      }
      case GET_AUDIENCE_REQUEST: {
        const { advertiserId, audienceId } = action.payload;

        draft.loading = true;
        draft.loadError = null;
        _.set(draft.lastEntityRequestTs, [advertiserId, audienceId], Date.now());
        break;
      }
      case GET_AUDIENCE_SUCCESS: {
        const advertiserId = action.payload.advertiserId;
        const audienceId = action.payload.audienceId;
        const audienceObj = action.payload.audience;
        draft.loading = false;
        _.set(draft.entities, [advertiserId, audienceId], audienceObj);
        break;
      }
      case GET_AUDIENCE_ERROR: {
        const { error } = action.payload;

        draft.loading = false;
        draft.loadError = error;
        break;
      }
      case SAVE_AUDIENCE_REQUEST: {
        draft.updating = true;
        draft.updateError = null;
        break;
      }
      case SAVE_AUDIENCE_SUCCESS: {
        const advertiserId = action.payload.advertiserId;
        const audienceObj = action.payload.audience;
        draft.updating = false;
        draft.entities[advertiserId][audienceObj.id] = audienceObj;
        break;
      }
      case SAVE_AUDIENCE_ERROR: {
        const { error } = action.payload;

        draft.updating = false;
        draft.updateError = error;
        break;
      }
      default:
        break;
    }
  });
}

export default audiencesReducer;
