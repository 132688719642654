import produce from 'immer';
import _ from 'lodash';
import { MIAdAccountUsersState } from '../types';

import {
  LIST_USERS_SUCCESS,
  LIST_USERS,
  LIST_USERS_ERROR,
  // GET_USER_SUCCESS,
  // GET_USER_ERROR,
  // GET_USER,
  SAVE_USER,
  SAVE_USER_SUCCESS,
  SAVE_USER_ERROR,
  DELETE_USER,
  DELETE_USER_SUCCESS,
  DELETE_USER_ERROR,
} from './types';

export const INITIAL_STATE: MIAdAccountUsersState = {
  loading: false,
  updating: false,
  loadError: null,
  updateError: null,
  entities: {},
};

function usersReducer(state = INITIAL_STATE, action: any) {
  return produce(state, (draft) => {
    switch (action.type) {
      case LIST_USERS: {
        draft.loading = true;
        draft.loadError = null;
        break;
      }
      case LIST_USERS_SUCCESS: {
        const { adAccountId, users } = action.payload;

        draft.loading = false;
        _.set(draft.entities, adAccountId, _.keyBy(users, 'id'));
        break;
      }
      case LIST_USERS_ERROR: {
        draft.loading = false;
        draft.loadError = action.error;
        break;
      }
      case SAVE_USER: {
        draft.updating = true;
        draft.updateError = null;
        break;
      }
      case SAVE_USER_SUCCESS: {
        const { user } = action.payload;

        draft.updating = false;
        _.set(draft.entities, [user.id], user);
        break;
      }
      case SAVE_USER_ERROR: {
        draft.updating = false;
        draft.updateError = action.error;
        break;
      }
      case DELETE_USER: {
        draft.updating = true;
        draft.updateError = null;
        break;
      }
      case DELETE_USER_SUCCESS: {
        const { adAccountId, userId } = action.payload;

        draft.updating = false;
        delete draft.entities[adAccountId][userId];

        break;
      }
      case DELETE_USER_ERROR: {
        draft.updating = false;
        draft.updateError = action.error;
        break;
      }
      default:
        break;
    }
  });
}

export default usersReducer;
