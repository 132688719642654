import _ from 'lodash';
import moment from 'moment';
import { isValidIDFA } from './index.ts';

// toUIObjectDefaultHelper gets the parameter 'proto' which is a piece of any type from proto and then converts it into UIObject without Class.
export const toUIObjectDefaultHelper = (proto, nonProtoMessages) => {
  if (!_.isObjectLike(proto)) {
    // The parameter 'proto' is neither a plain object, or array type. ex) number, string
    return proto;
  }
  if (_.isArray(proto)) {
    // array type.
    return proto.map((elem) => toUIObjectDefaultHelper(elem, nonProtoMessages));
  }

  // The parameter 'proto' is a plain object type.
  const newUIObject = {};
  // eslint-disable-next-line guard-for-in,no-restricted-syntax
  for (const key in proto) {
    const newKey = _.camelCase(key); // Use camel case key for UIObject.
    if (!nonProtoMessages || !nonProtoMessages.includes(_.camelCase(key))) {
      newUIObject[newKey] = toUIObjectDefaultHelper(proto[key], nonProtoMessages);
    } else {
      newUIObject[newKey] = _.cloneDeep(proto[key]);
    }
  }
  newUIObject.proto = proto; // Store the original proto in the proto field not to lose any fields in the original proto.

  return newUIObject;
};

// fromUIObjectDefaultHelper gets the parameter 'uiObject' which is a piece of any type from UIObject and then converts it into a proto.
export const fromUIObjectDefaultHelper = (uiObject, nonProtoMessages) => {
  if (!_.isObjectLike(uiObject)) {
    // The parameter 'uiObject' is neither a plain object, or array type. ex) number, string
    return uiObject;
  }
  if (_.isArray(uiObject)) {
    // array type.
    return uiObject.map((elem) => fromUIObjectDefaultHelper(elem, nonProtoMessages));
  }

  // The parameter 'uiObject' is a plain object type.
  const newProto = {};
  // eslint-disable-next-line guard-for-in,no-restricted-syntax
  for (const key in uiObject) {
    const newKey = _.snakeCase(key); // Use snake case for proto.
    if (!nonProtoMessages || !nonProtoMessages.includes(key)) {
      newProto[newKey] = fromUIObjectDefaultHelper(uiObject[key], nonProtoMessages);
    } else {
      newProto[newKey] = _.cloneDeep(uiObject[key]);
    }
  }
  delete newProto.proto;

  if (!_.isEmpty(uiObject.proto)) {
    // Update the original proto not to lose any field in the original proto.
    const originalProto = _.cloneDeep(uiObject.proto);

    // eslint-disable-next-line guard-for-in,no-restricted-syntax
    for (const key in newProto) {
      originalProto[key] = newProto[key];
    }

    return originalProto;
  }
  // A uiObject doesn't have the proto field if it's newly created.
  return newProto;
};

export const toUIObjectDefault = (proto, Class) => {
  return new Class(toUIObjectDefaultHelper(proto, Class.nonProtoMessages));
};

// toUIObject automatically detects whether the Class has its own customized toUIObject otherwise uses toUIObjectDefault.
export const toUIObject = (proto, Class) => {
  if (_.has(Class, 'toUIObject')) {
    return Class.toUIObject(proto);
  }
  return toUIObjectDefault(proto, Class);
};

export const fromUIObjectDefault = (uiObject, Class) => {
  return fromUIObjectDefaultHelper(new Class(uiObject), Class.nonProtoMessages);
};

// fromUIObject automatically detects whether the Class has its own customized fromUIObject otherwise uses fromUIObjectDefault.
export const fromUIObject = (object, Class) => {
  if (_.has(Class, 'fromUIObject')) {
    return Class.fromUIObject(object);
  }
  return fromUIObjectDefault(object, Class);
};

export const Validators = {
  required: (value) => (_.isEmpty(value) && !_.isNumber(value) ? 'Required.' : ''),
  mapRequired: (paths, condition) => (valueMap) => {
    let isEmpty = true;
    let isFull = true;
    paths.forEach((path) => {
      const value = _.get(valueMap, [path], null);
      if (_.isEmpty(value) && !_.isNumber(value)) {
        isFull = false;
      } else {
        isEmpty = false;
      }
    });

    if (isEmpty || (condition === 'all' && !isFull)) {
      return 'Required.';
    }
    return '';
  },
  number: (value) => (value && !_.isNumber(value) ? 'Only number is allowed.' : ''),
  positiveNumber: (value) => (value > 0 ? '' : 'Only positive number is allowed.'),
  zeroOrPositiveNumber: (value) => (value >= 0 ? '' : 'Only 0 or positive numbers are allowed.'),
  alphaNumericHyphen: (value) =>
    /^[a-zA-Z0-9_-]*$/.test(value) ? '' : 'Invalid. Only alphabet, numbers and -, _ are allowed.',
  lowercaseAlphaNumeric: (value) =>
    /^[a-z0-9_-]+$/.test(value)
      ? ''
      : 'Invalid. Only lowercase alphabet, numbers and -, _ are allowed.',
  noSpecialChar: (value) =>
    // detect special characters
    // eslint-disable-next-line no-useless-escape
    /[~`!#$%\^&*+=\[\]\\';,/{}|\\":<>\?]/g.test(value) ||
    // detect emojis
    /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g.test(
      value,
    )
      ? 'Invalid. Special characters are not allowed.'
      : '',
  isURL: (value) => {
    try {
      // eslint-disable-next-line no-unused-vars
      const url = new URL(value);
      return '';
    } catch {
      return 'Invalid URL.';
    }
  },
  advertiserDomain: (value) =>
    /^(?!(:\/\/|www\.))([a-z0-9]+(-[a-z0-9]+)*\.)+[a-z]{2,}$/.test(value)
      ? ''
      : `Please don't include 'http' or 'https' protocol and hostname such as 'www.'.`,
  iosBundleId: (value) =>
    /^[0-9]{9,10}$/i.test(value) ? '' : 'Only numeric value with 9-10 digits is allowed.',
  androidBundleId: (storeUrl) => {
    if (storeUrl) {
      if (storeUrl.includes('onestore.co.kr')) {
        return (value) => {
          return /^[0-9]{9,11}$/i.test(value)
            ? ''
            : 'Only numeric value with 9-11 digits is allowed.';
        };
      }
      if (storeUrl.includes('galaxystore.samsung.com')) {
        return (value) => {
          return /^[a-z]+(\.[a-z_0-9]+)+$/i.test(value)
            ? ''
            : 'Invalid Galaxy store app bundle format.';
        };
      }
    }

    return (value) => {
      return /^[a-z][a-z0-9_]*(\.[a-z0-9_]+)+$/i.test(value) ? '' : 'Invalid android bundle id.';
    };
  },
  emailAddress: (value) =>
    // eslint-disable-next-line no-useless-escape,no-control-regex
    /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/.test(
      value,
    )
      ? ''
      : 'Invalid email address format!',
  password: (value) =>
    // eslint-disable-next-line no-useless-escape
    /^(?=.*[0-9])(?=.*[!@#$%^&*~+=`|{_}:;,.?"'()\\/<>\[\]-])[a-zA-Z0-9!@#$%^&*~+=`|{_}:;,.?"'()\\/<>\[\]-]{6,}$/.test(
      value,
    )
      ? ''
      : 'Password must contain at least 6 characters, including letters(A-a, a-z) and at least one special character and number.',
  passwordMatch: (newPassword) => (values) =>
    newPassword !== values ? "Password confirmation doesn't match the password." : '',
  oneOf: (validValues) => {
    if (!_.isArray(validValues)) {
      throw new Error('No list values are given.');
    }
    return (value) =>
      _.indexOf(validValues, value) >= 0
        ? ''
        : `Value should be one of ${validValues.join(',')} given ${value}.`;
  },
  minDate: (minDate, errorMessage) => {
    const baseDate = moment(minDate);

    return (value) => {
      const setDate = moment(value);
      return setDate.isBefore(baseDate) ? errorMessage : '';
    };
  },
  maxTextLength: (maxCharacterNumber) => {
    return (value) => {
      return value && value.length > maxCharacterNumber
        ? `The number of characters cannot exceed ${maxCharacterNumber}.`
        : '';
    };
  },
  idfa: (input) => (isValidIDFA(input) ? '' : `Invalid ADID or IDFA format.`),
  hasValueOfField: (fieldName) => {
    return (value) => {
      let errorMessage = '';
      if (!_.isEmpty(value)) {
        value.forEach((v) => {
          if (!_.isEmpty(_.get(v, fieldName, ''))) {
            errorMessage = `${fieldName} has value`;
          }
        });
      }
      return errorMessage;
    };
  },
  inRange: (min, max) => {
    return (value) => {
      return value >= min && value <= max ? '' : `Only ${min} to ${max} are allowed.`;
    };
  },
  minImpInterval: (min) => (value) =>
    !Number.isNaN(value) && _.isNumber(value) && value >= min
      ? ''
      : 'Minimum impression interval is 1',
  person: (value) =>
    /^(?!@)[a-z]+/.test(value) || value === 'n/a' || value === 'N/A'
      ? ''
      : 'Manager format should be name, n/a or N/A',
  optional: (cb) => (value) => (value ? cb(value) : ''),
  margin: (value) => {
    const minMargin = -1;
    const maxMargin = 1;

    if (value < minMargin) return `Minimum Margin is ${minMargin}`;
    if (value > maxMargin) return `Maximum Margin is ${maxMargin}`;
    return '';
  },
};

// validateUIObject validates 'object' according to 'validationSchema' recursively.
// Assume the parameter 'validationSchema' is always a plain object.
export const validateUIObject = (object, validationSchema) => {
  const errors = {};

  // eslint-disable-next-line guard-for-in,no-restricted-syntax
  for (const key in validationSchema) {
    // validationSchema[key] is either a plain object or array.
    if (_.isPlainObject(validationSchema[key])) {
      // It is a plain object when it has its own validationSchema.
      const childErrors = validateUIObject(object[key], validationSchema[key]);
      if (!_.isEmpty(childErrors)) {
        errors[key] = childErrors;
      }
      // eslint-disable-next-line no-continue
      continue;
    }

    // eslint-disable-next-line no-restricted-syntax
    for (const validator of validationSchema[key]) {
      if (_.isFunction(validator)) {
        const result = validator(object[key]);
        if (!_.isEmpty(result)) {
          errors[key] = result;
        }
      }
    }
  }

  return errors;
};

export const validate = (object, Class) => {
  return validateUIObject(object, Class.getValidationSchema(object));
};
