export enum MERole {
  // Legacy roles.
  UNIVERSE_ADMIN = 'UNIVERSE_ADMIN',
  UNIVERSE_USER_ADMIN = 'UNIVERSE_USER_ADMIN',
  UNIVERSE_USER_CREATOR = 'UNIVERSE_USER_CREATOR',
  PLATFORM_ADMIN = 'PLATFORM_ADMIN',
  PLATFORM_OWNER = 'PLATFORM_OWNER',
  PUBLISHER_PLATFORM_ADMIN = 'PUBLISHER_PLATFORM_ADMIN',
  DSP_PLATFORM_ADMINISTRATOR = 'DSP_PLATFORM_ADMINISTRATOR',
  DSP_PLATFORM_MANAGER = 'DSP_PLATFORM_MANAGER',

  // new roles
  DSP_WORKPLACE_OWNER = 'DSP_WORKPLACE_OWNER',
  DSP_WORKPLACE_USER = 'DSP_WORKPLACE_USER',
  DSP_ADACCOUNT_OWNER = 'DSP_ADACCOUNT_OWNER',
  DSP_ADACCOUNT_USER = 'DSP_ADACCOUNT_USER',
  DSP_ADACCOUNT_VIEWER = 'DSP_ADACCOUNT_VIEWER',

  // Special role
  USERSPACE_USER = 'USERSPACE_USER',
  UNKNOWN_ROLE = 'UNKNOWN_ROLE',
}

interface MIRoleProto {
  id: MERole;
  platform_id: string;
}

export interface MIRoleGrantProto {
  resource_id: string;
  resource_instance_id: string;
  roles: Array<MIRoleProto>;
}
