const defaultFont = {
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  fontWeight: '300',
  lineHeight: '1.5em',
};

const primaryColor = {
  extraLight: '#e6edfc',
  light: '#c6d8ff',
  main: '#4e7fed',
  dark: '#3c6ede',
  contrastText: '#fff',
};
const infoColor = {
  light: '#d9c5ff',
  main: '#9e93f3',
  dark: '#9465dc',
  contrastText: '#fff',
};
const successColor = {
  extraLight: '#E8F5EB',
  light: '#d6eaba',
  main: '#1db900',
  dark: '#6ca42a',
  contrastText: '#fff',
};
const warningColor = {
  light: '#72cbfd',
  main: '#489be2',
  dark: '#0251ac',
  contrastText: '#fff',
};
const dangerColor = {
  extraLight: '#fff0f0',
  light: '#e8a6b1',
  main: '#ff0000',
  dark: '#9d1f50',
  contrastText: '#fff',
};
const grayColor = {
  extraLight: '#fff',
  light: '#f5f5f5',
  main: '#888',
  dark: '#555',
  extraDark: '#333',
  contrastText: '#929292',
};

const matchParentWidth = {
  width: '100%',
};

const chipStyle = {
  height: '2rem',
  margin: '0.8px 0.8px 0.8px',
};

const drawerWidth = 240;
const topBarHeight = 64;

const selectorHeight = 95;
const titleBarHeight = 57;
const tabBarHeight = 48;

const inventoryManagerRootStyle = {
  topBarHeight: 64,
  selectorHeight: 77,
  singleSelectorHeight: 58,
  titleBarHeight: 82,
};

const globalNavigationBarWidth = 60;

export {
  defaultFont,
  primaryColor,
  warningColor,
  dangerColor,
  successColor,
  infoColor,
  grayColor,
  matchParentWidth,
  chipStyle,
  drawerWidth,
  topBarHeight,
  selectorHeight,
  titleBarHeight,
  tabBarHeight,
  globalNavigationBarWidth,
  inventoryManagerRootStyle,
};
