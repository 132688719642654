import { TABLE_PAGE_SIZE } from '../../common/utils/browser_cache_keys';
import { INITIALIZE_REDUX_STATE, SET_TABLE_PAGE_SIZE, TRIGGER_SUPER_USER } from './types';

export const setTablePageSize = (size) => ({
  type: SET_TABLE_PAGE_SIZE,
  payload: size,
});

export const changeTablePageSize = (size) => {
  localStorage.setItem(TABLE_PAGE_SIZE, size);
  return (dispatch) => {
    dispatch(setTablePageSize(size));
  };
};

export const triggerSuperUser = () => {
  return (dispatch) => {
    dispatch({ type: TRIGGER_SUPER_USER });
  };
};

export const initializeReduxState = (excludes = []) => ({
  type: INITIALIZE_REDUX_STATE,
  payload: {
    excludes,
  },
});
