/* eslint-disable @typescript-eslint/indent */
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import MUIArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import MUITooltip from '@material-ui/core/Tooltip';

const SCPlatformSelectWrapper = styled.div`
  position: relative;
  padding: 12px 0;
  margin: 0 12px;
  border-bottom: solid 1px #e0e0e0;
`;

const SCPlatformLabel = styled.div`
  font-size: 12px;
  font-weight: 500;
  color: #212121;
  margin-bottom: 4px;
`;

const SCPlatformSelect = styled.div`
  height: 46px;
  border-radius: 2px;
  border: solid 1px #bbbbbb;
  background-color: #f3f3f3;
  padding: 6px 10px 6px 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  user-select: none;
`;

const SCPlatformIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 34px;
  height: 34px;
  background-color: #ffffff;
  border: solid 1px #e0e0e0;
`;

const SCPlatformIcon = styled.img`
  padding: 4px;
  width: inherit;
  height: auto;
`;

type MTOverflowProps = {
  isOverflow: boolean;
};

const SCCurrentPlatformName = styled.div<MTOverflowProps>`
  flex: 1;
  font-size: 14px;
  width: 100%;
  font-weight: 500;
  color: #666666;
  padding: 0 14px;
  height: 17px;

  text-overflow: ${(props) => (props.isOverflow ? 'ellipsis' : 'unset')};
  overflow: ${(props) => (props.isOverflow ? 'hidden' : 'unset')};
  white-space: ${(props) => (props.isOverflow ? 'nowrap' : 'unset')};
`;

type MTArrowProps = {
  flip: boolean;
};

const SCArrowIcon = styled(MUIArrowDropDownIcon)`
  color: #8b8b8b;
  transform: ${(props: MTArrowProps): string => (props.flip ? 'scaleY(-1)' : 'scaleY(1)')};
`;

const SCPlatformOptionWrapper = styled.div`
  position: absolute;
  width: 206px;
  border: solid 1px #bbbbbb;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.3);
`;

type MTPlatformOptionProps = {
  active: boolean;
};

const SCPlatformOption = styled.div`
  height: 46px;
  border-bottom: solid 1px #bbbbbb;
  padding: 6px 10px 6px 6px;
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
  --active-bg-color: #edf2fd;
  background-color: ${(props: MTPlatformOptionProps): string =>
    props.active ? 'var(--active-bg-color)' : '#fff'};

  :hover {
    background-color: ${(props: MTPlatformOptionProps): string =>
      props.active ? 'var(--active-bg-color)' : '#f6f6f6'};
  }

  &:last-child {
    border-bottom: 0;
  }
`;

const SCPlatformOptionName = styled.div`
  flex: 1;
  font-size: 14px;
  font-weight: 500;
  color: #666666;
  padding: 0 14px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

type MTWorkplaceOptionProps = {
  workplace: any;
  isActive: boolean;
  onClick: () => void;
};

const MCWorkplaceOption = ({
  workplace,
  isActive,
  onClick,
}: MTWorkplaceOptionProps): React.ReactElement => {
  const workplaceRef = useRef<HTMLDivElement>(null);
  const [workplaceOverflow, setWorkplaceOverflow] = useState<boolean>(false);

  useEffect(() => {
    const wp = workplaceRef.current;
    if (
      !workplaceOverflow &&
      wp &&
      (wp.scrollHeight > wp.offsetHeight || wp.scrollWidth > wp.offsetWidth)
    ) {
      setWorkplaceOverflow(true);
    }
  }, [workplaceOverflow]);

  return (
    <MUITooltip title={workplace.title} arrow disableHoverListener={!workplaceOverflow}>
      <SCPlatformOption active={isActive} onClick={onClick}>
        <SCPlatformIconWrapper>
          <SCPlatformIcon src={workplace.iconUrl} />
        </SCPlatformIconWrapper>
        <SCPlatformOptionName ref={workplaceRef}>{workplace.title}</SCPlatformOptionName>
      </SCPlatformOption>
    </MUITooltip>
  );
};

type MTPlatformSelectProps = {
  currentPlatform: any;
  availablePlatforms: Array<any>;
  onChange: (newAdvertiserId: string) => void;
};

const MCPlatformSelect: React.FunctionComponent<MTPlatformSelectProps> = ({
  currentPlatform,
  availablePlatforms,
  onChange,
}: MTPlatformSelectProps) => {
  const [open, setOpen] = useState(false);
  const [selectedOverflow, setSelectedOverflow] = useState<boolean>(false);
  const selectItemNameRef = useRef<HTMLDivElement>(null);
  const selectorWrapper = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const selectedOption = selectItemNameRef.current;

    if (
      !selectedOverflow &&
      selectedOption &&
      (selectedOption.scrollHeight > selectedOption.offsetHeight ||
        selectedOption.scrollWidth > selectedOption.offsetWidth)
    ) {
      setSelectedOverflow(true);
    }
  }, [selectedOverflow]);

  const handleClick = () => {
    setOpen(!open);
  };

  const handlePlatformOptionClick = (newPlatformId: string) => {
    if (currentPlatform.id !== newPlatformId) {
      onChange(newPlatformId);
    }

    setOpen(false);
  };

  const handleClickOutside = (e: any) => {
    const node = selectorWrapper.current;
    if (node && !node.contains(e.target)) {
      setOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const options = availablePlatforms.map((platform: any) => (
    <MCWorkplaceOption
      key={platform.id}
      workplace={platform}
      isActive={platform.id === currentPlatform.id}
      onClick={() => handlePlatformOptionClick(platform.id)}
    />
  ));

  return (
    <SCPlatformSelectWrapper>
      <SCPlatformLabel>Workplace</SCPlatformLabel>
      <div ref={selectorWrapper}>
        <MUITooltip title={currentPlatform.title} arrow disableHoverListener={!selectedOverflow}>
          <SCPlatformSelect onClick={handleClick}>
            <SCPlatformIconWrapper>
              <SCPlatformIcon src={currentPlatform.iconUrl} />
            </SCPlatformIconWrapper>
            <SCCurrentPlatformName ref={selectItemNameRef} isOverflow={selectedOverflow}>
              {currentPlatform.title}
            </SCCurrentPlatformName>
            <SCArrowIcon flip={open} />
          </SCPlatformSelect>
        </MUITooltip>
        {open ? <SCPlatformOptionWrapper>{options}</SCPlatformOptionWrapper> : null}
      </div>
    </SCPlatformSelectWrapper>
  );
};

export default MCPlatformSelect;
