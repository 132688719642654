import { applyMiddleware, compose, createStore } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import { reactReduxFirebase } from 'react-redux-firebase';
import { reduxFirestore } from 'redux-firestore';
import { createBrowserHistory } from 'history';
import thunk from 'redux-thunk';
import logger from 'redux-logger';

import firebase from '../common/firebase';
import bugsnag from '../common/utils/bugsnag';
import createRootReducer from './index';
import { initReduxStateEnhancer } from './main/enhancer';
import createBugsnagReporterMiddleware from './middleware/bugsnagReporter';

export const history = createBrowserHistory();
const initialState = {};
const enhancers = [];
const middleware = [thunk, routerMiddleware(history), createBugsnagReporterMiddleware(bugsnag)];

if (process.env.NODE_ENV === 'development') {
  middleware.push(logger);
  // eslint-disable-next-line no-underscore-dangle
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;
  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension());
  }
}

const reactReduxFirebaseConfig = {
  userProfile: 'users', // firebase root where user profiles are stored
  enableLogging: true, // enable/disable Firebase's database logging
  useFirestoreForProfile: true,
};

const createStoreWithFirebase = compose(
  reduxFirestore(firebase),
  reactReduxFirebase(firebase, reactReduxFirebaseConfig),
)(createStore);

const composedEnhancers = compose(applyMiddleware(...middleware), ...enhancers);

export default createStoreWithFirebase(
  initReduxStateEnhancer(createRootReducer(history)),
  initialState,
  composedEnhancers,
);
