import _ from 'lodash';
import { MECampaignGoalType, MECampaignType, MEProductType } from '../types';
import {
  MEImageCreativeSubTypeProto,
  MIImageCreativeAvailableDimensionsProto,
  MIPlatformSettingProto,
} from '../proto/platformSetting';
import { MEImageCreativeType } from '../../components-v2/CampaignManager/Overview/Creatives/types';
import { dimensionObjCompareFn } from './utils';

type MTSupportedCampaignSetups = Record<
  MEProductType,
  Record<MECampaignType, Array<MECampaignGoalType>>
>;

export interface MIImageCreativeConfig {
  width: number;
  height: number;
  type: MEImageCreativeType;
}

export interface MINativeMainImageConfig {
  width: number;
  height: number;
}

const getCampaignSetupsByGoal = (goalType: MECampaignGoalType) => {
  // TODO: Change the logic after checking whether the campaignType according to the goal can be judged only by the name of the goal.
  switch (goalType) {
    case MECampaignGoalType.OPTIMIZE_CPA_FOR_APP_UA:
    case MECampaignGoalType.OPTIMIZE_CPC_FOR_APP_UA:
    case MECampaignGoalType.OPTIMIZE_CPI_FOR_APP_UA:
    case MECampaignGoalType.OPTIMIZE_ROAS_FOR_APP_UA:
    case MECampaignGoalType.FIXED_CPM_FOR_APP_UA:
      return [
        { productType: MEProductType.APP, campaignType: MECampaignType.APP_USER_ACQUISITION },
      ];
    case MECampaignGoalType.OPTIMIZE_CPC_FOR_APP_RE:
    case MECampaignGoalType.FIXED_CPM_FOR_APP_RE:
      return [{ productType: MEProductType.APP, campaignType: MECampaignType.APP_REENGAGEMENT }];
    case MECampaignGoalType.OPTIMIZE_CPC_FOR_WEB_VISIT:
      return [{ productType: MEProductType.WEB, campaignType: MECampaignType.WEBSITE_VISIT }];
    default:
      return [];
  }
};

const getSupportedImageCreativesFromProto = (
  imageCreativeAvailableDimensionsProto: MIImageCreativeAvailableDimensionsProto,
) => {
  const result: Array<MIImageCreativeConfig> = [];

  Object.entries(imageCreativeAvailableDimensionsProto).forEach(([imageCreativeSubType, v]) => {
    if (v?.dimensions) {
      v.dimensions.forEach((dimension: string) => {
        const [width, height] = dimension.split('x').map((d) => parseInt(d, 10));
        let imageCreativeType;
        switch (imageCreativeSubType) {
          case MEImageCreativeSubTypeProto.CREATIVE_SUB_TYPE_IMAGE_BANNER:
            imageCreativeType = MEImageCreativeType.BANNER;
            break;
          case MEImageCreativeSubTypeProto.CREATIVE_SUB_TYPE_IMAGE_FULL_SCREEN:
            imageCreativeType =
              width > height
                ? MEImageCreativeType.FULL_SCREEN_LANDSCAPE
                : MEImageCreativeType.FULL_SCREEN_PORTRAIT;
            break;
          case MEImageCreativeSubTypeProto.CREATIVE_SUB_TYPE_IMAGE_MEDIUM:
            imageCreativeType = MEImageCreativeType.MEDIUM;
            break;
          case MEImageCreativeSubTypeProto.CREATIVE_SUB_TYPE_IMAGE_TABLET:
            if (height / width < 0.25) {
              imageCreativeType = MEImageCreativeType.TABLET_BANNER;
            } else if (width > height) {
              imageCreativeType = MEImageCreativeType.TABLET_FULL_SCREEN_LANDSCAPE;
            } else {
              imageCreativeType = MEImageCreativeType.TABLET_FULL_SCREEN_PORTRAIT;
            }
            break;
          default:
            imageCreativeType = MEImageCreativeType.UNKNOWN;
        }
        result.push({
          width,
          height,
          type: imageCreativeType,
        });
      });
    }
  });

  return result.sort(dimensionObjCompareFn);
};

export default class PlatformSettings {
  features: Array<string> = [];
  supportedMMPs: Array<string> = [];
  supportedExchanges: Array<string> = [];
  availableGoals: Array<MECampaignGoalType> = [];
  supportedCampaignSetups: MTSupportedCampaignSetups;
  supportedImageCreatives: Array<MIImageCreativeConfig>;
  supportedNativeMainImages: Array<MINativeMainImageConfig>;
  isSupportVideoCreative: boolean;

  constructor(
    proto: MIPlatformSettingProto = {} as MIPlatformSettingProto,
    features: Array<string> = [],
    meta = {},
  ) {
    const frontendData = _.keyBy(proto.frontend_data ?? [], 'key');
    const frontendFeatures = _.get(frontendData, ['supported_features', 'data'], []) as Array<
      string
    >;

    // Shared with BE + For only FE in DSP configuration + For only FE in FE configuration
    this.features = _.uniq(
      (proto.supported_features ?? []).concat(frontendFeatures).concat(features),
    );
    this.supportedMMPs = (proto.available_mmps ?? []).sort();
    this.supportedExchanges = (proto.available_exchanges ?? []).sort();
    this.availableGoals = _.uniq(
      ((proto.available_goals ?? []) as Array<MECampaignGoalType>).map(
        (goalType: MECampaignGoalType) => {
          switch (goalType) {
            case MECampaignGoalType.OPTIMIZE_APP_INSTALLS:
              return MECampaignGoalType.OPTIMIZE_CPI_FOR_APP_UA;
            case MECampaignGoalType.OPTIMIZE_APP_ROAS:
              return MECampaignGoalType.OPTIMIZE_ROAS_FOR_APP_UA;
            case MECampaignGoalType.OPTIMIZE_CLICKS_TO_APP:
              return MECampaignGoalType.OPTIMIZE_CPC_FOR_APP_UA;
            case MECampaignGoalType.OPTIMIZE_CLICKS_TO_WEB:
              return MECampaignGoalType.OPTIMIZE_CPC_FOR_WEB_VISIT;
            default:
              return goalType;
          }
        },
      ),
    );
    this.supportedCampaignSetups = {} as MTSupportedCampaignSetups;

    const availableCampaignSetupsFromFeConfig = _.get(meta, 'campaign_setups', []);

    if (!_.isEmpty(availableCampaignSetupsFromFeConfig)) {
      // Set supportedCampaignSetups using FE configuration
      availableCampaignSetupsFromFeConfig.forEach((availableSetup: any) => {
        const productType = _.get(availableSetup, 'landing_type', null);
        const campaignType = _.get(availableSetup, 'campaign_type', null);
        const goalTypes = _.get(availableSetup, 'goal_types', null);

        if (productType) {
          if (goalTypes) {
            const includingGoalTypes = goalTypes.filter((type: MECampaignGoalType) =>
              this.availableGoals.includes(type),
            );

            if (!_.isEmpty(includingGoalTypes)) {
              if (
                _.has(this.supportedCampaignSetups, [productType, campaignType]) &&
                // @ts-ignore
                _.isArray(this.supportedCampaignSetups[productType][campaignType])
              ) {
                _.set(
                  this.supportedCampaignSetups,
                  [productType, campaignType],
                  _.union(
                    // @ts-ignore
                    this.supportedCampaignSetups[productType][campaignType],
                    includingGoalTypes,
                  ),
                );
              } else {
                _.set(
                  this.supportedCampaignSetups,
                  [productType, campaignType],
                  includingGoalTypes,
                );
              }
            }
          }
        }
      });
    } else {
      // Set supportedCampaignSetups using only availableGoals
      this.availableGoals.forEach((availableGoal) => {
        const campaignSetups: Array<{
          productType: MEProductType;
          campaignType: MECampaignType;
        }> = getCampaignSetupsByGoal(availableGoal);

        campaignSetups.forEach((campaignSetup) => {
          const { productType, campaignType } = campaignSetup;
          const newGoalTypes: Array<MECampaignGoalType> = _.get(
            this.supportedCampaignSetups,
            [productType, campaignType],
            [],
          );
          newGoalTypes.push(availableGoal);
          _.set(this.supportedCampaignSetups, [productType, campaignType], newGoalTypes);
        });
      });
    }

    this.supportedImageCreatives = getSupportedImageCreativesFromProto(
      proto.available_creatives?.CREATIVE_TYPE_IMAGE?.available_dimensions ?? {},
    );

    this.supportedNativeMainImages =
      proto.available_creatives?.CREATIVE_TYPE_NATIVE?.available_dimensions?.CREATIVE_SUB_TYPE_NATIVE_MAIN_IMAGE?.dimensions
        ?.map((dimension) => {
          const [width, height] = dimension.split('x').map((d) => parseInt(d, 10));
          return { width, height };
        })
        .sort(dimensionObjCompareFn) ?? [];

    this.isSupportVideoCreative =
      (
        proto.available_creatives?.CREATIVE_TYPE_VIDEO?.available_dimensions
          ?.CREATIVE_SUB_TYPE_VIDEO_VIDEO?.dimensions ?? []
      ).length > 0;
  }
}
