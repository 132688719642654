import { Validators } from '../../common/utils/modelUtils';
import { FIELD_TYPE } from '../../components/Common/Formik/consts';
import { MICreativeProto } from '../proto/creative';
import { MECreativeType } from '../types';
import { NativeResource } from './nativeResource';
import { MICreativeNativeSrcProto } from '../proto/creativeSource';

// eslint-disable-next-line import/prefer-default-export
export class NativeCreative {
  public proto: MICreativeProto;
  public id: string;
  public adAccountId: string;
  public title: string;
  public originalFilename: string;
  public type: MECreativeType;
  public sizeInBytes: number;
  public native: NativeResource;
  public createdAt: string;
  public updatedAt: string;

  constructor(proto: MICreativeProto) {
    this.proto = proto;

    this.id = proto.id ?? '';
    this.adAccountId = proto.ad_account_id ?? '';
    this.title = proto.title ?? '';
    this.originalFilename = proto.original_filename ?? '';
    this.type = proto.type ?? MECreativeType.NATIVE;
    this.sizeInBytes = proto.size_in_bytes ?? 0;
    this.native = NativeResource.fromProto(proto.native ?? ({} as MICreativeNativeSrcProto));
    if (this.native.video) {
      this.native.video.originalFilename = this.originalFilename;
    } else if (this.native.mainImage) {
      this.native.mainImage.originalFilename = this.originalFilename;
    }

    this.createdAt = proto.created_at ?? '';
    this.updatedAt = proto.updated_at ?? '';
  }

  static fromProto = (proto: MICreativeProto) => {
    return new NativeCreative(proto);
  };

  static toProto = (uiObject: NativeCreative) => {
    const proto = uiObject.proto;

    proto.native = NativeResource.toProto(uiObject.native);

    if (uiObject.native.video) {
      proto.original_filename = uiObject.native.video.originalFilename;
    } else {
      proto.original_filename = uiObject.native.mainImage?.originalFilename ?? '';
    }

    proto.title = uiObject.title;

    return proto;
  };

  static getValidationSchema = () => ({
    native: {
      title: [Validators.required],
      text: [Validators.required],
      ctaText: [Validators.required],
    },
  });

  static getUISchema = () => ({
    native: {
      title: {
        fieldType: FIELD_TYPE.TEXT_FIELD,
        label: 'Title',
        required: true,
        placeholder: 'Please enter a title',
      },
      text: {
        fieldType: FIELD_TYPE.TEXT_FIELD,
        label: 'Description',
        required: true,
        placeholder: 'Please enter a description',
      },
      ctaText: {
        fieldType: FIELD_TYPE.TEXT_FIELD,
        label: 'Call To Action Text',
        required: true,
        placeholder: 'Please enter a call to action text',
      },
    },
  });
}
