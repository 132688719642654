import React, { useEffect, Suspense } from 'react';
import { useDispatch } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';
import { ThemeProvider, StylesProvider } from '@material-ui/core/styles';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { FocusStyleManager } from '@blueprintjs/core';
import CssBaseline from '@material-ui/core/CssBaseline/CssBaseline';

// eslint-disable-next-line import/extensions
import * as routes from './common/constants/routes';
import EasterEgg from './components/Common/EasterEgg/EasterEgg';
import { initializeAuth } from './redux/auth/actions';
import Theme from './theme';
import MCSignIn from './containers-v2/Auth/MCSignIn.tsx';
import MCRequestResetPassword from './containers-v2/Auth/MCRequestResetPassword.tsx';
import MCEmailActionHandler from './containers-v2/Auth/MCEmailActionHandler.tsx';
import MCInAppAlertProvider from './components-v2/Common/InAppAlert/MCInAppAlertProvider.tsx';
import MCInAppAlertConsumer from './containers/Common/MCInAppAlertConsumer';
import './common/utils/fontawesome';

const Main = React.lazy(() => import('./components/Main/Main')); // lazy load v1 components

// Disable showing border line of Blueprint components on mouse click.
FocusStyleManager.onlyShowFocusOnTabs();

function App() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(initializeAuth());
  }, [dispatch]);

  return (
    <StylesProvider injectFirst>
      <ThemeProvider theme={Theme}>
        <StyledThemeProvider theme={Theme}>
          <MCInAppAlertProvider>
            <MCInAppAlertConsumer />
            <CssBaseline />
            {/* TODO: show loading screen if necessary */}
            <Suspense fallback={<></>}>
              <Switch>
                <Route exact path={routes.SIGN_IN}>
                  <MCSignIn />
                </Route>
                <Route exact path={routes.RESET_PASSWORD}>
                  <MCRequestResetPassword />
                </Route>
                <Route exact path={routes.EMAIL_ACTION}>
                  <MCEmailActionHandler />
                </Route>
                <Route path="/v1">
                  <Main />
                </Route>
                <Redirect to="/v1" />
              </Switch>
            </Suspense>
            <EasterEgg />
          </MCInAppAlertProvider>
        </StyledThemeProvider>
      </ThemeProvider>
    </StylesProvider>
  );
}

export default App;
