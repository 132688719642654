import { ImageCreative } from '../../../../common-v2/model/imageCreative';
import { NativeCreative } from '../../../../common-v2/model/nativeCreative';
import { VideoCreative } from '../../../../common-v2/model/videoCreative';
import { MECreativeTitleOptions } from './MCCreativeTitleForm';
import { VideoResource } from '../../../../common-v2/model/videoResource';
import { ImageResource } from '../../../../common-v2/model/imageResource';

export enum MEImageCreativeType {
  BANNER = 'BANNER',
  FULL_SCREEN_LANDSCAPE = 'FULL_SCREEN_LANDSCAPE',
  FULL_SCREEN_PORTRAIT = 'FULL_SCREEN_PORTRAIT',
  MEDIUM = 'MEDIUM',
  TABLET_BANNER = 'TABLET_BANNER',
  TABLET_FULL_SCREEN_LANDSCAPE = 'TABLET_FULL_SCREEN_LANDSCAPE',
  TABLET_FULL_SCREEN_PORTRAIT = 'TABLET_FULL_SCREEN_PORTRAIT',
  UNKNOWN = 'UNKNOWN',
}

export interface MIImageCreativeData extends ImageCreative {
  dimension: string;
  imageType: MEImageCreativeType;
}

export interface MINativeCreativeData extends NativeCreative {
  dimension: string;
}

export interface MIVideoCreativeData extends VideoCreative {
  dimension: string;
}

export type MTTitleOption = {
  option: MECreativeTitleOptions;
  value: string;
};

export type MTVideoTouchedMap = {
  confirmedVideoResources: boolean;
  confirmedEndCardResources: boolean;
};

export type MTNativeTouchedMap = {
  businessTitle: boolean;
  description: boolean;
  cta: boolean;
  confirmedIconResources: boolean;
  confirmedImageResources: boolean;
};

export type MTVideoForm = {
  videos: Array<VideoResource>;
  endCardImages: Array<ImageResource>;
};

export type MTNativeForm = {
  title: string;
  text: string;
  ctaText: string;
  icon: Array<ImageResource>;
  mainImages: Array<ImageResource>;
  video: Array<VideoResource>;
};

export enum MEFileUploadState {
  READY = 'READY',
  INVALID = 'INVALID',
  UPLOADING = 'UPLOADING',
  FINISHED = 'FINISHED',
  FAILED = 'FAILED',
}

export type MTCreativeUploadContext = {
  state: MEFileUploadState;
  orgFile: File;
  remoteFilename: string;
  height?: number;
  width?: number;
  invalidReason?: string;
  uploadFile?: File;
  finalUrl?: string;
  lengthSeconds?: number;
};

export enum MEImageFileExtension {
  JPG = 'image/jpg',
  JPEG = 'image/jpeg',
  PNG = 'image/png',
  GIF = 'image/gif',
}

export enum MENativeUploadFileType {
  ICON = 'ICON',
  IMAGE = 'IMAGE',
  VIDEO = 'VIDEO',
}

export enum MEVideoUploadFileType {
  VIDEO = 'VIDEO',
  END_CARD = 'END_CARD',
}

export enum MEQueryKey {
  CREATIVE_LIST_TAB = 'crlt',
  CREATIVE_IMPORT_TAB = 'crit',
}

export enum MECreativeColumns {
  TITLE = 'TITLE',
  ID = 'ID',
  DIMENSION = 'DIMENSION',
}
