import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';

import { removeNotification } from '../../redux/inAppAlert/actions';
import MCWebNotificationInAppAlert from '../../components-v2/Common/Notification/MCWebNotificationInAppAlert.tsx';

function MCInAppAlertConsumer() {
  // React Redux hooks
  const dispatch = useDispatch();
  const inAppAlerts = useSelector((state) => state.inAppAlert.items || []);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  // Store the displayed inAppAlerts in component state
  const [displayed, setDisplayed] = useState([]);

  useEffect(() => {
    const storeDisplayed = (id) => {
      setDisplayed((prevDisplayed) => [...prevDisplayed, id]);
    };

    const removeDisplayed = (id) => {
      setDisplayed((prevDisplayed) => [...prevDisplayed.filter((key) => id !== key)]);
    };

    inAppAlerts.forEach(({ key, message, options = {}, dismissed = false }) => {
      if (dismissed) {
        // dismiss snackbar using notistack
        closeSnackbar(key);
        return;
      }

      // do nothing if snackbar is already displayed
      if (displayed.includes(key)) return;

      // for web notification
      const webNotificationOptions = {};
      if (options.webNotification) {
        webNotificationOptions.content = (key1) => (
          <MCWebNotificationInAppAlert id={key1} notification={options.webNotification} />
        );
        webNotificationOptions.anchorOrigin = { horizontal: 'right', vertical: 'bottom' };
      }

      // display snackbar using notistack
      enqueueSnackbar(message, {
        key,
        autoHideDuration: 5000,
        preventDuplicate: true,
        ...options,
        ...webNotificationOptions,
        onClose: (event, reason, myKey) => {
          if (options.onClose) {
            options.onClose(event, reason, myKey);
          }
        },
        onExited: (event, myKey) => {
          // remove this snackbar from redux store;
          dispatch(removeNotification(myKey));
          removeDisplayed(myKey);
        },
      });

      // keep track of snackbars that we've displayed
      storeDisplayed(key);
    });
  }, [dispatch, inAppAlerts, displayed, enqueueSnackbar, closeSnackbar]);

  return null;
}

export default MCInAppAlertConsumer;
