import { combineReducers } from 'redux';
import preferencesReducer from './preferences/reducers';
import deliveriesReducer from './deliveries/reducers';

const notificationReducer = combineReducers({
  deliveries: deliveriesReducer,
  preferences: preferencesReducer,
});

export default notificationReducer;
