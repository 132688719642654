import produce from 'immer';
import _ from 'lodash';

import {
  LIST_USERS_SUCCESS,
  LIST_USERS,
  LIST_USERS_ERROR,
  GET_USER_SUCCESS,
  GET_USER_ERROR,
  GET_USER,
  SAVE_USER,
  SAVE_USER_SUCCESS,
  SAVE_USER_ERROR,
  DELETE_USER,
  DELETE_USER_SUCCESS,
  DELETE_USER_ERROR,
} from './types';

export const INITIAL_STATE = {
  loading: false,
  updating: false,
  loadError: null,
  updateError: null,
  entities: {},
};

function usersReducer(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case LIST_USERS: {
        draft.loading = true;
        draft.loadError = null;
        break;
      }
      case LIST_USERS_SUCCESS: {
        const { users } = action.payload;

        draft.loading = false;
        draft.entities = _.keyBy(users, 'id');
        break;
      }
      case LIST_USERS_ERROR: {
        draft.loading = false;
        draft.loadError = action.error;
        draft.entities = [];
        break;
      }
      case GET_USER: {
        draft.loading = true;
        draft.loadError = null;
        break;
      }
      case GET_USER_SUCCESS: {
        const { user } = action.payload;

        draft.loading = false;
        _.set(draft.entities, [user.id], user);
        break;
      }
      case GET_USER_ERROR: {
        draft.loading = false;
        draft.loadError = action.error;
        break;
      }
      case SAVE_USER: {
        const { user } = action.payload;

        draft.updating = true;
        draft.updateError = null;

        if (user && user.id) {
          draft.entities[user.id] = {
            ...draft.entities[user.id],
            updating: true,
          };

          // TODO: Figure out why below line doesn't make a new cloned state but just change the property value.
          // draft.entities[advertiserId][productId][campaignId][adGroupId].updating = true;
        }
        break;
      }
      case SAVE_USER_SUCCESS: {
        const { user } = action.payload;

        draft.updating = false;
        _.set(draft.entities, [user.id], user);
        break;
      }
      case SAVE_USER_ERROR: {
        draft.updating = false;
        draft.updateError = action.error;
        break;
      }
      case DELETE_USER: {
        draft.updating = true;
        draft.updateError = null;
        break;
      }
      case DELETE_USER_SUCCESS: {
        const { userId } = action.payload;

        draft.updating = false;
        if (userId) {
          delete draft.entities[userId];
        }

        break;
      }
      case DELETE_USER_ERROR: {
        draft.updating = false;
        draft.updateError = action.error;
        break;
      }
      default:
        break;
    }
  });
}

export default usersReducer;
