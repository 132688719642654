import produce from 'immer';
import _ from 'lodash';

import { MINotificationPreferencesState } from 'redux/types';
import {
  LIST_NOTIFICATION_PREFERENCES_REQUEST,
  LIST_NOTIFICATION_PREFERENCES_SUCCESS,
  LIST_NOTIFICATION_PREFERENCES_ERROR,
  SAVE_NOTIFICATION_PREFERENCES_REQUEST,
  SAVE_NOTIFICATION_PREFERENCES_SUCCESS,
  SAVE_NOTIFICATION_PREFERENCES_ERROR,
  MINotificationPreferencesAction,
  MIListNotificationPreferencesSuccessAction,
  MIListNotificationPreferencesErrorAction,
  MISaveNotificationPreferencesSuccessAction,
  MISaveNotificationPreferencesErrorAction,
} from './types';

export const INITIAL_STATE: MINotificationPreferencesState = {
  entities: {},
  templates: [],
  loading: false,
  updating: false,
  loadError: undefined,
  updateError: undefined,
};

function preferencesReducer(state = INITIAL_STATE, action: MINotificationPreferencesAction) {
  return produce(state, (draft) => {
    switch (action.type) {
      case LIST_NOTIFICATION_PREFERENCES_REQUEST: {
        draft.loadError = undefined;
        draft.loading = true;
        break;
      }
      case LIST_NOTIFICATION_PREFERENCES_SUCCESS: {
        const {
          preferences,
          templates,
        } = (action as MIListNotificationPreferencesSuccessAction).payload;
        const keyMap = _.keyBy(preferences, 'adAccountId');

        draft.loading = false;
        draft.entities = keyMap;
        draft.templates = templates;
        break;
      }
      case LIST_NOTIFICATION_PREFERENCES_ERROR: {
        const { error } = (action as MIListNotificationPreferencesErrorAction).payload;
        draft.loadError = error;
        draft.loading = false;
        break;
      }
      case SAVE_NOTIFICATION_PREFERENCES_REQUEST: {
        draft.updateError = undefined;
        draft.updating = true;
        break;
      }
      case SAVE_NOTIFICATION_PREFERENCES_SUCCESS: {
        const { preferences } = (action as MISaveNotificationPreferencesSuccessAction).payload;
        _.forEach(preferences, (preference) => {
          _.set(draft.entities, [preference.adAccountId], preference);
        });

        draft.updating = false;
        break;
      }
      case SAVE_NOTIFICATION_PREFERENCES_ERROR: {
        const { error } = (action as MISaveNotificationPreferencesErrorAction).payload;
        draft.updateError = error;
        draft.updating = false;
        break;
      }
      default:
        break;
    }
  });
}

export default preferencesReducer;
