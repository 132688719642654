export const setLocalCacheObject = (key, obj) => {
  localStorage.setItem(key, JSON.stringify(obj));
};

export const getLocalCacheObject = (key) => {
  const cachedStr = localStorage.getItem(key);
  if (cachedStr === 'undefined') return null;
  return JSON.parse(cachedStr);
};

export const removeLocalCacheObject = (key) => {
  localStorage.removeItem(key);
};

export const setSessionCacheObject = (key, obj) => {
  sessionStorage.setItem(key, JSON.stringify(obj));
};

export const getSessionCacheObject = (key) => {
  const cachedStr = sessionStorage.getItem(key);
  if (cachedStr === 'undefined') return null;
  return JSON.parse(cachedStr);
};

export const removeSessionCacheObject = (key) => {
  sessionStorage.removeItem(key);
};
