import _ from 'lodash';
import moment from 'moment';
import { isValidIDFA } from './index';

// toUIObjectDefaultHelper gets the parameter 'proto' which is a piece of any type from proto and then converts it into UIObject without Class.
export const toUIObjectDefaultHelper = (proto, nonProtoMessages) => {
  if (!_.isObjectLike(proto)) {
    // The parameter 'proto' is neither a plain object, or array type. ex) number, string
    return proto;
  }
  if (_.isArray(proto)) {
    // array type.
    return proto.map((elem) => toUIObjectDefaultHelper(elem, nonProtoMessages));
  }

  // The parameter 'proto' is a plain object type.
  const newUIObject = {};
  // eslint-disable-next-line guard-for-in,no-restricted-syntax
  for (const key in proto) {
    const newKey = _.camelCase(key); // Use camel case key for UIObject.
    if (!nonProtoMessages || !nonProtoMessages.includes(_.camelCase(key))) {
      newUIObject[newKey] = toUIObjectDefaultHelper(proto[key], nonProtoMessages);
    } else {
      newUIObject[newKey] = _.cloneDeep(proto[key]);
    }
  }
  newUIObject.proto = proto; // Store the original proto in the proto field not to lose any fields in the original proto.

  return newUIObject;
};

// fromUIObjectDefaultHelper gets the parameter 'uiObject' which is a piece of any type from UIObject and then converts it into a proto.
export const fromUIObjectDefaultHelper = (uiObject, nonProtoMessages) => {
  if (!_.isObjectLike(uiObject)) {
    // The parameter 'uiObject' is neither a plain object, or array type. ex) number, string
    return uiObject;
  }
  if (_.isArray(uiObject)) {
    // array type.
    return uiObject.map((elem) => fromUIObjectDefaultHelper(elem, nonProtoMessages));
  }

  // The parameter 'uiObject' is a plain object type.
  const newProto = {};
  // eslint-disable-next-line guard-for-in,no-restricted-syntax
  for (const key in uiObject) {
    const newKey = _.snakeCase(key); // Use snake case for proto.
    if (!nonProtoMessages || !nonProtoMessages.includes(key)) {
      newProto[newKey] = fromUIObjectDefaultHelper(uiObject[key], nonProtoMessages);
    } else {
      newProto[newKey] = _.cloneDeep(uiObject[key]);
    }
  }
  delete newProto.proto;

  if (!_.isEmpty(uiObject.proto)) {
    // Update the original proto not to lose any field in the original proto.
    const originalProto = _.cloneDeep(uiObject.proto);

    // eslint-disable-next-line guard-for-in,no-restricted-syntax
    for (const key in newProto) {
      originalProto[key] = newProto[key];
    }

    return originalProto;
  }
  // A uiObject doesn't have the proto field if it's newly created.
  return newProto;
};

export const toUIObjectDefault = (proto, Class) => {
  return new Class(toUIObjectDefaultHelper(proto, Class.nonProtoMessages));
};

// toUIObject automatically detects whether the Class has its own customized toUIObject otherwise uses toUIObjectDefault.
export const toUIObject = (proto, Class) => {
  if (_.has(Class, 'toUIObject')) {
    return Class.toUIObject(proto);
  }
  return toUIObjectDefault(proto, Class);
};

export const fromUIObjectDefault = (uiObject, Class) => {
  return fromUIObjectDefaultHelper(new Class(uiObject), Class.nonProtoMessages);
};

// fromUIObject automatically detects whether the Class has its own customized fromUIObject otherwise uses fromUIObjectDefault.
export const fromUIObject = (object, Class) => {
  if (_.has(Class, 'fromUIObject')) {
    return Class.fromUIObject(object);
  }
  return fromUIObjectDefault(object, Class);
};

export const Validators = {
  required: (value) => (_.isEmpty(value) && !_.isNumber(value) ? 'Required.' : ''),
  mapRequired: (paths, condition) => (valueMap) => {
    let isEmpty = true;
    let isFull = true;
    paths.forEach((path) => {
      const value = _.get(valueMap, [path], null);
      if (_.isEmpty(value) && !_.isNumber(value)) {
        isFull = false;
      } else {
        isEmpty = false;
      }
    });

    if (isEmpty || (condition === 'all' && !isFull)) {
      return 'Required.';
    }
    return '';
  },
  number: (value) => (value && !_.isNumber(value) ? 'Only number is allowed.' : ''),
  positiveNumber: (value) => (value > 0 ? '' : 'Only positive number is allowed.'),
  zeroOrPositiveNumber: (value) => (value >= 0 ? '' : 'Only 0 or positive numbers are allowed.'),
  alphaNumericHyphen: (value) =>
    /^[a-zA-Z0-9_-]*$/.test(value) ? '' : 'Invalid. Only alphabet, numbers and -, _ are allowed.',
  lowercaseAlphaNumeric: (value) =>
    /^[a-z0-9_-]+$/.test(value)
      ? ''
      : 'Invalid. Only lowercase alphabet, numbers and -, _ are allowed.',
  noSpecialChar: (value) =>
    // eslint-disable-next-line no-useless-escape
    /[~`!#$%\^&*+=\[\]\\';,/{}|\\":<>\?]/g.test(value)
      ? 'Invalid. Special characters are not allowed.'
      : '',
  advertiserDomain: (value) =>
    /^(?!(:\/\/|www\.))([a-z0-9]+(-[a-z0-9]+)*\.)+[a-z]{2,}$/.test(value)
      ? ''
      : `Please don't include 'http' or 'https' protocol and hostname such as 'www.'.`,
  iosBundleId: (value) =>
    /^[0-9]{9,10}$/i.test(value) ? '' : 'Only numeric value with 9-10 digits is allowed.',
  androidBundleId: (storeUrl) => {
    if (storeUrl) {
      if (storeUrl.includes('onestore.co.kr')) {
        return (value) => {
          return /^[0-9]{9,11}$/i.test(value)
            ? ''
            : 'Only numeric value with 9-11 digits is allowed.';
        };
      }
      if (storeUrl.includes('galaxystore.samsung.com')) {
        return (value) => {
          return /^[a-z]+(\.[a-z_0-9]+)+$/i.test(value)
            ? ''
            : 'Invalid Galaxy store app bundle format.';
        };
      }
    }

    return (value) => {
      return /^[a-z][a-z0-9_]*(\.[a-z0-9_]+)+$/i.test(value) ? '' : 'Invalid android bundle id.';
    };
  },
  emailAddress: (value) =>
    // eslint-disable-next-line no-useless-escape,no-control-regex
    /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/.test(
      value,
    )
      ? ''
      : 'Invalid email address format.',
  oneOf: (validValues) => {
    if (!_.isArray(validValues)) {
      throw new Error('No list values are given.');
    }
    return (value) =>
      _.indexOf(validValues, value) >= 0
        ? ''
        : `Value should be one of ${validValues.join(',')} given ${value}.`;
  },
  minDate: (minDate, errorMessage) => {
    const baseDate = moment(minDate);

    return (value) => {
      const setDate = moment(value);
      return setDate.isBefore(baseDate) ? errorMessage : '';
    };
  },
  maxTextLength: (maxCharacterNumber) => {
    return (value) => {
      return value && value.length > maxCharacterNumber
        ? `The number of characters cannot exceed ${maxCharacterNumber}.`
        : '';
    };
  },
  idfa: (input) => (isValidIDFA(input) ? '' : `Invalid ADID or IDFA format.`),
  hasValueOfField: (fieldName) => {
    return (value) => {
      let errorMessage = '';
      if (!_.isEmpty(value)) {
        value.forEach((v) => {
          if (!_.isEmpty(_.get(v, fieldName, ''))) {
            errorMessage = `${fieldName} has value`;
          }
        });
      }
      return errorMessage;
    };
  },
  inRange: (min, max) => {
    return (value) => {
      return value >= min && value <= max ? '' : `Only ${min} to ${max} are allowed.`;
    };
  },
};

// validateUIObject validates 'object' according to 'validationSchema' recursively.
// Assume the parameter 'validationSchema' is always a plain object.
export const validateUIObject = (object, validationSchema) => {
  const errors = {};

  // eslint-disable-next-line guard-for-in,no-restricted-syntax
  for (const key in validationSchema) {
    // validationSchema[key] is either a plain object or array.
    if (_.isPlainObject(validationSchema[key])) {
      // It is a plain object when it has its own validationSchema.
      const childErrors = validateUIObject(object[key], validationSchema[key]);
      if (!_.isEmpty(childErrors)) {
        errors[key] = childErrors;
      }
      // eslint-disable-next-line no-continue
      continue;
    }

    // eslint-disable-next-line no-restricted-syntax
    for (const validator of validationSchema[key]) {
      if (_.isFunction(validator)) {
        const result = validator(object[key]);
        if (!_.isEmpty(result)) {
          errors[key] = result;
        }
      }
    }
  }

  return errors;
};

export const validate = (object, Class) => {
  return validateUIObject(object, Class.getValidationSchema(object));
};
