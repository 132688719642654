/*
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here. We prefix them with 'componentName' so we avoid
 * reducers accidentally picking up actions they shouldn't.
 *
 * Follow this format:
 * export const YOUR_ACTION_CONSTANT = 'YourContainer/YOUR_ACTION_CONSTANT';
 */

import { ACTION_TYPE_CAMPAIGN_MANAGER } from '../consts.ts';

export const ACTION_TYPE_CREATIVE_GROUP = 'creative_group';

export const LIST_CREATIVE_GROUP_REQUEST = `${ACTION_TYPE_CAMPAIGN_MANAGER}/${ACTION_TYPE_CREATIVE_GROUP}/LIST_CREATIVE_GROUP_REQUEST`;
export const LIST_CREATIVE_GROUP_SUCCESS = `${ACTION_TYPE_CAMPAIGN_MANAGER}/${ACTION_TYPE_CREATIVE_GROUP}/LIST_CREATIVE_GROUP_SUCCESS`;
export const LIST_CREATIVE_GROUP_ERROR = `${ACTION_TYPE_CAMPAIGN_MANAGER}/${ACTION_TYPE_CREATIVE_GROUP}/LIST_CREATIVE_GROUP_ERROR`;

export const GET_CREATIVE_GROUP_REQUEST = `${ACTION_TYPE_CAMPAIGN_MANAGER}/${ACTION_TYPE_CREATIVE_GROUP}/GET_CREATIVE_GROUP_REQUEST`;
export const GET_CREATIVE_GROUP_SUCCESS = `${ACTION_TYPE_CAMPAIGN_MANAGER}/${ACTION_TYPE_CREATIVE_GROUP}/GET_CREATIVE_GROUP_SUCCESS`;
export const GET_CREATIVE_GROUP_ERROR = `${ACTION_TYPE_CAMPAIGN_MANAGER}/${ACTION_TYPE_CREATIVE_GROUP}/GET_CREATIVE_GROUP_ERROR`;

export const SAVE_CREATIVE_GROUP_REQUEST = `${ACTION_TYPE_CAMPAIGN_MANAGER}/${ACTION_TYPE_CREATIVE_GROUP}/SAVE_CREATIVE_GROUP_REQUEST`;
export const SAVE_CREATIVE_GROUP_SUCCESS = `${ACTION_TYPE_CAMPAIGN_MANAGER}/${ACTION_TYPE_CREATIVE_GROUP}/SAVE_CREATIVE_GROUP_SUCCESS`;
export const SAVE_CREATIVE_GROUP_ERROR = `${ACTION_TYPE_CAMPAIGN_MANAGER}/${ACTION_TYPE_CREATIVE_GROUP}/SAVE_CREATIVE_GROUP_ERROR`;

export const BULK_SAVE_CREATIVE_GROUP_REQUEST = `${ACTION_TYPE_CAMPAIGN_MANAGER}/${ACTION_TYPE_CREATIVE_GROUP}/BULK_SAVE_CREATIVE_GROUP_REQUEST`;
export const BULK_SAVE_CREATIVE_GROUP_SUCCESS = `${ACTION_TYPE_CAMPAIGN_MANAGER}/${ACTION_TYPE_CREATIVE_GROUP}/BULK_SAVE_CREATIVE_GROUP_SUCCESS`;
export const BULK_SAVE_CREATIVE_GROUP_ERROR = `${ACTION_TYPE_CAMPAIGN_MANAGER}/${ACTION_TYPE_CREATIVE_GROUP}/BULK_SAVE_CREATIVE_GROUP_ERROR`;
