import axios from 'axios';

function getGCSUploadUrl(signedUrl, mimeType) {
  const config = {
    method: 'POST',
    url: signedUrl,
    data: null,
    headers: {
      'Content-Length': '0',
      'Content-Type': mimeType,
      'x-goog-resumable': 'start',
    },
  };

  return axios(config);
}

function updateFileToGCS(uploadUrl, file, mimeType) {
  const config = {
    method: 'PUT',
    url: uploadUrl,
    data: file,
    headers: {
      'content-type': mimeType,
    },
  };

  return axios(config);
}

function updateChunkedFileToGCS(uploadUrl, dataChunk, mimeType, offset, bytesToWrite, totalBytes) {
  const config = {
    method: 'PUT',
    url: uploadUrl,
    data: dataChunk,
    headers: {
      'content-type': mimeType,
      'content-range': `bytes ${offset}-${offset + bytesToWrite - 1}/${totalBytes}`,
    },
  };

  return axios(config);
}

export default {
  getGCSUploadUrl,
  updateFileToGCS,
  updateChunkedFileToGCS,
};
