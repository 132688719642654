import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MUIBadge from '@material-ui/core/Badge';

import generatePathForRoute from 'common-v2/route/generatePathForRoute';
import { MERouteKeys } from 'common-v2/route/consts';
import NotificationDelivery from 'common-v2/model/notificationDelivery';

import MCWebNotificationPanel from './MCWebNotificationPanel';
import { RootState } from '../../../redux/types';
import { batchUpdateNotificationDeliveriesReadStatus } from '../../../redux/notification/deliveries/actions';

const SCNotificationControlWrapper = styled.div`
  position: relative;
`;

const SCBellWrapper = styled.div`
  margin-right: ${(props) => props.theme.spacing(2)}px;
  cursor: pointer;
`;

const SCBellIcon = styled(FontAwesomeIcon)<{ active: boolean }>`
  color: ${(props) => (props.active ? '#4e7fed' : '#929292')};
  font-size: 18px;
  pointer-events: none;
`;

// eslint-disable-next-line react/jsx-props-no-spreading
const SCBadge = styled(({ ...rest }) => <MUIBadge {...rest} />)`
  display: block;

  & > span {
    background-color: #ff0000;
    font-size: 9px;
    height: 13px;
    min-width: 13px;
    border-radius: 6.5px;
    padding: 0 4px;
  }
`;

function MCWebNotificationControl() {
  const history = useHistory();
  const match = useRouteMatch();

  const wrapperRef = React.useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState<boolean>(false);

  const dispatch = useDispatch();

  const deliveries = useSelector((state: RootState) =>
    _.values(state.notification.deliveries.entities)
      .sort((a, b) => b.receivedAtTimestamp - a.receivedAtTimestamp)
      .slice(0, 300),
  );

  const numberOfUnreadDeliveries = deliveries.filter(
    (delivery: NotificationDelivery) => !delivery.readFlag,
  ).length;

  const readAll = useCallback(() => {
    dispatch(
      batchUpdateNotificationDeliveriesReadStatus(() => {
        setOpen(false);
      }),
    );
  }, [dispatch]);

  const handleClickSetting = () => {
    readAll();
    history.push(generatePathForRoute(MERouteKeys.USER_SETTINGS_NOTIFICATION, match.params));
  };

  const handleTogglePanel = () => {
    if (open) {
      readAll();
    } else {
      setOpen(true);
    }
  };

  // close popper when click outside
  useEffect(() => {
    const clickOutside = (e: any) => {
      if (!open) return;

      const wrapper = wrapperRef.current;

      if (wrapper && !wrapper.contains(e.target)) {
        readAll();
      }
    };

    document.addEventListener('mousedown', clickOutside);
    return () => {
      document.removeEventListener('mousedown', clickOutside);
    };
  }, [open, readAll]);

  return (
    <SCNotificationControlWrapper ref={wrapperRef}>
      <SCBellWrapper>
        <SCBadge
          badgeContent={numberOfUnreadDeliveries}
          onClick={handleTogglePanel}
          max={999}
          color="secondary"
        >
          <SCBellIcon active={numberOfUnreadDeliveries > 0} icon={['fas', 'bell']} />
        </SCBadge>
      </SCBellWrapper>
      {open && (
        <MCWebNotificationPanel
          open={open}
          deliveries={deliveries}
          onClickSetting={handleClickSetting}
        />
      )}
    </SCNotificationControlWrapper>
  );
}

export default MCWebNotificationControl;
