/*
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here. We prefix them with 'componentName' so we avoid
 * reducers accidentally picking up actions they shouldn't.
 *
 * Follow this format:
 * export const YOUR_ACTION_CONSTANT = 'YourContainer/YOUR_ACTION_CONSTANT';
 */

import { ACTION_TYPE_CAMPAIGN_MANAGER } from '../consts.ts';

export const ACTION_TYPE_AD_GROUP = 'ad_group';

export const LIST_AD_GROUP_REQUEST = `${ACTION_TYPE_CAMPAIGN_MANAGER}/${ACTION_TYPE_AD_GROUP}/LIST_AD_GROUP_REQUEST`;
export const LIST_AD_GROUP_SUCCESS = `${ACTION_TYPE_CAMPAIGN_MANAGER}/${ACTION_TYPE_AD_GROUP}/LIST_AD_GROUP_SUCCESS`;
export const LIST_AD_GROUP_ERROR = `${ACTION_TYPE_CAMPAIGN_MANAGER}/${ACTION_TYPE_AD_GROUP}/LIST_AD_GROUP_ERROR`;

export const GET_AD_GROUP_REQUEST = `${ACTION_TYPE_CAMPAIGN_MANAGER}/${ACTION_TYPE_AD_GROUP}/GET_AD_GROUP_REQUEST`;
export const GET_AD_GROUP_SUCCESS = `${ACTION_TYPE_CAMPAIGN_MANAGER}/${ACTION_TYPE_AD_GROUP}/GET_AD_GROUP_SUCCESS`;
export const GET_AD_GROUP_ERROR = `${ACTION_TYPE_CAMPAIGN_MANAGER}/${ACTION_TYPE_AD_GROUP}/GET_AD_GROUP_ERROR`;

export const SAVE_AD_GROUP_REQUEST = `${ACTION_TYPE_CAMPAIGN_MANAGER}/${ACTION_TYPE_AD_GROUP}/SAVE_AD_GROUP_REQUEST`;
export const SAVE_AD_GROUP_SUCCESS = `${ACTION_TYPE_CAMPAIGN_MANAGER}/${ACTION_TYPE_AD_GROUP}/SAVE_AD_GROUP_SUCCESS`;
export const SAVE_AD_GROUP_ERROR = `${ACTION_TYPE_CAMPAIGN_MANAGER}/${ACTION_TYPE_AD_GROUP}/SAVE_AD_GROUP_ERROR`;

export const DELETE_AD_GROUP_REQUEST = `${ACTION_TYPE_CAMPAIGN_MANAGER}/${ACTION_TYPE_AD_GROUP}/DELETE_AD_GROUP_REQUEST`;
export const DELETE_AD_GROUP_SUCCESS = `${ACTION_TYPE_CAMPAIGN_MANAGER}/${ACTION_TYPE_AD_GROUP}/DELETE_AD_GROUP_SUCCESS`;
export const DELETE_AD_GROUP_ERROR = `${ACTION_TYPE_CAMPAIGN_MANAGER}/${ACTION_TYPE_AD_GROUP}/DELETE_AD_GROUP_ERROR`;
