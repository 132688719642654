import produce from 'immer';
import _ from 'lodash';

import {
  LIST_AD_GROUP_SUCCESS,
  LIST_AD_GROUP_REQUEST,
  LIST_AD_GROUP_ERROR,
  GET_AD_GROUP_SUCCESS,
  GET_AD_GROUP_ERROR,
  GET_AD_GROUP_REQUEST,
  SAVE_AD_GROUP_REQUEST,
  SAVE_AD_GROUP_SUCCESS,
  SAVE_AD_GROUP_ERROR,
  DELETE_AD_GROUP_REQUEST,
  DELETE_AD_GROUP_SUCCESS,
  DELETE_AD_GROUP_ERROR,
} from './types';

// The initial state of the Ad Groups.
export const initialState = {
  loading: false,
  updating: false,
  loadError: null,
  updateError: null,
  lastListRequestTs: {},
  lastEntityRequestTs: {},
  entities: {},
};

function adGroupsReducer(state = initialState, action) {
  // eslint-disable-next-line consistent-return
  return produce(state, (draft) => {
    // eslint-disable-next-line default-case
    switch (action.type) {
      case LIST_AD_GROUP_REQUEST: {
        const { advertiserId, productId, campaignId } = action.payload;

        draft.loading = true;
        draft.loadError = null;
        _.set(draft.lastListRequestTs, [advertiserId, productId, campaignId], Date.now());
        break;
      }
      case LIST_AD_GROUP_SUCCESS: {
        const { advertiserId, productId, campaignId, adGroups } = action.payload;

        draft.loading = false;
        _.set(draft.entities, [advertiserId, productId, campaignId], _.keyBy(adGroups, 'id'));
        break;
      }
      case LIST_AD_GROUP_ERROR: {
        const { error } = action.payload;

        draft.loading = false;
        draft.loadError = error;
        break;
      }
      case GET_AD_GROUP_REQUEST: {
        const { advertiserId, productId, campaignId, adGroupId } = action.payload;

        draft.loading = true;
        draft.loadError = null;
        _.set(
          draft.lastEntityRequestTs,
          [advertiserId, productId, campaignId, adGroupId],
          Date.now(),
        );
        break;
      }
      case GET_AD_GROUP_SUCCESS: {
        const { advertiserId, productId, campaignId, adGroup } = action.payload;

        draft.loading = false;
        _.set(draft.entities, [advertiserId, productId, campaignId, adGroup.id], adGroup);
        break;
      }
      case GET_AD_GROUP_ERROR: {
        const { error } = action.payload;

        draft.loading = false;
        draft.loadError = error;
        break;
      }
      case SAVE_AD_GROUP_REQUEST: {
        draft.updating = true;
        draft.updateError = null;
        break;
      }
      case SAVE_AD_GROUP_SUCCESS: {
        const { advertiserId, productId, campaignId, adGroup } = action.payload;

        draft.updating = false;
        _.set(draft.entities, [advertiserId, productId, campaignId, adGroup.id], adGroup);
        break;
      }
      case SAVE_AD_GROUP_ERROR: {
        const { error } = action.payload;

        draft.updating = false;
        draft.updateError = error;
        break;
      }
      case DELETE_AD_GROUP_REQUEST: {
        draft.updating = true;
        draft.updateError = null;
        break;
      }
      case DELETE_AD_GROUP_SUCCESS: {
        const { advertiserId, productId, campaignId, adGroupId } = action.payload;

        draft.updating = false;
        _.unset(draft.entities, [advertiserId, productId, campaignId, adGroupId]);
        break;
      }
      case DELETE_AD_GROUP_ERROR: {
        const { error } = action.payload;

        draft.updating = false;
        draft.updateError = error;
        break;
      }
      default:
        break;
    }
  });
}

export default adGroupsReducer;
