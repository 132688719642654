import React from 'react';
import styled from 'styled-components';
import MUICircularProgress from '@material-ui/core/CircularProgress';

const SCWrapper = styled.div<{ fillParent?: boolean; horizontalAlign?: string }>`
  display: flex;
  align-items: ${(props) => props.horizontalAlign || 'center'};
  justify-content: center;
  flex-direction: column;
  ${(props) =>
    props.fillParent &&
    `
    height: 100%;
    width: 100%;
  `}
`;

const SCCircularProgressWrapper = styled.div`
  color: ${(props) => props.color};
`;

const SCMessage = styled.div`
  padding: 5px;
`;

type MTLoaderProps = {
  color?: string;
  message?: string | React.ReactNode;
  size?: number;
  className?: string;
  fillParent?: boolean;
  horizontalAlign?: 'flex-start' | 'flex-end' | 'center' | 'baseline' | 'stretch';
};

const MCLoader: React.FunctionComponent<MTLoaderProps> = (props: MTLoaderProps) => {
  const { color, message, size, className, fillParent, horizontalAlign } = props;

  return (
    <SCWrapper
      data-testid="loader"
      className={className}
      fillParent={fillParent}
      horizontalAlign={horizontalAlign}
    >
      <SCCircularProgressWrapper color={color}>
        <MUICircularProgress size={size} />
      </SCCircularProgressWrapper>
      {message && <SCMessage>{message}</SCMessage>}
    </SCWrapper>
  );
};

MCLoader.defaultProps = {
  color: '#4da5ef',
  message: '',
  size: 32,
};

export default MCLoader;
