import produce from 'immer';
import _ from 'lodash';

import {
  CHANGE_EXCHANGE,
  LIST_EXCHANGES,
  LIST_EXCHANGES_ERROR,
  LIST_EXCHANGES_SUCCESS,
  SAVE_EXCHANGE,
  SAVE_EXCHANGE_ERROR,
  SAVE_EXCHANGE_SUCCESS,
} from './types';
import { Exchange } from '../../../common/model/exchange';

const initState = {
  loading: false,
  updating: false,
  loadError: null,
  updateError: null,
  lastListRequestTs: 0,
  /*
    exchanges: {
      "exchange_id1": {
        "id": ...,
        "title": ...,
        "description": ...,
      }
    }
   */
  entities: {},
  currentExchange: null,
};

function exchangesReducer(state = initState, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case CHANGE_EXCHANGE: {
        draft.currentExchange = action.payload;
        break;
      }
      case LIST_EXCHANGES: {
        draft.loading = true;
        draft.loadError = null;
        draft.lastListRequestTs = Date.now();
        break;
      }
      case LIST_EXCHANGES_SUCCESS: {
        draft.entities = _.keyBy(action.payload.exchanges, (exchange) => exchange.id);

        if (_.isEmpty(action.payload.exchanges)) {
          draft.currentExchange = null;
        } else if (_.isEmpty(draft.currentExchange)) {
          if (
            action.payload.cachedExchange &&
            _.find(action.payload.exchanges, { id: action.payload.cachedExchange.id })
          ) {
            draft.currentExchange = _.find(action.payload.exchanges, {
              id: action.payload.cachedExchange.id,
            });
          } else {
            draft.currentExchange = new Exchange(action.payload.exchanges[0]);
          }
        } else if (!_.find(action.payload.exchanges, { id: draft.currentExchange.id })) {
          draft.currentExchange = new Exchange(action.payload.exchanges[0]);
        }

        draft.loading = false;

        break;
      }
      case LIST_EXCHANGES_ERROR: {
        draft.loading = false;
        draft.loadError = action.error;
        break;
      }
      case SAVE_EXCHANGE: {
        draft.updating = true;
        draft.updateError = null;
        break;
      }
      case SAVE_EXCHANGE_SUCCESS: {
        draft.updating = false;
        draft.entities[action.payload.id] = action.payload;
        draft.currentExchange = action.payload;
        break;
      }
      case SAVE_EXCHANGE_ERROR: {
        draft.updating = false;
        draft.updateError = action.error;
        break;
      }
      default: {
        break;
      }
    }
    return draft;
  });
}

export default exchangesReducer;
