import produce from 'immer';
import _ from 'lodash';
import {
  GET_PUBLISHER_REPORT,
  GET_PUBLISHER_REPORT_SUCCESS,
  GET_PUBLISHER_REPORT_ERROR,
} from './types';

const initState = {
  loading: false,
  loadError: null,
  /*
    publisherReports : {
      "publisher_id1": {
        "app_id1": {
            "groupBy": ...,
        }
      }
    }
   */
  entities: {},
};

function publisherReportsReducer(state = initState, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case GET_PUBLISHER_REPORT: {
        draft.loading = true;
        draft.loadError = null;
        break;
      }
      case GET_PUBLISHER_REPORT_SUCCESS: {
        draft.loading = false;
        _.set(draft.entities, action.payload.key, action.payload.uiPublisherReports);
        break;
      }
      case GET_PUBLISHER_REPORT_ERROR: {
        draft.loading = false;
        draft.loadError = action.error;
        break;
      }
      default: {
        break;
      }
    }
    return draft;
  });
}

export default publisherReportsReducer;
