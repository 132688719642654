import React from 'react';
import styled from 'styled-components';

import errorIcon from '../../../assets/imgs/error_boundary_icon.svg';

const SCWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #f0f0f0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SCErrorBox = styled.div`
  display: flex;
`;

const SCErrorMessage = styled.div`
  width: 450px;
  padding-right: 20px;
  color: #625f5f;

  & > h1 {
    font-size: 36px;
    font-weight: bold;
    margin: 0;
    padding: 0 0 16px 0;
  }

  & > p {
    font-size: 16px;
    padding: 0 0 16px 0;
  }
`;

const SCErrorIcon = styled.img`
  width: 250px;
  height: 250px;
`;

const SCButton = styled.a`
  font-size: 14px;
  font-weight: bold;
  border-radius: 2px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  padding: 8px 16px;
  color: #fff;
  background-color: #4e7fed;
  text-decoration: none;
  &:hover {
    outline: none;
    text-decoration: none;
    color: #fff;
  }
`;

// not using common components to minimmize dependency
const MCErrorScreen = () => {
  return (
    <SCWrapper>
      <SCErrorBox>
        <SCErrorMessage>
          <h1>Sorry, something went wrong.</h1>
          <p>
            The error has been reported to us.
            <br />
            Press the button below to go to the homepage and try again.
            <br />
            Please contact our support team if the problem persists
          </p>
          <SCButton href="/">Go to Homepage</SCButton>
        </SCErrorMessage>
        <SCErrorIcon src={errorIcon} alt="Error" />
      </SCErrorBox>
    </SCWrapper>
  );
};

export default MCErrorScreen;
