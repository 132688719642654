import produce from 'immer';
import { MEPollingState, MTProgressActionTypes } from './types';

export interface MIPollingState {
  intervalId: Record<string, number>;
  state: Record<string, MEPollingState>;
  response: Record<string, any>;
}

const initialState: MIPollingState = {
  intervalId: {},
  state: {},
  response: {},
};

function pollingReducer(state = initialState, action: MTProgressActionTypes) {
  return produce(state, (draft) => {
    const { type, payload } = action;

    switch (type) {
      case MEPollingState.POLLING_START: {
        // @ts-ignore
        const { id, intervalId } = payload;
        draft.state[id] = MEPollingState.POLLING_START;
        draft.intervalId[id] = intervalId;
        delete draft.response[id];
        break;
      }
      case MEPollingState.POLLING_FINISH: {
        const { id } = payload;
        draft.state[id] = MEPollingState.POLLING_FINISH;
        delete draft.response[id];
        delete draft.intervalId[id];
        break;
      }
      case MEPollingState.POLLING: {
        // @ts-ignore
        const { id, response } = payload;
        draft.state[id] = MEPollingState.POLLING;
        draft.response[id] = response;
        break;
      }
      default:
        break;
    }
  });
}

export default pollingReducer;
