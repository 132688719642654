export const FORM_WIDTH = '830px';

export enum MEFormState {
  COMPLETE = 'complete',
  EDITING = 'editing',
}

export enum MEFormFieldWidth {
  SMALL = 'small',
  MEDIUM = 'medium',
  FULL = 'full',
  UNSET = 'unset',
}
