import produce from 'immer';
import _ from 'lodash';

import {
  LIST_LOG,
  LIST_LOG_SUCCESS,
  LIST_LOG_ERROR,
  GET_LOG_STATUS,
  GET_LOG_STATUS_SUCCESS,
  GET_LOG_STATUS_ERROR,
  SAVE_LOG,
  SAVE_LOG_SUCCESS,
  SAVE_LOG_ERROR,
  LIST_LOG_TRANSFER_REQUEST,
  LIST_LOG_TRANSFER_SUCCESS,
  SAVE_LOG_TRANSFER_SUCCESS,
  DELETE_LOG_TRANSFER_SUCCESS,
} from './types';

// The initial state of the Log exports.
export const initialState = {
  loading: false,
  updating: false,
  loadError: null,
  updateError: null,
  lastListRequestTs: 0,
  logs: [],
  logStatusEntities: {},
  logTransfers: [],
};

function logDataReducer(state = initialState, action) {
  // eslint-disable-next-line consistent-return
  return produce(state, (draft) => {
    // eslint-disable-next-line default-case
    switch (action.type) {
      case LIST_LOG: {
        draft.loading = true;
        draft.loadError = null;
        draft.lastListRequestTs = Date.now();
        break;
      }
      case LIST_LOG_SUCCESS: {
        const { logs } = action.payload;

        draft.loading = false;
        _.set(draft, 'logs', logs);
        break;
      }
      case LIST_LOG_ERROR: {
        draft.loading = false;
        draft.loadError = action.error;
        break;
      }
      case GET_LOG_STATUS: {
        draft.loading = true;
        draft.loadError = null;
        break;
      }
      case GET_LOG_STATUS_SUCCESS: {
        const { logId, logStatus } = action.payload;

        draft.loading = false;
        _.set(draft.logStatusEntities, logId, logStatus);
        break;
      }
      case GET_LOG_STATUS_ERROR: {
        draft.loading = false;
        draft.loadError = action.error;
        break;
      }
      case SAVE_LOG: {
        draft.updating = true;
        draft.updateError = null;
        break;
      }
      case SAVE_LOG_SUCCESS: {
        const { logId, logType, logDate, logStatus } = action.payload;
        const logs = _.cloneDeep(state.logs);

        draft.updating = false;

        const index = _.findIndex(logs, { id: logId });

        if (index === -1) {
          logs.push({
            id: logId,
            type: logType,
            date: logDate,
            status: logStatus,
          });
        } else {
          logs[index].status = logStatus;
        }
        draft.logs = logs;
        break;
      }
      case SAVE_LOG_ERROR: {
        draft.updating = false;
        draft.updateError = action.error;
        break;
      }
      case LIST_LOG_TRANSFER_REQUEST: {
        draft.lastListRequestTs = Date.now();
        break;
      }
      case LIST_LOG_TRANSFER_SUCCESS: {
        const { logTransfers } = action.payload;

        _.set(draft, 'logTransfers', logTransfers);
        break;
      }
      case SAVE_LOG_TRANSFER_SUCCESS: {
        const { logTransfers } = action.payload;
        _.set(draft, 'logTransfers', logTransfers);
        break;
      }
      case DELETE_LOG_TRANSFER_SUCCESS: {
        _.unset(draft, 'logTransfers');
        break;
      }
      default:
        break;
    }
  });
}

export default logDataReducer;
