import produce from 'immer';
import _ from 'lodash';
import {
  LIST_PUBLISHER_APP,
  LIST_PUBLISHER_APP_SUCCESS,
  LIST_PUBLISHER_APP_ERROR,
  SAVE_PUBLISHER_APP,
  SAVE_PUBLISHER_APP_SUCCESS,
  SAVE_PUBLISHER_APP_ERROR,
  DELETE_PUBLISHER_APP,
  DELETE_PUBLISHER_APP_SUCCESS,
  DELETE_PUBLISHER_APP_ERROR,
  GET_PUBLISHER_APP,
  GET_PUBLISHER_APP_SUCCESS,
  GET_PUBLISHER_APP_ERROR,
} from './types';

const initState = {
  loading: false,
  updating: false,
  loadError: null,
  updateError: null,
  lastListRequestTs: {},
  lastEntityRequestTs: {},
  /*
    publisherApps : {
      "publisher_id1": {
        "app_id1": {
            "id": ...,
            "title": ...,
        }
      }
    }
   */
  entities: {},
};

function publisherAppsReducer(state = initState, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case LIST_PUBLISHER_APP: {
        const { publisherId } = action.payload;

        draft.loading = true;
        draft.loadError = null;
        _.set(draft.lastListRequestTs, [publisherId], Date.now());
        break;
      }
      case LIST_PUBLISHER_APP_SUCCESS: {
        const { publisherId, publisherApps } = action.payload;

        draft.loading = false;
        _.set(
          draft.entities,
          publisherId,
          _.keyBy(publisherApps, (publisherApp) => publisherApp.id),
        );
        break;
      }
      case LIST_PUBLISHER_APP_ERROR: {
        draft.loading = false;
        draft.loadError = action.error;
        break;
      }
      case SAVE_PUBLISHER_APP: {
        draft.updating = true;
        draft.updateError = null;
        break;
      }
      case SAVE_PUBLISHER_APP_ERROR: {
        draft.updating = false;
        draft.updateError = action.error;
        break;
      }
      case SAVE_PUBLISHER_APP_SUCCESS: {
        draft.updating = false;
        if (_.has(draft.entities, action.payload.publisherId)) {
          draft.entities[action.payload.publisherId][action.payload.publisherApp.id] =
            action.payload.publisherApp;
        }
        break;
      }
      case DELETE_PUBLISHER_APP: {
        draft.updating = true;
        draft.updateError = null;
        break;
      }
      case DELETE_PUBLISHER_APP_SUCCESS: {
        draft.updating = false;
        if (_.has(draft.entities, action.payload.publisherId)) {
          delete draft.entities[action.payload.publisherId][action.payload.publisherAppId];
        }
        break;
      }
      case DELETE_PUBLISHER_APP_ERROR: {
        draft.updating = false;
        draft.updateError = action.error;
        break;
      }
      case GET_PUBLISHER_APP: {
        const { publisherId, publisherAppId } = action.payload;

        draft.loading = true;
        draft.loadError = null;
        _.set(draft.lastEntityRequestTs, [publisherId, publisherAppId], Date.now());
        break;
      }
      case GET_PUBLISHER_APP_SUCCESS: {
        const { publisherId, publisherApp } = action.payload;
        const publisherAppID = publisherApp;

        draft.loading = false;
        if (!_.has(draft.entities, publisherId)) {
          draft.entities[publisherId] = {};
        }
        if (!_.has(draft.entities[publisherId], publisherAppID)) {
          draft.entities[publisherId][publisherAppID] = {};
        }
        draft.entities[publisherId][publisherAppID] = publisherApp;

        break;
      }
      case GET_PUBLISHER_APP_ERROR: {
        draft.loading = false;
        draft.loadError = action.error;
        break;
      }
      default: {
        break;
      }
    }
    return draft;
  });
}

export default publisherAppsReducer;
