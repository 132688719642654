import { ImageCreative } from 'common-v2/model/imageCreative';
import { NativeCreative } from 'common-v2/model/nativeCreative';
import { VideoCreative } from 'common-v2/model/videoCreative';
import { MECreativeType } from 'common-v2/types';

/*
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here. We prefix them with 'componentName' so we avoid
 * reducers accidentally picking up actions they shouldn't.
 *
 * Follow this format:
 * export const YOUR_ACTION_CONSTANT = 'YourContainer/YOUR_ACTION_CONSTANT';
 */

import { ACTION_TYPE_CAMPAIGN_MANAGER } from '../consts';

export const ACTION_TYPE_CREATIVE = 'creative';

export const LIST_CREATIVES = `${ACTION_TYPE_CAMPAIGN_MANAGER}/${ACTION_TYPE_CREATIVE}/LIST_CREATIVES`;
export const LIST_CREATIVES_SUCCESS = `${ACTION_TYPE_CAMPAIGN_MANAGER}/${ACTION_TYPE_CREATIVE}/LIST_CREATIVES_SUCCESS`;
export const LIST_CREATIVES_ERROR = `${ACTION_TYPE_CAMPAIGN_MANAGER}/${ACTION_TYPE_CREATIVE}/LIST_CREATIVES_ERROR`;

export const GET_CREATIVE = `${ACTION_TYPE_CAMPAIGN_MANAGER}/${ACTION_TYPE_CREATIVE}/GET_CREATIVE`;
export const GET_CREATIVE_SUCCESS = `${ACTION_TYPE_CAMPAIGN_MANAGER}/${ACTION_TYPE_CREATIVE}/GET_CREATIVES_SUCCESS`;
export const GET_CREATIVE_ERROR = `${ACTION_TYPE_CAMPAIGN_MANAGER}/${ACTION_TYPE_CREATIVE}/GET_CREATIVES_ERROR`;

export const BULK_SAVE_NATIVE_CREATIVES = `${ACTION_TYPE_CAMPAIGN_MANAGER}/${ACTION_TYPE_CREATIVE}/BULK_SAVE_NATIVE_CREATIVES`;
export const BULK_SAVE_NATIVE_CREATIVES_SUCCESS = `${ACTION_TYPE_CAMPAIGN_MANAGER}/${ACTION_TYPE_CREATIVE}/BULK_SAVE_NATIVE_CREATIVES_SUCCESS`;
export const BULK_SAVE_NATIVE_CREATIVES_ERROR = `${ACTION_TYPE_CAMPAIGN_MANAGER}/${ACTION_TYPE_CREATIVE}/BULK_SAVE_NATIVE_CREATIVES_ERROR`;

type MTCreativeModel = ImageCreative | NativeCreative | VideoCreative;

export interface MICreativesState {
  // used by useCreatives
  loading: boolean;
  updating: boolean;
  loadError: string | null;
  updateError: string | null;
  lastListRequestTs: {
    [advertiserId: string]: {
      [productId: string]: number;
    };
  };
  entities: {
    [advertiserId: string]: {
      [productId: string]: {
        [creativeType in MECreativeType]: {
          [creativeId: string]: MTCreativeModel;
        };
      };
    };
  };
  // used by useCreative
  entitiesById: {
    [creativeId: string]: MTCreativeModel;
  };
  entitiesByIdLoading: {
    [creativeId: string]: boolean;
  };
  entitiesByIdLoadError: {
    [creativeId: string]: string | null;
  };
}

// custom type guards
export const isImageCreative = (creative: MTCreativeModel): creative is ImageCreative =>
  creative.type === MECreativeType.IMAGE;

export const isVideoCreative = (creative: MTCreativeModel): creative is VideoCreative =>
  creative.type === MECreativeType.VIDEO;

export const isNativeCreative = (creative: MTCreativeModel): creative is NativeCreative =>
  creative.type === MECreativeType.NATIVE;
