/*
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here. We prefix them with 'componentName' so we avoid
 * reducers accidentally picking up actions they shouldn't.
 *
 * Follow this format:
 * export const YOUR_ACTION_CONSTANT = 'YourContainer/YOUR_ACTION_CONSTANT';
 */

import { ACTION_TYPE_CAMPAIGN_MANAGER } from '../consts.ts';

export const ACTION_TYPE_LOG_DATA = 'logData';

export const LIST_LOG = `${ACTION_TYPE_CAMPAIGN_MANAGER}/${ACTION_TYPE_LOG_DATA}/LIST_LOG`;
export const LIST_LOG_SUCCESS = `${ACTION_TYPE_CAMPAIGN_MANAGER}/${ACTION_TYPE_LOG_DATA}/LIST_LOG_SUCCESS`;
export const LIST_LOG_ERROR = `${ACTION_TYPE_CAMPAIGN_MANAGER}/${ACTION_TYPE_LOG_DATA}/LIST_LOG_ERROR`;

export const GET_LOG_STATUS = `${ACTION_TYPE_CAMPAIGN_MANAGER}/${ACTION_TYPE_LOG_DATA}/GET_LOG_STATUS`;
export const GET_LOG_STATUS_SUCCESS = `${ACTION_TYPE_CAMPAIGN_MANAGER}/${ACTION_TYPE_LOG_DATA}/GET_LOG_STATUS_SUCCESS`;
export const GET_LOG_STATUS_ERROR = `${ACTION_TYPE_CAMPAIGN_MANAGER}/${ACTION_TYPE_LOG_DATA}/GET_LOG_STATUS_ERROR`;

export const SAVE_LOG = `${ACTION_TYPE_CAMPAIGN_MANAGER}/${ACTION_TYPE_LOG_DATA}/SAVE_LOG`;
export const SAVE_LOG_SUCCESS = `${ACTION_TYPE_CAMPAIGN_MANAGER}/${ACTION_TYPE_LOG_DATA}/SAVE_LOG_SUCCESS`;
export const SAVE_LOG_ERROR = `${ACTION_TYPE_CAMPAIGN_MANAGER}/${ACTION_TYPE_LOG_DATA}/SAVE_LOG_ERROR`;

export const LIST_LOG_TRANSFER_REQUEST = `${ACTION_TYPE_CAMPAIGN_MANAGER}/${ACTION_TYPE_LOG_DATA}/LIST_LOG_TRANSFER_REQUEST`;
export const LIST_LOG_TRANSFER_SUCCESS = `${ACTION_TYPE_CAMPAIGN_MANAGER}/${ACTION_TYPE_LOG_DATA}/LIST_LOG_TRANSFER_SUCCESS`;
export const LIST_LOG_TRANSFER_ERROR = `${ACTION_TYPE_CAMPAIGN_MANAGER}/${ACTION_TYPE_LOG_DATA}/LIST_LOG_TRANSFER_ERROR`;

export const SAVE_LOG_TRANSFER_REQUEST = `${ACTION_TYPE_CAMPAIGN_MANAGER}/${ACTION_TYPE_LOG_DATA}/SAVE_LOG_TRANSFER_REQUEST`;
export const SAVE_LOG_TRANSFER_SUCCESS = `${ACTION_TYPE_CAMPAIGN_MANAGER}/${ACTION_TYPE_LOG_DATA}/SAVE_LOG_TRANSFER_SUCCESS`;
export const SAVE_LOG_TRANSFER_ERROR = `${ACTION_TYPE_CAMPAIGN_MANAGER}/${ACTION_TYPE_LOG_DATA}/SAVE_LOG_TRANSFER_ERROR`;

export const DELETE_LOG_TRANSFER_REQUEST = `${ACTION_TYPE_CAMPAIGN_MANAGER}/${ACTION_TYPE_LOG_DATA}/DELETE_LOG_TRANSFER_REQUEST`;
export const DELETE_LOG_TRANSFER_SUCCESS = `${ACTION_TYPE_CAMPAIGN_MANAGER}/${ACTION_TYPE_LOG_DATA}/DELETE_LOG_TRANSFER_SUCCESS`;
export const DELETE_LOG_TRANSFER_ERROR = `${ACTION_TYPE_CAMPAIGN_MANAGER}/${ACTION_TYPE_LOG_DATA}/DELETE_LOG_TRANSFER_ERROR`;
