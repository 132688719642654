import _ from 'lodash';
import axios from 'axios';

import api from '../../common-v2/api/index.ts';
import { USERSPACE_HOSTS } from '../../common-v2/constants/auth.ts';
import { SERVICES, USER_SPACES } from '../../common/constants/common';
import * as routes from '../../common/constants/routes';
import { CAMPAIGN_MANAGER_VER } from '../../common/constants/version';
import * as firebase from '../../common/firebase';
import { doSignInWithEmailAndPassword } from '../../common/firebase';
import { PlatformInfo } from '../../common/model/auth/platformInfo';
import { TokenCache } from '../../common/model/auth/tokenCache';
import { AuthUser } from '../../common-v2/model/authuser.ts';
import PlatformSettings from '../../common-v2/model/platformSettings.ts';
import { extractErrorMessage } from '../../common/utils';
import * as cache from '../../common/utils/browser_cache';
import { BROWSER_CACHE_KEY } from '../../common/utils/browser_cache_keys';
import bugsnagClient from '../../common/utils/bugsnag';
import { SET_PLATFORM_SETTINGS } from '../main/types';
import { initializeReduxState } from '../main/actions';
import { clearNotifications } from '../inAppAlert/actions';
import { initNotificationCache } from '../notification/deliveries/actions.ts';
import {
  AUTH_STATE,
  SET_AUTH_MESSAGE,
  SET_AUTH_STATE,
  SET_AUTH_TOKEN,
  SET_AUTH_USER,
  SET_AVAILABLE_PLATFORMS,
  SET_PLATFORM,
  SET_MANUAL_LOGOUT,
  SET_USE_MORSE,
  SET_USER_SPACE,
  SAVE_WORKPLACE_REQUEST,
  SAVE_WORKPLACE_SUCCESS,
  SAVE_WORKPLACE_ERROR,
} from './types';

const loadConfiguration = async (userSpaceId, workplaceId, authUser) => {
  let workplaceConfigLoadFailed = false;
  let feConfigData = { features: undefined, meta: undefined };

  try {
    // Import FE configurations
    const importPath = `/configs/cm/${btoa(
      `${CAMPAIGN_MANAGER_VER}_${userSpaceId}_${workplaceId}`,
    )}.json?ts=${Date.now()}`;
    const resp = await axios.get(importPath);
    feConfigData = resp.data;
    // Check if it's valid json data.
  } catch (e) {
    // No error
    workplaceConfigLoadFailed = true;
  }

  if (workplaceConfigLoadFailed || typeof feConfigData !== 'object') {
    try {
      // Fallback to user space config loading
      const importPath = `/configs/cm/${btoa(
        `${CAMPAIGN_MANAGER_VER}_${userSpaceId}`,
      )}.json?ts=${Date.now()}`;

      const resp = await axios.get(importPath);
      feConfigData = resp.data;
    } catch (e) {
      // No error but should exists.
    }
  }

  try {
    const { features, meta } = feConfigData;

    // TODO: After applied role for PlatformSettings of InventoryManager, Should remove this condition.
    let dspConfig = null;
    const userServices = authUser.services;
    if (userServices.includes(SERVICES.CAMPAIGN_MANAGER)) {
      // Retrieve all workplace settings.
      const dspConfigRes = await api.getDspConfiguration();
      dspConfig = _.get(dspConfigRes.data, 'configuration', null);
    }

    return new PlatformSettings(dspConfig, features, meta);
  } catch (err) {
    const errorMsg = extractErrorMessage(err);
    const message = `Something wrong happened when loading configurations - ${errorMsg}`;
    throw new Error(message);
  }
};

export const setUseMorse = (useMorse) => {
  return {
    type: SET_USE_MORSE,
    payload: {
      useMorse,
    },
  };
};

export const setUserSpace = (userSpace) => {
  return {
    type: SET_USER_SPACE,
    payload: {
      userSpace,
    },
  };
};

export const setPlatform = (platformId) => {
  return {
    type: SET_PLATFORM,
    payload: {
      platform: platformId,
    },
  };
};

export const setAuthState = (state) => {
  return {
    type: SET_AUTH_STATE,
    payload: {
      state,
    },
  };
};

export const setAvailablePlatforms = (platforms) => {
  return {
    type: SET_AVAILABLE_PLATFORMS,
    payload: {
      platforms,
    },
  };
};

export const updatePlatform = (uiWorkplace, onFinish) => {
  return async (dispatch) => {
    const workplaceProto = PlatformInfo.toProto(uiWorkplace);

    const progressKey = workplaceProto.id;

    dispatch({
      type: SAVE_WORKPLACE_REQUEST,
      payload: {
        id: workplaceProto.id,
        progressKey,
      },
    });

    try {
      const resp = await api.updatePlatform(workplaceProto);
      const newWorkplace = PlatformInfo.fromProto(resp.data.platform);

      dispatch({
        type: SAVE_WORKPLACE_SUCCESS,
        payload: {
          workplace: newWorkplace,
          progressKey,
        },
      });

      onFinish();
    } catch (error) {
      const errMsg = extractErrorMessage(error);
      const context = `Error occurred while updating the workplace info: ${errMsg}`;
      onFinish(context);

      dispatch({
        type: SAVE_WORKPLACE_ERROR,
        payload: {
          id: workplaceProto.id,
          progressKey,
        },
        error: errMsg,
        bugsnagMeta: {
          error,
          uiWorkplace,
          context,
        },
      });
    }
  };
};

export const uploadWorkplaceLogo = (workplaceId, logo, onFinish) => {
  return async (dispatch) => {
    const progressKey = workplaceId;

    dispatch({
      type: SAVE_WORKPLACE_REQUEST,
      payload: {
        id: workplaceId,
        progressKey,
      },
    });

    try {
      const resp = await api.uploadWorkplaceLogo(workplaceId, logo);
      const newWorkplace = PlatformInfo.fromProto(resp.data.platform);

      dispatch({
        type: SAVE_WORKPLACE_SUCCESS,
        payload: {
          workplace: newWorkplace,
          progressKey,
        },
      });

      onFinish();
    } catch (error) {
      const errMsg = extractErrorMessage(error);
      const context = `Error occurred while uploading the workplace logo: ${errMsg}`;
      onFinish(context);

      dispatch({
        type: SAVE_WORKPLACE_ERROR,
        payload: {
          id: workplaceId,
          progressKey,
        },
        error: errMsg,
        bugsnagMeta: {
          error,
          workplaceId,
          context,
        },
      });
    }
  };
};

export const setAuthUser = (authUser) => ({
  type: SET_AUTH_USER,
  payload: {
    user: authUser,
  },
});

export const setAuthToken = (authToken) => {
  return {
    type: SET_AUTH_TOKEN,
    payload: {
      authToken,
    },
  };
};

export const fetchAdCloudUser = (firebaseUser) => async (dispatch) => {
  const uid = firebaseUser.uid;
  try {
    const res = await api.listAdCloudUserPlatforms(uid);

    if (!_.has(res, 'data.platform_ids')) {
      throw new Error('Failed to get the user`s permission');
    }

    const associatedPlatforms = res.data.platform_ids;
    if (_.isEmpty(associatedPlatforms)) {
      throw new Error('Cannot identify the user');
    }

    // TODO: When user has multiple platforms, redirect the user to platform selection page.
    const userPlatform = associatedPlatforms[0];
    dispatch(setPlatform(userPlatform));

    const res2 = await api.getAdCloudUser(uid);
    const adCloudUser = res2.data.user;
    const roleResp = await api.getAdCloudUserRoles(adCloudUser.id);
    const roleGrants = _.get(roleResp, 'data.role_grants', [])
      .map((roleGrant) => ({
        resource_id: '',
        ressource_instance_id: '',
        roles: roleGrant.roles?.map((role) => ({ id: role, platform_id: 'ALL' })),
      }))
      .filter((roleGrant) => roleGrant.roles);
    const authUser = AuthUser.newFirebaseUser(firebaseUser, adCloudUser);
    authUser.setRoles(roleGrants);

    dispatch(setAuthUser(authUser));

    const settings = await loadConfiguration(USER_SPACES.GENERAL, userPlatform, authUser);
    dispatch({
      type: SET_PLATFORM_SETTINGS,
      payload: {
        platformSettings: settings,
      },
    });

    dispatch(setAuthState(AUTH_STATE.LOGGED_IN));
  } catch (error) {
    // TODO: Report to Bugsnag
    dispatch(setAuthUser(null));
    dispatch({
      type: SET_AUTH_MESSAGE,
      msg: `Authentication failed: ${extractErrorMessage(error)}`,
    });
    await firebase.doSignOut();

    dispatch(setAuthState(AUTH_STATE.INIT));
  }
};

export const setFirebaseUser = (fbUser) => async (dispatch) => {
  if (!fbUser) {
    dispatch(setAuthUser(null));
    dispatch(setAuthState(AUTH_STATE.INIT));
    return;
  }

  const token = await fbUser.getIdToken();
  dispatch(setAuthToken(token));

  bugsnagClient.user = {
    name: fbUser.displayName ? fbUser.displayName : '',
    email: fbUser.email,
  };

  dispatch(fetchAdCloudUser(fbUser));
};

function getUserSpace() {
  const currentHost = window.location.host;

  // eslint-disable-next-line no-restricted-syntax
  for (const entry of USERSPACE_HOSTS) {
    // eslint-disable-next-line no-restricted-syntax
    for (const host of entry.hosts) {
      if (currentHost.includes(host)) return entry.userspace;
    }
  }

  return USER_SPACES.GENERAL;
}

export function initializeAuth() {
  const userSpace = getUserSpace();

  return (dispatch) => {
    dispatch(setUseMorse(userSpace !== USER_SPACES.GENERAL));
    dispatch(setUserSpace(userSpace));
    dispatch(setAuthState(AUTH_STATE.INIT));
  };
}

export const logoutUser = (msg, manualLogout = false) => {
  return async (dispatch, getState) => {
    const { useMorse } = getState().auth;

    cache.removeSessionCacheObject(BROWSER_CACHE_KEY.AUTH_INFO);

    if (!useMorse) {
      await firebase.doSignOut();
    }

    dispatch(clearNotifications());
    dispatch(initializeReduxState());
    dispatch(initializeAuth());
    dispatch({
      type: SET_MANUAL_LOGOUT,
      payload: {
        manualLogout,
      },
    });
    if (msg) {
      dispatch({
        type: SET_AUTH_MESSAGE,
        msg,
      });
    }
  };
};

export const refreshIdToken = () => async (dispatch, getState) => {
  const { user: authUser, useMorse } = getState().auth;

  try {
    let token = '';

    if (useMorse) {
      const tokenResp = await api.refreshMorseToken();
      token = _.get(tokenResp, 'data.token', '');
    } else {
      token = await authUser.getToken(true);
    }

    if (!token) {
      throw new Error('Refreshing authentication token failed.');
    }

    dispatch(setAuthToken(token));
  } catch (error) {
    const errorMsg = extractErrorMessage(error);

    const opts = {
      context: 'Error while refreshing token',
      metaData: {
        ...authUser,
        errorMsg,
      },
    };
    bugsnagClient.notify(error, opts);

    dispatch(logoutUser(`Got exception while refreshing token - ${errorMsg}.`, true));
  }
};

export const getAvailablePlatforms = (email, password, onFinish) => {
  return async (dispatch, getState) => {
    const { userSpace } = getState().auth;

    try {
      const resp = await api.getPlatforms(userSpace, email, password);

      const platformInfos = _.get(resp, 'data.platforms', []).map((info) =>
        PlatformInfo.fromProto(info),
      );

      if (_.isEmpty(platformInfos)) {
        return onFinish('Unregistered email address.');
      }

      dispatch(setAuthState(AUTH_STATE.SELECTING_PLATFORM));
      dispatch(setAvailablePlatforms(platformInfos));

      onFinish(null, platformInfos);
    } catch (error) {
      if (error.response) {
        const status = error.response.status;

        if (status === 404 || status === 401) {
          return onFinish('Invalid email or password.');
        }

        const errorMsg = extractErrorMessage(error);
        const opts = {
          context: 'Error while getting platforms',
          metaData: {
            email,
            userSpace,
            errorMsg,
          },
        };
        bugsnagClient.notify(error, opts);

        if (errorMsg) {
          onFinish(errorMsg);
        } else {
          onFinish(`Unknown error occurred - status:${status}`);
        }

        return null;
      }

      onFinish(`Unknown error occurred - ${error}`);
    }

    return null;
  };
};

const afterTokenProcess = async (userSpaceId, platformId, token, authUser, dispatch) => {
  // Exclude the Auth reducer from resetting
  dispatch(initializeReduxState(['auth']));
  dispatch(setAuthToken(token));
  dispatch(setPlatform(platformId));

  // TODO: Get user info via API.
  const roleResp = await api.getUserRoles(platformId, authUser.id);
  const roleGrants = _.get(roleResp, 'data.role_grants', []);
  authUser.setRoles(roleGrants);

  dispatch(setAuthUser(authUser));

  const settings = await loadConfiguration(userSpaceId, platformId, authUser);
  dispatch({
    type: SET_PLATFORM_SETTINGS,
    payload: {
      platformSettings: settings,
    },
  });

  dispatch(initNotificationCache());

  dispatch(setAuthState(AUTH_STATE.LOGGED_IN));
};

export const signInMorse = (platformId, email, password, onFinish) => {
  return async (dispatch, getState) => {
    const { userSpace, availablePlatforms } = getState().auth;

    dispatch(setAuthState(AUTH_STATE.VERIFYING_ACCOUNT));

    try {
      const resp = await api.getMorseToken(userSpace, platformId, email, password);
      const token = _.get(resp.data, 'token', '');
      if (!token) {
        dispatch(setAuthState(AUTH_STATE.INIT));
        onFinish('Authentication failed - could not get user API token');
        return;
      }

      const user = _.get(resp.data, 'user', null);
      if (!user) {
        dispatch(setAuthState(AUTH_STATE.INIT));
        onFinish('Authentication failed - could not get user information');
        return;
      }

      bugsnagClient.user = {
        name: user.id,
        email: user.email,
      };

      const authUser = AuthUser.newMorseUser(user);
      await afterTokenProcess(userSpace, platformId, token, authUser, dispatch);

      // Set session cache of token to avoid typing id/pw again during token is alive.
      cache.setSessionCacheObject(
        BROWSER_CACHE_KEY.AUTH_INFO,
        new TokenCache(
          userSpace,
          platformId,
          user.id,
          user.email,
          user.name,
          token,
          availablePlatforms,
        ).toJson(),
      );

      onFinish();
    } catch (error) {
      const errorMsg = extractErrorMessage(error);

      const opts = {
        context: 'Error while signing in user',
        metaData: {
          email,
          userSpace,
          errorMsg,
        },
      };
      bugsnagClient.notify(error, opts);

      if (errorMsg) {
        onFinish(errorMsg);
      } else {
        onFinish(`Unknown error occurred - ${error}`);
      }

      dispatch(setAuthState(AUTH_STATE.INIT));
    }
  };
};

export const reloadSession = (cachedAuthInfo, onFinish) => {
  const authInfo = TokenCache.fromJson(cachedAuthInfo);

  return async (dispatch) => {
    if (authInfo && !authInfo.isExpired()) {
      bugsnagClient.user = {
        name: authInfo.userId,
        email: authInfo.email,
      };

      const authUser = AuthUser.newMorseUser({
        id: authInfo.userId,
        email: authInfo.email,
        name: authInfo.displayName,
      });

      try {
        await afterTokenProcess(
          authInfo.userSpaceId,
          authInfo.platformId,
          authInfo.token,
          authUser,
          dispatch,
        );

        dispatch(setAvailablePlatforms(authInfo.availablePlatforms));

        onFinish();
      } catch (error) {
        cache.removeSessionCacheObject(BROWSER_CACHE_KEY.AUTH_INFO);
        dispatch(setAuthState(AUTH_STATE.INIT));

        const errorMsg = extractErrorMessage(error);

        const opts = {
          context: 'Error while reload session',
          metaData: {
            cachedAuthInfo,
            errorMsg,
          },
        };
        bugsnagClient.notify(error, opts);

        if (errorMsg) {
          onFinish(errorMsg);
        } else {
          onFinish(`Unknown error occurred - ${error}`);
        }
      }
    } else {
      cache.removeSessionCacheObject(BROWSER_CACHE_KEY.AUTH_INFO);
      dispatch(setAuthState(AUTH_STATE.INIT));
      onFinish('Invalid cached token.');
    }
  };
};

export const sendPasswordResetRequest = (email, onFinish) => {
  return async (dispatch, getState) => {
    const { userSpace } = getState().auth;
    try {
      const resetUrl = `${window.location.origin}${
        routes.EMAIL_ACTION
      }?mode=resetPassword&email=${encodeURIComponent(email)}`;
      await api.deletePassword(userSpace, email, resetUrl);

      onFinish();
    } catch (error) {
      const status = error.response.status;

      if (status === 404) {
        onFinish('Invalid user email.');
        return;
      }

      const errorMsg = extractErrorMessage(error);

      const opts = {
        context: 'Error while sending password reset request',
        metaData: {
          userSpace,
          email,
          errorMsg,
        },
      };
      bugsnagClient.notify(error, opts);

      if (errorMsg) {
        onFinish(errorMsg);
      } else {
        onFinish(`Unknown error occurred - ${error}`);
      }
    }
  };
};

export function updatePassword(token, email, password, onFinish) {
  return async () => {
    try {
      await api.updatePassword(token, email, password);
      onFinish();
    } catch (error) {
      if (error.response) {
        const status = error.response.status;

        if (status === 401) {
          onFinish('Invalid token request.');
        } else {
          const errorMsg = extractErrorMessage(error);

          const opts = {
            context: 'Error while updating password',
            metaData: {
              email,
              errorMsg,
            },
          };
          bugsnagClient.notify(error, opts);

          if (errorMsg) {
            onFinish(errorMsg);
          } else {
            onFinish(`Unknown error occurred - status:${status}`);
          }
        }
        return;
      }

      onFinish(`Unknown error occurred - ${error}`);
    }
  };
}

export const signInFirebase = (userId, password, onFinish) => {
  return async () => {
    doSignInWithEmailAndPassword(userId, password)
      .then(() => {
        onFinish();
      })
      .catch((e) => {
        const msg = _.get(e, 'message', '');
        onFinish(msg || 'Exception occurred while logging in.');
      });
  };
};
