/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import styled, { css } from 'styled-components';
import { useHistory } from 'react-router-dom';

export enum MEButtonVariant {
  CONTAINED = 'contained',
  TEXT = 'text',
  ICON = 'icon',
}

export enum MEButtonColor {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  DANGER = 'danger',
  DEFAULT = 'default',
}

const buttonStyleMap: Record<MEButtonVariant, any> = {
  [MEButtonVariant.CONTAINED]: css`
    border-radius: 2px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    padding: 0 15px;

    &:disabled {
      cursor: default;
      opacity: 0.4;
    }
  `,
  [MEButtonVariant.TEXT]: css`
    background-color: transparent;
    padding: 0 4px;

    &:disabled {
      cursor: default;
      color: #ccc;
    }
  `,
  [MEButtonVariant.ICON]: css`
    font-size: 24px;
    padding: 0;
    border: 0;
    box-shadow: none;
    height: auto;
    background-color: transparent;

    &:disabled {
      cursor: default;
      color: #ccc;
    }
  `,
};

const buttonColorMap: Record<MEButtonVariant, Record<MEButtonColor, any>> = {
  [MEButtonVariant.CONTAINED]: {
    [MEButtonColor.PRIMARY]: css`
      background-color: ${(props) => props.theme.palette.primary.main};
      color: ${(props) => props.theme.palette.primary.contrastText};
      &:enabled:hover {
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 2px 10px 0 rgba(0, 0, 0, 0.16);
        background-color: #3c6ede;
      }
    `,
    [MEButtonColor.SECONDARY]: css`
      background-color: #50acfb;
      color: ${(props) => props.theme.palette.primary.contrastText};
      &:enabled:hover {
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 2px 9px 0 rgba(0, 0, 0, 0.16);
        background-color: #3795e5;
      }
    `,
    [MEButtonColor.DANGER]: css`
      background-color: ${(props) => props.theme.palette.danger.main};
      color: ${(props) => props.theme.palette.primary.contrastText};
    `,
    [MEButtonColor.DEFAULT]: css`
      background-color: #fff;
      color: #898989;
      &:enabled:hover {
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 2px 10px 0 rgba(0, 0, 0, 0.16);
        background-color: #f5f5f5;
      }
    `,
  },
  [MEButtonVariant.TEXT]: {
    [MEButtonColor.PRIMARY]: css`
      color: ${(props) => props.theme.palette.primary.main};
    `,
    [MEButtonColor.SECONDARY]: css`
      color: #50acfb;
    `,
    [MEButtonColor.DANGER]: css`
      color: ${(props) => props.theme.palette.danger.main};
    `,
    [MEButtonColor.DEFAULT]: css`
      color: #898989;
    `,
  },
  [MEButtonVariant.ICON]: {
    [MEButtonColor.PRIMARY]: css`
      color: ${(props) => props.theme.palette.primary.main};
    `,
    [MEButtonColor.SECONDARY]: css`
      color: #50acfb;
    `,
    [MEButtonColor.DANGER]: css`
      color: ${(props) => props.theme.palette.danger.main};
    `,
    [MEButtonColor.DEFAULT]: css`
      color: #898989;
    `,
  },
};

const SCButton = styled.button<
  { variant: MEButtonVariant; color: MEButtonColor } & React.ButtonHTMLAttributes<HTMLElement>
>`
  display: flex;
  cursor: pointer;
  height: 32px;
  border: 0;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  align-items: center;

  &:focus {
    outline: none;
  }

  ${(props) => buttonStyleMap[props.variant]}
  ${(props) => buttonColorMap[props.variant][props.color]}
`;

const SCLeftIconWrapper = styled.div`
  margin-right: 0.5em;
`;

const SCRightIconWrapper = styled.div`
  margin-left: 0.5em;
`;

type MTButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  testId?: string;
  variant?: MEButtonVariant;
  color?: MEButtonColor;
  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
  linkPath?: string;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
};

export const MCButton = React.forwardRef((props: MTButtonProps, ref) => {
  const {
    testId,
    variant = MEButtonVariant.CONTAINED,
    color = MEButtonColor.DEFAULT,
    children,
    type,
    leftIcon,
    rightIcon,
    linkPath,
    ...rest
  } = props;

  const history = useHistory();
  const handleClick = linkPath
    ? (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        // initiate onClick listener before navigate to next page
        if (rest.onClick) {
          rest.onClick(e);
        }
        history.push(linkPath);
      }
    : rest.onClick;

  return (
    <SCButton
      {...rest}
      data-testid={testId}
      variant={variant}
      color={color}
      type={type || 'button'}
      onClick={handleClick}
      ref={ref as any}
    >
      {leftIcon && <SCLeftIconWrapper>{leftIcon}</SCLeftIconWrapper>}
      {children}
      {rightIcon && <SCRightIconWrapper>{rightIcon}</SCRightIconWrapper>}
    </SCButton>
  );
});

export const MCButtonGroup = styled.div`
  display: flex;

  & ${SCButton} {
    margin: 0 ${(props) => props.theme.spacing(2)}px 0 0;

    &:last-child {
      margin-right: 0;
    }
  }
`;
