import _ from 'lodash';
import { INITIALIZE_REDUX_STATE } from './types';

const initReduxStateEnhancer = (rootReducer) => (state, action) => {
  if (action.type === INITIALIZE_REDUX_STATE) {
    // Excluding reducers from being reset
    // eslint-disable-next-line no-param-reassign
    state = action.payload.excludes.length > 0 ? _.pick(state, action.payload.excludes) : undefined;
  }

  return rootReducer(state, action);
};

// eslint-disable-next-line import/prefer-default-export
export { initReduxStateEnhancer };
