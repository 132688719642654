import produce from 'immer';

import {
  LIST_CREATIVE_REVIEW_REQUEST,
  LIST_CREATIVE_REVIEW_SUCCESS,
  MICreativeReviewsState,
  MIListCreativeReviewsRequestAction,
  MIListCreativeReviewsSuccessAction,
  MTCreativeReviewsAction,
} from './types';

export const initialState: MICreativeReviewsState = {
  lastListRequestTs: {},
  entities: {},
};

function creativeReviewsReducer(state = initialState, action: MTCreativeReviewsAction) {
  // eslint-disable-next-line consistent-return
  return produce(state, (draft) => {
    switch (action.type) {
      case LIST_CREATIVE_REVIEW_REQUEST: {
        const { adAccountId } = (action as MIListCreativeReviewsRequestAction).payload;
        draft.lastListRequestTs[adAccountId] = Date.now();
        break;
      }
      case LIST_CREATIVE_REVIEW_SUCCESS: {
        const {
          adAccountId,
          creativeReviewsMapByCreativeType,
        } = (action as MIListCreativeReviewsSuccessAction).payload;
        draft.entities[adAccountId] = creativeReviewsMapByCreativeType;
        break;
      }
      default:
        break;
    }
  });
}

export default creativeReviewsReducer;
