import produce from 'immer';
import _ from 'lodash';

import {
  LIST_CREATIVES,
  LIST_CREATIVES_SUCCESS,
  LIST_CREATIVES_ERROR,
  BULK_SAVE_NATIVE_CREATIVES,
  BULK_SAVE_NATIVE_CREATIVES_SUCCESS,
  BULK_SAVE_NATIVE_CREATIVES_ERROR,
  GET_CREATIVE,
  GET_CREATIVE_SUCCESS,
  GET_CREATIVE_ERROR,
  MICreativesState,
} from './types';

// The initial state of the Creative Groups.
export const initialState: MICreativesState = {
  loading: false,
  updating: false,
  loadError: null,
  updateError: null,
  lastListRequestTs: {},
  entities: {},
  entitiesById: {},
  entitiesByIdLoading: {},
  entitiesByIdLoadError: {},
};

function creativesReducer(state = initialState, action: any) {
  // eslint-disable-next-line consistent-return
  return produce(state, (draft) => {
    switch (action.type) {
      case LIST_CREATIVES: {
        const { advertiserId, productId } = action.payload;

        draft.loading = true;
        draft.loadError = null;
        _.set(draft.lastListRequestTs, [advertiserId, productId], Date.now());
        break;
      }
      case LIST_CREATIVES_SUCCESS: {
        const { advertiserId, productId, creatives } = action.payload;

        draft.loading = false;
        _.set(draft.entities, [advertiserId, productId], creatives);
        break;
      }
      case LIST_CREATIVES_ERROR: {
        const { error } = action.payload;

        draft.loading = false;
        draft.loadError = error;
        break;
      }
      case BULK_SAVE_NATIVE_CREATIVES: {
        draft.updating = true;
        draft.updateError = null;
        break;
      }
      case BULK_SAVE_NATIVE_CREATIVES_SUCCESS: {
        draft.updating = false;
        break;
      }
      case BULK_SAVE_NATIVE_CREATIVES_ERROR: {
        const { error } = action.payload;

        draft.updateError = error;
        break;
      }
      case GET_CREATIVE: {
        const { creativeId } = action.payload;
        draft.entitiesByIdLoading[creativeId] = true;
        draft.entitiesByIdLoadError[creativeId] = null;
        break;
      }
      case GET_CREATIVE_SUCCESS: {
        const { creative } = action.payload;
        draft.entitiesByIdLoading[creative.id] = false;
        draft.entitiesByIdLoadError[creative.id] = null;
        draft.entitiesById[creative.id] = creative;
        break;
      }
      case GET_CREATIVE_ERROR: {
        const { creativeId, error } = action.payload;
        draft.entitiesByIdLoading[creativeId] = false;
        draft.entitiesByIdLoadError[creativeId] = error;
        break;
      }
      default:
        break;
    }
  });
}

export default creativesReducer;
