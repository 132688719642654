import fileSize from 'filesize';
import _ from 'lodash';
import deployEnv from '../../common/constants/deployEnv';
import { CURRENCY_DATA_MAP, MCR_HOSTS, MECurrency } from '../constants/common';

export const formatFileSize = (sizeInBytes: number, spacer: string = ' ') =>
  sizeInBytes ? fileSize(sizeInBytes, { spacer }) : 'N/A';

export const getFileExtension = (filename: string, withDot = false) => {
  const idx = filename.lastIndexOf('.');
  // handle cases like [ .hidden / filename without extension ]
  return idx < 1 ? '' : `${withDot ? '.' : ''}${filename.substr(idx + 1)}`;
};

export function generateRemoteFilename(filename: string) {
  return `${new Date().valueOf().toString(36)}_${Math.random()
    .valueOf()
    .toString(36)
    .substring(1, 5)}${getFileExtension(filename, true)}`;
}

// isValidIDFA is used to check if the IDFA is valid.
// A valid IDFA has a pattern of 'XXXXXXXX-XXXX-4XXX-YXXX-XXXXXXXXXXXX',
// where X is a hexadecimal digit and Y is one of "8,9,a,b".
export function isValidIDFA(value: string) {
  return /^[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}/i.test(value);
}

export const isFalsyNotZero = (val: number) => {
  return !val && val !== 0;
};

export const createObjectSortFunc = (
  field: string,
  order: 'asc' | 'desc' = 'asc',
  refineFunc: (val: any) => any = (val) => val,
) => {
  return (a: any, b: any) => {
    const aVal = refineFunc(a[field]);
    const bVal = refineFunc(b[field]);

    if (aVal < bVal) {
      return order === 'asc' ? -1 : 1;
    }
    if (aVal > bVal) {
      return order === 'asc' ? 1 : -1;
    }
    return 0;
  };
};

export const getCurrencySymbol = (currency: string) => {
  // @ts-ignore
  const currencyInfo = CURRENCY_DATA_MAP[currency];
  if (currencyInfo) {
    return currencyInfo.symbol;
  }

  return '';
};

export const getLang = () =>
  (navigator.languages !== undefined ? navigator.languages[0] : navigator.language) || 'en-US';

export const convertNumberToCurrencyFormat = (value: number, currency: MECurrency) => {
  return Number(value).toLocaleString(getLang(), { style: 'currency', currency });
};

export const getMicroToNormalValue = (micro: number) => {
  return micro / 1000000;
};

export const getNormalValueToMicro = (value: number) => {
  return Math.floor(+(value * 1000000).toFixed(2));
};

export function extractErrorMessage(error: any): string {
  let errorMessage;
  if (error) {
    if (_.isString(error)) {
      errorMessage = error;
    } else if (error.response && error.response.data) {
      if (_.isObject(error.response.data)) {
        if ('error' in error.response.data) {
          errorMessage = error.response.data.error;
        } else if ('message' in error.response.data) {
          errorMessage = error.response.data.message;
        } else if ('reason' in error.response.data) {
          errorMessage = error.response.data.reason;
        } else {
          errorMessage = JSON.stringify(error.response.data);
        }
      } else {
        errorMessage = error.response.data;
      }
    } else {
      errorMessage = JSON.stringify(error.message);
    }
    return errorMessage;
  }
  return '';
}

export const includesObject = (arrayOfObj: Array<object>, targetObj: object) => {
  // eslint-disable-next-line no-restricted-syntax
  for (const obj of arrayOfObj) {
    if (_.isEqual(obj, targetObj)) return true;
  }
  return false;
};

export function getUserTimezone() {
  try {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  } catch {
    return 'America/Los_Angeles';
  }
}

const isMCRModeCached =
  !!process.env.REACT_APP_MCR_MODE ||
  _.some(MCR_HOSTS, (host) => window.location.host.includes(host));

export const isMCRHost = () => isMCRModeCached;

export function debugLog(...params: any[]) {
  return !deployEnv.isProd() && console.log(params);
}

export function debugTrace(...params: any[]) {
  return !deployEnv.isProd() && console.trace(params);
}
