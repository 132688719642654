import { createMuiTheme } from '@material-ui/core/styles';
import {
  dangerColor,
  grayColor,
  primaryColor,
  successColor,
  warningColor,
} from './assets/jss/rootStyle';

export default createMuiTheme({
  palette: {
    primary: primaryColor,
    secondary: successColor,
    warning: warningColor,
    danger: dangerColor,
    success: successColor,
    default: grayColor,
    action: {
      hover: 'rgba(78, 127, 237, 0.1)',
      selected: '#edf2fd',
    },
  },
});
