import _ from 'lodash';

const WORKPLACE_DEFAULT_LOGO =
  'https://storage.googleapis.com/morse-public/platform-images/workplace_no_logo_square.png';

// eslint-disable-next-line import/prefer-default-export
export class PlatformInfo {
  constructor(proto) {
    this.proto = proto || {};

    this.id = _.get(proto, 'id', '');
    this.title = _.get(proto, 'title', '');
    this.iconUrl = _.get(proto, 'logo_url') || WORKPLACE_DEFAULT_LOGO;
  }

  static fromProto = (proto) => {
    return new PlatformInfo(proto);
  };

  static toProto = (uiPlatformInfo) => {
    const proto = _.cloneDeep(uiPlatformInfo.proto);

    _.set(proto, 'id', uiPlatformInfo.id);
    _.set(proto, 'title', uiPlatformInfo.title);
    _.set(proto, 'logo_url', uiPlatformInfo.iconUrl);

    return proto;
  };
}
