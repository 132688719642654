import React from 'react';

export const createKeySnatcher = (targetKeys = '') => {
  let enteredKey = '';
  const targetKeysLength = targetKeys.length;

  return (key) => {
    enteredKey = (enteredKey + key).slice(-targetKeysLength);
    return enteredKey === targetKeys;
  };
};

export function useKeySnatcher(targetKeys, callBackFunc) {
  const keySnatcher = React.useMemo(() => createKeySnatcher(targetKeys), [targetKeys]);

  const listener = ({ key }) => {
    if (keySnatcher(key)) {
      callBackFunc();
    }
  };

  React.useEffect(() => {
    window.addEventListener('keydown', listener);
    return () => window.removeEventListener('keydown', listener);
  });
}
