/*
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here. We prefix them with 'componentName' so we avoid
 * reducers accidentally picking up actions they shouldn't.
 *
 * Follow this format:
 * export const YOUR_ACTION_CONSTANT = 'YourContainer/YOUR_ACTION_CONSTANT';
 */

import { ACTION_TYPE_CAMPAIGN_MANAGER } from '../consts.ts';

export const ACTION_TYPE_PRICING_MODEL = 'pricingModel';

export const LIST_PRICING_MODELS = `${ACTION_TYPE_CAMPAIGN_MANAGER}/${ACTION_TYPE_PRICING_MODEL}/LIST_PRICING_MODELS`;
export const LIST_PRICING_MODELS_SUCCESS = `${ACTION_TYPE_CAMPAIGN_MANAGER}/${ACTION_TYPE_PRICING_MODEL}/LIST_PRICING_MODELS_SUCCESS`;
export const LIST_PRICING_MODELS_ERROR = `${ACTION_TYPE_CAMPAIGN_MANAGER}/${ACTION_TYPE_PRICING_MODEL}/LIST_PRICING_MODELS_ERROR`;

export const GET_PRICING_MODEL = `${ACTION_TYPE_CAMPAIGN_MANAGER}/${ACTION_TYPE_PRICING_MODEL}/GET_PRICING_MODEL`;
export const GET_PRICING_MODEL_SUCCESS = `${ACTION_TYPE_CAMPAIGN_MANAGER}/${ACTION_TYPE_PRICING_MODEL}/GET_PRICING_MODEL_SUCCESS`;
export const GET_PRICING_MODEL_ERROR = `${ACTION_TYPE_CAMPAIGN_MANAGER}/${ACTION_TYPE_PRICING_MODEL}/GET_PRICING_MODEL_ERROR`;

export const SAVE_PRICING_MODEL = `${ACTION_TYPE_CAMPAIGN_MANAGER}/${ACTION_TYPE_PRICING_MODEL}/SAVE_PRICING_MODEL`;
export const SAVE_PRICING_MODEL_SUCCESS = `${ACTION_TYPE_CAMPAIGN_MANAGER}/${ACTION_TYPE_PRICING_MODEL}/SAVE_PRICING_MODEL_SUCCESS`;
export const SAVE_PRICING_MODEL_ERROR = `${ACTION_TYPE_CAMPAIGN_MANAGER}/${ACTION_TYPE_PRICING_MODEL}/SAVE_PRICING_MODEL_ERROR`;
