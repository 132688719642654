import produce from 'immer';
import _ from 'lodash';

import {
  LIST_REPORT_EXPORT_REQUEST,
  LIST_REPORT_EXPORT_SUCCESS,
  SAVE_REPORT_EXPORT_SUCCESS,
  DELETE_REPORT_EXPORT_SUCCESS,
} from './types';

// The initial state of the Audiences.
export const initialState = {
  loading: false,
  updating: false,
  loadError: null,
  updateError: null,
  lastListRequestTs: {},
  lastEntityRequestTs: {},
  entities: {},
};

function reportExportReducer(state = initialState, action) {
  // eslint-disable-next-line consistent-return
  return produce(state, (draft) => {
    // eslint-disable-next-line default-case
    switch (action.type) {
      case LIST_REPORT_EXPORT_REQUEST: {
        draft.lastListRequestTs = Date.now();
        break;
      }
      case LIST_REPORT_EXPORT_SUCCESS: {
        const { reportExports, adAccountId } = action.payload;
        const keyMap = _.keyBy(reportExports, 'id');

        draft.entities[adAccountId] = keyMap;
        break;
      }
      case SAVE_REPORT_EXPORT_SUCCESS: {
        const { adAccountId, reportExport, reportExportId } = action.payload;

        if (_.has(draft.entities, reportExportId)) {
          draft.entities[adAccountId][reportExportId] = reportExport;
        }
        break;
      }
      case DELETE_REPORT_EXPORT_SUCCESS: {
        const { reportExportId, adAccountId } = action.payload;

        if (reportExportId) {
          delete draft.entities[adAccountId][reportExportId];
        }
        break;
      }
      default:
        break;
    }
  });
}

export default reportExportReducer;
