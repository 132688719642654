import NotificationPreference, {
  MINotificationTemplate,
} from 'common-v2/model/notificationPreference';
import { ACTION_TYPE_NOTIFICATION } from '../types';

export const ACTION_TYPE_PREFERENCES = `${ACTION_TYPE_NOTIFICATION}/preferences`;

export const LIST_NOTIFICATION_PREFERENCES_REQUEST = `${ACTION_TYPE_PREFERENCES}/LIST_NOTIFICATION_PREFERENCES_REQUEST`;
export const LIST_NOTIFICATION_PREFERENCES_SUCCESS = `${ACTION_TYPE_PREFERENCES}/LIST_NOTIFICATION_PREFERENCES_SUCCESS`;
export const LIST_NOTIFICATION_PREFERENCES_ERROR = `${ACTION_TYPE_PREFERENCES}/LIST_NOTIFICATION_PREFERENCES_ERROR`;

export const SAVE_NOTIFICATION_PREFERENCES_REQUEST = `${ACTION_TYPE_PREFERENCES}/SAVE_NOTIFICATION_PREFERENCES_REQUEST`;
export const SAVE_NOTIFICATION_PREFERENCES_SUCCESS = `${ACTION_TYPE_PREFERENCES}/SAVE_NOTIFICATION_PREFERENCES_SUCCESS`;
export const SAVE_NOTIFICATION_PREFERENCES_ERROR = `${ACTION_TYPE_PREFERENCES}/SAVE_NOTIFICATION_PREFERENCES_ERROR`;

export interface MIListNotificationPreferencesRequestAction {
  type: typeof LIST_NOTIFICATION_PREFERENCES_REQUEST;
}

export interface MIListNotificationPreferencesSuccessAction {
  type: typeof LIST_NOTIFICATION_PREFERENCES_SUCCESS;
  payload: {
    preferences: Array<NotificationPreference>;
    templates: Array<MINotificationTemplate>;
  };
}

export interface MIListNotificationPreferencesErrorAction {
  type: typeof LIST_NOTIFICATION_PREFERENCES_ERROR;
  payload: {
    error: string;
  };
}

export interface MISaveNotificationPreferencesRequestAction {
  type: typeof SAVE_NOTIFICATION_PREFERENCES_REQUEST;
}

export interface MISaveNotificationPreferencesSuccessAction {
  type: typeof SAVE_NOTIFICATION_PREFERENCES_SUCCESS;
  payload: {
    preferences: Array<NotificationPreference>;
  };
}

export interface MISaveNotificationPreferencesErrorAction {
  type: typeof SAVE_NOTIFICATION_PREFERENCES_ERROR;
  payload: {
    error: string;
  };
}

export type MIListNotificationPreferencesAction =
  | MIListNotificationPreferencesRequestAction
  | MIListNotificationPreferencesSuccessAction
  | MIListNotificationPreferencesErrorAction;

export type MISaveNotificationPreferencesAction =
  | MISaveNotificationPreferencesRequestAction
  | MISaveNotificationPreferencesSuccessAction
  | MISaveNotificationPreferencesErrorAction;

export type MINotificationPreferencesAction =
  | MIListNotificationPreferencesAction
  | MISaveNotificationPreferencesAction;
