import axios from 'axios';

import { ADCLOUD_PUBLISHER_V1_API_PATH } from '../constants/apiEndpoints';
import GlobalStore, { STORE_KEY } from '../global/globalStorage';

function getBaseEndpoint() {
  return `${ADCLOUD_PUBLISHER_V1_API_PATH}/platforms/${GlobalStore.get(STORE_KEY.PLATFORM)}`;
}

function getHeader() {
  return {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${GlobalStore.get(STORE_KEY.AUTH_TOKEN)}`,
  };
}

function listExchanges() {
  const config = {
    method: 'GET',
    url: `${getBaseEndpoint()}/private-exchanges`,
    headers: getHeader(),
  };
  return axios(config);
}

function updateExchange(exchange) {
  const config = {
    method: 'PUT',
    url: `${getBaseEndpoint()}/private-exchanges/${exchange.id}`,
    headers: getHeader(),
    data: exchange,
  };
  return axios(config);
}

function listCreativeReviews(exchangeId) {
  const config = {
    method: 'GET',
    url: `${getBaseEndpoint()}/private-exchanges/${exchangeId}/creative-reviews`,
    headers: getHeader(),
  };
  return axios(config);
}

function getCreativeReview(creativeReviewId) {
  const config = {
    method: 'GET',
    url: `${getBaseEndpoint()}/creative-reviews/${creativeReviewId}`,
    headers: getHeader(),
  };
  return axios(config);
}

function updateCreativeReviews(exchangeId, creativeReviewActions) {
  const config = {
    method: 'POST',
    url: `${getBaseEndpoint()}/private-exchanges/${exchangeId}/creative-reviews`,
    headers: getHeader(),
    data: creativeReviewActions,
  };
  return axios(config);
}

function listPublishers(exchangeID) {
  const config = {
    method: 'GET',
    url: `${getBaseEndpoint()}/private-exchanges/${exchangeID}/publishers`,
    headers: getHeader(),
  };
  return axios(config);
}

function getPublisher(publisherID) {
  const config = {
    method: 'GET',
    url: `${getBaseEndpoint()}/publishers/${publisherID}`,
    headers: getHeader(),
  };
  return axios(config);
}

function createPublisher(publisher) {
  const config = {
    method: 'POST',
    url: `${getBaseEndpoint()}/publishers`,
    headers: getHeader(),
    data: publisher,
  };
  return axios(config);
}

function updatePublisher(publisherID, publisher) {
  const config = {
    method: 'PUT',
    url: `${getBaseEndpoint()}/publishers/${publisherID}`,
    headers: getHeader(),
    data: publisher,
  };
  return axios(config);
}

function deletePublisher(publisherID) {
  const config = {
    method: 'DELETE',
    url: `${getBaseEndpoint()}/publishers/${publisherID}`,
    headers: getHeader(),
  };
  return axios(config);
}

function listPublisherApps(publisherID) {
  const config = {
    method: 'GET',
    url: `${getBaseEndpoint()}/publishers/${publisherID}/publisher-apps`,
    headers: getHeader(),
  };
  return axios(config);
}

function getPublisherApp(publisherAppID) {
  const config = {
    method: 'GET',
    url: `${getBaseEndpoint()}/publisher-apps/${publisherAppID}`,
    headers: getHeader(),
  };
  return axios(config);
}

function createPublisherApp(publisherApp) {
  const config = {
    method: 'POST',
    url: `${getBaseEndpoint()}/publisher-apps`,
    headers: getHeader(),
    data: publisherApp,
  };
  return axios(config);
}

function updatePublisherApp(publisherAppID, publisherApp) {
  const config = {
    method: 'PUT',
    url: `${getBaseEndpoint()}/publisher-apps/${publisherAppID}`,
    headers: getHeader(),
    data: publisherApp,
  };
  return axios(config);
}

function deletePublisherApp(publisherAppID) {
  const config = {
    method: 'DELETE',
    url: `${getBaseEndpoint()}/publisher-apps/${publisherAppID}`,
    headers: getHeader(),
  };
  return axios(config);
}

function getPublisherReport(exchangeId, publisherId, publisherAppId, dateRange, groupBy) {
  const config = {
    method: 'GET',
    url: `${getBaseEndpoint()}/private-exchanges/${exchangeId}/reports`,
    headers: getHeader(),
  };

  config.params = new URLSearchParams();
  groupBy.forEach((col) => config.params.append('group_by', col));
  // config.params.append('group_by', 'DATE');
  // config.params.append('group_by', 'PLATFORM');
  config.params.append('date_range.start', dateRange.start);
  config.params.append('date_range.end', dateRange.end);

  if (publisherId) {
    config.params.publisher_id = publisherId;
  }

  if (publisherAppId) {
    config.params.publisher_app_id = publisherAppId;
  }
  return axios(config);
}

function listAdUnits(publisherAppID) {
  const config = {
    method: 'GET',
    url: `${getBaseEndpoint()}/publisher-apps/${publisherAppID}/ad-units`,
    headers: getHeader(),
  };
  return axios(config);
}

function getAdUnit(adUnitID) {
  const config = {
    method: 'GET',
    url: `${getBaseEndpoint()}/ad-units/${adUnitID}`,
    headers: getHeader(),
  };
  return axios(config);
}

function createAdUnit(adUnit) {
  const config = {
    method: 'POST',
    url: `${getBaseEndpoint()}/ad-units`,
    headers: getHeader(),
    data: adUnit,
  };
  return axios(config);
}

function updateAdUnit(adUnitID, adUnit) {
  const config = {
    method: 'PUT',
    url: `${getBaseEndpoint()}/ad-units/${adUnitID}`,
    headers: getHeader(),
    data: adUnit,
  };
  return axios(config);
}

function deleteAdUnit(adUnitID) {
  const config = {
    method: 'DELETE',
    url: `${getBaseEndpoint()}/ad-units/${adUnitID}`,
    headers: getHeader(),
  };
  return axios(config);
}

export default {
  listExchanges,
  updateExchange,
  listCreativeReviews,
  getCreativeReview,
  updateCreativeReviews,
  listPublishers,
  getPublisher,
  createPublisher,
  updatePublisher,
  deletePublisher,
  listPublisherApps,
  getPublisherApp,
  createPublisherApp,
  updatePublisherApp,
  deletePublisherApp,
  getPublisherReport,
  listAdUnits,
  getAdUnit,
  createAdUnit,
  updateAdUnit,
  deleteAdUnit,
};
