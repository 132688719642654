import React, { useEffect, useRef, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import Select from 'react-select';
import { Form, Formik } from 'formik2';
import styled, { css } from 'styled-components';

import MUIList from '@material-ui/core/List';
import MUIListItemIcon from '@material-ui/core/ListItemIcon';
import MUIListItemText from '@material-ui/core/ListItemText';
import MUITooltip from '@material-ui/core/Tooltip';

import { AUTH_STATE } from 'redux/auth/types';
import { RootState } from 'redux/types';
import {
  getAvailablePlatforms,
  setUseMorse,
  setUserSpace,
  signInFirebase,
  signInMorse,
} from 'redux/auth/actions';
import { PlatformInfo } from 'common-v2/model/platformInfo';
import * as routes from 'common-v2/constants/routes';
import { USER_SPACE_OPTIONS, USER_SPACES } from 'common-v2/constants/common';
import { validateUIObject, Validators } from 'common-v2/utils/modelUtils';
import deployEnv from 'common-v2/constants/deployEnv';
import { MEButtonColor, MEButtonVariant } from 'components-v2/Common/Button/MCButton';
import { MEFormFieldWidth } from 'components-v2/Common/Form/consts';
import MCFormTextField from 'components-v2/Common/Form/MCFormTextField';
import MCLoader from 'components-v2/Common/MCLoader';
import withTracking from 'containers/Common/withTracking';
import {
  PlatformListIcon,
  PlatformListItem,
  SCAuthButton,
  SCAuthErrorMessage,
  SCPageTitle,
} from './AuthStyledComponents';
import MCAuthLayout from './MCAuthLayout';

export const SCForgotPasswordContainer = styled.div`
  width: 100%;
  margin-top: -${(props) => props.theme.spacing(1)}px;
  margin-bottom: ${(props) => props.theme.spacing(2)}px;
  text-align: end;
  font-size: 12px;
  color: #999999;
  & > a {
    text-decoration: none;
  }
`;

const LIST_ITEM_HEIGHT = 60;
const MAX_WORKPLACE = 6;

// eslint-disable-next-line react/jsx-props-no-spreading
const SCList = styled(({ overflow, ...rest }) => <MUIList {...rest} />)<{
  overflow: boolean;
}>`
  max-height: ${LIST_ITEM_HEIGHT * MAX_WORKPLACE + LIST_ITEM_HEIGHT / 2}px;
  ${(props) =>
    props.overflow &&
    css`
      overflow: auto;
      background: linear-gradient(white 30%, rgba(255, 255, 255, 0)),
        linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%,
        radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0)),
        radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0)) 0 100%;
      background-repeat: no-repeat;
      background-color: white;
      background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;
      background-attachment: local, local, scroll, scroll;
    `}
`;

const SCListItemText = styled(MUIListItemText)`
  word-break: break-all;
  line-break: anywhere;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

type MTPlatforms = Array<{ id: string }>;

type MTPlatformListItemProps = {
  platformInfo: PlatformInfo;
  onClick: () => void;
};

const MCPlatformListItem = (props: MTPlatformListItemProps) => {
  const { platformInfo, onClick } = props;
  const itemTextRef = useRef<HTMLDivElement>(null);
  const [overflow, setOverflow] = useState<boolean>(false);

  useEffect(() => {
    const itemText = itemTextRef.current;
    if (!overflow && itemText && itemText.scrollWidth > itemText.offsetWidth) {
      setOverflow(true);
    }
  }, [overflow]);

  return (
    <MUITooltip title={platformInfo.title} disableHoverListener={!overflow}>
      <PlatformListItem alignItems="center" button key={platformInfo.id} onClick={onClick}>
        <MUIListItemIcon>
          <PlatformListIcon src={platformInfo.iconUrl} alt={platformInfo.id} />
        </MUIListItemIcon>
        <SCListItemText ref={itemTextRef} primary={platformInfo.title} />
      </PlatformListItem>
    </MUITooltip>
  );
};

const LOGIN_VALIDATION_SCHEMA = {
  email: [Validators.required, Validators.emailAddress],
  password: [Validators.required],
};

type MTLoginInfo = {
  email: string;
  password: string;
};

function MCSignIn() {
  const history = useHistory();
  const { search: searchParams } = useLocation();

  const [loginInfo, setLoginInfo] = useState<MTLoginInfo | null>(null);
  const [error, setError] = useState('');
  const { useMorse, userSpace, authMsg, authState, availablePlatforms } = useSelector(
    (state: RootState) => ({
      useMorse: state.auth.useMorse,
      userSpace: state.auth.userSpace,
      authMsg: state.auth.authMsg,
      authState: state.auth.state,
      availablePlatforms: state.auth.availablePlatforms,
    }),
    shallowEqual,
  );
  const dispatch = useDispatch();

  if (authState === AUTH_STATE.NOT_READY) {
    return <MCLoader />;
  }

  const handleSelectPlatform = (
    platformId: string,
    info: MTLoginInfo | null,
    redirectPath?: string,
  ) => {
    if (!info) return;

    dispatch(
      signInMorse(platformId, info.email, info.password, (errMsg: string) => {
        if (errMsg) {
          setError(errMsg);
        } else {
          setLoginInfo(null);
          setError('');
          history.replace(redirectPath || routes.LANDING);
        }
      }),
    );
  };

  const handleRedirectToPrevious = (platformInfos: MTPlatforms, info: MTLoginInfo) => {
    const redirectPath = new URLSearchParams(searchParams).get('redirect');
    const matchedPlatform = platformInfos.filter(({ id }) => redirectPath?.includes(`/p/${id}/`));

    if (redirectPath && matchedPlatform.length === 1) {
      handleSelectPlatform(matchedPlatform[0].id, info, redirectPath);
    }
  };

  const handleEmailLogin = (values: MTLoginInfo) => {
    const { email, password } = values;

    setError('');
    setLoginInfo({ email, password });

    if (useMorse) {
      dispatch(
        getAvailablePlatforms(email, password, (err: string, platformInfos: MTPlatforms) => {
          if (err) {
            setError(err);
          } else if (platformInfos) {
            if (searchParams) {
              handleRedirectToPrevious(platformInfos, { email, password });
            } else if (platformInfos.length === 1) {
              handleSelectPlatform(platformInfos[0].id, { email, password });
            }
          }
        }),
      );
    } else {
      dispatch(
        signInFirebase(email, password, (err: string) => {
          if (err) {
            setError(err);
          } else {
            setLoginInfo({ email, password });
            setError('');
            history.replace(routes.LANDING);
          }
        }),
      );
    }
  };

  const handleSignInValidation = (values: MTLoginInfo): { email?: string; password?: string } => {
    return validateUIObject(values, LOGIN_VALIDATION_SCHEMA);
  };

  const handleKeyDownOnPassword = (event: any, values: MTLoginInfo) => {
    if (event.keyCode === 13) {
      handleEmailLogin(values);
    }
  };

  const handleChangeUserSpace = (t: any) => {
    const selectedUserSpace = t.value;
    if (
      selectedUserSpace === USER_SPACES.USERSPACE_C ||
      selectedUserSpace === USER_SPACES.USERSPACE_MOLOCO
    ) {
      dispatch(setUseMorse(true));
    } else {
      dispatch(setUseMorse(false));
    }
    dispatch(setUserSpace(selectedUserSpace));
  };

  const getLoginInput = () => {
    return (
      <>
        {deployEnv.isDev() && (
          <Select
            options={USER_SPACE_OPTIONS}
            value={{ label: userSpace, value: userSpace }}
            onChange={handleChangeUserSpace}
          />
        )}
        <Formik
          validateOnChange
          enableReinitialize
          initialValues={{ email: '', password: '' }}
          validate={handleSignInValidation}
          onSubmit={handleEmailLogin}
        >
          {(formikProps) => {
            return (
              <Form>
                <MCFormTextField
                  name="email"
                  width={MEFormFieldWidth.FULL}
                  label="Email"
                  placeholder="email@domain.com"
                />
                <MCFormTextField
                  name="password"
                  width={MEFormFieldWidth.FULL}
                  label="Password"
                  type="password"
                  placeholder="******"
                  onKeyDown={(e) => handleKeyDownOnPassword(e, formikProps.values)}
                />
                <SCForgotPasswordContainer>
                  <NavLink data-testid="forgotPassword" to={routes.RESET_PASSWORD}>
                    Forgot password
                  </NavLink>
                </SCForgotPasswordContainer>
                {error && <SCAuthErrorMessage>{error}</SCAuthErrorMessage>}
                {authMsg && <SCAuthErrorMessage>{authMsg}</SCAuthErrorMessage>}
                <SCAuthButton
                  testId="signinButton"
                  variant={MEButtonVariant.CONTAINED}
                  color={MEButtonColor.PRIMARY}
                  onClick={formikProps.submitForm}
                  disabled={!formikProps.isValid}
                >
                  Login
                </SCAuthButton>
              </Form>
            );
          }}
        </Formik>
      </>
    );
  };

  const getPlatformSelection = () => {
    const overflow = availablePlatforms.length > MAX_WORKPLACE;
    return (
      <>
        <SCPageTitle>Select a workplace</SCPageTitle>
        <SCList component="nav" overflow={overflow}>
          {availablePlatforms.map((platformInfo) => (
            <MCPlatformListItem
              key={platformInfo.id}
              platformInfo={platformInfo}
              onClick={() => handleSelectPlatform(platformInfo.id, loginInfo)}
            />
          ))}
        </SCList>
      </>
    );
  };

  const getLoginView = () => {
    switch (authState) {
      case AUTH_STATE.SELECTING_PLATFORM:
        return getPlatformSelection();
      case AUTH_STATE.VERIFYING_ACCOUNT:
        return <MCLoader message="Logging in..." />;
      default:
        return getLoginInput();
    }
  };

  return <MCAuthLayout>{getLoginView()}</MCAuthLayout>;
}

export default withTracking(MCSignIn);
