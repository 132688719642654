export enum MEPollingState {
  POLLING_START = 'POLLING_START',
  POLLING_FINISH = 'POLLING_FINISH',
  POLLING = 'POLLING',
}

interface MIPollingStartAction {
  type: MEPollingState.POLLING_START;
  payload: {
    id: string;
    intervalId: number;
  };
}

interface MIPollingFinishAction {
  type: MEPollingState.POLLING_FINISH;
  payload: {
    id: string;
  };
}

interface MIPollingAction {
  type: MEPollingState.POLLING;
  payload: {
    id: string;
    response: any;
  };
}

export type MTProgressActionTypes = MIPollingStartAction | MIPollingFinishAction | MIPollingAction;
