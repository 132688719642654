/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useField } from 'formik2';

import {
  MCFormField,
  MCFormLabel,
  MCFormInput,
  MCFormHint,
  MCFormError,
  MCFormCharacterCounter,
  MCFormFieldBody,
  MCFormControl,
  MCFormFieldHelp,
  MCFormTextFieldPrefix,
  MCFormTextArea,
  MCFormTextFieldSuffix,
} from './FormStyledComponents';

import { MEFormFieldWidth } from './consts';
import MCFormTooltip from './MCFormTooltip';

export type MTTextFieldProps = {
  testId?: string;
  type?: string;
  name: string;
  label?: string;
  placeholder?: string;
  hint?: string;
  required?: boolean;
  useCharacterCounter?: boolean;
  totalCharacterLimit?: number;
  disabled?: boolean;
  width?: MEFormFieldWidth;
  prefix?: string;
  suffix?: string;
  multiline?: boolean;
  rows?: number;
  tooltip?: string;
  onKeyDown?: (v: React.KeyboardEvent) => void;
};

const MCFormTextField = ({
  testId,
  type = 'text',
  name,
  label,
  placeholder,
  hint,
  required = false,
  useCharacterCounter = false,
  totalCharacterLimit = 100,
  disabled = false,
  width,
  prefix,
  suffix,
  multiline = false,
  rows = 3,
  tooltip = undefined,
  onKeyDown = undefined,
}: MTTextFieldProps) => {
  const [field, meta, helpers] = useField(name);
  const { value } = meta;
  const characterCount = value ? value.length : 0;

  const handleOnBlur = (event: React.FocusEvent) => {
    field.onBlur(event);
    helpers.setValue(typeof value === 'string' ? value.trim() : value);
  };

  return (
    <MCFormField width={width}>
      {label && (
        <MCFormLabel htmlFor={name} required={required}>
          {label}
          {tooltip && <MCFormTooltip markdownText={tooltip} />}
        </MCFormLabel>
      )}
      <MCFormFieldBody>
        <MCFormControl>
          {prefix && <MCFormTextFieldPrefix>{prefix}</MCFormTextFieldPrefix>}
          {!multiline ? (
            <MCFormInput
              id={name}
              placeholder={placeholder}
              type={type}
              data-testid={testId}
              {...field}
              disabled={disabled}
              hasPrefix={!!prefix}
              hasSuffix={!!suffix}
              onKeyDown={onKeyDown}
              onBlur={handleOnBlur}
            />
          ) : (
            <MCFormTextArea
              id={name}
              placeholder={placeholder}
              {...field}
              disabled={disabled}
              hasPrefix={!!prefix}
              rows={rows}
              onKeyDown={onKeyDown}
              onBlur={handleOnBlur}
            />
          )}
          {/* Currently only allowed for single-line. */}
          {!multiline && suffix && <MCFormTextFieldSuffix>{suffix}</MCFormTextFieldSuffix>}
          {useCharacterCounter && (
            <MCFormCharacterCounter isOverflow={characterCount > totalCharacterLimit}>
              {characterCount} / {totalCharacterLimit}
            </MCFormCharacterCounter>
          )}
        </MCFormControl>
        <MCFormFieldHelp>
          {hint ? <MCFormHint>{hint}</MCFormHint> : null}
          {meta.touched && meta.error ? <MCFormError>{meta.error}</MCFormError> : null}
        </MCFormFieldHelp>
      </MCFormFieldBody>
    </MCFormField>
  );
};

export default MCFormTextField;
