/*
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here. We prefix them with `componentName` so we avoid
 * reducers accidentally picking up actions they shouldn`t.
 *
 * Follow this format:
 * export const YOUR_ACTION_CONSTANT = `YourContainer/YOUR_ACTION_CONSTANT`;
 */

import { ACTION_TYPE_INVENTORY_MANAGER } from '../types';

const ACTION_TYPE_CREATIVE_REVIEW = 'crReview';

export const LIST_CREATIVE_REVIEWS = `${ACTION_TYPE_INVENTORY_MANAGER}/${ACTION_TYPE_CREATIVE_REVIEW}/LIST_CREATIVE_REVIEWS`;
export const LIST_CREATIVE_REVIEWS_SUCCESS = `${ACTION_TYPE_INVENTORY_MANAGER}/${ACTION_TYPE_CREATIVE_REVIEW}/LIST_CREATIVE_REVIEWS_SUCCESS`;
export const LIST_CREATIVE_REVIEWS_ERROR = `${ACTION_TYPE_INVENTORY_MANAGER}/${ACTION_TYPE_CREATIVE_REVIEW}/LIST_CREATIVE_REVIEWS_ERROR`;

export const GET_CREATIVE_REVIEW = `${ACTION_TYPE_INVENTORY_MANAGER}/${ACTION_TYPE_CREATIVE_REVIEW}/GET_CREATIVE_REVIEW`;
export const GET_CREATIVE_REVIEW_SUCCESS = `${ACTION_TYPE_INVENTORY_MANAGER}/${ACTION_TYPE_CREATIVE_REVIEW}/GET_CREATIVE_REVIEW_SUCCESS`;
export const GET_CREATIVE_REVIEW_ERROR = `${ACTION_TYPE_INVENTORY_MANAGER}/${ACTION_TYPE_CREATIVE_REVIEW}/GET_CREATIVE_REVIEW_ERROR`;

export const SAVE_CREATIVE_REVIEWS = `${ACTION_TYPE_INVENTORY_MANAGER}/${ACTION_TYPE_CREATIVE_REVIEW}/SAVE_CREATIVE_REVIEWS`;
export const SAVE_CREATIVE_REVIEWS_ERROR = `${ACTION_TYPE_INVENTORY_MANAGER}/${ACTION_TYPE_CREATIVE_REVIEW}/SAVE_CREATIVE_REVIEWS_ERROR`;
export const SAVE_CREATIVE_REVIEWS_SUCCESS = `${ACTION_TYPE_INVENTORY_MANAGER}/${ACTION_TYPE_CREATIVE_REVIEW}/SAVE_CREATIVE_REVIEWS_SUCCESS`;
