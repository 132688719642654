/* eslint-disable no-unused-vars */
import _ from 'lodash';

export const COLUMN_TYPES = {
  GROUP_BY: 'GROUP_BY',
  BASE_METRICS: 'BASE_METRICS',
  POSTPROCESS_METRICS: 'POSTPROCESS_METRICS',
  BREAKDOWN: 'BREAKDOWN',
};

export const REPORT_SUMMARY_COLUMNS = {
  AD_ACCOUNT: 'AD_ACCOUNT',
  AD_ACCOUNT_ID: 'AD_ACCOUNT_ID',
  AD_ACCOUNT_TITLE: 'AD_ACCOUNT_TITLE',
  APP_OR_SITE: 'APP_OR_SITE',
  APP_OR_SITE_ID: 'APP_OR_SITE_ID',
  APP_OR_SITE_TITLE: 'APP_OR_SITE_TITLE',
  CAMPAIGN: 'CAMPAIGN',
  CAMPAIGN_ID: 'CAMPAIGN_ID',
  CAMPAIGN_TITLE: 'CAMPAIGN_TITLE',
  AD_GROUP: 'AD_GROUP',
  AD_GROUP_ID: 'AD_GROUP_ID',
  AD_GROUP_TITLE: 'AD_GROUP_TITLE',
  CREATIVE_GROUP: 'CREATIVE_GROUP',
  CREATIVE_GROUP_ID: 'CREATIVE_GROUP_ID',
  CREATIVE_GROUP_TITLE: 'CREATIVE_GROUP_TITLE',
  IMPRESSION: 'IMPRESSION',
  CLICK: 'CLICK',
  INSTALL: 'INSTALL',
  SPEND: 'SPEND',
  REVENUE: 'REVENUE',
  CTR: 'CTR',
  CPI: 'CPI',
  DATE: 'DATE',
};

export const REPORT_COLUMN_TYPE_SET = {
  [COLUMN_TYPES.GROUP_BY]: [
    REPORT_SUMMARY_COLUMNS.AD_ACCOUNT,
    REPORT_SUMMARY_COLUMNS.APP_OR_SITE,
    REPORT_SUMMARY_COLUMNS.CAMPAIGN,
    REPORT_SUMMARY_COLUMNS.AD_GROUP,
    REPORT_SUMMARY_COLUMNS.CREATIVE_GROUP,
  ],
  [COLUMN_TYPES.BASE_METRICS]: [
    REPORT_SUMMARY_COLUMNS.IMPRESSION,
    REPORT_SUMMARY_COLUMNS.CLICK,
    REPORT_SUMMARY_COLUMNS.INSTALL,
    REPORT_SUMMARY_COLUMNS.SPEND,
    REPORT_SUMMARY_COLUMNS.REVENUE,
  ],
  [COLUMN_TYPES.POSTPROCESS_METRICS]: [REPORT_SUMMARY_COLUMNS.CTR, REPORT_SUMMARY_COLUMNS.CPI],
  [COLUMN_TYPES.BREAKDOWN]: [REPORT_SUMMARY_COLUMNS.DATE],
};

// If you want to guarantee the latest data of groupBy column (e.g AD_ACCOUNT_TITLE), you should use it in an array sorted by the date order of the data.
// This Reducer is currently for only overviewTable.
export const campaignReportSummaryRowReducer = (accumulator, currentValue) => {
  const newValue = _.cloneDeep(currentValue);

  REPORT_COLUMN_TYPE_SET[COLUMN_TYPES.BASE_METRICS].forEach((column) => {
    newValue[column] = accumulator[column] + currentValue[column];
  });

  newValue[REPORT_SUMMARY_COLUMNS.SPEND] = _.round(newValue[REPORT_SUMMARY_COLUMNS.SPEND], 2);
  newValue[REPORT_SUMMARY_COLUMNS.REVENUE] = _.round(newValue[REPORT_SUMMARY_COLUMNS.REVENUE], 2);
  newValue[REPORT_SUMMARY_COLUMNS.CTR] =
    newValue[REPORT_SUMMARY_COLUMNS.IMPRESSION] > 0
      ? _.round(
          (newValue[REPORT_SUMMARY_COLUMNS.CLICK] / newValue[REPORT_SUMMARY_COLUMNS.IMPRESSION]) *
            100.0,
          3,
        )
      : 0.0;
  newValue[REPORT_SUMMARY_COLUMNS.CPI] =
    newValue[REPORT_SUMMARY_COLUMNS.INSTALL] > 0
      ? _.round(
          newValue[REPORT_SUMMARY_COLUMNS.SPEND] / newValue[REPORT_SUMMARY_COLUMNS.INSTALL],
          2,
        )
      : 0.0;

  return newValue;
};

export class CampaignReportSummaryRow {
  constructor(proto) {
    const adAccountId = _.get(proto, 'ad_account.id', '');
    const adAccountTitle = _.get(proto, 'ad_account.title', '');
    const appId = _.get(proto, 'app.id', '');
    const appTitle = _.get(proto, 'app.title', '');
    const campaignId = _.get(proto, 'campaign.id', '');
    const campaignTitle = _.get(proto, 'campaign.title', '');
    const adGroupId = _.get(proto, 'ad_group.id', '');
    const adGroupTitle = _.get(proto, 'ad_group.title', '');
    const creativeGroupId = _.get(proto, 'creative_group.id', '');
    const creativeGroupTitle = _.get(proto, 'creative_group.title', '');
    const impression = parseInt(_.get(proto, 'metric.impressions', 0), 10);
    const click = parseInt(_.get(proto, 'metric.clicks', 0), 10);
    const install = parseInt(_.get(proto, 'metric.installs', 0), 10);
    const spend = _.round(_.get(proto, 'metric.spend', 0.0), 2);
    const revenue = _.round(_.get(proto, 'metric.revenue', 0.0), 2);
    const date = _.get(proto, 'date', '');
    const ctr = impression > 0 ? _.round((click / impression) * 100.0, 3) : 0.0;
    const cpi = install > 0 ? _.round(spend / install, 2) : 0.0;

    this.currency = _.get(proto, 'ad_account.currency', null);
    _.set(
      this,
      REPORT_SUMMARY_COLUMNS.AD_ACCOUNT,
      adAccountId ? `${adAccountTitle} (${adAccountId})` : '',
    );
    _.set(this, REPORT_SUMMARY_COLUMNS.AD_ACCOUNT_ID, adAccountId);
    _.set(this, REPORT_SUMMARY_COLUMNS.AD_ACCOUNT_TITLE, adAccountTitle);
    _.set(this, REPORT_SUMMARY_COLUMNS.APP_OR_SITE, appId ? `${appTitle} (${appId})` : '');
    _.set(this, REPORT_SUMMARY_COLUMNS.APP_OR_SITE_ID, appId);
    _.set(this, REPORT_SUMMARY_COLUMNS.APP_OR_SITE_TITLE, appTitle);
    _.set(
      this,
      REPORT_SUMMARY_COLUMNS.CAMPAIGN,
      campaignId ? `${campaignTitle} (${campaignId})` : '',
    );
    _.set(this, REPORT_SUMMARY_COLUMNS.CAMPAIGN_ID, campaignId);
    _.set(this, REPORT_SUMMARY_COLUMNS.CAMPAIGN_TITLE, campaignTitle);
    _.set(this, REPORT_SUMMARY_COLUMNS.AD_GROUP, adGroupId ? `${adGroupTitle} (${adGroupId})` : '');
    _.set(this, REPORT_SUMMARY_COLUMNS.AD_GROUP_ID, adGroupId);
    _.set(this, REPORT_SUMMARY_COLUMNS.AD_GROUP_TITLE, adGroupTitle);
    _.set(
      this,
      REPORT_SUMMARY_COLUMNS.CREATIVE_GROUP,
      creativeGroupId ? `${creativeGroupTitle} (${creativeGroupId})` : '',
    );
    _.set(this, REPORT_SUMMARY_COLUMNS.CREATIVE_GROUP_ID, creativeGroupId);
    _.set(this, REPORT_SUMMARY_COLUMNS.CREATIVE_GROUP_TITLE, creativeGroupTitle);
    _.set(this, REPORT_SUMMARY_COLUMNS.IMPRESSION, impression);
    _.set(this, REPORT_SUMMARY_COLUMNS.CLICK, click);
    _.set(this, REPORT_SUMMARY_COLUMNS.INSTALL, install);
    _.set(this, REPORT_SUMMARY_COLUMNS.SPEND, spend);
    _.set(this, REPORT_SUMMARY_COLUMNS.REVENUE, revenue);
    _.set(this, REPORT_SUMMARY_COLUMNS.CTR, ctr);
    _.set(this, REPORT_SUMMARY_COLUMNS.CPI, cpi);
    _.set(this, REPORT_SUMMARY_COLUMNS.DATE, date);

    return this;
  }

  static fromProto = (proto) => {
    const row = new CampaignReportSummaryRow(proto);
    return row;
  };
}

// eslint-disable-next-line import/prefer-default-export
export class CampaignReportSummary {
  rows = [];

  constructor(proto) {
    const rowsProto = _.get(proto, 'rows', []);
    this.rows = rowsProto.map((row) => CampaignReportSummaryRow.fromProto(row));
  }

  static fromProto = (proto) => {
    const uiObject = new CampaignReportSummary(proto);
    return uiObject;
  };
}
