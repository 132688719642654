import produce, { current } from 'immer';
import _ from 'lodash';

import { MINotificationDeliveriesState } from 'redux/types';
import NotificationDelivery from 'common-v2/model/notificationDelivery';

import {
  LOAD_CACHED_NOTIFICATION_INTERVAL,
  POLLING_NOTIFICATION_DELIVERIES_REQUEST,
  POLLING_NOTIFICATION_DELIVERIES_SUCCESS,
  POLLING_NOTIFICATION_DELIVERIES_ERROR,
  READ_NOTIFICATION_DELIVERIES_REQUEST,
  READ_NOTIFICATION_DELIVERIES_SUCCESS,
  READ_NOTIFICATION_DELIVERIES_ERROR,
  LOAD_NOTIFICATION_DELIVERIES_REQUEST,
  LOAD_NOTIFICATION_DELIVERIES_SUCCESS,
  LOAD_NOTIFICATION_DELIVERIES_ERROR,
  MILoadCachedNotificationIntervalAction,
  MTNotificationDeliveriesAction,
  MIPollingNotificationDeliveriesSuccessAction,
  MIPollingNotificationDeliveriesErrorAction,
  MIReadNotificationDeliveriesSuccessAction,
  MIReadNotificationDeliveriesErrorAction,
  MILoadNotificationDeliveriesSuccessAction,
  MILoadNotificationDeliveriesErrorAction,
} from './types';
import { setLocalCacheObject } from '../../../common-v2/utils/browserCache';

export const INITIAL_STATE: MINotificationDeliveriesState = {
  entities: {},
  interval: {
    end: new Date().getTime(),
    start: new Date().getTime(),
  },
  loading: false,
  updating: false,
  polling: false,
  loadError: undefined,
  updateError: undefined,
  pollError: undefined,
};

function deliveriesReducer(state = INITIAL_STATE, action: MTNotificationDeliveriesAction) {
  return produce(state, (draft) => {
    let deliveryCacheKey;
    switch (action.type) {
      case LOAD_CACHED_NOTIFICATION_INTERVAL: {
        const { interval } = (action as MILoadCachedNotificationIntervalAction).payload;
        draft.interval = interval;
        break;
      }
      case POLLING_NOTIFICATION_DELIVERIES_REQUEST: {
        draft.pollError = undefined;
        draft.polling = true;
        break;
      }
      case POLLING_NOTIFICATION_DELIVERIES_SUCCESS: {
        const {
          cacheKey,
          deliveries,
          intervalEnd,
        } = (action as MIPollingNotificationDeliveriesSuccessAction).payload;

        deliveryCacheKey = cacheKey;
        deliveries.forEach((delivery: NotificationDelivery) => {
          _.set(draft.entities, delivery.id, delivery);
        });

        draft.polling = false;
        draft.interval.end = intervalEnd;
        break;
      }
      case POLLING_NOTIFICATION_DELIVERIES_ERROR: {
        const { error } = (action as MIPollingNotificationDeliveriesErrorAction).payload;
        draft.pollError = error;
        draft.polling = false;
        break;
      }
      case LOAD_NOTIFICATION_DELIVERIES_REQUEST: {
        draft.loadError = undefined;
        draft.loading = true;
        break;
      }
      case LOAD_NOTIFICATION_DELIVERIES_SUCCESS: {
        const {
          cacheKey,
          deliveries,
          intervalStart,
        } = (action as MILoadNotificationDeliveriesSuccessAction).payload;

        deliveryCacheKey = cacheKey;
        deliveries.forEach((delivery: NotificationDelivery) => {
          _.set(draft.entities, delivery.id, delivery);
        });

        draft.loading = false;
        draft.interval.start = intervalStart;
        break;
      }
      case LOAD_NOTIFICATION_DELIVERIES_ERROR: {
        const { error } = (action as MILoadNotificationDeliveriesErrorAction).payload;
        draft.loadError = error;
        draft.loading = false;
        break;
      }
      case READ_NOTIFICATION_DELIVERIES_REQUEST: {
        draft.updateError = undefined;
        draft.updating = true;
        break;
      }
      case READ_NOTIFICATION_DELIVERIES_SUCCESS: {
        const {
          cacheKey,
          deliveryIds,
        } = (action as MIReadNotificationDeliveriesSuccessAction).payload;

        deliveryCacheKey = cacheKey;
        deliveryIds.forEach((id: string) => {
          draft.entities[id].readFlag = true;
        });

        draft.updating = false;
        break;
      }
      case READ_NOTIFICATION_DELIVERIES_ERROR: {
        const { error } = (action as MIReadNotificationDeliveriesErrorAction).payload;
        draft.updateError = error;
        draft.updating = false;
        break;
      }
      default:
        break;
    }

    if (deliveryCacheKey) {
      const interval = current(draft.interval);
      setLocalCacheObject(deliveryCacheKey, interval);
    }
  });
}

export default deliveriesReducer;
