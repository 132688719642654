import React from 'react';
import PropTypes from 'prop-types';
import { Remarkable } from 'remarkable';

const md = new Remarkable({ breaks: true, html: true });

function Markdown(props) {
  const { markdownText } = props;
  // eslint-disable-next-line react/no-danger
  return <div dangerouslySetInnerHTML={{ __html: md.render(markdownText) }} />;
}

Markdown.propTypes = {
  markdownText: PropTypes.string.isRequired,
};

export default Markdown;
