import produce from 'immer';
import _ from 'lodash';

import {
  LIST_TRACKING_LINK_VERIFICATION_REQUEST,
  LIST_TRACKING_LINK_VERIFICATION_SUCCESS,
  LIST_TRACKING_LINK_VERIFICATION_ERROR,
} from './types';

// The initial state of the Tracking Links.
export const initialState = {
  loading: false,
  updating: false,
  loadError: null,
  updateError: null,
  entities: {},
};

function trackingLinkTestsReducer(state = initialState, action) {
  // eslint-disable-next-line consistent-return
  return produce(state, (draft) => {
    // eslint-disable-next-line default-case
    switch (action.type) {
      case LIST_TRACKING_LINK_VERIFICATION_REQUEST: {
        draft.loading = true;
        draft.loadError = null;
        break;
      }
      case LIST_TRACKING_LINK_VERIFICATION_SUCCESS: {
        const { advertiserId, productId, trackingLinkId, verificationAttempts } = action.payload;
        draft.loading = false;
        _.set(draft.entities, [advertiserId, productId, trackingLinkId], verificationAttempts);
        break;
      }
      case LIST_TRACKING_LINK_VERIFICATION_ERROR: {
        const { error } = action.payload;

        draft.loading = false;
        draft.loadError = error;
        break;
      }
      default:
        break;
    }
  });
}

export default trackingLinkTestsReducer;
