import _ from 'lodash';
import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';

import api from 'common-v2/api';
import { extractErrorMessage } from 'common-v2/utils';
import { getLocalCacheObject } from 'common-v2/utils/browserCache';
import { getWebNotificationCacheKey } from 'common-v2/utils/browserCacheKeys';
import NotificationDelivery from 'common-v2/model/notificationDelivery';
import { MINotificationDeliveryProto } from 'common-v2/proto/notification';
import { RootState } from 'redux/types';

import {
  LOAD_CACHED_NOTIFICATION_INTERVAL,
  POLLING_NOTIFICATION_DELIVERIES_REQUEST,
  POLLING_NOTIFICATION_DELIVERIES_SUCCESS,
  POLLING_NOTIFICATION_DELIVERIES_ERROR,
  READ_NOTIFICATION_DELIVERIES_REQUEST,
  READ_NOTIFICATION_DELIVERIES_SUCCESS,
  READ_NOTIFICATION_DELIVERIES_ERROR,
  LOAD_NOTIFICATION_DELIVERIES_REQUEST,
  LOAD_NOTIFICATION_DELIVERIES_SUCCESS,
  LOAD_NOTIFICATION_DELIVERIES_ERROR,
} from './types';

const INTERVAL_START_MIN_DATE = '2020-10-13';

export function initNotificationCache(): ThunkAction<void, RootState, unknown, Action<string>> {
  return async (dispatch, getState) => {
    const userId = getState().auth.user.id;
    const workplaceId = getState().auth.platform;

    const now = new Date().getTime();

    const cacheKey = getWebNotificationCacheKey(workplaceId, userId);
    const interval: { start: number; end: number } = getLocalCacheObject(cacheKey) || {
      end: now,
      start: now,
    };

    dispatch({
      type: LOAD_CACHED_NOTIFICATION_INTERVAL,
      payload: {
        interval,
      },
    });
  };
}

export function loadNotificationDeliveries(): ThunkAction<
  void,
  RootState,
  unknown,
  Action<string>
> {
  return async (dispatch, getState) => {
    const userId = getState().auth.user.id;
    const workplaceId = getState().auth.platform;
    const interval = getState().notification.deliveries.interval;
    const cacheKey = getWebNotificationCacheKey(workplaceId, userId);

    // load previous notifications (from recent interval time (interval.end) to 60 days ago)
    const intervalEnd = interval.end;
    let intervalStart = intervalEnd - 1000 * 3600 * 24 * 60;

    // this logic is for get only notification created after template fixed time
    if (new Date(INTERVAL_START_MIN_DATE) > new Date(intervalStart)) {
      intervalStart = new Date(INTERVAL_START_MIN_DATE).getTime();
    }

    dispatch({
      type: LOAD_NOTIFICATION_DELIVERIES_REQUEST,
    });

    try {
      const resp = await api.listNotificationDeliveries(intervalStart, intervalEnd);
      const deliveriesProto = _.get(resp, 'data.deliveries', []);

      const deliveries = deliveriesProto
        .map((deliveryProto: MINotificationDeliveryProto) =>
          NotificationDelivery.fromProto(deliveryProto),
        )
        .filter((delivery: NotificationDelivery) => !delivery.isExpired());

      dispatch({
        type: LOAD_NOTIFICATION_DELIVERIES_SUCCESS,
        payload: {
          cacheKey,
          deliveries,
          intervalStart,
        },
      });
    } catch (error) {
      const errMsg = extractErrorMessage(error);

      dispatch({
        type: LOAD_NOTIFICATION_DELIVERIES_ERROR,
        payload: {
          error: errMsg,
        },
        bugsnagMeta: {
          error,
          context: 'Error occurred while loading notification deliveries',
        },
      });
    }
  };
}

export function pollingNotificationDeliveries(
  onFinish?: (error?: string, newDeliveries?: Array<NotificationDelivery>) => void,
): ThunkAction<void, RootState, unknown, Action<string>> {
  return async (dispatch, getState) => {
    const userId = getState().auth.user.id;
    const workplaceId = getState().auth.platform;

    const cacheKey = getWebNotificationCacheKey(workplaceId, userId);

    // Request notification deliveries from the recent API call time (interval.end) to now
    // Interval is updated like below
    // original: interval.start ~ interval.end
    // updated : interval.start ~(interval.end)~ now
    const intervalEnd = new Date().getTime();
    const intervalStart = getState().notification.deliveries.interval.end;

    dispatch({
      type: POLLING_NOTIFICATION_DELIVERIES_REQUEST,
    });

    try {
      const resp = await api.listNotificationDeliveries(intervalStart, intervalEnd);
      const deliveriesProto = _.get(resp, 'data.deliveries', []);

      const deliveries = deliveriesProto
        .map((deliveryProto: MINotificationDeliveryProto) =>
          NotificationDelivery.fromProto(deliveryProto),
        )
        .filter((delivery: NotificationDelivery) => !delivery.isExpired());

      dispatch({
        type: POLLING_NOTIFICATION_DELIVERIES_SUCCESS,
        payload: {
          cacheKey,
          deliveries,
          intervalEnd,
        },
      });

      if (onFinish) {
        onFinish(undefined, deliveries);
      }
    } catch (error) {
      const errMsg = extractErrorMessage(error);

      dispatch({
        type: POLLING_NOTIFICATION_DELIVERIES_ERROR,
        payload: {
          error: errMsg,
        },
        bugsnagMeta: {
          error,
          context: 'Error occurred while polling notification deliveries',
        },
      });

      if (onFinish) {
        onFinish(errMsg);
      }
    }
  };
}

export function batchUpdateNotificationDeliveriesReadStatus(
  onStart?: () => void,
  onFinish?: (error?: string) => void,
): ThunkAction<void, RootState, unknown, Action<string>> {
  return async (dispatch, getState) => {
    if (onStart) {
      onStart();
    }

    const userId = getState().auth.user.id;
    const workplaceId = getState().auth.platform;

    const cacheKey = getWebNotificationCacheKey(workplaceId, userId);

    const deliveries = getState().notification.deliveries.entities;
    const unreadDeliveryIds = _.values(deliveries)
      .filter((delivery) => !delivery.readFlag)
      .map((delivery) => delivery.id);

    if (unreadDeliveryIds.length === 0) return;

    dispatch({
      type: READ_NOTIFICATION_DELIVERIES_REQUEST,
    });

    try {
      await api.batchUpdateNotificationDeliveriesReadStatus(unreadDeliveryIds);

      dispatch({
        type: READ_NOTIFICATION_DELIVERIES_SUCCESS,
        payload: {
          cacheKey,
          deliveryIds: unreadDeliveryIds,
        },
      });

      if (onFinish) {
        onFinish();
      }
    } catch (error) {
      const errMsg = extractErrorMessage(error);

      dispatch({
        type: READ_NOTIFICATION_DELIVERIES_ERROR,
        payload: {
          error: errMsg,
        },
        bugsnagMeta: {
          error,
          deliveryIds: unreadDeliveryIds,
          context: 'Error occurred while updating notification deliveries',
        },
      });

      if (onFinish) {
        onFinish(errMsg);
      }
    }
  };
}
