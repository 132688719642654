// Feature list shared by BE and FE.
export enum MESharedFeatures {
  LOGGING = 'LOGGING',
  MARKUP = 'MARKUP',
  REPORT_SUMMARY = 'REPORT_SUMMARY',
}

// Feature list used only in FE
// This features used in fe configuration file and frontend_data of DSP configuration.
export enum MEFrontendFeatures {
  DISABLE_CREATING_ADVERTISER = 'DISABLE_CREATING_ADVERTISER',
  ENABLE_OVERVIEW_DASHBOARD = 'ENABLE_OVERVIEW_DASHBOARD',
  MENU_CM_ONLY = 'MENU_CM_ONLY',
  ONLY_LAYOUT_V1 = 'ONLY_LAYOUT_V1',
  REPORT_EXPORT = 'REPORT_EXPORT',
  MODEL_EDITOR = 'MODEL_EDITOR',
  ACTION_ANALYTICS = 'ACTION_ANALYTICS', // To control a) Actions/CPA metrics in AdAccount/App/Campaign Overview, Report and Analytics > Creative/Inventory/Exchange Analysis and b) Cohort Action Analysis menu in Analytics
  VIEWER_ROLE = 'VIEWER_ROLE', // Support ad account viewer role.
  TARGET_ROAS = 'TARGET_ROAS',
  LNB_ACTION = 'LNB_ACTION',

  // TODO: Remove after v18
  LAT_SETTING = 'LAT_SETTING',
  MRP_REPORT = 'MRP_REPORT',
  MRP_OVERVIEW = 'MRP_OVERVIEW',
  MAX_NATIVE_DESC_LIMIT = 'MAX_NATIVE_DESC_LIMIT',
  USE_WEB_LANDING = 'USE_WEB_LANDING',
  LINK_TEST_HISTORY = 'LINK_TEST_HISTORY',
  FIXED_CPI_PAYOUT_CAMPAIGN = 'FIXED_CPI_PAYOUT_CAMPAIGN',
  OPERATION_ITEM = 'OPERATION_ITEM',
  UX_SELECT_DISABLED_STATE = 'UX_SELECT_DISABLED_STATE',
  UX_READONLY_SELECT = 'UX_READONLY_SELECT',

  // TODO: Remove after v19
  CREATIVE_REVIEW_STATUS = 'CREATIVE_REVIEW_STATUS',
  REVENUE_ACTION = 'REVENUE_ACTION',
  UX_OVERVIEW_LAYOUT = 'UX_OVERVIEW_LAYOUT',
  NOTIFICATION_ON_WEB = 'NOTIFICATION_ON_WEB',
  REMEMBER_REPORT_SETTINGS = 'REMEMBER_REPORT_SETTINGS', // ref: https://moloco.phacility.com/T12158
  CREATIVE_PREVIEW_FROM_REPORT = 'CREATIVE_PREVIEW_FROM_REPORT', // ref: https://moloco.phacility.com/T12167
  ZENDESK_WEB_WIDGET = 'ZENDESK_WEB_WIDGET',

  // TODO: Remove after launch MOCAS
  USE_CONFIG_CREATIVE_SPECS = 'USE_CONFIG_CREATIVE_SPECS',

  // TODO: Remove after v20
  SPENDING_OVERVIEW = 'SPENDING_OVERVIEW', // ref: https://moloco.phacility.com/T12259
}

export const FEATURES = { ...MESharedFeatures, ...MEFrontendFeatures };
