/*
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here. We prefix them with 'componentName' so we avoid
 * reducers accidentally picking up actions they shouldn't.
 *
 * Follow this format:
 * export const YOUR_ACTION_CONSTANT = 'YourContainer/YOUR_ACTION_CONSTANT';
 */

import { ACTION_TYPE_INVENTORY_MANAGER } from '../types';

const ACTION_TYPE_EXCHANGE = 'exchange';

export const CHANGE_EXCHANGE = `${ACTION_TYPE_INVENTORY_MANAGER}/${ACTION_TYPE_EXCHANGE}/CHANGE_EXCHANGE`;

export const LIST_EXCHANGES = `${ACTION_TYPE_INVENTORY_MANAGER}/${ACTION_TYPE_EXCHANGE}/LIST_EXCHANGES`;
export const LIST_EXCHANGES_SUCCESS = `${ACTION_TYPE_INVENTORY_MANAGER}/${ACTION_TYPE_EXCHANGE}/LIST_EXCHANGES_SUCCESS`;
export const LIST_EXCHANGES_ERROR = `${ACTION_TYPE_INVENTORY_MANAGER}/${ACTION_TYPE_EXCHANGE}/LIST_EXCHANGES_ERROR`;

export const SAVE_EXCHANGE = `${ACTION_TYPE_INVENTORY_MANAGER}/${ACTION_TYPE_EXCHANGE}/SAVE_EXCHANGE`;
export const SAVE_EXCHANGE_SUCCESS = `${ACTION_TYPE_INVENTORY_MANAGER}/${ACTION_TYPE_EXCHANGE}/SAVE_EXCHANGE_SUCCESS`;
export const SAVE_EXCHANGE_ERROR = `${ACTION_TYPE_INVENTORY_MANAGER}/${ACTION_TYPE_EXCHANGE}/SAVE_EXCHANGE_ERROR`;

export const GET_EXCHANGE = `${ACTION_TYPE_INVENTORY_MANAGER}/${ACTION_TYPE_EXCHANGE}/GET_EXCHANGE`;
export const GET_EXCHANGE_SUCCESS = `${ACTION_TYPE_INVENTORY_MANAGER}/${ACTION_TYPE_EXCHANGE}/GET_EXCHANGE_SUCCESS`;
export const GET_EXCHANGE_ERROR = `${ACTION_TYPE_INVENTORY_MANAGER}/${ACTION_TYPE_EXCHANGE}/GET_EXCHANGE_ERROR`;
