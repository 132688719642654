import produce from 'immer';
import _ from 'lodash';
import {
  LIST_AD_UNIT,
  LIST_AD_UNIT_SUCCESS,
  LIST_AD_UNIT_ERROR,
  GET_AD_UNIT,
  GET_AD_UNIT_SUCCESS,
  GET_AD_UNIT_ERROR,
  SAVE_AD_UNIT,
  SAVE_AD_UNIT_SUCCESS,
  SAVE_AD_UNIT_ERROR,
  DELETE_AD_UNIT,
  DELETE_AD_UNIT_SUCCESS,
  DELETE_AD_UNIT_ERROR,
} from './types';

const initState = {
  loading: false,
  updating: false,
  loadError: null,
  updateError: null,
  lastListRequestTs: {},
  lastEntityRequestTs: {},
  /*
    publisherAdUnits : {
      "publisher_app_id1": {
        "ad_unit_id1": {
            "id": ...,
            "title": ...,
        }
      }
    }
   */
  entities: {},
};

function adUnitsReducer(state = initState, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case LIST_AD_UNIT: {
        const { publisherAppId } = action.payload;

        draft.loading = true;
        draft.loadError = null;
        _.set(draft.lastListRequestTs, [publisherAppId], Date.now());
        break;
      }
      case LIST_AD_UNIT_SUCCESS: {
        const { publisherAppId, adUnits } = action.payload;

        draft.loading = false;
        _.set(
          draft.entities,
          publisherAppId,
          _.keyBy(adUnits, (adUnit) => adUnit.id),
        );
        break;
      }
      case LIST_AD_UNIT_ERROR: {
        draft.loading = false;
        draft.loadError = action.error;
        break;
      }
      case SAVE_AD_UNIT: {
        draft.updating = true;
        draft.updateError = null;
        break;
      }
      case SAVE_AD_UNIT_ERROR: {
        draft.updating = false;
        draft.updateError = action.error;
        break;
      }
      case SAVE_AD_UNIT_SUCCESS: {
        const { publisherAppId, adUnit } = action.payload;

        draft.updating = false;
        if (_.has(draft.entities, publisherAppId)) {
          draft.entities[publisherAppId][adUnit.id] = adUnit;
        }
        break;
      }
      case DELETE_AD_UNIT: {
        draft.updating = true;
        draft.updateError = null;
        break;
      }
      case DELETE_AD_UNIT_SUCCESS: {
        const { publisherAppId, adUnitId } = action.payload;

        draft.updating = false;
        if (_.has(draft.entities, publisherAppId)) {
          delete draft.entities[publisherAppId][adUnitId];
        }
        break;
      }
      case DELETE_AD_UNIT_ERROR: {
        draft.updating = false;
        draft.updateError = action.error;
        break;
      }
      case GET_AD_UNIT: {
        const { publisherAppId, adUnitId } = action.payload;

        draft.loading = true;
        draft.loadError = null;
        _.set(draft.lastEntityRequestTs, [publisherAppId, adUnitId], Date.now());
        break;
      }
      case GET_AD_UNIT_SUCCESS: {
        const { publisherAppId, adUnitId, adUnit } = action.payload;

        draft.loading = false;
        if (!_.has(draft.entities, publisherAppId)) {
          draft.entities[publisherAppId] = {};
        }
        if (!_.has(draft.entities[publisherAppId], adUnitId)) {
          draft.entities[publisherAppId][adUnitId] = {};
        }
        draft.entities[publisherAppId][adUnitId] = adUnit;

        break;
      }
      case GET_AD_UNIT_ERROR: {
        draft.loading = false;
        draft.loadError = action.error;
        break;
      }
      default: {
        break;
      }
    }
    return draft;
  });
}

export default adUnitsReducer;
