import deployEnv from '../constants/deployEnv';

// eslint-disable-next-line import/prefer-default-export
export const TABLE_PAGE_SIZE = 'table_page_size';

export const BROWSER_CACHE_KEY = {
  AUTH_INFO: 'AUTH_INFO',
};

// Campaign Manager
export const getAdvertiserCacheKey = (platform) =>
  `${deployEnv.getDeployEnv()}:${platform}:advertiser`;

export const getProductCacheKey = (platform) => `${deployEnv.getDeployEnv()}:${platform}:product`;

export const getLogDataCacheKey = (platform) => `${deployEnv.getDeployEnv()}:${platform}:logData`;

export const getOverviewCacheKey = (platform) => `${deployEnv.getDeployEnv()}:${platform}:overview`;

// Inventory Manager
export const getExchangeCacheKey = (platform) => `${deployEnv.getDeployEnv()}:${platform}:exchange`;

export const getPublisherCacheKey = (platform) =>
  `${deployEnv.getDeployEnv()}:${platform}:publisher`;
