import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import MUIAvatar from '@material-ui/core/Avatar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MUITooltip from '@material-ui/core/Tooltip';

import MOLOCOLogo from '../../assets/imgs/moloco_logo.svg';
import { PlatformInfo } from '../../common-v2/model/platformInfo';
import MCHeaderPopper from './MCHeaderPopper';
import { MERouteKeys } from '../../common-v2/route/consts';
import generatePathForRoute from '../../common-v2/route/generatePathForRoute';
import MCHelpCenterPopper from './MCHelpCenterPopper';
import MCWebNotificationControl from '../Common/Notification/MCWebNotificationControl';
import usePlatformSettings from '../../common-v2/hooks/usePlatformSettings';
import { MEFrontendFeatures } from '../../common-v2/constants/features';

export const HEADER_HEIGHT = '62px';

const SCWrapper = styled.div`
  position: absolute;
  width: 100%;
  min-width: 600px;
  height: ${HEADER_HEIGHT};
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.3);
  background-color: #ffffff;
  display: flex;
  padding: 0 16px;
  justify-content: space-between;
  z-index: 999;
`;

const SCLeft = styled.div`
  display: flex;
  align-items: center;
`;

const SCRight = styled.div`
  display: flex;
  align-items: center;
`;

const SCDivider = styled.div`
  width: 0;
  height: 20px;
  margin: 0 13px;
  border-left: 1px solid #d0d0d0;
`;

const SCSelectorWrapper = styled.div`
  font-size: 18px;
  font-weight: 500;
  color: #666666;

  & > a {
    color: inherit;

    &:hover {
      color: inherit;
      text-decoration: none;
    }
  }
`;

const SCWorkplaceWrapper = styled.div`
  font-size: 12px;
  font-weight: 500;
  color: #666666;
`;

const SCHelpCenterControlWrapper = styled.div`
  position: relative;
`;

const SCHelpCenterIcon = styled(FontAwesomeIcon)`
  margin-right: ${(props) => props.theme.spacing(2)}px;
  color: #9e9e9e;
  cursor: pointer;
  font-size: 18px;
`;

const SCControlWrapper = styled.div`
  position: relative;
`;

const SCUserInfoWrapper = styled.div`
  display: flex;
  height: 38px;
  padding: 0 10px;
  border-radius: 2px;
  border: solid 1px #bbbbbb;
  background-color: #ffffff;
  align-items: center;
  cursor: pointer;
`;

const SCAvatar = styled(MUIAvatar)`
  width: 18px;
  height: 18px;
  margin-right: 10px;
`;

const SCUserName = styled.div`
  color: #212121;
  font-size: 12px;
  user-select: none;
`;

export const MCHeader = (props: {
  user: any;
  currentPlatform?: PlatformInfo;
  availablePlatforms: Array<PlatformInfo>;
  showHelp: boolean;
  showNotification: boolean;
  onPlatformChange: (newAdvertiserId: string) => void;
  onLogout: () => void;
}) => {
  const {
    user,
    currentPlatform,
    availablePlatforms,
    showHelp,
    showNotification,
    onPlatformChange,
    onLogout,
  } = props;
  const { features } = usePlatformSettings();
  const enableNotificationOnWeb = features.includes(MEFrontendFeatures.NOTIFICATION_ON_WEB);

  const [openHelpCenterPopper, setOpenHelpCenterPopper] = React.useState<boolean>(false);
  const helpCenterControlWrapper = React.useRef<HTMLDivElement>(null);

  const [openUserInfoPopup, setOpenUserInfoPopup] = React.useState(false);
  const controlWrapper = React.useRef<HTMLDivElement>(null);

  const handleClickHelpCenter = () => {
    setOpenHelpCenterPopper(!openHelpCenterPopper);
  };

  const closeHelpCenterPopper = () => {
    setOpenHelpCenterPopper(false);
  };

  const handleClickUserInfo = () => {
    setOpenUserInfoPopup(!openUserInfoPopup);
  };

  const handleCloseUserInfo = () => {
    setOpenUserInfoPopup(false);
  };

  React.useEffect(() => {
    // close popper when click outside
    const handleClickOutside = (e: any) => {
      const helpCenterNode = helpCenterControlWrapper.current;
      if (helpCenterNode && !helpCenterNode.contains(e.target)) {
        closeHelpCenterPopper();
      }

      const userInfoNode = controlWrapper.current;
      if (userInfoNode && !userInfoNode.contains(e.target)) {
        setOpenUserInfoPopup(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // show platform selector if availablePlatforms have beed provided
  const usePlatformSelect = availablePlatforms && availablePlatforms.length > 1;

  const pathToHome = generatePathForRoute(MERouteKeys.PLATFORM_ROOT);

  return (
    <SCWrapper>
      <SCLeft>
        <Link to={pathToHome}>
          <img src={MOLOCOLogo} alt="MOLOCO" />
        </Link>
        {user && currentPlatform && (
          <>
            <SCDivider />
            <SCSelectorWrapper>
              <Link to={pathToHome}>Campaign Manager</Link>
            </SCSelectorWrapper>
          </>
        )}
      </SCLeft>
      <SCRight>
        {user && currentPlatform && (
          <>
            <SCWorkplaceWrapper>{currentPlatform.title}</SCWorkplaceWrapper>
            <SCDivider />
            {showHelp && (
              <SCHelpCenterControlWrapper ref={helpCenterControlWrapper}>
                <MUITooltip title={openHelpCenterPopper ? '' : 'Help Center'} placement="bottom">
                  <div>
                    <SCHelpCenterIcon
                      icon={['fas', 'question-circle']}
                      onClick={handleClickHelpCenter}
                    />
                  </div>
                </MUITooltip>
                <MCHelpCenterPopper
                  open={openHelpCenterPopper}
                  closePopper={closeHelpCenterPopper}
                />
              </SCHelpCenterControlWrapper>
            )}
            {showNotification && enableNotificationOnWeb && <MCWebNotificationControl />}
            <SCControlWrapper ref={controlWrapper}>
              <SCUserInfoWrapper data-testid="headerPopperOpen" onClick={handleClickUserInfo}>
                <SCAvatar aria-label="user" src={user.photoURL} />
                <SCUserName>{user.email}</SCUserName>
              </SCUserInfoWrapper>
              <MCHeaderPopper
                open={openUserInfoPopup}
                user={user}
                currentPlatform={currentPlatform}
                availablePlatforms={availablePlatforms}
                onClose={handleCloseUserInfo}
                onPlatformChange={onPlatformChange}
                onLogout={onLogout}
                usePlatformSelect={usePlatformSelect}
              />
            </SCControlWrapper>
          </>
        )}
      </SCRight>
    </SCWrapper>
  );
};
