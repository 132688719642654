import produce from 'immer';
import _ from 'lodash';

import {
  LIST_CREATIVE_GROUP_SUCCESS,
  LIST_CREATIVE_GROUP_REQUEST,
  LIST_CREATIVE_GROUP_ERROR,
  GET_CREATIVE_GROUP_SUCCESS,
  GET_CREATIVE_GROUP_ERROR,
  GET_CREATIVE_GROUP_REQUEST,
  SAVE_CREATIVE_GROUP_REQUEST,
  SAVE_CREATIVE_GROUP_SUCCESS,
  SAVE_CREATIVE_GROUP_ERROR,
  BULK_SAVE_CREATIVE_GROUP_REQUEST,
  BULK_SAVE_CREATIVE_GROUP_SUCCESS,
  BULK_SAVE_CREATIVE_GROUP_ERROR,
} from './types';

// The initial state of the Creative Groups.
export const initialState = {
  loading: false,
  updating: false,
  loadError: null,
  updateError: null,
  lastListRequestTs: {},
  lastEntityRequestTs: {},
  entities: {},
};

function creativeGroupsReducer(state = initialState, action) {
  // eslint-disable-next-line consistent-return
  return produce(state, (draft) => {
    // eslint-disable-next-line default-case
    switch (action.type) {
      case LIST_CREATIVE_GROUP_REQUEST: {
        const { advertiserId, productId } = action.payload;

        draft.loading = true;
        draft.loadError = null;
        _.set(draft.lastListRequestTs, [advertiserId, productId], Date.now());
        break;
      }
      case LIST_CREATIVE_GROUP_SUCCESS: {
        const { advertiserId, productId, creativeGroups } = action.payload;

        draft.loading = false;
        _.set(draft.entities, [advertiserId, productId], _.keyBy(creativeGroups, 'id'));
        break;
      }
      case LIST_CREATIVE_GROUP_ERROR: {
        const { error } = action.payload;

        draft.loading = false;
        draft.loadError = error;
        break;
      }
      case GET_CREATIVE_GROUP_REQUEST: {
        const { advertiserId, productId, creativeGroupId } = action.payload;

        draft.loading = true;
        draft.loadError = null;
        _.set(draft.lastEntityRequestTs, [advertiserId, productId, creativeGroupId], Date.now());
        break;
      }
      case GET_CREATIVE_GROUP_SUCCESS: {
        const { advertiserId, productId, creativeGroupId, creativeGroup } = action.payload;

        draft.loading = false;
        _.set(draft.entities, [advertiserId, productId, creativeGroupId], creativeGroup);
        break;
      }
      case GET_CREATIVE_GROUP_ERROR: {
        const { error } = action.payload;

        draft.loading = false;
        draft.loadError = error;
        break;
      }
      case SAVE_CREATIVE_GROUP_REQUEST: {
        draft.updating = true;
        draft.updateError = null;
        break;
      }
      case SAVE_CREATIVE_GROUP_SUCCESS: {
        const { advertiserId, productId, creativeGroupId, creativeGroup } = action.payload;

        draft.updating = false;
        draft.entities[advertiserId][productId][creativeGroupId] = creativeGroup;
        break;
      }
      case SAVE_CREATIVE_GROUP_ERROR: {
        const { error } = action.payload;

        draft.updating = false;
        draft.updateError = error;
        break;
      }
      case BULK_SAVE_CREATIVE_GROUP_REQUEST: {
        draft.updating = true;
        draft.updateError = null;
        break;
      }
      case BULK_SAVE_CREATIVE_GROUP_SUCCESS: {
        draft.updating = false;
        break;
      }
      case BULK_SAVE_CREATIVE_GROUP_ERROR: {
        const { error } = action.payload;

        draft.updateError = error;
        break;
      }
      default:
        break;
    }
  });
}

export default creativeGroupsReducer;
