import axios, { AxiosPromise } from 'axios';
import _ from 'lodash';

import { MATTERS_42_URL } from '../constants/apiEndpoints';
import { MATTERS_42_ACCESS_TOKEN } from '../constants/apiKeys';
import { MIAndroidApplication, MIApplication, MIiOSApplication } from '../types/42matters';
import { MEOs } from '../types';

const TIMEOUT = 7 * 1000; // 7s

export const SEARCH_APP_FIELDS: Record<string, MIApplication> = {
  [MEOs.ANDROID]: {
    title: 'title',
    domain: 'website',
    rating: 'rating',
    bundleId: 'package_name',
    appStoreUrl: 'market_url',
    thumbnail: 'icon',
  },
  [MEOs.IOS]: {
    title: 'trackCensoredName',
    domain: 'sellerUrl',
    rating: 'averageUserRatingForCurrentVersion',
    bundleId: 'trackId',
    appStoreUrl: 'trackViewUrl',
    thumbnail: 'artworkUrl100',
  },
};

interface MIGetSearchAppResponse {
  has_next: boolean;
  limit: number;
  num_pages: number;
  page: number;
  results: Array<MIAndroidApplication | MIiOSApplication>;
}

function getSearchApp(os: string, term: string): AxiosPromise<MIGetSearchAppResponse> {
  return axios({
    timeout: TIMEOUT,
    method: 'GET',
    url: `${MATTERS_42_URL}/v2.0/${os.toLowerCase()}/apps/search.json`,
    params: {
      q: term,
      limit: 50,
      fields: _.values(SEARCH_APP_FIELDS[os.toUpperCase()]).join(','),
      access_token: MATTERS_42_ACCESS_TOKEN,
    },
  });
}

interface MIGetIabCategoryResponse {
  iab: Array<{ id: string }>;
}

function getIabCategory(os: string, bundleId: string): AxiosPromise<MIGetIabCategoryResponse> {
  return axios({
    timeout: TIMEOUT,
    method: 'GET',
    url: `${MATTERS_42_URL}/v4.0/${os.toLowerCase()}/apps/category.json`,
    params: {
      [os.toUpperCase() === MEOs.ANDROID ? 'p' : 'id']: bundleId,
      access_token: MATTERS_42_ACCESS_TOKEN,
    },
  });
}

const api = {
  getSearchApp,
  getIabCategory,
};

export default api;
