/*
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here. We prefix them with 'componentName' so we avoid
 * reducers accidentally picking up actions they shouldn't.
 *
 * Follow this format:
 * export const YOUR_ACTION_CONSTANT = 'YourContainer/YOUR_ACTION_CONSTANT';
 */

import { ACTION_TYPE_CAMPAIGN_MANAGER } from '../consts';
import {
  ImageCreativeReview,
  NativeCreativeReview,
  VideoCreativeReview,
} from '../../../common-v2/model/creativeReview';
import { MECreativeType } from '../../../common-v2/types';
import { MIProgressKey } from '../progress/types';

export const ACTION_TYPE_CREATIVE_REVIEW = 'creative_review';

export const LIST_CREATIVE_REVIEW_REQUEST = `${ACTION_TYPE_CAMPAIGN_MANAGER}/${ACTION_TYPE_CREATIVE_REVIEW}/LIST_CREATIVE_REVIEW_REQUEST`;
export const LIST_CREATIVE_REVIEW_SUCCESS = `${ACTION_TYPE_CAMPAIGN_MANAGER}/${ACTION_TYPE_CREATIVE_REVIEW}/LIST_CREATIVE_REVIEW_SUCCESS`;
export const LIST_CREATIVE_REVIEW_ERROR = `${ACTION_TYPE_CAMPAIGN_MANAGER}/${ACTION_TYPE_CREATIVE_REVIEW}/LIST_CREATIVE_REVIEW_ERROR`;

export type MTCreativeReviewsMapByCreativeType = {
  [MECreativeType.IMAGE]: Record<string, ImageCreativeReview>;
  [MECreativeType.NATIVE]: Record<string, NativeCreativeReview>;
  [MECreativeType.VIDEO]: Record<string, VideoCreativeReview>;
};

export interface MICreativeReviewsState {
  lastListRequestTs: Record<string, number>;
  entities: Record<string, MTCreativeReviewsMapByCreativeType>;
}

export interface MIListCreativeReviewsRequestAction {
  type: typeof LIST_CREATIVE_REVIEW_REQUEST;
  payload: {
    adAccountId: string;
    progressKey: MIProgressKey;
  };
}

export interface MIListCreativeReviewsSuccessAction {
  type: typeof LIST_CREATIVE_REVIEW_SUCCESS;
  payload: {
    adAccountId: string;
    progressKey: MIProgressKey;
    creativeReviewsMapByCreativeType: MTCreativeReviewsMapByCreativeType;
  };
}

export interface MIListCreativeReviewsErrorAction {
  type: typeof LIST_CREATIVE_REVIEW_ERROR;
  payload: {
    adAccountId: string;
    progressKey: MIProgressKey;
    error: string;
  };
}

type MTListCreativeReviewsAction =
  | MIListCreativeReviewsRequestAction
  | MIListCreativeReviewsSuccessAction
  | MIListCreativeReviewsErrorAction;

export type MTCreativeReviewsAction = MTListCreativeReviewsAction;
