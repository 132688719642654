import produce from 'immer';
import _ from 'lodash';

import {
  LIST_ADGROUP_HISTORY,
  LIST_ADGROUP_HISTORY_ERROR,
  LIST_ADGROUP_HISTORY_SUCCESS,
  LIST_CAMPAIGN_HISTORY,
  LIST_CAMPAIGN_HISTORY_ERROR,
  LIST_CAMPAIGN_HISTORY_SUCCESS,
} from './types';

// The initial state of the EntityLogs.
export const initialState = {
  loading: false,
  loadError: null,
  lastCampaignLogListRequestTs: {},
  lastAdGroupLogListRequestTs: {},
  campaignLogs: {},
  adGroupLogs: {},
};

function entityLogsReducer(state = initialState, action) {
  // eslint-disable-next-line consistent-return
  return produce(state, (draft) => {
    // eslint-disable-next-line default-case
    switch (action.type) {
      case LIST_CAMPAIGN_HISTORY: {
        const { advertiserId, productId, campaignId } = action.payload;

        draft.loading = true;
        draft.loadError = null;
        _.set(
          draft.lastCampaignLogListRequestTs,
          [advertiserId, productId, campaignId],
          Date.now(),
        );
        break;
      }
      case LIST_CAMPAIGN_HISTORY_SUCCESS: {
        const { advertiserId, productId, campaignId, logs } = action.payload;

        draft.loading = false;
        _.set(draft.campaignLogs, [advertiserId, productId, campaignId], logs);
        break;
      }
      case LIST_CAMPAIGN_HISTORY_ERROR: {
        draft.loading = false;
        draft.loadError = action.error;
        break;
      }
      case LIST_ADGROUP_HISTORY: {
        const { advertiserId, productId, campaignId, adGroupId } = action.payload;

        draft.loading = true;
        draft.loadError = null;
        _.set(
          draft.lastAdGroupLogListRequestTs,
          [advertiserId, productId, campaignId, adGroupId],
          Date.now(),
        );
        break;
      }
      case LIST_ADGROUP_HISTORY_SUCCESS: {
        const { advertiserId, productId, campaignId, adGroupId, logs } = action.payload;

        draft.loading = false;
        _.set(draft.adGroupLogs, [advertiserId, productId, campaignId, adGroupId], logs);
        break;
      }
      case LIST_ADGROUP_HISTORY_ERROR: {
        draft.loading = false;
        draft.loadError = action.error;
        break;
      }
      default:
        break;
    }
  });
}

export default entityLogsReducer;
