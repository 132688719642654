import produce from 'immer';
import _ from 'lodash';

import {
  LIST_OPERATION_ITEM_REQUEST,
  LIST_OPERATION_ITEM_SUCCESS,
  LIST_OPERATION_ITEM_ERROR,
  GET_OPERATION_ITEM_REQUEST,
  GET_OPERATION_ITEM_SUCCESS,
  GET_OPERATION_ITEM_ERROR,
  SAVE_OPERATION_ITEM_REQUEST,
  SAVE_OPERATION_ITEM_SUCCESS,
  SAVE_OPERATION_ITEM_ERROR,
} from './types';

// The initial state of the Ad Groups.
export const initialState = {
  loading: false,
  updating: false,
  loadError: null,
  updateError: null,
  lastListRequestTs: {},
  lastEntityRequestTs: {},
  entities: {},
};

function operationItemReducer(state = initialState, action) {
  // eslint-disable-next-line consistent-return
  return produce(state, (draft) => {
    // eslint-disable-next-line default-case
    switch (action.type) {
      case LIST_OPERATION_ITEM_REQUEST: {
        const { advertiserId, productId, campaignId } = action.payload;

        draft.loading = true;
        draft.loadError = null;
        _.set(draft.lastListRequestTs, [advertiserId, productId, campaignId], Date.now());
        break;
      }
      case LIST_OPERATION_ITEM_SUCCESS: {
        const { advertiserId, productId, campaignId, operationItems } = action.payload;

        draft.loading = false;
        _.set(
          draft.entities,
          [advertiserId, productId, campaignId],
          _.keyBy(operationItems, 'effectiveDate'),
        );
        break;
      }
      case LIST_OPERATION_ITEM_ERROR: {
        const { error } = action.payload;
        draft.loading = false;
        draft.loadError = error;
        break;
      }
      case GET_OPERATION_ITEM_REQUEST: {
        draft.loading = true;
        draft.loadError = null;
        break;
      }
      case GET_OPERATION_ITEM_SUCCESS: {
        const { advertiserId, productId, campaignId, operationItem } = action.payload;

        draft.loading = false;
        _.set(
          draft.entities,
          [advertiserId, productId, campaignId, operationItem.effectiveDate],
          operationItem,
        );
        break;
      }
      case GET_OPERATION_ITEM_ERROR: {
        const { error } = action.payload;
        draft.loading = false;
        draft.loadError = error;
        break;
      }
      case SAVE_OPERATION_ITEM_REQUEST: {
        draft.updating = true;
        draft.updateError = null;
        break;
      }
      case SAVE_OPERATION_ITEM_SUCCESS: {
        const { advertiserId, productId, campaignId, operationItem } = action.payload;

        draft.updating = false;
        _.set(
          draft.entities,
          [advertiserId, productId, campaignId, operationItem.effectiveDate],
          operationItem,
        );
        break;
      }
      case SAVE_OPERATION_ITEM_ERROR: {
        const { error } = action.payload;
        draft.updating = false;
        draft.updateError = error;
        break;
      }
      default:
        break;
    }
  });
}

export default operationItemReducer;
