import axios from 'axios';

import GlobalStore, { STORE_KEY } from '../global/globalStorage';
import { ADCLOUD_AUTH_V1_API_PATH } from '../constants/apiEndpoints';

function getBaseEndpoint() {
  return `${ADCLOUD_AUTH_V1_API_PATH}/platforms/${GlobalStore.get(STORE_KEY.PLATFORM)}`;
}

function getHeader() {
  return {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${GlobalStore.get(STORE_KEY.AUTH_TOKEN)}`,
  };
}

function listAdCloudUserPlatforms(uid) {
  const config = {
    method: 'GET',
    url: `${ADCLOUD_AUTH_V1_API_PATH}/uusers/${uid}/platforms`,
    headers: getHeader(),
  };
  return axios(config);
}

function listAdCloudUsers() {
  const config = {
    method: 'GET',
    url: `${getBaseEndpoint()}/users`,
    headers: getHeader(),
  };
  return axios(config);
}

function createAdCloudUser(user, resourcePath, role) {
  const config = {
    method: 'POST',
    url: `${getBaseEndpoint()}/users`,
    headers: getHeader(),
    data: {
      ...user,
      send_invitation_email: true,
      role_grants: [
        {
          resource_path: resourcePath,
          roles: [role],
        },
      ],
    },
  };
  return axios(config);
}

function getAdCloudUser(uid) {
  const config = {
    method: 'GET',
    url: `${getBaseEndpoint()}/users/${uid}`,
    headers: getHeader(),
  };
  return axios(config);
}

function updateAdCloudUser(uid, user) {
  const config = {
    method: 'PUT',
    url: `${getBaseEndpoint()}/users/${uid}`,
    headers: getHeader(),
    data: user,
  };
  return axios(config);
}

function getAdCloudUserRoles(uid) {
  const config = {
    method: 'GET',
    url: `${getBaseEndpoint()}/users/${uid}/roles`,
    headers: getHeader(),
  };
  return axios(config);
}

function grantAdCloudUserRole(uid, resourcePath, role) {
  const config = {
    method: 'POST',
    url: `${getBaseEndpoint()}/users/${uid}/roles`,
    headers: getHeader(),
    data: {
      rgs: [
        {
          user_id: uid,
          resource_path: resourcePath,
          roles: [role],
        },
      ],
    },
  };
  return axios(config);
}

function updateAdCloudUserRole(uid, resourcePath, addRoles, removeRoles) {
  const config = {
    method: 'PUT',
    url: `${getBaseEndpoint()}/users/${uid}/resources/${btoa(resourcePath)}`,
    headers: getHeader(),
    data: {
      add_roles: addRoles,
      remove_roles: removeRoles,
    },
  };
  return axios(config);
}

function revokeAdCloudUserRole(uid, grantId) {
  const config = {
    method: 'DELETE',
    url: `${getBaseEndpoint()}/users/${uid}/roles/${grantId}`,
    headers: getHeader(),
  };
  return axios(config);
}

export default {
  listAdCloudUserPlatforms,
  listAdCloudUsers,
  createAdCloudUser,
  getAdCloudUser,
  updateAdCloudUser,
  getAdCloudUserRoles,
  grantAdCloudUserRole,
  updateAdCloudUserRole,
  revokeAdCloudUserRole,
};
