import React from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { push } from 'connected-react-router';

import { MERouteKeys } from '../../common-v2/route/consts';
import generatePathForRoute from '../../common-v2/route/generatePathForRoute';
import { isMCRHost } from '../../common-v2/utils';

import { MCHeader } from '../../components-v2/Header/MCHeader';
import { logoutUser } from '../../redux/auth/actions';

export default function MCHeaderContainer(): React.ReactElement {
  const { user: authUser, currentPlatformId, availablePlatforms } = useSelector(
    (state: any) => ({
      user: state.auth.user,
      currentPlatformId: state.auth.platform,
      availablePlatforms: state.auth.availablePlatforms,
    }),
    shallowEqual,
  );

  const alwaysViewer = authUser && authUser.isViewerForAllAdAccount();
  const isMCRMode = isMCRHost();
  const dispatch = useDispatch();

  const currentPlatform =
    // eslint-disable-next-line no-nested-ternary
    availablePlatforms.length > 0
      ? availablePlatforms.find((element: any) => {
          return element.id === currentPlatformId;
        })
      : currentPlatformId
      ? { title: currentPlatformId.id }
      : null; // use platformId as title if no availablePlatforms. S Class does not provide availablePlatforms

  const handlePlatformChange = (newPlatformId: string) => {
    const path = generatePathForRoute(MERouteKeys.SWITCH_WORKPLACE);
    dispatch(push(`${path}?workplace=${newPlatformId}`));
  };

  const handleLogout = () => {
    dispatch(logoutUser());
  };

  return (
    <MCHeader
      user={authUser}
      currentPlatform={currentPlatform}
      availablePlatforms={availablePlatforms}
      showHelp={!alwaysViewer}
      showNotification={!isMCRMode}
      onPlatformChange={handlePlatformChange}
      onLogout={handleLogout}
    />
  );
}
