import React from 'react';
import ReactDOM from 'react-dom';
import { Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';

import './assets/css/main.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import MCErrorBoundary from './components-v2/Common/Error/MCErrorBoundary';

import store, { history } from './redux/store';

// Fix for crash when google translate is turn on
// TODO: Remove this logic when the issues mentioned in D28190 are resolved
if (typeof Node === 'function' && Node.prototype) {
  const originalRemoveChild = Node.prototype.removeChild;
  Node.prototype.removeChild = function(child) {
    if (child.parentNode !== this) {
      // Cannot remove a child from a different parent: child, this
      return child;
    }
    // eslint-disable-next-line prefer-rest-params
    return originalRemoveChild.apply(this, arguments);
  };

  const originalInsertBefore = Node.prototype.insertBefore;
  Node.prototype.insertBefore = function(newNode, referenceNode) {
    if (referenceNode && referenceNode.parentNode !== this) {
      // Cannot insert before a reference node from a different parent: referenceNode, this
      return newNode;
    }
    // eslint-disable-next-line prefer-rest-params
    return originalInsertBefore.apply(this, arguments);
  };
}

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <MCErrorBoundary>
        <Route component={App} />
      </MCErrorBoundary>
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

if (module.hot) {
  module.hot.accept();
}
