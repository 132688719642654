import React from 'react';
import styled from 'styled-components';
import MUITooltip from '@material-ui/core/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import withStyles from '@material-ui/core/styles/withStyles';
import { Theme } from '@material-ui/core';

import Markdown from '../../../components/Common/Markdown';

const Tooltip = withStyles((theme: Theme) => ({
  tooltip: {
    fontSize: '12px',
    padding: theme.spacing(2),
    backgroundColor: 'rgba(0,0,0,0.9)',
  },
  arrow: {
    color: 'rgba(0,0,0,0.9)',
  },
}))(MUITooltip);

const SCTooltipWrapper = styled.div`
  display: inline-block;
  padding: 0 4px;
  color: #9e9e9e;
`;

type MTFormTooltipProps = {
  markdownText: string;
};

const MCFormTooltip = ({ markdownText }: MTFormTooltipProps) => {
  return (
    <SCTooltipWrapper>
      <Tooltip arrow placement="top-start" title={<Markdown markdownText={markdownText} />}>
        <div>
          <FontAwesomeIcon icon={['fal', 'exclamation-circle']} />
        </div>
      </Tooltip>
    </SCTooltipWrapper>
  );
};

export default MCFormTooltip;
