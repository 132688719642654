import produce from 'immer';
import { subDays } from 'date-fns';
import _ from 'lodash';

import { REPORT_SUMMARY_COLUMNS } from '../../../common-v2/model/campaignReportSummary';

import {
  GET_ADVERTISER_REPORT_SUMMARY,
  GET_ADVERTISER_REPORT_SUMMARY_ERROR,
  GET_ADVERTISER_REPORT_SUMMARY_SUCCESS,
} from './types';
import { getUserTimezone } from '../../../common/utils';

// The initial state of the EntityLogs.
export const initialState = {
  loading: false,
  loadError: null,
  advertiserId: '',
  groupByConditions: [REPORT_SUMMARY_COLUMNS.CAMPAIGN],
  metrics: [
    REPORT_SUMMARY_COLUMNS.IMPRESSION,
    REPORT_SUMMARY_COLUMNS.CLICK,
    REPORT_SUMMARY_COLUMNS.INSTALL,
    REPORT_SUMMARY_COLUMNS.SPEND,
    REPORT_SUMMARY_COLUMNS.REVENUE,
    REPORT_SUMMARY_COLUMNS.CTR,
    REPORT_SUMMARY_COLUMNS.CPI,
  ],
  timezone: getUserTimezone(),
  startDate: subDays(new Date(), 7),
  endDate: new Date(),
  rows: [],
  columnEntries: {},
};

function reportSummaryReducer(state = initialState, action) {
  // eslint-disable-next-line consistent-return
  return produce(state, (draft) => {
    // eslint-disable-next-line default-case
    switch (action.type) {
      case GET_ADVERTISER_REPORT_SUMMARY: {
        const {
          advertiserId,
          timezone,
          startDate,
          endDate,
          groupByConditions,
          metrics,
        } = action.payload;

        draft.advertiserId = advertiserId;
        draft.loading = true;
        draft.loadError = null;
        draft.timezone = timezone;
        draft.startDate = startDate;
        draft.endDate = endDate;
        draft.groupByConditions = groupByConditions;
        draft.metrics = metrics;
        break;
      }
      case GET_ADVERTISER_REPORT_SUMMARY_SUCCESS: {
        const {
          advertiserId,
          timezone,
          startDate,
          endDate,
          groupByConditions,
          metrics,
          reportSummary,
        } = action.payload;

        draft.advertiserId = advertiserId;
        draft.loading = false;
        draft.loadError = null;
        draft.timezone = timezone;
        draft.startDate = startDate;
        draft.endDate = endDate;
        draft.groupByConditions = groupByConditions;
        draft.metrics = metrics;
        draft.rows = reportSummary.rows;

        const colEntries = {};
        groupByConditions.forEach((colType) => {
          colEntries[colType] = new Map();
        });

        reportSummary.rows.forEach((row) => {
          groupByConditions.forEach((colType) => {
            if (colType === REPORT_SUMMARY_COLUMNS.DATE) return;

            const title = _.get(row, colType, '');
            const id = _.get(row, colType, '');
            colEntries[colType].set(`${id}_${title}`, { id, title });
          });
        });

        _.keys(colEntries).forEach((colType) => {
          _.set(draft.columnEntries, colType, Array.from(colEntries[colType].values()));
        });
        break;
      }
      case GET_ADVERTISER_REPORT_SUMMARY_ERROR: {
        draft.loading = false;
        draft.loadError = action.error;
        break;
      }
      default:
        break;
    }
  });
}

export default reportSummaryReducer;
