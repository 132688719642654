import React from 'react';
import styled from 'styled-components';
import { format } from 'date-fns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import NotificationDelivery, { MENotificationLevel } from 'common-v2/model/notificationDelivery';

import EmergencyAsset from 'assets/imgs/notificationIcon/emergency.svg';
import EmergencyFillAsset from 'assets/imgs/notificationIcon/emergencyFill.svg';
import CriticalAsset from 'assets/imgs/notificationIcon/critical.svg';
import CriticalFillAsset from 'assets/imgs/notificationIcon/criticalFill.svg';
import WarningAsset from 'assets/imgs/notificationIcon/warning.svg';
import WarningFillAsset from 'assets/imgs/notificationIcon/warningFill.svg';
import NormalAsset from 'assets/imgs/notificationIcon/normal.svg';
import NormalFillAsset from 'assets/imgs/notificationIcon/normalFill.svg';
import InfoAsset from 'assets/imgs/notificationIcon/info.svg';
import InfoFillAsset from 'assets/imgs/notificationIcon/infoFill.svg';

const SCPopper = styled.div<{ open: boolean }>`
  position: absolute;
  top: calc(100% + ${(props) => props.theme.spacing(1)}px);
  right: -100px;
  width: 430px;
  box-shadow: 0 4px 9px 0 rgba(0, 0, 0, 0.3);
  background-color: #ffffff;
  z-index: 10;
  display: ${(props) => (props.open ? 'block' : 'none')};

  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
`;

// header
const SCHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 6px 12px;
  border-bottom: solid 1px #e0e0e0;
`;

const SCHeader = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: #666666;
`;

const SCSettingIcon = styled(FontAwesomeIcon)`
  color: #bdbdbd;
  cursor: pointer;
  font-size: 18px;
`;

// body
const SCEmptyNotification = styled.div`
  padding: 48px 0;
  font-size: 12px;
  color: #bdbdbd;
  text-align: center;
`;

const SCDeliveriesWrapper = styled.div`
  overflow: auto;
  max-height: 486px;
`;

const SCDeliveryWrapper = styled.div<{ read: boolean }>`
  border-bottom: solid 1px #e0e0e0;

  &:last-child {
    border: none;
  }

  padding: 9px 12px;
  background-color: ${(props) => (!props.read ? '#edf2fd' : '#fff')};
`;

const SCMessage = styled.span`
  font-size: 12px;
  line-height: 1.25;
  color: #212121;
  display: inline-block;
`;

const SCIcon = styled.img`
  width: 16px;
  height: 16px;
  margin-right: 4px;
  vertical-align: text-bottom;
`;

const SCReceivedAt = styled.div`
  font-size: 11px;
  color: #a9a9a9;
`;

const NOTIFICATION_ASSETS: Record<MENotificationLevel, { fill: string; empty: string }> = {
  [MENotificationLevel.EMERGENCY]: {
    fill: EmergencyFillAsset,
    empty: EmergencyAsset,
  },
  [MENotificationLevel.CRITICAL]: {
    fill: CriticalFillAsset,
    empty: CriticalAsset,
  },
  [MENotificationLevel.WARNING]: {
    fill: WarningFillAsset,
    empty: WarningAsset,
  },
  [MENotificationLevel.NORMAL]: {
    fill: NormalFillAsset,
    empty: NormalAsset,
  },
  [MENotificationLevel.INFORMATION]: {
    fill: InfoFillAsset,
    empty: InfoAsset,
  },
};

const SCDelivery = (props: { delivery: NotificationDelivery }) => {
  const {
    delivery: { level, msg, receivedAtTimestamp, readFlag },
  } = props;

  const asset = readFlag ? NOTIFICATION_ASSETS[level]?.empty : NOTIFICATION_ASSETS[level]?.fill;

  return (
    <SCDeliveryWrapper read={readFlag}>
      <SCMessage>
        <SCIcon draggable="false" src={asset} alt={`level-${level}`} />
        {msg}
      </SCMessage>
      <SCReceivedAt>{format(new Date(receivedAtTimestamp), 'MMM dd, yyyy, hh:mm')}</SCReceivedAt>
    </SCDeliveryWrapper>
  );
};

type MTNotificationControlPopperProps = {
  open: boolean;
  deliveries: Array<NotificationDelivery>;
  onClickSetting: () => void;
};

function MCWebNotificationPanel(props: MTNotificationControlPopperProps) {
  const { open, deliveries, onClickSetting } = props;

  return (
    <SCPopper open={open}>
      <SCHeaderWrapper>
        <SCHeader>Notifications</SCHeader>
        <SCSettingIcon icon={['fas', 'cog']} onClick={onClickSetting} />
      </SCHeaderWrapper>
      {deliveries.length === 0 ? (
        <SCEmptyNotification>No notifications yet</SCEmptyNotification>
      ) : (
        <SCDeliveriesWrapper>
          {deliveries.map((delivery) => (
            <SCDelivery key={delivery.id} delivery={delivery} />
          ))}
        </SCDeliveriesWrapper>
      )}
    </SCPopper>
  );
}

export default MCWebNotificationPanel;
