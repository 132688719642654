import axios, { AxiosRequestConfig } from 'axios';

import { MORSE_V1_API_PATH } from '../constants/apiEndpoints';
import GlobalStore, { STORE_KEY } from '../global/globalStorage';
import { MERole } from '../proto/auth';
import { MIPasswordChangeRequestProto, MIWorkspaceUserProto } from '../proto/morseUser';

function getHeader() {
  return {
    'Content-Type': 'application/json',
  };
}

function getHeaderWithToken(contentType = 'application/json') {
  return {
    'Content-Type': contentType,
    Authorization: `Bearer ${GlobalStore.get(STORE_KEY.AUTH_TOKEN)}`,
  };
}

function getPlatforms(userSpaceId: string, email: string, password: string) {
  const config: AxiosRequestConfig = {
    method: 'POST',
    url: `${MORSE_V1_API_PATH}/userspaces/${userSpaceId}/platforms`,
    headers: getHeader(),
    data: {
      email,
      password,
    },
  };

  return axios(config);
}

function updatePlatform(platform: any) {
  const config: AxiosRequestConfig = {
    method: 'PUT',
    url: `${MORSE_V1_API_PATH}/platforms/${platform.id}`,
    headers: getHeaderWithToken(),
    data: {
      platform,
    },
  };

  return axios(config);
}

function uploadWorkplaceLogo(workplaceId: string, logo: any) {
  const formData = new FormData();
  formData.append('logo', logo);

  const config: AxiosRequestConfig = {
    method: 'POST',
    url: `${MORSE_V1_API_PATH}/platforms/${workplaceId}/logo`,
    headers: getHeaderWithToken('multipart/form-data'),
    data: formData,
  };

  return axios(config);
}

function getMorseToken(userSpaceId: string, workplaceId: string, email: string, password: string) {
  const config: AxiosRequestConfig = {
    method: 'POST',
    url: `${MORSE_V1_API_PATH}/tokens`,
    headers: getHeader(),
    data: {
      userspace_id: userSpaceId,
      workplace_id: workplaceId,
      email,
      password,
    },
  };

  return axios(config);
}

function refreshMorseToken() {
  const config: AxiosRequestConfig = {
    method: 'PUT',
    url: `${MORSE_V1_API_PATH}/tokens`,
    headers: getHeaderWithToken(),
  };

  return axios(config);
}

function listUsers(userspaceId: string, platformId: string, requestRoleInfo: boolean) {
  const config: AxiosRequestConfig = {
    method: 'GET',
    url: `${MORSE_V1_API_PATH}/users`,
    headers: getHeaderWithToken(),
    params: {
      userspace_id: userspaceId,
      platform_id: platformId,
      with_roles: requestRoleInfo,
    },
  };

  return axios(config);
}

function getUser(
  userspaceId: string,
  platformId: string,
  userId: string,
  requestRoleInfo: boolean = false,
) {
  const config: AxiosRequestConfig = {
    method: 'GET',
    url: `${MORSE_V1_API_PATH}/users/${userId}`,
    headers: getHeaderWithToken(),
    params: {
      userspace_id: userspaceId,
      platform_id: platformId,
      with_roles: requestRoleInfo,
    },
  };

  return axios(config);
}

function createUser(user: MIWorkspaceUserProto, passwordResetUrl: string) {
  const config: AxiosRequestConfig = {
    method: 'POST',
    url: `${MORSE_V1_API_PATH}/users`,
    headers: getHeaderWithToken(),
    data: {
      user,
      forward_url: passwordResetUrl,
    },
  };

  return axios(config);
}

function updateUser(
  userspaceId: string,
  user: MIWorkspaceUserProto,
  passwordRequestPayload?: MIPasswordChangeRequestProto,
) {
  const isChangePassword =
    passwordRequestPayload &&
    passwordRequestPayload.current_password &&
    passwordRequestPayload.new_password;

  const data = {
    userspace_id: userspaceId,
    email: user.email,
    name: user.name,
    password: isChangePassword ? passwordRequestPayload : undefined,
  };

  const config: AxiosRequestConfig = {
    method: 'PUT',
    url: `${MORSE_V1_API_PATH}/users/${user.id}`,
    headers: getHeaderWithToken(),
    data,
  };

  return axios(config);
}

function deleteUser(userSpaceId: string, userId: string) {
  const config: AxiosRequestConfig = {
    method: 'DELETE',
    url: `${MORSE_V1_API_PATH}/users/${userId}`,
    headers: getHeaderWithToken(),
    params: {
      userspace_id: userSpaceId,
    },
  };

  return axios(config);
}

function getUserRoles(platformId: string, userId: string) {
  const config: AxiosRequestConfig = {
    method: 'GET',
    url: `${MORSE_V1_API_PATH}/users/${userId}/rolegrants`,
    headers: getHeaderWithToken(),
    params: {
      platform_id: platformId,
    },
  };

  return axios(config);
}

function createRoleGrant(
  platformId: string,
  userId: string,
  roles: Array<MERole>,
  resourceId: string,
  resourceInstanceId: string,
) {
  const config: AxiosRequestConfig = {
    method: 'POST',
    url: `${MORSE_V1_API_PATH}/users/${userId}/rolegrants`,
    headers: getHeaderWithToken(),
    data: {
      platform_id: platformId,
      roles: roles.map((role) => ({ id: role, platform_id: 'ALL' })), // For now all the roles are bound to 'ALL'
      resource_id: resourceId,
      resource_instance_id: resourceInstanceId,
    },
  };

  return axios(config);
}

function deleteRoleGrant(
  platformId: string,
  userId: string,
  roleId: MERole,
  resourceId: string,
  resourceInstanceId: string,
) {
  const config: AxiosRequestConfig = {
    method: 'DELETE',
    url: `${MORSE_V1_API_PATH}/users/${userId}/rolegrants`,
    headers: getHeaderWithToken(),
    params: {
      platform_id: platformId,
      role_id: roleId,
      role_platform_id: 'ALL',
      resource_id: resourceId,
      resource_instance_id: resourceInstanceId,
    },
  };

  return axios(config);
}

function createWorkplaceRegistration(userId: string, platformId: string, forwardUrl?: string) {
  const config: AxiosRequestConfig = {
    method: 'POST',
    url: `${MORSE_V1_API_PATH}/users/${userId}/platforms/${platformId}`,
    headers: getHeaderWithToken(),
  };

  if (forwardUrl) {
    config.data = {
      forward_url: forwardUrl,
    };
  }

  return axios(config);
}

function deleteWorkplaceRegistration(userId: string, platformId: string) {
  const config: AxiosRequestConfig = {
    method: 'DELETE',
    url: `${MORSE_V1_API_PATH}/users/${userId}/platforms/${platformId}`,
    headers: getHeaderWithToken(),
  };

  return axios(config);
}

function updatePassword(passwordResetToken: string, email: string, password: string) {
  const config: AxiosRequestConfig = {
    method: 'PUT',
    url: `${MORSE_V1_API_PATH}/users/${email}/password`,
    headers: { ...getHeader(), Authorization: `Bearer ${passwordResetToken}` },
    data: {
      password,
    },
  };
  return axios(config);
}

function deletePassword(userSpaceId: string, userId: string, resetUrl?: string) {
  const config: AxiosRequestConfig = {
    method: 'DELETE',
    url: `${MORSE_V1_API_PATH}/users/${userId}/password`,
    headers: getHeader(),
    params: {
      userspace_id: userSpaceId,
      forward_url: resetUrl,
    },
  };

  return axios(config);
}

function listAdAccountUsers(platformId: string, adAccountId: string) {
  const config: AxiosRequestConfig = {
    method: 'GET',
    url: `${MORSE_V1_API_PATH}/ad-accounts/${adAccountId}/users`,
    headers: getHeaderWithToken(),
    params: {
      platform_id: platformId,
    },
  };

  return axios(config);
}

function createAdAccountRegistration(
  userId: string,
  platformId: string,
  adAccountId: string,
  adAccountTitle: string,
  forwardUrl = null,
) {
  const config: AxiosRequestConfig = {
    method: 'POST',
    url: `${MORSE_V1_API_PATH}/ad-accounts/${adAccountId}/users`,
    headers: getHeaderWithToken(),
  };

  config.data = {
    platform_id: platformId,
    registration: {
      ad_account_id: adAccountId,
      ad_account_title: adAccountTitle,
      id: userId,
    },
  };

  if (forwardUrl) {
    config.data.forward_url = forwardUrl;
  }

  return axios(config);
}

function deleteAdAccountRegistration(userId: string, platformId: string, adAccountId: string) {
  const config: AxiosRequestConfig = {
    method: 'DELETE',
    url: `${MORSE_V1_API_PATH}/ad-accounts/${adAccountId}/users/${userId}`,
    headers: getHeaderWithToken(),
    params: {
      platform_id: platformId,
    },
  };

  return axios(config);
}

export default {
  getMorseToken,
  refreshMorseToken,
  getPlatforms,
  updatePlatform,
  uploadWorkplaceLogo,
  getUserRoles,
  listUsers,
  getUser,
  createUser,
  updateUser,
  deleteUser,
  createRoleGrant,
  deleteRoleGrant,
  createWorkplaceRegistration,
  deleteWorkplaceRegistration,
  updatePassword,
  deletePassword,
  listAdAccountUsers,
  createAdAccountRegistration,
  deleteAdAccountRegistration,
};
