import _ from 'lodash';

import { MERole } from '../proto/auth';
import { USER_SPACES } from './common';

export const EMAIL_LENGTH_MAX = 50;
export const DISPLAY_NAME_LENGTH_MAX = 50;

export const USERSPACE_HOSTS = [
  {
    userspace: USER_SPACES.USERSPACE_C,
    hosts: ['localhost:7000', 'portal.moloco.cloud'],
  },
  {
    userspace: USER_SPACES.USERSPACE_MOLOCO,
    hosts: ['localhost:8000', 'internal.moloco.cloud', 'report.moloco.cloud'],
  },
];

export const DSP_SERVICE_ADMIN_ROLES = [
  MERole.UNIVERSE_ADMIN,
  MERole.PLATFORM_OWNER,
  MERole.PLATFORM_ADMIN, // temp
  MERole.DSP_PLATFORM_ADMINISTRATOR,

  MERole.DSP_WORKPLACE_OWNER,
];

export const DSP_SERVICE_USER_ROLES = [MERole.DSP_PLATFORM_MANAGER, MERole.DSP_WORKPLACE_USER];

export const ROLES_FOR_DSP_SERVICE = _.union(DSP_SERVICE_ADMIN_ROLES, DSP_SERVICE_USER_ROLES);
export const ROLES_FOR_PUBLISHER_SERVICE = [MERole.UNIVERSE_ADMIN, MERole.PUBLISHER_PLATFORM_ADMIN];

// This is for legacy CM v1
export const CAMPAIGN_MANAGER_ROLE_OPTIONS = [
  {
    label: 'Administrator',
    value: 'DSP_PLATFORM_ADMINISTRATOR',
  },
  {
    label: 'Manager',
    value: 'DSP_PLATFORM_MANAGER',
  },
];

export const WORKPLACE_USER_ROLE_OPTIONS = [
  {
    label: 'Workplace Owner',
    value: MERole.DSP_WORKPLACE_OWNER,
  },
  {
    label: 'Workplace Member',
    value: MERole.DSP_WORKPLACE_USER,
  },
];

export const AD_ACCOUNT_USER_ROLE_OPTIONS = [
  {
    label: 'Ad Account Owner',
    value: MERole.DSP_ADACCOUNT_OWNER,
  },
  {
    label: 'Ad Account Member',
    value: MERole.DSP_ADACCOUNT_USER,
  },
  {
    label: 'Ad Account Viewer',
    value: MERole.DSP_ADACCOUNT_VIEWER,
  },
];

export const CAMPAIGN_MANAGER_ROLE_DISPLAY_MAP = _.keyBy(CAMPAIGN_MANAGER_ROLE_OPTIONS, 'value');
export const WORKPLACE_USER_ROLE_DISPLAY_MAP = _.keyBy(WORKPLACE_USER_ROLE_OPTIONS, 'value');
export const AD_ACCOUNT_USER_ROLE_DISPLAY_MAP = _.keyBy(AD_ACCOUNT_USER_ROLE_OPTIONS, 'value');

export function isDSPAdmin(roles: Array<MERole>) {
  return _.intersection(roles, DSP_SERVICE_ADMIN_ROLES).length > 0;
}

export function isDSPUser(roles: Array<MERole>) {
  return _.intersection(roles, DSP_SERVICE_USER_ROLES).length > 0;
}

export const ALL_ROLES_DISPLAY_MAP = {
  ...AD_ACCOUNT_USER_ROLE_DISPLAY_MAP,
  ...WORKPLACE_USER_ROLE_DISPLAY_MAP,
};

export const getDisplayRole = (role?: MERole) => {
  if (!role) return 'Undefined';

  return _.get(ALL_ROLES_DISPLAY_MAP, role, {
    label: 'Undefined',
  }).label;
};
