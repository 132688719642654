import { MINotificationDeliveryProto } from '../proto/notification';

export enum MENotificationLevel {
  EMERGENCY = 'EMERGENCY',
  CRITICAL = 'CRITICAL',
  WARNING = 'WARNING',
  NORMAL = 'NORMAL',
  INFORMATION = 'INFORMATION',
}

export default class NotificationDelivery {
  id: string;
  level: MENotificationLevel;
  receivedAtTimestamp: number;
  receivedAt: string; // RFC3339 format
  msg: string;
  readFlag: boolean;

  private constructor(proto: MINotificationDeliveryProto) {
    this.id = proto.id;
    this.level = proto.level as MENotificationLevel;
    this.receivedAt = proto.received_at;
    this.receivedAtTimestamp = new Date(this.receivedAt).getTime();
    this.msg = proto.msg;
    this.readFlag = proto.read_flag;
  }

  static fromProto(proto: MINotificationDeliveryProto) {
    return new NotificationDelivery(proto);
  }

  isExpired(): boolean {
    // Notification delivery expires in 60 days.
    const diff = new Date().getTime() - this.receivedAtTimestamp;
    return diff / (1000 * 3600 * 24) > 60;
  }
}
