/*
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here. We prefix them with `componentName` so we avoid
 * reducers accidentally picking up actions they shouldn`t.
 *
 * Follow this format:
 * export const YOUR_ACTION_CONSTANT = `YourContainer/YOUR_ACTION_CONSTANT`;
 */

import { ACTION_TYPE_INVENTORY_MANAGER } from '../types';

const ACTION_TYPE_PUBLISHER_REPORT = 'publisherReport';

export const GET_PUBLISHER_REPORT = `${ACTION_TYPE_INVENTORY_MANAGER}/${ACTION_TYPE_PUBLISHER_REPORT}/GET_PUBLISHER_REPORT`;
export const GET_PUBLISHER_REPORT_SUCCESS = `${ACTION_TYPE_INVENTORY_MANAGER}/${ACTION_TYPE_PUBLISHER_REPORT}/GET_PUBLISHER_REPORT_SUCCESS`;
export const GET_PUBLISHER_REPORT_ERROR = `${ACTION_TYPE_INVENTORY_MANAGER}/${ACTION_TYPE_PUBLISHER_REPORT}/GET_PUBLISHER_REPORT_ERROR`;
