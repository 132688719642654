import _ from 'lodash';
import produce from 'immer';
import GlobalStore, { STORE_KEY } from '../../common/global/globalStorage';
import GlobalStoreV2, { STORE_KEY as STORE_KEY_V2 } from '../../common-v2/global/globalStorage';

import {
  AUTH_STATE,
  SET_AUTH_MESSAGE,
  SET_AUTH_STATE,
  SET_AUTH_TOKEN,
  SET_AUTH_USER,
  SET_AVAILABLE_PLATFORMS,
  SET_PLATFORM,
  SET_MANUAL_LOGOUT,
  SET_USE_MORSE,
  SET_USER_SPACE,
  SAVE_WORKPLACE_REQUEST,
  SAVE_WORKPLACE_SUCCESS,
  SAVE_WORKPLACE_ERROR,
} from './types';

const INITIAL_STATE = {
  state: AUTH_STATE.NOT_READY,
  useMorse: false,
  userSpace: '',
  platform: '',
  user: null,
  authToken: '',
  authMsg: '',
  availablePlatforms: [],
  services: [],
  manualLogout: false,
};

const authReducer = (state = INITIAL_STATE, action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case SET_USE_MORSE: {
        const { useMorse } = action.payload;

        draft.useMorse = useMorse;
        break;
      }
      case SET_PLATFORM: {
        const { platform } = action.payload;

        draft.platform = platform;
        GlobalStore.add(STORE_KEY.PLATFORM, platform);
        GlobalStoreV2.add(STORE_KEY_V2.PLATFORM, platform);
        break;
      }
      case SET_USER_SPACE: {
        const { userSpace } = action.payload;

        draft.userSpace = userSpace;
        GlobalStore.add(STORE_KEY.USER_SPACE, userSpace);
        GlobalStoreV2.add(STORE_KEY_V2.USER_SPACE, userSpace);
        break;
      }
      case SET_AUTH_TOKEN: {
        const { authToken } = action.payload;

        draft.authToken = authToken;
        GlobalStore.add(STORE_KEY.AUTH_TOKEN, authToken);
        GlobalStoreV2.add(STORE_KEY_V2.AUTH_TOKEN, authToken);
        break;
      }
      case SET_AUTH_STATE: {
        const { state: authState } = action.payload;

        draft.state = authState;
        break;
      }
      case SET_AUTH_USER: {
        const { user } = action.payload;

        draft.user = user;
        if (user) {
          draft.services = user.services;
        }
        break;
      }
      case SET_AUTH_MESSAGE: {
        draft.authMsg = action.msg;
        break;
      }
      case SET_AVAILABLE_PLATFORMS: {
        const { platforms } = action.payload;

        draft.availablePlatforms = platforms;
        break;
      }
      case SET_MANUAL_LOGOUT: {
        const { manualLogout } = action.payload;

        draft.manualLogout = manualLogout;
        break;
      }
      case SAVE_WORKPLACE_REQUEST: {
        const { id } = action.payload;
        const idx = _.findIndex(draft.availablePlatforms, { id });

        if (idx !== -1) {
          draft.availablePlatforms[idx].isUpdating = true;
        }
        break;
      }
      case SAVE_WORKPLACE_SUCCESS: {
        const { workplace } = action.payload;
        draft.availablePlatforms = draft.availablePlatforms.map((p) =>
          p.id === workplace.id ? workplace : p,
        );
        break;
      }
      case SAVE_WORKPLACE_ERROR: {
        const { id } = action.payload;
        const idx = _.findIndex(draft.availablePlatforms, { id });

        if (idx !== -1) {
          draft.availablePlatforms[idx].isUpdating = false;
        }
        break;
      }
      default: {
        break;
      }
    }
  });
};

export default authReducer;
