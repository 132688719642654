/* eslint-disable no-unused-vars */
import _ from 'lodash';

export const COLUMN_TYPES = {
  GROUP_BY: 'GROUP_BY',
  METRICS: 'METRICS',
  BREAKDOWN: 'BREAKDOWN',
};

export const REPORT_SUMMARY_COLUMNS = {
  ADVERTISER: 'AD_ACCOUNT',
  ADVERTISER_ID: 'AD_ACCOUNT_ID',
  PRODUCT: 'APP_OR_SITE',
  PRODUCT_ID: 'APP_OR_SITE_ID',
  CAMPAIGN: 'CAMPAIGN',
  CAMPAIGN_ID: 'CAMPAIGN_ID',
  AD_GROUP: 'AD_GROUP',
  AD_GROUP_ID: 'AD_GROUP_ID',
  CREATIVE_GROUP: 'CREATIVE_GROUP',
  CREATIVE_GROUP_ID: 'CREATIVE_GROUP_ID',
  IMPRESSION: 'IMPRESSION',
  CLICK: 'CLICK',
  INSTALL: 'INSTALL',
  SPEND: 'SPEND',
  REVENUE: 'REVENUE',
  CTR: 'CTR',
  CPI: 'CPI',
  DATE: 'DATE',
};

export const REPORT_SUMMARY_COLUMNS_ORDER = [
  REPORT_SUMMARY_COLUMNS.ADVERTISER,
  REPORT_SUMMARY_COLUMNS.PRODUCT,
  REPORT_SUMMARY_COLUMNS.CAMPAIGN,
  REPORT_SUMMARY_COLUMNS.AD_GROUP,
  REPORT_SUMMARY_COLUMNS.CREATIVE_GROUP,
  REPORT_SUMMARY_COLUMNS.DATE,
  REPORT_SUMMARY_COLUMNS.IMPRESSION,
  REPORT_SUMMARY_COLUMNS.CLICK,
  REPORT_SUMMARY_COLUMNS.INSTALL,
  REPORT_SUMMARY_COLUMNS.SPEND,
  REPORT_SUMMARY_COLUMNS.REVENUE,
  REPORT_SUMMARY_COLUMNS.CTR,
  REPORT_SUMMARY_COLUMNS.CPI,
];

export const REPORT_COLUMN_TYPE_SET = {
  [COLUMN_TYPES.GROUP_BY]: [
    REPORT_SUMMARY_COLUMNS.ADVERTISER,
    REPORT_SUMMARY_COLUMNS.PRODUCT,
    REPORT_SUMMARY_COLUMNS.CAMPAIGN,
    REPORT_SUMMARY_COLUMNS.AD_GROUP,
    REPORT_SUMMARY_COLUMNS.CREATIVE_GROUP,
  ],
  [COLUMN_TYPES.METRICS]: [
    REPORT_SUMMARY_COLUMNS.IMPRESSION,
    REPORT_SUMMARY_COLUMNS.CLICK,
    REPORT_SUMMARY_COLUMNS.INSTALL,
    REPORT_SUMMARY_COLUMNS.SPEND,
    REPORT_SUMMARY_COLUMNS.REVENUE,
    REPORT_SUMMARY_COLUMNS.CTR,
    REPORT_SUMMARY_COLUMNS.CPI,
  ],
  [COLUMN_TYPES.BREAKDOWN]: [REPORT_SUMMARY_COLUMNS.DATE],
};

export class CampaignReportSummaryRow {
  constructor(proto) {
    const advertiserId = _.get(proto, 'ad_account.id', '');
    const advertiserTitle = _.get(proto, 'ad_account.title', '');
    const productId = _.get(proto, 'app.id', '');
    const productTitle = _.get(proto, 'app.title', '');
    const campaignId = _.get(proto, 'campaign.id', '');
    const campaignTitle = _.get(proto, 'campaign.title', '');
    const adGroupId = _.get(proto, 'ad_group.id', '');
    const adGroupTitle = _.get(proto, 'ad_group.title', '');
    const creativeGroupId = _.get(proto, 'creative_group.id', '');
    const creativeGroupTitle = _.get(proto, 'creative_group.title', '');
    const impression = parseInt(_.get(proto, 'metric.impressions', 0), 10);
    const click = parseInt(_.get(proto, 'metric.clicks', 0), 10);
    const install = parseInt(_.get(proto, 'metric.installs', 0), 10);
    const spend = _.round(_.get(proto, 'metric.spend', 0.0), 2);
    const revenue = _.round(_.get(proto, 'metric.revenue', 0.0), 2);
    const date = _.get(proto, 'date', '');
    const ctr = impression > 0 ? _.round((click / impression) * 100.0, 3) : 0.0;
    const cpi = install > 0 ? _.round(spend / install, 2) : 0.0;

    this.currency = _.get(proto, 'ad_account.currency', null);
    _.set(this, REPORT_SUMMARY_COLUMNS.ADVERTISER, advertiserTitle);
    _.set(this, REPORT_SUMMARY_COLUMNS.ADVERTISER_ID, advertiserId);
    _.set(this, REPORT_SUMMARY_COLUMNS.PRODUCT, productTitle);
    _.set(this, REPORT_SUMMARY_COLUMNS.PRODUCT_ID, productId);
    _.set(this, REPORT_SUMMARY_COLUMNS.CAMPAIGN, campaignTitle);
    _.set(this, REPORT_SUMMARY_COLUMNS.CAMPAIGN_ID, campaignId);
    _.set(this, REPORT_SUMMARY_COLUMNS.AD_GROUP, adGroupTitle);
    _.set(this, REPORT_SUMMARY_COLUMNS.AD_GROUP_ID, adGroupId);
    _.set(this, REPORT_SUMMARY_COLUMNS.CREATIVE_GROUP, creativeGroupTitle);
    _.set(this, REPORT_SUMMARY_COLUMNS.CREATIVE_GROUP_ID, creativeGroupId);
    _.set(this, REPORT_SUMMARY_COLUMNS.IMPRESSION, impression);
    _.set(this, REPORT_SUMMARY_COLUMNS.CLICK, click);
    _.set(this, REPORT_SUMMARY_COLUMNS.INSTALL, install);
    _.set(this, REPORT_SUMMARY_COLUMNS.SPEND, spend);
    _.set(this, REPORT_SUMMARY_COLUMNS.REVENUE, revenue);
    _.set(this, REPORT_SUMMARY_COLUMNS.CTR, ctr);
    _.set(this, REPORT_SUMMARY_COLUMNS.CPI, cpi);
    _.set(this, REPORT_SUMMARY_COLUMNS.DATE, date);

    return this;
  }

  static fromProto = (proto) => {
    const row = new CampaignReportSummaryRow(proto);
    return row;
  };
}

// eslint-disable-next-line import/prefer-default-export
export class CampaignReportSummary {
  rows = [];

  constructor(proto) {
    const rowsProto = _.get(proto, 'rows', []);
    this.rows = rowsProto.map((row) => CampaignReportSummaryRow.fromProto(row));
  }

  static fromProto = (proto) => {
    const uiObject = new CampaignReportSummary(proto);
    return uiObject;
  };
}
