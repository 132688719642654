/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useField } from 'formik2';

import { MCCheckBoxInput } from './FormStyledComponents';

export type MTCheckBoxFieldProps = {
  testId?: string;
  type?: string;
  name: string;
  label?: string;
  disabled?: boolean;
};

const MCFormCheckBoxField = ({ name, label, disabled = false }: MTCheckBoxFieldProps) => {
  const [field, meta, helpers] = useField(name);
  const { value } = meta;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    helpers.setValue(event.target.checked);
  };

  return (
    <MCCheckBoxInput
      id={name}
      label={label}
      disabled={disabled}
      checked={value}
      {...field}
      onChange={handleChange}
    />
  );
};

export default MCFormCheckBoxField;
