import _ from 'lodash';
import { connect } from 'react-redux';
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import parseISO from 'date-fns/parseISO';
import { bindActionCreators } from 'redux';
import nanoid from 'nanoid';

import {
  closeNotification,
  notifyError,
  notifyInfo,
  notifySuccess,
  notifyWarning,
} from '../../redux/inAppAlert/actions';
import { CURRENCY_DATA_MAP } from '../constants/common';

export function extractErrorMessage(error) {
  let errorMessage;
  if (error) {
    if (_.isString(error)) {
      errorMessage = error;
    } else if (error.response && error.response.data) {
      if (_.isObject(error.response.data)) {
        if ('error' in error.response.data) {
          errorMessage = error.response.data.error;
        } else if ('message' in error.response.data) {
          errorMessage = error.response.data.message;
        } else if ('reason' in error.response.data) {
          errorMessage = error.response.data.reason;
        } else {
          errorMessage = JSON.stringify(error.response.data);
        }
      } else {
        errorMessage = error.response.data;
      }
    } else {
      errorMessage = JSON.stringify(error.message);
    }
    return errorMessage;
  }
  return '';
}

export function isLowercaseAlphaNumeric(value) {
  return /^[a-z0-9_-]+$/.test(value);
}

export function containSpecialChar(value) {
  // eslint-disable-next-line no-useless-escape
  return /[~`!#$%\^&*+=\[\]\\';,/{}|\\":<>\?]/g.test(value);
}

export function camelize(str) {
  return str.replace(/^([A-Z])|[\s-_](\w)/g, (match, p1, p2) => {
    if (p2) {
      return p2.toUpperCase();
    }
    return p1.toLowerCase();
  });
}

export function upperCamelize(str) {
  const camelCased = camelize(str);
  return camelCased.charAt(0).toUpperCase() + camelCased.slice(1);
}

export const convertRemoteDatetime = (datetime) => {
  // Parse ISO-8601 datetime string to local datetime
  try {
    return format(parseISO(datetime), 'yyyy-MM-dd HH:mm:ss');
  } catch {
    return '';
  }
};

export const convertTimestampNanoToDatetime = (timestampNano) => {
  // Nano seconds to human readable format.
  return format(parse(Math.floor(timestampNano / 1000000), 'T', new Date()), 'yyyy-MM-dd HH:mm:ss');
};

// includesObject returns whether an array of object includes a target object by comparing values.
export const includesObject = (arrayOfObj, targetObj) => {
  // eslint-disable-next-line no-restricted-syntax
  for (const obj of arrayOfObj) {
    if (_.isEqual(obj, targetObj)) return true;
  }
  return false;
};

export function getFileExtension(filename) {
  const ext = filename.split('.').pop();
  if (ext === filename) {
    return '';
  }
  return `.${ext}`;
}

export function generateRemoteFilename(filename) {
  return `${new Date().valueOf().toString(36)}_${Math.random()
    .valueOf()
    .toString(36)
    .substring(1, 5)}${getFileExtension(filename)}`;
}

export function padZero(num, size) {
  let s = `${num}`;
  while (s.length < size) {
    s = `0${s}`;
  }
  return s;
}

export const getCurrencySymbol = (currency) => {
  const currencyInfo = CURRENCY_DATA_MAP[currency];
  if (currencyInfo) {
    return currencyInfo.symbol;
  }

  return '';
};

export const getMicroToNormalValue = (micro) => {
  return micro / 1000000;
};

export const getNormalValueToMicro = (value) => {
  return Math.floor(+(value * 1000000).toFixed(2));
};

export const mapAdvertiserAndProductToProps = (state) => ({
  platform: state.auth.platform,
  advertiser: state.campaignManager.advertisers.currentAdvertiser,
  product: state.campaignManager.products.currentProduct,
});

export const withAdvertiserAndProduct = connect(mapAdvertiserAndProductToProps);

export const withNotification = connect(null, (dispatch) =>
  bindActionCreators(
    {
      notifySuccess,
      notifyInfo,
      notifyWarning,
      notifyError,
      closeNotification,
    },
    dispatch,
  ),
);

export const convertToOptions = (objs, labelFieldName = null, valueFieldName = null) => {
  if (!objs) {
    return [];
  }
  return objs.map((obj) => ({
    value: _.isNull(valueFieldName) ? obj : _.get(obj, valueFieldName, null),
    label: _.isNull(labelFieldName) ? obj.title : _.get(obj, labelFieldName, ''),
  }));
};

export const isFalsyNotZero = (val) => {
  return !val && val !== 0;
};

export const sortArrayObject = (objs, fieldName = 'label') => {
  return objs.sort((a, b) => {
    return _.get(a, fieldName, '')
      .toLowerCase()
      .localeCompare(_.get(b, fieldName, '').toLowerCase());
  });
};

// isValidIDFA is used to check if the IDFA is valid.
// A valid IDFA has a pattern of 'XXXXXXXX-XXXX-4XXX-YXXX-XXXXXXXXXXXX',
// where X is a hexadecimal digit and Y is one of "8,9,a,b".
export function isValidIDFA(value) {
  return /^[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}/i.test(value);
}

export function formatNumber(num) {
  return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
}

export function convertToPercentageString(num, decimal = 2) {
  // eslint-disable-next-line no-restricted-properties
  const dec = Math.pow(10, decimal);
  return `${String(Math.round(num * dec * 100) / dec)}%`;
}

export function getUserTimezone() {
  try {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  } catch {
    return 'America/Los_Angeles';
  }
}

export function generateRandomString(size = 8) {
  return nanoid(size);
}
