import * as matters42 from './42matters';

const types = {
  ...matters42,
};

export default types;

export enum MECreativeType {
  IMAGE = 'IMAGE',
  NATIVE = 'NATIVE',
  VIDEO = 'VIDEO',
}

export enum MECreativeResourceType {
  IMAGE = 'IMAGE',
  VIDEO = 'VIDEO',
}

export enum MEAdIdType {
  GOOGLE_ADID = 'GOOGLE_ADID',
  APPLE_IDFA = 'APPLE_IDFA',
}

export enum MEOs {
  ANDROID = 'ANDROID',
  IOS = 'IOS',
}

export enum MEEntity {
  WORKPLACE = 'WORKPLACE',
  AD_ACCOUNT = 'AD_ACCOUNT',
  APP = 'APP',
  CAMPAIGN = 'CAMPAIGN',
  AD_GROUP = 'AD_GROUP',
  CREATIVE_GROUP = 'CREATIVE_GROUP',
  CUSTOM_TARGET = 'CUSTOM_TARGET',
  TRACKING_LINK = 'TRACKING_LINK',
  CUSTOMER_ACTIVITY = 'CUSTOMER_ACTIVITY',
  CUSTOMER_SET = 'CUSTOMER_SET',
  OPERATION_ITEM = 'OPERATION_ITEM',
  REPORT_EXPORT = 'REPORT_EXPORT',
}

export type MTCustomAudienceEntity = MEEntity.CUSTOMER_ACTIVITY | MEEntity.CUSTOMER_SET;

export enum MEUserPermission {
  CREATE = 'CREATE',
  READ = 'READ',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
}

export enum MEProductType {
  APP = 'APP',
  WEB = 'WEB',
}

export enum MEAppMarkets {
  GOOGLE_PLAY = 'GOOGLE_PLAY',
  OTHER_STORE = 'OTHER_STORE',
  APP_STORE = 'APP_STORE',
}

export enum MECampaignType {
  APP_USER_ACQUISITION = 'APP_USER_ACQUISITION',
  APP_REENGAGEMENT = 'APP_REENGAGEMENT',
  WEBSITE_VISIT = 'WEBSITE_VISIT',
  DIRECT_SOLD_APP = 'DIRECT_SOLD_APP',
  DIRECT_SOLD_WEB = 'DIRECT_SOLD_WEB',
  HOUSE_AD_APP = 'HOUSE_AD_APP',
  HOUSE_AD_WEB = 'HOUSE_AD_WEB',
}

export enum MECampaignGoalType {
  OPTIMIZE_APP_INSTALLS = 'OPTIMIZE_APP_INSTALLS', // will be deprecated
  OPTIMIZE_APP_ROAS = 'OPTIMIZE_APP_ROAS', // will be deprecated
  OPTIMIZE_CLICKS_TO_APP = 'OPTIMIZE_CLICKS_TO_APP', // will be deprecated
  OPTIMIZE_CLICKS_TO_WEB = 'OPTIMIZE_CLICKS_TO_WEB', // will be deprecated
  OPTIMIZE_CPA_FOR_APP_UA = 'OPTIMIZE_CPA_FOR_APP_UA',
  OPTIMIZE_CPI_FOR_APP_UA = 'OPTIMIZE_CPI_FOR_APP_UA',
  OPTIMIZE_CPC_FOR_APP_UA = 'OPTIMIZE_CPC_FOR_APP_UA',
  OPTIMIZE_CPA_FOR_APP_RE = 'OPTIMIZE_CPA_FOR_APP_RE',
  OPTIMIZE_CPC_FOR_APP_RE = 'OPTIMIZE_CPC_FOR_APP_RE',
  OPTIMIZE_CPC_FOR_WEB_VISIT = 'OPTIMIZE_CPC_FOR_WEB_VISIT',
  OPTIMIZE_ROAS_FOR_APP_UA = 'OPTIMIZE_ROAS_FOR_APP_UA',
  OPTIMIZE_ROAS_FOR_APP_RE = 'OPTIMIZE_ROAS_FOR_APP_RE',
  FIXED_CPM_FOR_APP_UA = 'FIXED_CPM_FOR_APP_UA',
  FIXED_CPM_FOR_APP_RE = 'FIXED_CPM_FOR_APP_RE',
  OPTIMIZE_NONE = 'OPTIMIZE_NONE',
  UNKNOWN_TYPE = 'UNKNOWN_TYPE',
}

export enum MECampaignState {
  DRAFT = 'DRAFT',
  SUBMITTED = 'SUBMITTED',
  ACTIVE = 'ACTIVE',
  PAUSED = 'PAUSED',
  COMPLETED = 'COMPLETED',
}

export enum MEEnablingState {
  UNSPECIFIED = 'UNSPECIFIED',
  ENABLED = 'ENABLED',
  DISABLED = 'DISABLED',
}

export enum MEAppInstallGoalBidControlMode {
  TARGET_CPI_CENTRIC = 'TARGET_CPI_CENTRIC',
  BUDGET_CENTRIC = 'BUDGET_CENTRIC',
  MARGIN_CENTRIC = 'MARGIN_CENTRIC',
}

export enum MECpaForAppUaGoalBidControlMode {
  BUDGET_CENTRIC = 'BUDGET_CENTRIC',
  MARGIN_CENTRIC = 'MARGIN_CENTRIC',
}

export enum MEAppRoasGoalBidControlMode {
  BUDGET_CENTRIC = 'BUDGET_CENTRIC', // target roas and daily budget
  MARGIN_CENTRIC = 'MARGIN_CENTRIC', // fixed cpi
}

export enum MEAppRoasGoalBidControlPriority {
  TARGET_ROAS = 'TARGET_ROAS',
  DAILY_BUDGET = 'DAILY_BUDGET',
  MARGIN_CENTRIC = 'MARGIN_CENTRIC',
}

export type MTProgressType =
  | 'WORKPLACE'
  | 'ADVERTISER'
  | 'PRODUCT'
  | 'CAMPAIGN'
  | 'AD_GROUP'
  | 'OPERATION_ITEM'
  | 'CREATIVE_GROUP'
  | 'TRACKING_LINK'
  | 'TRACKING_LINK_VERIFICATION'
  | 'CREATIVE_REVIEW'
  | 'REPORT_EXPORT'
  | 'AUDIENCE'
  | 'CUSTOMER_SET'
  | 'CUSTOMER_ACTIVITY'
  | 'LOG_TRANSFER';

export enum MEIntegrationLevel {
  AD_ACCOUNT = 'AD_ACCOUNT',
  APP = 'APP',
}

export enum MEIntegrationPartner {
  SINGULAR = 'SINGULAR',
  APPSFLYER = 'APPSFLYER',
}

export enum METimeUnit {
  HOUR = 'HOUR',
  DAY = 'DAY',
}

export enum MEVerificationStatus {
  UNKNOWN_VERIFICATION_STATUS = 'UNKNOWN_VERIFICATION_STATUS',
  UNVERIFIED = 'UNVERIFIED',
  VERIFYING = 'VERIFYING',
  VERIFIED = 'VERIFIED',
}

export enum MEUrlDataType {
  UNKNOWN_URL_DATA_TYPE = 'UNKNOWN_URL_DATA_TYPE',
  CT_LINK = 'CT_LINK',
  VT_LINK = 'VT_LINK',
}

export enum MEAdTrackingAllowance {
  UNKNOWN_AD_TRACKING_ALLOWANCE = 'UNKNOWN_AD_TRACKING_ALLOWANCE',
  NON_LAT_ONLY = 'NON_LAT_ONLY',
  LAT_ONLY = 'LAT_ONLY',
  DO_NOT_CARE = 'DO_NOT_CARE',
}

export enum MECreativeReviewStatus {
  UNKNOWN = 'UNKNOWN',
  UNDER_REVIEW = 'UNDER_REVIEW',
  APPROVED = 'APPROVED',
  BLOCKED = 'BLOCKED',
  ETC = 'ETC',
}

export enum MEOffice {
  UNKNOWN = 'UNKNOWN_OFFICE',
  KOR = 'KOR',
  USA = 'USA',
  JPN = 'JPN',
  SGP = 'SGP',
  EMEA = 'EMEA',
}

export enum METier {
  UNKNOWN = 'TIER_UNKNOWN',
  TIER_1 = 'TIER_1',
  TIER_2 = 'TIER_2',
  TIER_3 = 'TIER_3',
  TIER_NEW = 'TIER_NEW',
}

export enum METargetEvent {
  IMPRESSION = 'imp',
  CLICK = 'click',
  INSTALL = 'install',
  SPENDING = 'spending',
}

export enum MEPayoutMethod {
  dCPM = 'dCPM',
  FIXED_CPI = 'FIXED_CPI',
  FIXED_CPM = 'FIXED_CPM',
}
