import NotificationDelivery from 'common-v2/model/notificationDelivery';
import { ACTION_TYPE_NOTIFICATION } from '../types';

export const ACTION_TYPE_DELIVERIES = `${ACTION_TYPE_NOTIFICATION}/deliveries`;

export const LOAD_CACHED_NOTIFICATION_INTERVAL = `${ACTION_TYPE_DELIVERIES}/LOAD_CACHED_NOTIFICATION_INTERVAL`;

export const POLLING_NOTIFICATION_DELIVERIES_REQUEST = `${ACTION_TYPE_DELIVERIES}/POLLING_NOTIFICATION_DELIVERIES_REQUEST`;
export const POLLING_NOTIFICATION_DELIVERIES_SUCCESS = `${ACTION_TYPE_DELIVERIES}/POLLING_NOTIFICATION_DELIVERIES_SUCCESS`;
export const POLLING_NOTIFICATION_DELIVERIES_ERROR = `${ACTION_TYPE_DELIVERIES}/POLLING_NOTIFICATION_DELIVERIES_ERROR`;

export const LOAD_NOTIFICATION_DELIVERIES_REQUEST = `${ACTION_TYPE_DELIVERIES}/LOAD_NOTIFICATION_DELIVERIES_REQUEST`;
export const LOAD_NOTIFICATION_DELIVERIES_SUCCESS = `${ACTION_TYPE_DELIVERIES}/LOAD_NOTIFICATION_DELIVERIES_SUCCESS`;
export const LOAD_NOTIFICATION_DELIVERIES_ERROR = `${ACTION_TYPE_DELIVERIES}/LOAD_NOTIFICATION_DELIVERIES_ERROR`;

export const READ_NOTIFICATION_DELIVERIES_REQUEST = `${ACTION_TYPE_DELIVERIES}/READ_NOTIFICATION_DELIVERIES_REQUEST`;
export const READ_NOTIFICATION_DELIVERIES_SUCCESS = `${ACTION_TYPE_DELIVERIES}/READ_NOTIFICATION_DELIVERIES_SUCCESS`;
export const READ_NOTIFICATION_DELIVERIES_ERROR = `${ACTION_TYPE_DELIVERIES}/READ_NOTIFICATION_DELIVERIES_ERROR`;

export interface MILoadCachedNotificationIntervalAction {
  type: typeof LOAD_CACHED_NOTIFICATION_INTERVAL;
  payload: {
    interval: {
      start: number;
      end: number;
    };
  };
}

export interface MIPollingNotificationDeliveriesRequestAction {
  type: typeof POLLING_NOTIFICATION_DELIVERIES_REQUEST;
}

export interface MIPollingNotificationDeliveriesSuccessAction {
  type: typeof POLLING_NOTIFICATION_DELIVERIES_SUCCESS;
  payload: {
    cacheKey: string;
    deliveries: Array<NotificationDelivery>;
    intervalEnd: number;
  };
}

export interface MIPollingNotificationDeliveriesErrorAction {
  type: typeof POLLING_NOTIFICATION_DELIVERIES_ERROR;
  payload: {
    error: string;
  };
}

export interface MILoadNotificationDeliveriesRequestAction {
  type: typeof LOAD_NOTIFICATION_DELIVERIES_REQUEST;
}

export interface MILoadNotificationDeliveriesSuccessAction {
  type: typeof LOAD_NOTIFICATION_DELIVERIES_SUCCESS;
  payload: {
    cacheKey: string;
    deliveries: Array<NotificationDelivery>;
    intervalStart: number;
  };
}

export interface MILoadNotificationDeliveriesErrorAction {
  type: typeof LOAD_NOTIFICATION_DELIVERIES_ERROR;
  payload: {
    error: string;
  };
}

export interface MIReadNotificationDeliveriesRequestAction {
  type: typeof READ_NOTIFICATION_DELIVERIES_REQUEST;
}

export interface MIReadNotificationDeliveriesSuccessAction {
  type: typeof READ_NOTIFICATION_DELIVERIES_SUCCESS;
  payload: {
    cacheKey: string;
    deliveryIds: Array<string>;
  };
}

export interface MIReadNotificationDeliveriesErrorAction {
  type: typeof READ_NOTIFICATION_DELIVERIES_ERROR;
  payload: {
    error: string;
  };
}

type MTLoadCachedNotificationIntervalAction = MILoadCachedNotificationIntervalAction;

type MTPollingNotificationDeliveriesAction =
  | MIPollingNotificationDeliveriesRequestAction
  | MIPollingNotificationDeliveriesSuccessAction
  | MIPollingNotificationDeliveriesErrorAction;

type MTLoadNotificationDeliveriesAction =
  | MILoadNotificationDeliveriesRequestAction
  | MILoadNotificationDeliveriesSuccessAction
  | MILoadNotificationDeliveriesErrorAction;

type MTReadNotificationDeliveriesAction =
  | MIReadNotificationDeliveriesRequestAction
  | MIReadNotificationDeliveriesSuccessAction
  | MIReadNotificationDeliveriesErrorAction;

export type MTNotificationDeliveriesAction =
  | MTLoadCachedNotificationIntervalAction
  | MTPollingNotificationDeliveriesAction
  | MTLoadNotificationDeliveriesAction
  | MTReadNotificationDeliveriesAction;
