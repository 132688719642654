import { shallowEqual, useSelector } from 'react-redux';
import { MIImageCreativeConfig, MINativeMainImageConfig } from '../model/platformSettings';

interface MIUsePlatformSettings {
  availableGoals: Array<string>;
  features: Array<string>;
  supportedCampaignSetups: Record<string, Record<string, Array<string>>>;
  supportedExchanges: Array<string>;
  supportedMMPs: Array<string>;
  supportedImageCreatives: Array<MIImageCreativeConfig>;
  supportedNativeMainImages: Array<MINativeMainImageConfig>;
  isSupportVideoCreative: boolean;
}

function usePlatformSettings(): MIUsePlatformSettings {
  const {
    availableGoals,
    features,
    supportedCampaignSetups,
    supportedExchanges,
    supportedMMPs,
    supportedImageCreatives,
    supportedNativeMainImages,
    isSupportVideoCreative,
  } = useSelector(
    (state: any) => ({
      availableGoals: state.main.platformSettings.availableGoals,
      features: state.main.platformSettings.features,
      supportedCampaignSetups: state.main.platformSettings.supportedCampaignSetups,
      supportedExchanges: state.main.platformSettings.supportedExchanges,
      supportedMMPs: state.main.platformSettings.supportedMMPs,
      supportedImageCreatives: state.main.platformSettings.supportedImageCreatives,
      supportedNativeMainImages: state.main.platformSettings.supportedNativeMainImages,
      isSupportVideoCreative: state.main.platformSettings.isSupportVideoCreative,
    }),
    shallowEqual,
  );

  return {
    availableGoals,
    features,
    supportedCampaignSetups,
    supportedExchanges,
    supportedMMPs,
    supportedImageCreatives,
    supportedNativeMainImages,
    isSupportVideoCreative,
  };
}

export default usePlatformSettings;
