import { Form, Formik } from 'formik2';
import React, { useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { RootState } from 'redux/types';
import { sendPasswordResetRequest } from 'redux/auth/actions';
import { validateUIObject, Validators } from 'common-v2/utils/modelUtils';
import * as routes from 'common-v2/constants/routes';
import { extractErrorMessage } from 'common-v2/utils';
import { doPasswordResetRequest } from 'common/firebase';
import withTracking from 'containers/Common/withTracking';
import { MEButtonColor, MEButtonVariant } from 'components-v2/Common/Button/MCButton';
import { MEFormFieldWidth } from 'components-v2/Common/Form/consts';
import MCFormTextField from 'components-v2/Common/Form/MCFormTextField';
import {
  SCAuthButton,
  SCAuthErrorMessage,
  SCFormWrapper,
  SCNormalText,
  SCPageTitle,
} from './AuthStyledComponents';
import MCAuthLayout from './MCAuthLayout';

const STATUS = {
  READY: 0,
  REQUEST: 1,
  DONE: 2,
};

const STATUS_MESSAGE = {
  [STATUS.REQUEST]: 'Try sending reset password request.',
};

const RESET_PASSWORD_REQUEST_SCHEMA = {
  email: [Validators.required, Validators.emailAddress],
};

type MTRequestPasswordResetInfo = {
  email: string;
};

type MTRequestResetPasswordProps = {
  hideHeader: boolean;
};

function MCRequestResetPassword(props: MTRequestResetPasswordProps): React.ReactElement {
  const { hideHeader = false } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const [status, setStatus] = useState(STATUS.READY);
  const [error, setError] = useState('');
  const { useMorse, user } = useSelector(
    (state: RootState) => ({
      useMorse: state.auth.useMorse,
      user: state.auth.user,
    }),
    shallowEqual,
  );

  const handleRequestResetPassword = async (values: MTRequestPasswordResetInfo) => {
    setStatus(STATUS.REQUEST);

    if (useMorse) {
      dispatch(
        sendPasswordResetRequest(values.email, (resetError: string) => {
          if (resetError) {
            setError(resetError);
            setStatus(STATUS.READY);
          } else {
            setError('');
            setStatus(STATUS.DONE);
          }
        }),
      );
    } else {
      try {
        await doPasswordResetRequest(values.email);
        setStatus(STATUS.DONE);
      } catch (ex) {
        setError(extractErrorMessage(ex));
        setStatus(STATUS.READY);
      }
    }
  };

  const handleGotoLogin = () => {
    history.replace(routes.SIGN_IN);
  };

  const handleRequestBack = () => {
    history.goBack();
  };

  const handleValidation = (values: MTRequestPasswordResetInfo): { email?: string } => {
    // reset error message
    if (error) {
      setError('');
    }
    return validateUIObject(values, RESET_PASSWORD_REQUEST_SCHEMA);
  };

  const getRequestForm = () => {
    return (
      <>
        <SCNormalText>
          {user
            ? 'We’ll send you a link to reset your password.'
            : 'Please enter your email you used to sign up, and we’ll send you a link to reset it.'}
        </SCNormalText>
        <Formik
          validateOnChange
          enableReinitialize
          initialValues={{ email: user ? user.email : '' }}
          validate={handleValidation}
          onSubmit={handleRequestResetPassword}
        >
          {(formikProps) => {
            return (
              <Form>
                <SCFormWrapper>
                  <MCFormTextField
                    name="email"
                    width={MEFormFieldWidth.FULL}
                    label="Email"
                    placeholder="email@domain.com"
                    disabled={status !== STATUS.READY}
                  />
                  {error && <SCAuthErrorMessage>{error}</SCAuthErrorMessage>}
                  {status !== STATUS.READY && <SCNormalText>{STATUS_MESSAGE[status]}</SCNormalText>}
                </SCFormWrapper>
                <SCAuthButton
                  testId="forgotPasswordSubmit"
                  variant={MEButtonVariant.CONTAINED}
                  color={MEButtonColor.PRIMARY}
                  onClick={formikProps.submitForm}
                  disabled={!formikProps.isValid || status !== STATUS.READY}
                >
                  Send password reset email
                </SCAuthButton>
              </Form>
            );
          }}
        </Formik>
      </>
    );
  };

  return (
    <MCAuthLayout hideHeader={hideHeader}>
      {status !== STATUS.DONE ? (
        <>
          <SCPageTitle>Reset Your Password</SCPageTitle>
          {getRequestForm()}
        </>
      ) : (
        <>
          <SCPageTitle>We’ve sent you an email.</SCPageTitle>
          <SCNormalText>Please check your email and reset your password.</SCNormalText>
          {user ? (
            <SCAuthButton onClick={handleRequestBack}>Back</SCAuthButton>
          ) : (
            <SCAuthButton onClick={handleGotoLogin}>Login</SCAuthButton>
          )}
        </>
      )}
    </MCAuthLayout>
  );
}
export default withTracking(MCRequestResetPassword);
