import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/analytics';

const firebaseConfig = {
  dev: {
    apiKey: 'AIzaSyBhNnUPDL4f_BbD2CUznH8_Ng_4JVJOh1k',
    authDomain: 'moloco-adcloud-dev.firebaseapp.com',
    databaseURL: 'https://moloco-adcloud-dev.firebaseio.com',
    projectId: 'moloco-adcloud-dev',
    storageBucket: 'moloco-adcloud-dev.appspot.com',
    messagingSenderId: '188779779939',
    appId: '1:188779779939:web:9e97890bb4cae7f0',
    measurementId: 'G-7GZM8RM2KS',
  },
  prod: {
    apiKey: 'AIzaSyCTG5Kok6B1jSNyMBxO6mZxg_atxC7Eg9k',
    authDomain: 'moloco-adcloud-prod.firebaseapp.com',
    databaseURL: 'https://moloco-adcloud-prod.firebaseio.com',
    projectId: 'moloco-adcloud-prod',
    storageBucket: 'moloco-adcloud-prod.appspot.com',
    messagingSenderId: '227906387966',
    appId: '1:227906387966:web:6821d2296956e0af',
    measurementId: 'G-FV86XBB4VW',
  },
};

if (!firebase.apps.length) {
  let cfg = null;
  if (process.env.REACT_APP_FIREBASE_ENV === 'dev') {
    cfg = firebaseConfig.dev;
  } else if (process.env.REACT_APP_FIREBASE_ENV === 'prod') {
    cfg = firebaseConfig.prod;
  }
  firebase.initializeApp(cfg);
  // firebase.firestore().settings({ timestampsInSnapshots: true });
}

const db = firebase.firestore();
const auth = firebase.auth();
const analytics = firebase.analytics();

// Sign In
const doSignInWithEmailAndPassword = (email, password) =>
  auth
    .setPersistence(firebase.auth.Auth.Persistence.SESSION)
    .then(() => auth.signInWithEmailAndPassword(email, password));

// Sign out
const doSignOut = () => auth.signOut();

// Password Reset
const doPasswordResetRequest = (email) => auth.sendPasswordResetEmail(email);

// Verify password reset code with Firebase
const verifyPasswordResetCode = (code) => auth.verifyPasswordResetCode(code);

// Change password through reset password email link.
const confirmPasswordReset = (actionCode, newPassword) =>
  auth.confirmPasswordReset(actionCode, newPassword);

// Password Change
const doPasswordUpdate = (password) => auth.currentUser.updatePassword(password);

const getCurrentUser = () => auth.currentUser;

const getFirestore = () => db;

// Analytics
const getAnalytics = () => analytics;

export {
  doSignInWithEmailAndPassword,
  doSignOut,
  doPasswordResetRequest,
  doPasswordUpdate,
  verifyPasswordResetCode,
  confirmPasswordReset,
  getCurrentUser,
  getFirestore,
  getAnalytics,
};

export default firebase;
