import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { ZENDESK_KEY } from 'common-v2/constants/apiKeys';
import { RootState } from 'redux/types';
import {
  HELP_CENTER_WORKPLACE_ID_FIELD_ID,
  HELP_CENTER_WORKPLACE_TITLE_FIELD_ID,
} from 'common-v2/constants/common';
import { MIZendeskWindow } from './types';

const zendeskWindow = window as MIZendeskWindow;

const canUseDOM = () => {
  return !(typeof window === 'undefined' || !window.document || !window.document.createElement);
};

const insertScript = (onLoad: () => void) => {
  const script = document.createElement('script');
  script.async = true;
  script.id = 'ze-snippet';
  script.src = `https://static.zdassets.com/ekr/snippet.js?key=${ZENDESK_KEY}`;
  script.addEventListener('load', onLoad);
  document.body.appendChild(script);
};

export const hideWebWidget = () => {
  if (zendeskWindow.zE) {
    zendeskWindow.zE('webWidget', 'hide');
  }
};

export const openWebWidget = () => {
  if (zendeskWindow.zE) {
    zendeskWindow.zE('webWidget', 'show');
    zendeskWindow.zE('webWidget', 'open');
  }
};

const MCZendesk: React.FunctionComponent = () => {
  const { user, platform, availablePlatforms } = useSelector(
    (state: RootState) => ({
      user: state.auth.user,
      platform: state.auth.platform,
      availablePlatforms: state.auth.availablePlatforms,
    }),
    shallowEqual,
  );
  const currentWorkplace = availablePlatforms.find(
    (availablePlatform) => availablePlatform.id === platform,
  );

  const updatePrefill = React.useCallback(() => {
    if (zendeskWindow.zE) {
      zendeskWindow.zE('webWidget', 'updateSettings', {
        webWidget: {
          contactForm: {
            fields: [
              {
                id: 'name',
                prefill: {
                  '*': user?.displayName || '',
                },
                readOnly: true,
              },
              {
                id: 'email',
                prefill: { '*': user?.email || '' },
              },
              {
                id: HELP_CENTER_WORKPLACE_ID_FIELD_ID,
                prefill: {
                  '*': currentWorkplace?.id || '',
                },
              },
              {
                id: HELP_CENTER_WORKPLACE_TITLE_FIELD_ID,
                prefill: {
                  '*': currentWorkplace?.title || '',
                },
              },
            ],
          },
        },
      });
    }
  }, [user, currentWorkplace]);

  React.useEffect(() => {
    // default setting
    zendeskWindow.zESettings = {
      webWidget: {
        offset: {
          vertical: '62px',
        },
        position: {
          vertical: 'top',
        },
        contactForm: {
          subject: true,
        },
      },
    };
  }, []);

  React.useEffect(() => {
    if (canUseDOM() && !zendeskWindow.zE) {
      // insert Zendesk-snippet script to DOM
      insertScript(() => {
        hideWebWidget();
        updatePrefill();
        if (zendeskWindow.zE) {
          // add onClose func: When user closes Web Widget, hide the Web Widget Icon
          zendeskWindow.zE('webWidget:on', 'close', hideWebWidget);
        }
      });
    } else {
      updatePrefill();
    }

    return () => {
      hideWebWidget();
    };
  }, [updatePrefill]);

  return null;
};

export default MCZendesk;
