/*
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here. We prefix them with 'componentName' so we avoid
 * reducers accidentally picking up actions they shouldn't.
 *
 * Follow this format:
 * export const YOUR_ACTION_CONSTANT = 'YourContainer/YOUR_ACTION_CONSTANT';
 */

import { ACTION_TYPE_CAMPAIGN_MANAGER } from '../consts.ts';

export const ACTION_TYPE_ADVERTISER = 'advertiser';

export const CHANGE_ADVERTISER_CONTEXT = `${ACTION_TYPE_CAMPAIGN_MANAGER}/${ACTION_TYPE_ADVERTISER}/CHANGE_ADVERTISER_CONTEXT`;

export const LIST_ADVERTISER_REQUEST = `${ACTION_TYPE_CAMPAIGN_MANAGER}/${ACTION_TYPE_ADVERTISER}/LIST_ADVERTISER_REQUEST`;
export const LIST_ADVERTISER_SUCCESS = `${ACTION_TYPE_CAMPAIGN_MANAGER}/${ACTION_TYPE_ADVERTISER}/LIST_ADVERTISER_SUCCESS`;
export const LIST_ADVERTISER_ERROR = `${ACTION_TYPE_CAMPAIGN_MANAGER}/${ACTION_TYPE_ADVERTISER}/LIST_ADVERTISER_ERROR`;

export const GET_ADVERTISER_REQUEST = `${ACTION_TYPE_CAMPAIGN_MANAGER}/${ACTION_TYPE_ADVERTISER}/GET_ADVERTISER_REQUEST`;
export const GET_ADVERTISER_SUCCESS = `${ACTION_TYPE_CAMPAIGN_MANAGER}/${ACTION_TYPE_ADVERTISER}/GET_ADVERTISER_SUCCESS`;
export const GET_ADVERTISER_ERROR = `${ACTION_TYPE_CAMPAIGN_MANAGER}/${ACTION_TYPE_ADVERTISER}/GET_ADVERTISER_ERROR`;

export const SAVE_ADVERTISER_REQUEST = `${ACTION_TYPE_CAMPAIGN_MANAGER}/${ACTION_TYPE_ADVERTISER}/SAVE_ADVERTISER_REQUEST`;
export const SAVE_ADVERTISER_SUCCESS = `${ACTION_TYPE_CAMPAIGN_MANAGER}/${ACTION_TYPE_ADVERTISER}/SAVE_ADVERTISER_SUCCESS`;
export const SAVE_ADVERTISER_ERROR = `${ACTION_TYPE_CAMPAIGN_MANAGER}/${ACTION_TYPE_ADVERTISER}/SAVE_ADVERTISER_ERROR`;

export const DELETE_ADVERTISER_REQUEST = `${ACTION_TYPE_CAMPAIGN_MANAGER}/${ACTION_TYPE_ADVERTISER}/DELETE_ADVERTISER_REQUEST`;
export const DELETE_ADVERTISER_SUCCESS = `${ACTION_TYPE_CAMPAIGN_MANAGER}/${ACTION_TYPE_ADVERTISER}/DELETE_ADVERTISER_SUCCESS`;
export const DELETE_ADVERTISER_ERROR = `${ACTION_TYPE_CAMPAIGN_MANAGER}/${ACTION_TYPE_ADVERTISER}/DELETE_ADVERTISER_ERROR`;
