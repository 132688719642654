export const MANAGEFNT_URL = process.env.REACT_APP_MANAGEFNT_URL;
export const ADCLOUD_API_URL = process.env.REACT_APP_ADCLOUD_API_URL;
export const MORSE_BASE_PATH = process.env.REACT_APP_MORSE_URL;
export const MORSE_V1_API_PATH = `${MORSE_BASE_PATH}/v1`;
export const ADCLOUD_BASE_PATH = `${MANAGEFNT_URL}/api/adcloud`;
export const ADCLOUD_AUTH_V1_API_PATH = `${ADCLOUD_BASE_PATH}/auth/v1`; // TODO: migrate to new api endpoint
export const ADCLOUD_DSP_V1_API_PATH = `${ADCLOUD_API_URL}/cm/v1`; // use new api endpoint url
export const ADCLOUD_PUBLISHER_V1_API_PATH = `${ADCLOUD_BASE_PATH}/publisher/v1`; // TODO: migrate to new api endpoint

// 3rd party api
export const MATTERS_42_URL = process.env.REACT_APP_MATTERS_42_URL;
