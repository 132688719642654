import React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { triggerSuperUser } from '../../../redux/main/actions';
import { useKeySnatcher } from '../../../common/hooks/useKeySnatcher';

const SUPER_USER_CHEAT_KEYS = 'black sheep wall';
const MOLOCO_EMAIL_DOMAIN = '@molocoads.com';

function EasterEgg() {
  const dispatch = useDispatch();

  const { user, isSuperUser } = useSelector(
    (state) => ({ user: state.auth.user, isSuperUser: state.main.superUser }),
    shallowEqual,
  );

  const isMOLOCOUser = React.useMemo(
    () => user && user.email && user.email.includes(MOLOCO_EMAIL_DOMAIN),
    [user],
  );

  const handleSuperUserTrigger = () => {
    if (isMOLOCOUser) {
      dispatch(triggerSuperUser());
    }
  };

  // Multiple KeyCrawler can be registered.
  useKeySnatcher(SUPER_USER_CHEAT_KEYS, handleSuperUserTrigger);

  return (
    <>
      {/* TODO: Add copyright-free audio */}
      {isSuperUser && (
        <audio controls autoPlay style={{ position: 'absolute', bottom: 0, left: 0 }}>
          <track kind="captions" />
        </audio>
      )}
    </>
  );
}

export default EasterEgg;
