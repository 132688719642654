import { generatePath } from 'react-router-dom';

import { MIRouteIndex, MIRouteConfig } from './types';
import { MERouteKeys } from './consts';

// build route index
const routeIndex: MIRouteIndex = {};

export const buildRouteIndex = (parentPath: string, routeConfigs: Array<MIRouteConfig>) => {
  routeConfigs.forEach((config) => {
    const { key } = config;
    const path = `${parentPath}${config.basePath}${
      config.pathParam ? `/:${config.pathParam}` : ''
    }`;

    routeIndex[key] = {
      key,
      path,
      pathParam: config.pathParam || '',
      paramRequired: !!config.paramRequired,
    };

    if (config.routes) {
      buildRouteIndex(path, config.routes);
    }
  });
};

type ParamType = {
  [key: string]: string | number;
};

type MTGeneratePathForRoute = (key: MERouteKeys, params?: ParamType) => string;

const generatePathForRoute: MTGeneratePathForRoute = (key, params = {}): string => {
  const entry = routeIndex[key];
  if (entry) {
    const { path, pathParam, paramRequired } = entry;
    const paramMatcher = /:([^/]+)/g;
    let match = paramMatcher.exec(path);

    while (match != null) {
      const requiredParam = match[1];
      if (!(requiredParam in params)) {
        if (requiredParam === pathParam && !paramRequired) {
          return generatePath(path.replace(`/:${pathParam}`, ''), params);
        }
        return routeIndex[MERouteKeys.INVALID].path;
      }
      if (params) match = paramMatcher.exec(path);
    }

    return generatePath(path, params);
  }

  return '';
};

export default generatePathForRoute;
