/*
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here. We prefix them with 'componentName' so we avoid
 * reducers accidentally picking up actions they shouldn't.
 *
 * Follow this format:
 * export const YOUR_ACTION_CONSTANT = 'YourContainer/YOUR_ACTION_CONSTANT';
 */

import { ACTION_TYPE_INVENTORY_MANAGER } from '../types';

const ACTION_TYPE_PUBLISHER = 'publishers';

export const CHANGE_PUBLISHER = `${ACTION_TYPE_INVENTORY_MANAGER}/${ACTION_TYPE_PUBLISHER}/CHANGE_PUBLISHER`;

export const LIST_PUBLISHERS = `${ACTION_TYPE_INVENTORY_MANAGER}/${ACTION_TYPE_PUBLISHER}/LIST_PUBLISHERS`;
export const LIST_PUBLISHERS_SUCCESS = `${ACTION_TYPE_INVENTORY_MANAGER}/${ACTION_TYPE_PUBLISHER}/LIST_PUBLISHERS_SUCCESS`;
export const LIST_PUBLISHERS_ERROR = `${ACTION_TYPE_INVENTORY_MANAGER}/${ACTION_TYPE_PUBLISHER}/LIST_PUBLISHERS_ERROR`;

export const GET_PUBLISHER = `${ACTION_TYPE_INVENTORY_MANAGER}/${ACTION_TYPE_PUBLISHER}/GET_PUBLISHER`;
export const GET_PUBLISHER_SUCCESS = `${ACTION_TYPE_INVENTORY_MANAGER}/${ACTION_TYPE_PUBLISHER}/GET_PUBLISHER_SUCCESS`;
export const GET_PUBLISHER_ERROR = `${ACTION_TYPE_INVENTORY_MANAGER}/${ACTION_TYPE_PUBLISHER}/GET_PUBLISHER_ERROR`;

export const SAVE_PUBLISHER = `${ACTION_TYPE_INVENTORY_MANAGER}/${ACTION_TYPE_PUBLISHER}/SAVE_PUBLISHER`;
export const SAVE_PUBLISHER_SUCCESS = `${ACTION_TYPE_INVENTORY_MANAGER}/${ACTION_TYPE_PUBLISHER}/SAVE_PUBLISHER_SUCCESS`;
export const SAVE_PUBLISHER_ERROR = `${ACTION_TYPE_INVENTORY_MANAGER}/${ACTION_TYPE_PUBLISHER}/SAVE_PUBLISHER_ERROR`;

export const DELETE_PUBLISHER = `${ACTION_TYPE_INVENTORY_MANAGER}/${ACTION_TYPE_PUBLISHER}/DELETE_PUBLISHER`;
export const DELETE_PUBLISHER_SUCCESS = `${ACTION_TYPE_INVENTORY_MANAGER}/${ACTION_TYPE_PUBLISHER}/DELETE_PUBLISHER_SUCCESS`;
export const DELETE_PUBLISHER_ERROR = `${ACTION_TYPE_INVENTORY_MANAGER}/${ACTION_TYPE_PUBLISHER}/DELETE_PUBLISHER_ERROR`;
