export const ACTION_MODE = {
  CREATE_PASSWORD: 'createPassword',
  RESET_PASSWORD: 'resetPassword',
};

export const EMAIL_ACTION_QUERY_KEYS = {
  MODE: 'mode',
  TOKEN: 'token',
  OOBCODE: 'oobCode',
  EMAIL: 'email',
};
