import produce from 'immer';
import _ from 'lodash';

import {
  SET_CURRENT_TRACKING_LINK,
  LIST_TRACKING_LINK_SUCCESS,
  LIST_TRACKING_LINK_REQUEST,
  LIST_TRACKING_LINK_ERROR,
  GET_TRACKING_LINK_SUCCESS,
  GET_TRACKING_LINK_ERROR,
  GET_TRACKING_LINK_REQUEST,
  SAVE_TRACKING_LINK_REQUEST,
  SAVE_TRACKING_LINK_SUCCESS,
  SAVE_TRACKING_LINK_ERROR,
} from './types';

// The initial state of the Tracking Links.
export const initialState = {
  loading: false,
  updating: false,
  loadError: null,
  updateError: null,
  lastListRequestTs: {},
  lastEntityRequestTs: {},
  entities: {},
  verificationAttempts: {},
  currentTrackingLink: null,
};

function trackingLinksReducer(state = initialState, action) {
  // eslint-disable-next-line consistent-return
  return produce(state, (draft) => {
    // eslint-disable-next-line default-case
    switch (action.type) {
      case SET_CURRENT_TRACKING_LINK: {
        draft.currentTrackingLink = action.payload;
        break;
      }
      case LIST_TRACKING_LINK_REQUEST: {
        const { advertiserId, productId } = action.payload;

        draft.loading = true;
        draft.loadError = null;
        _.set(draft.lastListRequestTs, [advertiserId, productId], Date.now());
        break;
      }
      case LIST_TRACKING_LINK_SUCCESS: {
        const { trackingLinks } = action.payload;
        draft.loading = false;

        _.forEach(trackingLinks, (trackingLink) => {
          _.set(
            draft.entities,
            [
              trackingLink.adAccountId || trackingLink.advertiserId, // use advertiserId if adAccountId does not exists to keep compatibility with v1 model
              trackingLink.productId,
              trackingLink.id,
            ],
            trackingLink,
          );
        });

        break;
      }
      case LIST_TRACKING_LINK_ERROR: {
        const { error } = action.payload;

        draft.loading = false;
        draft.loadError = error;
        break;
      }
      case GET_TRACKING_LINK_REQUEST: {
        const { trackingLinkId } = action.payload;

        draft.loading = true;
        draft.loadError = null;
        _.set(draft.lastEntityRequestTs, [trackingLinkId], Date.now()); // use only trackingLinkId as key
        break;
      }
      case GET_TRACKING_LINK_SUCCESS: {
        const { trackingLink } = action.payload;

        draft.loading = false;
        _.set(
          draft.entities,
          [
            trackingLink.adAccountId || trackingLink.advertiserId,
            trackingLink.productId,
            trackingLink.id,
          ], // use advertiserId/productId/id in the fetched data
          trackingLink,
        );
        break;
      }
      case GET_TRACKING_LINK_ERROR: {
        const { error } = action.payload;

        draft.loading = false;
        draft.loadError = error;
        break;
      }
      case SAVE_TRACKING_LINK_REQUEST: {
        draft.updating = true;
        draft.updateError = null;
        break;
      }
      case SAVE_TRACKING_LINK_SUCCESS: {
        const { trackingLink } = action.payload;
        draft.updating = false;
        _.set(
          draft.entities,
          [
            trackingLink.adAccountId || trackingLink.advertiserId,
            trackingLink.productId,
            trackingLink.id,
          ], // use advertiserId/productId/id in the fetched data
          trackingLink,
        );
        break;
      }
      case SAVE_TRACKING_LINK_ERROR: {
        const { error } = action.payload;

        draft.updating = false;
        draft.updateError = error;
        break;
      }
      default:
        break;
    }
  });
}

export default trackingLinksReducer;
