/*
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here. We prefix them with 'componentName' so we avoid
 * reducers accidentally picking up actions they shouldn't.
 *
 * Follow this format:
 * export const YOUR_ACTION_CONSTANT = 'YourContainer/YOUR_ACTION_CONSTANT';
 */

import { ACTION_TYPE_CAMPAIGN_MANAGER } from '../consts.ts';

export const ACTION_TYPE_CUSTOMER_SET = 'customerSet';

export const LIST_CUSTOMER_SET_REQUEST = `${ACTION_TYPE_CAMPAIGN_MANAGER}/${ACTION_TYPE_CUSTOMER_SET}/LIST_CUSTOMER_SET_REQUEST`;
export const LIST_CUSTOMER_SET_SUCCESS = `${ACTION_TYPE_CAMPAIGN_MANAGER}/${ACTION_TYPE_CUSTOMER_SET}/LIST_CUSTOMER_SET_SUCCESS`;
export const LIST_CUSTOMER_SET_ERROR = `${ACTION_TYPE_CAMPAIGN_MANAGER}/${ACTION_TYPE_CUSTOMER_SET}/LIST_CUSTOMER_SET_ERROR`;

export const GET_CUSTOMER_SET_REQUEST = `${ACTION_TYPE_CAMPAIGN_MANAGER}/${ACTION_TYPE_CUSTOMER_SET}/GET_CUSTOMER_SET_REQUEST`;
export const GET_CUSTOMER_SET_SUCCESS = `${ACTION_TYPE_CAMPAIGN_MANAGER}/${ACTION_TYPE_CUSTOMER_SET}/GET_CUSTOMER_SET_SUCCESS`;
export const GET_CUSTOMER_SET_ERROR = `${ACTION_TYPE_CAMPAIGN_MANAGER}/${ACTION_TYPE_CUSTOMER_SET}/GET_CUSTOMER_SET_ERROR`;

export const SAVE_CUSTOMER_SET_REQUEST = `${ACTION_TYPE_CAMPAIGN_MANAGER}/${ACTION_TYPE_CUSTOMER_SET}/SAVE_CUSTOMER_SET_REQUEST`;
export const SAVE_CUSTOMER_SET_SUCCESS = `${ACTION_TYPE_CAMPAIGN_MANAGER}/${ACTION_TYPE_CUSTOMER_SET}/SAVE_CUSTOMER_SET_SUCCESS`;
export const SAVE_CUSTOMER_SET_ERROR = `${ACTION_TYPE_CAMPAIGN_MANAGER}/${ACTION_TYPE_CUSTOMER_SET}/SAVE_CUSTOMER_SET_ERROR`;
