interface MIFrontendData {
  key: string;
  data: Array<string>;
}

export enum MEImageCreativeSubTypeProto {
  CREATIVE_SUB_TYPE_IMAGE_BANNER = 'CREATIVE_SUB_TYPE_IMAGE_BANNER',
  CREATIVE_SUB_TYPE_IMAGE_FULL_SCREEN = 'CREATIVE_SUB_TYPE_IMAGE_FULL_SCREEN',
  CREATIVE_SUB_TYPE_IMAGE_MEDIUM = 'CREATIVE_SUB_TYPE_IMAGE_MEDIUM',
  CREATIVE_SUB_TYPE_IMAGE_TABLET = 'CREATIVE_SUB_TYPE_IMAGE_TABLET',
  CREATIVE_SUB_TYPE_IMAGE_UNKNOWN = 'CREATIVE_SUB_TYPE_IMAGE_UNKNOWN',
}

export enum MENativeCreativeSubTypeProto {
  CREATIVE_SUB_TYPE_NATIVE_MAIN_IMAGE = 'CREATIVE_SUB_TYPE_NATIVE_MAIN_IMAGE',
  // Not used yet
  // CREATIVE_SUB_TYPE_NATIVE_ICON = 'CREATIVE_SUB_TYPE_NATIVE_ICON',
  // CREATIVE_SUB_TYPE_NATIVE_UNKNOWN = 'CREATIVE_SUB_TYPE_NATIVE_UNKNOWN',
}

export enum MEVideoCreativeSubTypeProto {
  CREATIVE_SUB_TYPE_VIDEO_VIDEO = 'CREATIVE_SUB_TYPE_VIDEO_VIDEO',
}

export interface MIImageCreativeAvailableDimensionsProto {
  [MEImageCreativeSubTypeProto.CREATIVE_SUB_TYPE_IMAGE_BANNER]?: {
    dimensions?: Array<string>;
  };
  [MEImageCreativeSubTypeProto.CREATIVE_SUB_TYPE_IMAGE_FULL_SCREEN]?: {
    dimensions?: Array<string>;
  };
  [MEImageCreativeSubTypeProto.CREATIVE_SUB_TYPE_IMAGE_MEDIUM]?: {
    dimensions?: Array<string>;
  };
  [MEImageCreativeSubTypeProto.CREATIVE_SUB_TYPE_IMAGE_TABLET]?: {
    dimensions?: Array<string>;
  };
  [MEImageCreativeSubTypeProto.CREATIVE_SUB_TYPE_IMAGE_UNKNOWN]?: {
    dimensions?: Array<string>;
  };
}

export interface MINativeCreativeAvailableDimensionsProto {
  [MENativeCreativeSubTypeProto.CREATIVE_SUB_TYPE_NATIVE_MAIN_IMAGE]?: {
    dimensions?: Array<string>;
  };
}

export interface MIVideoCreativeAvailableDimensionsProto {
  [MEVideoCreativeSubTypeProto.CREATIVE_SUB_TYPE_VIDEO_VIDEO]?: {
    dimensions?: Array<string>;
  };
}

interface MIAvailableCreativesProto {
  CREATIVE_TYPE_IMAGE?: {
    available_dimensions?: MIImageCreativeAvailableDimensionsProto;
  };
  CREATIVE_TYPE_NATIVE?: {
    available_dimensions?: MINativeCreativeAvailableDimensionsProto;
  };
  CREATIVE_TYPE_VIDEO?: {
    available_dimensions?: MIVideoCreativeAvailableDimensionsProto;
  };
}

export interface MIPlatformSettingProto {
  frontend_data?: Array<MIFrontendData>;
  supported_features?: Array<string>;
  available_mmps?: Array<string>;
  available_exchanges?: Array<string>;
  available_goals?: Array<string>;
  available_creatives?: MIAvailableCreativesProto;
}
