import common from './common';
import auth from './auth';
import campaign from './campaign';
import inventory from './inventory';
import morse from './morse';
import matters42 from './42matters';

const api = {
  ...common,
  ...auth,
  ...campaign,
  ...inventory,
  ...morse,
  ...matters42,
};

export default api;
