import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  HELP_CENTER_IS_CLOUD_USER_FIELD_ID,
  HELP_CENTER_NAME_FIELD_ID,
  HELP_CENTER_REQUEST_URI,
  HELP_CENTER_TICKET_FORM_ID,
  HELP_CENTER_URI,
  HELP_CENTER_WORKPLACE_ID_FIELD_ID,
  HELP_CENTER_WORKPLACE_TITLE_FIELD_ID,
} from 'common-v2/constants/common';
import { RootState } from 'redux/types';
import usePlatformSettings from 'common-v2/hooks/usePlatformSettings';
import { FEATURES } from 'common-v2/constants/features';
import { openWebWidget } from '../Common/Zendesk/MCZendesk';

const SCPopper = styled.div<{ open: boolean }>`
  position: absolute;
  top: calc(100% + ${(props) => props.theme.spacing(1)}px);
  right: -100px;
  width: 200px;
  box-shadow: 0 4px 9px 0 rgba(0, 0, 0, 0.3);
  background-color: #ffffff;
  z-index: 10;
  padding: ${(props) => props.theme.spacing(1)}px 0;
  display: ${(props) => (props.open ? 'block' : 'none')};
`;

const SCAnchor = styled.a`
  &:hover {
    text-decoration: none;
  }
`;

const SCItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 200px;
  height: 27px;
  background-color: #ffffff;
  font-size: 12px;
  font-weight: 500;
  color: #666666;
  padding: ${(props) => props.theme.spacing(1)}px ${(props) => props.theme.spacing(2)}px;
  cursor: pointer;

  &:hover {
    background-color: #f3f3f3;
  }
`;

type MTHelpCenterPopperProps = { open: boolean; closePopper: () => void };

const MCHelpCenterPopper: React.FunctionComponent<MTHelpCenterPopperProps> = ({
  open,
  closePopper,
}: MTHelpCenterPopperProps) => {
  const { features } = usePlatformSettings();
  const { user, platform, availablePlatforms } = useSelector(
    (state: RootState) => ({
      user: state.auth.user,
      platform: state.auth.platform,
      availablePlatforms: state.auth.availablePlatforms,
    }),
    shallowEqual,
  );
  const currentWorkplace = availablePlatforms.find(
    (availablePlatform) => availablePlatform.id === platform,
  );

  const helpCenterRequestUri = `${HELP_CENTER_REQUEST_URI}?ticket_form_id=${HELP_CENTER_TICKET_FORM_ID}&${HELP_CENTER_IS_CLOUD_USER_FIELD_ID}=yes&${HELP_CENTER_NAME_FIELD_ID}=${
    user.displayName
  }&email=${user.email}&${HELP_CENTER_WORKPLACE_ID_FIELD_ID}=${currentWorkplace?.id ??
    ''}&${HELP_CENTER_WORKPLACE_TITLE_FIELD_ID}=${currentWorkplace?.title ?? ''}
`;

  return (
    <SCPopper open={open}>
      {features.includes(FEATURES.ZENDESK_WEB_WIDGET) && (
        <SCItem
          onClick={() => {
            closePopper();
            openWebWidget();
          }}
        >
          Get help
        </SCItem>
      )}

      <SCAnchor href={HELP_CENTER_URI} target="_blank" rel="noopener noreferrer">
        <SCItem>
          Help center <FontAwesomeIcon icon={['far', 'external-link-alt']} />
        </SCItem>
      </SCAnchor>

      <SCAnchor href={helpCenterRequestUri} target="_blank" rel="noopener noreferrer">
        <SCItem>
          Contact us <FontAwesomeIcon icon={['far', 'external-link-alt']} />
        </SCItem>
      </SCAnchor>
    </SCPopper>
  );
};

export default MCHelpCenterPopper;
