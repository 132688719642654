/*
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here. We prefix them with 'componentName' so we avoid
 * reducers accidentally picking up actions they shouldn't.
 *
 * Follow this format:
 * export const YOUR_ACTION_CONSTANT = 'YourContainer/YOUR_ACTION_CONSTANT';
 */

import { ACTION_TYPE_CAMPAIGN_MANAGER } from '../consts.ts';

export const ACTION_TYPE_OPERATION_ITEM = 'operation_item';

export const LIST_OPERATION_ITEM_REQUEST = `${ACTION_TYPE_CAMPAIGN_MANAGER}/${ACTION_TYPE_OPERATION_ITEM}/LIST_OPERATION_ITEM_REQUEST`;
export const LIST_OPERATION_ITEM_SUCCESS = `${ACTION_TYPE_CAMPAIGN_MANAGER}/${ACTION_TYPE_OPERATION_ITEM}/LIST_OPERATION_ITEM_SUCCESS`;
export const LIST_OPERATION_ITEM_ERROR = `${ACTION_TYPE_CAMPAIGN_MANAGER}/${ACTION_TYPE_OPERATION_ITEM}/LIST_OPERATION_ITEM_ERROR`;

export const GET_OPERATION_ITEM_REQUEST = `${ACTION_TYPE_CAMPAIGN_MANAGER}/${ACTION_TYPE_OPERATION_ITEM}/GET_OPERATION_ITEM_REQUEST`;
export const GET_OPERATION_ITEM_SUCCESS = `${ACTION_TYPE_CAMPAIGN_MANAGER}/${ACTION_TYPE_OPERATION_ITEM}/GET_OPERATION_ITEM_SUCCESS`;
export const GET_OPERATION_ITEM_ERROR = `${ACTION_TYPE_CAMPAIGN_MANAGER}/${ACTION_TYPE_OPERATION_ITEM}/GET_OPERATION_ITEM_ERROR`;

export const SAVE_OPERATION_ITEM_REQUEST = `${ACTION_TYPE_CAMPAIGN_MANAGER}/${ACTION_TYPE_OPERATION_ITEM}/SAVE_OPERATION_ITEM_REQUEST`;
export const SAVE_OPERATION_ITEM_SUCCESS = `${ACTION_TYPE_CAMPAIGN_MANAGER}/${ACTION_TYPE_OPERATION_ITEM}/SAVE_OPERATION_ITEM_SUCCESS`;
export const SAVE_OPERATION_ITEM_ERROR = `${ACTION_TYPE_CAMPAIGN_MANAGER}/${ACTION_TYPE_OPERATION_ITEM}/SAVE_OPERATION_ITEM_ERROR`;
