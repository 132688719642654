import produce from 'immer';
import _ from 'lodash';

import {
  LIST_CUSTOMER_ACTIVITY_REQUEST,
  LIST_CUSTOMER_ACTIVITY_SUCCESS,
  LIST_CUSTOMER_ACTIVITY_ERROR,
  GET_CUSTOMER_ACTIVITY_REQUEST,
  GET_CUSTOMER_ACTIVITY_SUCCESS,
  GET_CUSTOMER_ACTIVITY_ERROR,
  SAVE_CUSTOMER_ACTIVITY_REQUEST,
  SAVE_CUSTOMER_ACTIVITY_SUCCESS,
  SAVE_CUSTOMER_ACTIVITY_ERROR,
} from './types';

// The initial state of the CustomerActivities.
export const initialState = {
  loading: false,
  updating: false,
  loadError: null,
  updateError: null,
  lastListRequestTs: {},
  lastEntityRequestTs: {},
  entities: {},
};

function customerActivitiesReducer(state = initialState, action) {
  // eslint-disable-next-line consistent-return
  return produce(state, (draft) => {
    switch (action.type) {
      case LIST_CUSTOMER_ACTIVITY_REQUEST: {
        const { advertiserId } = action.payload;

        draft.loading = true;
        draft.loadError = null;
        _.set(draft.lastListRequestTs, [advertiserId], Date.now());
        break;
      }
      case LIST_CUSTOMER_ACTIVITY_SUCCESS: {
        const { advertiserId, customerActivities } = action.payload;

        draft.loading = false;
        draft.entities[advertiserId] = _.keyBy(
          customerActivities,
          (customerActivity) => customerActivity.id,
        );
        break;
      }
      case LIST_CUSTOMER_ACTIVITY_ERROR: {
        const { error } = action.payload;

        draft.loading = false;
        draft.loadError = error;
        break;
      }
      case GET_CUSTOMER_ACTIVITY_REQUEST: {
        const { advertiserId, customerActivityId } = action.payload;

        draft.loading = true;
        draft.loadError = null;
        _.set(draft.lastEntityRequestTs, [advertiserId, customerActivityId], Date.now());
        break;
      }
      case GET_CUSTOMER_ACTIVITY_SUCCESS: {
        const { advertiserId, customerActivity } = action.payload;

        draft.loading = false;
        _.set(draft.entities, [advertiserId, customerActivity.id], customerActivity);
        break;
      }
      case GET_CUSTOMER_ACTIVITY_ERROR: {
        const { error } = action.payload;

        draft.loading = false;
        draft.loadError = error;
        break;
      }
      case SAVE_CUSTOMER_ACTIVITY_REQUEST: {
        const { advertiserId, customerActivityId } = action.payload;

        draft.updating = true;
        draft.updateError = null;
        if (advertiserId && customerActivityId) {
          const newEntity = draft.entities[advertiserId][customerActivityId];
          newEntity.updating = true;
          newEntity.updateError = null;

          _.set(draft.entities, [advertiserId, customerActivityId], newEntity);
        }
        break;
      }
      case SAVE_CUSTOMER_ACTIVITY_SUCCESS: {
        const { advertiserId, customerActivity } = action.payload;

        draft.updating = false;
        _.set(draft.entities, [advertiserId, customerActivity.id], customerActivity);
        break;
      }
      case SAVE_CUSTOMER_ACTIVITY_ERROR: {
        const { advertiserId, customerActivityId, error } = action.payload;

        draft.updating = true;
        draft.updateError = error;
        if (advertiserId && customerActivityId) {
          const newEntity = draft.entities[advertiserId][customerActivityId];
          newEntity.updating = false;
          newEntity.updateError = error;

          _.set(draft.entities, [advertiserId, customerActivityId], newEntity);
        }
        break;
      }
      default:
        break;
    }
  });
}

export default customerActivitiesReducer;
