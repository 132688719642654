import _ from 'lodash';
import PropTypes from 'prop-types';

import { FIELD_TYPE } from '../../components/Common/Formik/consts';
import {
  toUIObjectDefault,
  fromUIObjectDefault,
  validateUIObject,
  Validators,
} from '../utils/modelUtils';

const PUBLISHER_TIMEZONE_OPTIONS = [{ label: 'Asia/Seoul', value: 'Asia/Seoul' }];
const MAX_CHAR_NUM = 30;

export class Publisher {
  constructor(object) {
    this.id = _.get(object, 'id', '');
    this.title = _.get(object, 'title', '');
    this.description = _.get(object, 'description', '');
    this.exchangeName = _.get(object, 'exchangeName', '');
    this.bcat = _.get(object, 'bcat', []);
    this.badv = _.get(object, 'badv', []);
    this.bapp = _.get(object, 'bapp', []);
    this.timezone = _.get(object, 'timezone', '');
    this.createdAt = _.get(object, 'createdAt', null);
    this.updatedAt = _.get(object, 'updatedAt', null);
    this.proto = _.get(object, 'proto', null);
  }

  static getValidationSchema = () => {
    return {
      title: [
        Validators.required,
        Validators.noSpecialChar,
        Validators.maxTextLength(MAX_CHAR_NUM),
      ],
      exchangeName: [Validators.required],
      timezone: [Validators.required],
    };
  };

  static getUISchema = (exchanges, isCreate, isProcessing) => {
    return {
      id: {
        fieldType: FIELD_TYPE.TEXT_FIELD,
        label: 'ID(Debugging)',
        disabled: true,
      },
      title: {
        fieldType: FIELD_TYPE.TEXT_FIELD,
        label: 'Name',
        required: true,
        placeholder: 'Please enter a name',
        displayNumCharacters: true,
        maxNumCharacters: MAX_CHAR_NUM,
      },
      exchangeName: {
        fieldType: FIELD_TYPE.SELECT_FIELD,
        label: 'Exchange',
        required: true,
        placeholder: 'Please select an exchange',
        options: exchanges,
        disabled: !isCreate || isProcessing,
      },
      timezone: {
        fieldType: FIELD_TYPE.SEARCHABLE_SELECT,
        label: 'Timezone',
        required: true,
        placeholder: 'Please select a timezone',
        options: PUBLISHER_TIMEZONE_OPTIONS,
        disabled: !isCreate || isProcessing,
        isMulti: false,
      },
    };
  };

  static toUIObject = (proto) => {
    return toUIObjectDefault(proto, Publisher);
  };

  static fromUIObject = (object) => {
    return fromUIObjectDefault(object, Publisher);
  };

  static validateUIObject = (object) => {
    return validateUIObject(object, Publisher.getValidationSchema(object));
  };
}

export const PublisherPropTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  exchangeName: PropTypes.string.isRequired,
  description: PropTypes.string,
  bcat: PropTypes.array,
  badv: PropTypes.array,
  bapp: PropTypes.array,
};
