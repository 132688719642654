export const setLocalCacheObject = (key: string, obj: any) => {
  localStorage.setItem(key, JSON.stringify(obj));
};

export const getLocalCacheObject = (key: string) => {
  const cachedStr: string | null = localStorage.getItem(key);
  if (cachedStr === null) return null;
  return JSON.parse(cachedStr);
};

export const removeLocalCacheObject = (key: string) => {
  localStorage.removeItem(key);
};

export const setSessionCacheObject = (key: string, obj: any) => {
  sessionStorage.setItem(key, JSON.stringify(obj));
};

export const getSessionCacheObject = (key: string) => {
  const cachedStr: string | null = sessionStorage.getItem(key);
  if (cachedStr === null) return null;
  return JSON.parse(cachedStr);
};

export const removeSessionCacheObject = (key: string) => {
  sessionStorage.removeItem(key);
};
