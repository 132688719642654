import _ from 'lodash';
import {
  isDSPAdmin,
  isDSPUser,
  ROLES_FOR_DSP_SERVICE,
  ROLES_FOR_PUBLISHER_SERVICE,
} from '../constants/auth';
import { MEPlatforms, SERVICES } from '../constants/common';
import { MERole, MIRoleGrantProto } from '../proto/auth';
import { MEEntity, MEUserPermission } from '../types';

export type MTPermissionControlEntity =
  | MEEntity.WORKPLACE
  | MEEntity.AD_ACCOUNT
  | MEEntity.APP
  | MEEntity.CAMPAIGN
  | MEEntity.AD_GROUP;

const REQUIRED_ROLES: Record<MTPermissionControlEntity, Record<MEUserPermission, Array<MERole>>> = {
  [MEEntity.WORKPLACE]: {
    [MEUserPermission.CREATE]: [MERole.DSP_PLATFORM_ADMINISTRATOR, MERole.DSP_WORKPLACE_OWNER],
    [MEUserPermission.READ]: [
      MERole.DSP_PLATFORM_ADMINISTRATOR,
      MERole.DSP_WORKPLACE_OWNER,
      MERole.DSP_ADACCOUNT_OWNER,
      MERole.DSP_ADACCOUNT_USER,
      MERole.DSP_ADACCOUNT_VIEWER,
    ],
    [MEUserPermission.UPDATE]: [MERole.DSP_PLATFORM_ADMINISTRATOR, MERole.DSP_WORKPLACE_OWNER],
    [MEUserPermission.DELETE]: [MERole.DSP_PLATFORM_ADMINISTRATOR, MERole.DSP_WORKPLACE_OWNER],
  },
  [MEEntity.AD_ACCOUNT]: {
    [MEUserPermission.CREATE]: [MERole.DSP_PLATFORM_ADMINISTRATOR, MERole.DSP_WORKPLACE_OWNER],
    [MEUserPermission.READ]: [
      MERole.DSP_PLATFORM_ADMINISTRATOR,
      MERole.DSP_WORKPLACE_OWNER,
      MERole.DSP_ADACCOUNT_OWNER,
      MERole.DSP_ADACCOUNT_USER,
      MERole.DSP_ADACCOUNT_VIEWER,
    ],
    [MEUserPermission.UPDATE]: [
      MERole.DSP_PLATFORM_ADMINISTRATOR,
      MERole.DSP_WORKPLACE_OWNER,
      MERole.DSP_ADACCOUNT_OWNER,
    ],
    [MEUserPermission.DELETE]: [
      MERole.DSP_PLATFORM_ADMINISTRATOR,
      MERole.DSP_WORKPLACE_OWNER,
      MERole.DSP_ADACCOUNT_OWNER,
    ],
  },
  [MEEntity.APP]: {
    [MEUserPermission.CREATE]: [
      MERole.DSP_PLATFORM_ADMINISTRATOR,
      MERole.DSP_WORKPLACE_OWNER,
      MERole.DSP_ADACCOUNT_OWNER,
      MERole.DSP_ADACCOUNT_USER,
    ],
    [MEUserPermission.READ]: [
      MERole.DSP_PLATFORM_ADMINISTRATOR,
      MERole.DSP_WORKPLACE_OWNER,
      MERole.DSP_ADACCOUNT_OWNER,
      MERole.DSP_ADACCOUNT_USER,
      MERole.DSP_ADACCOUNT_VIEWER,
    ],
    [MEUserPermission.UPDATE]: [
      MERole.DSP_PLATFORM_ADMINISTRATOR,
      MERole.DSP_WORKPLACE_OWNER,
      MERole.DSP_ADACCOUNT_OWNER,
      MERole.DSP_ADACCOUNT_USER,
    ],
    [MEUserPermission.DELETE]: [
      MERole.DSP_PLATFORM_ADMINISTRATOR,
      MERole.DSP_WORKPLACE_OWNER,
      MERole.DSP_ADACCOUNT_OWNER,
      MERole.DSP_ADACCOUNT_USER,
    ],
  },
  [MEEntity.CAMPAIGN]: {
    [MEUserPermission.CREATE]: [
      MERole.DSP_PLATFORM_ADMINISTRATOR,
      MERole.DSP_WORKPLACE_OWNER,
      MERole.DSP_ADACCOUNT_OWNER,
      MERole.DSP_ADACCOUNT_USER,
    ],
    [MEUserPermission.READ]: [
      MERole.DSP_PLATFORM_ADMINISTRATOR,
      MERole.DSP_WORKPLACE_OWNER,
      MERole.DSP_ADACCOUNT_OWNER,
      MERole.DSP_ADACCOUNT_USER,
      MERole.DSP_ADACCOUNT_VIEWER,
    ],
    [MEUserPermission.UPDATE]: [
      MERole.DSP_PLATFORM_ADMINISTRATOR,
      MERole.DSP_WORKPLACE_OWNER,
      MERole.DSP_ADACCOUNT_OWNER,
      MERole.DSP_ADACCOUNT_USER,
    ],
    [MEUserPermission.DELETE]: [
      MERole.DSP_PLATFORM_ADMINISTRATOR,
      MERole.DSP_WORKPLACE_OWNER,
      MERole.DSP_ADACCOUNT_OWNER,
      MERole.DSP_ADACCOUNT_USER,
    ],
  },
  [MEEntity.AD_GROUP]: {
    [MEUserPermission.CREATE]: [
      MERole.DSP_PLATFORM_ADMINISTRATOR,
      MERole.DSP_WORKPLACE_OWNER,
      MERole.DSP_ADACCOUNT_OWNER,
      MERole.DSP_ADACCOUNT_USER,
    ],
    [MEUserPermission.READ]: [
      MERole.DSP_PLATFORM_ADMINISTRATOR,
      MERole.DSP_WORKPLACE_OWNER,
      MERole.DSP_ADACCOUNT_OWNER,
      MERole.DSP_ADACCOUNT_USER,
    ],
    [MEUserPermission.UPDATE]: [
      MERole.DSP_PLATFORM_ADMINISTRATOR,
      MERole.DSP_WORKPLACE_OWNER,
      MERole.DSP_ADACCOUNT_OWNER,
      MERole.DSP_ADACCOUNT_USER,
    ],
    [MEUserPermission.DELETE]: [
      MERole.DSP_PLATFORM_ADMINISTRATOR,
      MERole.DSP_WORKPLACE_OWNER,
      MERole.DSP_ADACCOUNT_OWNER,
      MERole.DSP_ADACCOUNT_USER,
    ],
  },
};

function hasPermission(
  roles: Array<MERole>,
  entity: MTPermissionControlEntity,
  permission: MEUserPermission,
): boolean {
  return _.intersection(roles, REQUIRED_ROLES[entity][permission]).length > 0;
}

export function getUserRoleRank(role: MERole) {
  switch (role) {
    case MERole.DSP_PLATFORM_ADMINISTRATOR:
    case MERole.DSP_WORKPLACE_OWNER:
      return 0;
    case MERole.DSP_ADACCOUNT_OWNER:
      return 1;
    case MERole.DSP_ADACCOUNT_USER:
      return 2;
    case MERole.DSP_ADACCOUNT_VIEWER:
      return 3;
    default:
      break;
  }

  return 4;
}

export type MTAuthUserAdAccountRoles = Array<{ adAccountId: string; roles: Array<MERole> }>;

// eslint-disable-next-line import/prefer-default-export
export class AuthUser {
  id: string = '';
  // eslint-disable-next-line react/static-property-placement
  displayName: string = '';
  email: string = '';
  fbUser: any = null;
  platform: string = '';
  workplaceRoles: Array<MERole> = [];
  adAccountRoles: MTAuthUserAdAccountRoles = [];
  services: Array<string> = [];
  photoURL: string = '';

  static newFirebaseUser(firebaseUser: any, adCloudUser: any) {
    const authUser = new AuthUser();

    authUser.email = _.get(firebaseUser, 'email', '');
    authUser.photoURL = _.get(firebaseUser, 'photoURL', '');
    authUser.fbUser = firebaseUser;
    authUser.id = _.get(adCloudUser, 'id', '');
    authUser.displayName = _.get(adCloudUser, 'display_name', '');

    // TODO: For Universe platform user temporarily set skt as default, user can change platform later.
    authUser.platform = _.get(adCloudUser, 'platform_id', MEPlatforms.SKT).toUpperCase();

    return authUser;
  }

  static newMorseUser(morseUser: any): AuthUser {
    const authUser = new AuthUser();

    authUser.id = _.get(morseUser, 'id', '');
    authUser.email = _.get(morseUser, 'email', '');
    authUser.displayName = _.get(morseUser, 'name', '');
    authUser.photoURL = _.get(
      morseUser,
      'photoURL',
      'https://storage.googleapis.com/extitems/acp/account_icon.png',
    );

    return authUser;
  }

  setRoles(roleGrants: Array<MIRoleGrantProto>): void {
    this.workplaceRoles = _.flatten(
      roleGrants
        .filter(
          (grant) => grant.resource_id !== 'ADVERTISERS' && grant.resource_id !== 'AD_ACCOUNTS',
        )
        .map((grant) => grant.roles.map((roleInfo) => roleInfo.id)),
    );

    this.adAccountRoles = roleGrants
      .filter((grant) => grant.resource_id === 'ADVERTISERS' || grant.resource_id === 'AD_ACCOUNTS')
      .map((grant) => ({
        adAccountId: grant.resource_instance_id,
        roles: grant.roles.map((grantedRole) => grantedRole.id),
      }));

    if (_.intersection(this.workplaceRoles, ROLES_FOR_DSP_SERVICE).length > 0) {
      this.services.push(SERVICES.CAMPAIGN_MANAGER);
    }

    if (_.intersection(this.workplaceRoles, ROLES_FOR_PUBLISHER_SERVICE).length > 0) {
      this.services.push(SERVICES.INVENTORY_MANAGER);
    }
  }

  getToken(refresh: boolean): string {
    return this.fbUser.getIdToken(refresh);
  }

  isDSPAdmin(): boolean {
    return isDSPAdmin(this.workplaceRoles);
  }

  isDSPUser(): boolean {
    return isDSPUser(this.workplaceRoles);
  }

  getAdAccountRoles(adAccountId: string): Array<MERole> | undefined {
    const rolesForAdAccount = _.find(this.adAccountRoles, { adAccountId });
    return rolesForAdAccount?.roles;
  }

  hasPermissionToResource(
    resource: MTPermissionControlEntity,
    permission: MEUserPermission,
    adAccountId: string,
  ): boolean {
    return hasPermission([this.getOutstandingRole(adAccountId)], resource, permission);
  }

  hasWorkplacePermission(permission: MEUserPermission): boolean {
    return hasPermission(this.workplaceRoles, MEEntity.WORKPLACE, permission);
  }

  hasAdAccountPermission(permission: MEUserPermission, adAccountId: string | null): boolean {
    if (adAccountId === null) {
      if (permission === MEUserPermission.CREATE) {
        // Special permission
        return hasPermission(this.workplaceRoles, MEEntity.AD_ACCOUNT, permission);
      }
      return false;
    }

    // Check user's Ad Account role
    return this.hasPermissionToResource(MEEntity.AD_ACCOUNT, permission, adAccountId);
  }

  getPermissionsToResource(
    resource: MTPermissionControlEntity,
    adAccountId: string,
  ): Record<MEUserPermission, boolean> {
    const userRole = this.getOutstandingRole(adAccountId);
    const permRoleMap = REQUIRED_ROLES[resource];

    return (Object.keys(permRoleMap) as Array<MEUserPermission>).reduce(
      (acc: Record<MEUserPermission, boolean>, perm: MEUserPermission) => {
        const rolesForPerm = permRoleMap[perm];
        return {
          ...acc,
          [perm]: rolesForPerm && rolesForPerm.includes(userRole),
        };
      },
      {} as Record<MEUserPermission, boolean>,
    );
  }

  isViewerForAllAdAccount(): boolean {
    if (this.getOutstandingRole() === MERole.DSP_WORKPLACE_OWNER) return false;
    if (this.adAccountRoles.length === 0) return false;

    for (let i = 0; i < this.adAccountRoles.length; i += 1) {
      const roleGrant = this.adAccountRoles[i];
      if (roleGrant.roles && !roleGrant.roles.includes(MERole.DSP_ADACCOUNT_VIEWER)) return false;
    }

    return true;
  }

  isViewerForAdAccount(adAccountId: string): boolean | undefined {
    return this.getAdAccountRoles(adAccountId)?.includes(MERole.DSP_ADACCOUNT_VIEWER);
  }

  getOutstandingRole(adAccountId?: string): MERole {
    // Check for workplace level roles first.
    if (
      _.intersection(
        [MERole.DSP_WORKPLACE_OWNER, MERole.DSP_PLATFORM_ADMINISTRATOR, MERole.UNIVERSE_ADMIN],
        this.workplaceRoles,
      ).length > 0
    ) {
      return MERole.DSP_WORKPLACE_OWNER;
    }

    // Then check ad account level roles.
    if (adAccountId) {
      const adAccountRoles = this.getAdAccountRoles(adAccountId);
      if (adAccountRoles) {
        if (adAccountRoles.includes(MERole.DSP_ADACCOUNT_OWNER)) return MERole.DSP_ADACCOUNT_OWNER;
        if (adAccountRoles.includes(MERole.DSP_ADACCOUNT_USER)) return MERole.DSP_ADACCOUNT_USER;
        if (adAccountRoles.includes(MERole.DSP_ADACCOUNT_VIEWER))
          return MERole.DSP_ADACCOUNT_VIEWER;
      }
    }

    return MERole.DSP_WORKPLACE_USER;
  }
}
