import deployEnv from '../constants/deployEnv';
import { isMCRHost } from './index';

export const TABLE_PAGE_SIZE = 'table_page_size';

export const BROWSER_CACHE_KEY = {
  AUTH_INFO: 'AUTH_INFO',
};

// Campaign Manager
export const getAdAccountCacheKey = (workplace: string, userId: string) =>
  `${deployEnv.getDeployEnv()}:${isMCRHost()}:${workplace}:${userId}:adAccount`;

export const getLogDataCacheKey = (workplace: string, userId: string, adAccount: string) => {
  return `${deployEnv.getDeployEnv()}:${workplace}:${userId}:${adAccount}:logData`;
};

export const getOverviewCacheKey = (workplace: string, userId: string) =>
  `${deployEnv.getDeployEnv()}:${isMCRHost()}:${workplace}:${userId}:overview`;

export const getWebNotificationCacheKey = (workplace: string, userId: string) =>
  `${deployEnv.getDeployEnv()}:${workplace}:${userId}:webNotificationInterval`;

// Analytics
export const getReportTableFilterCacheKey = (
  workplace: string,
  userId: string,
  adAccountId: string,
  tableId: string,
) =>
  `${deployEnv.getDeployEnv()}:${workplace}:${userId}:${adAccountId}:${tableId}:reportTableFilter`;

export const getCampaignReportCacheKey = (
  workplace: string,
  userId: string,
  adAccountId: string,
  stateName: 'groupByCondition' | 'metrics',
) =>
  `${deployEnv.getDeployEnv()}:${workplace}:${userId}:${adAccountId}:${stateName}:campaignReport`;
