/*
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here. We prefix them with 'componentName' so we avoid
 * reducers accidentally picking up actions they shouldn't.
 *
 * Follow this format:
 * export const YOUR_ACTION_CONSTANT = 'YourContainer/YOUR_ACTION_CONSTANT';
 */

import { ACTION_TYPE_CAMPAIGN_MANAGER } from '../consts.ts';

export const ACTION_TYPE_ENTITY_LOG = 'entityLog';

export const LIST_CAMPAIGN_HISTORY = `${ACTION_TYPE_CAMPAIGN_MANAGER}/${ACTION_TYPE_ENTITY_LOG}/LIST_CAMPAIGN_HISTORY`;
export const LIST_CAMPAIGN_HISTORY_SUCCESS = `${ACTION_TYPE_CAMPAIGN_MANAGER}/${ACTION_TYPE_ENTITY_LOG}/LIST_CAMPAIGN_HISTORY_SUCCESS`;
export const LIST_CAMPAIGN_HISTORY_ERROR = `${ACTION_TYPE_CAMPAIGN_MANAGER}/${ACTION_TYPE_ENTITY_LOG}/LIST_CAMPAIGN_HISTORY_ERROR`;

export const LIST_ADGROUP_HISTORY = `${ACTION_TYPE_CAMPAIGN_MANAGER}/${ACTION_TYPE_ENTITY_LOG}/LIST_ADGROUP_HISTORY`;
export const LIST_ADGROUP_HISTORY_SUCCESS = `${ACTION_TYPE_CAMPAIGN_MANAGER}/${ACTION_TYPE_ENTITY_LOG}/LIST_ADGROUP_HISTORY_SUCCESS`;
export const LIST_ADGROUP_HISTORY_ERROR = `${ACTION_TYPE_CAMPAIGN_MANAGER}/${ACTION_TYPE_ENTITY_LOG}/LIST_ADGROUP_HISTORY_ERROR`;
