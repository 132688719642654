/*
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here. We prefix them with `componentName` so we avoid
 * reducers accidentally picking up actions they shouldn`t.
 *
 * Follow this format:
 * export const YOUR_ACTION_CONSTANT = `YourContainer/YOUR_ACTION_CONSTANT`;
 */

import { ACTION_TYPE_INVENTORY_MANAGER } from '../types';

const ACTION_TYPE_AD_UNIT = 'adUnit';

export const LIST_AD_UNIT = `${ACTION_TYPE_INVENTORY_MANAGER}/${ACTION_TYPE_AD_UNIT}/LIST_AD_UNIT`;
export const LIST_AD_UNIT_SUCCESS = `${ACTION_TYPE_INVENTORY_MANAGER}/${ACTION_TYPE_AD_UNIT}/LIST_AD_UNIT_SUCCESS`;
export const LIST_AD_UNIT_ERROR = `${ACTION_TYPE_INVENTORY_MANAGER}/${ACTION_TYPE_AD_UNIT}/LIST_AD_UNIT_ERROR`;

export const GET_AD_UNIT = `${ACTION_TYPE_INVENTORY_MANAGER}/${ACTION_TYPE_AD_UNIT}/GET_AD_UNIT`;
export const GET_AD_UNIT_SUCCESS = `${ACTION_TYPE_INVENTORY_MANAGER}/${ACTION_TYPE_AD_UNIT}/GET_AD_UNIT_SUCCESS`;
export const GET_AD_UNIT_ERROR = `${ACTION_TYPE_INVENTORY_MANAGER}/${ACTION_TYPE_AD_UNIT}/GET_AD_UNIT_ERROR`;

export const SAVE_AD_UNIT = `${ACTION_TYPE_INVENTORY_MANAGER}/${ACTION_TYPE_AD_UNIT}/SAVE_AD_UNIT`;
export const SAVE_AD_UNIT_SUCCESS = `${ACTION_TYPE_INVENTORY_MANAGER}/${ACTION_TYPE_AD_UNIT}/SAVE_AD_UNIT_SUCCESS`;
export const SAVE_AD_UNIT_ERROR = `${ACTION_TYPE_INVENTORY_MANAGER}/${ACTION_TYPE_AD_UNIT}/SAVE_AD_UNIT_ERROR`;

export const DELETE_AD_UNIT = `${ACTION_TYPE_INVENTORY_MANAGER}/${ACTION_TYPE_AD_UNIT}/DELETE_AD_UNIT`;
export const DELETE_AD_UNIT_SUCCESS = `${ACTION_TYPE_INVENTORY_MANAGER}/${ACTION_TYPE_AD_UNIT}/DELETE_AD_UNIT_SUCCESS`;
export const DELETE_AD_UNIT_ERROR = `${ACTION_TYPE_INVENTORY_MANAGER}/${ACTION_TYPE_AD_UNIT}/DELETE_AD_UNIT_ERROR`;
