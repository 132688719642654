import _ from 'lodash';
import { MECreativeType } from '../types';
import { ImageCreative } from './imageCreative';
import { NativeCreative } from './nativeCreative';
import { VideoCreative } from './videoCreative';
import { MTCreativesByType } from './types';
import { MEImageCreativeType } from '../../components-v2/CampaignManager/Overview/Creatives/types';

export const getCreativesByValidTypeFromProto = (creatives: null | undefined | Array<any>) => {
  const creativesByType: MTCreativesByType = {
    [MECreativeType.IMAGE]: [],
    [MECreativeType.NATIVE]: [],
    [MECreativeType.VIDEO]: [],
  };

  if (!creatives || creatives.length === 0) {
    return creativesByType;
  }

  creatives.forEach((proto: any) => {
    if (proto.type === 'IMAGE') {
      creativesByType.IMAGE.push(ImageCreative.fromProto(proto));
    } else if (proto.type === 'VIDEO') {
      creativesByType.VIDEO.push(VideoCreative.fromProto(proto));
    } else if (proto.type === 'NATIVE') {
      creativesByType.NATIVE.push(NativeCreative.fromProto(proto));
    }
  });

  return creativesByType;
};

export const getCpi = (spend: number, installs: number) =>
  installs > 0 ? _.round(spend / installs, 2) : null;

export const getCtr = (clicks: number, impressions: number) =>
  impressions > 0 ? _.round((clicks / impressions) * 100.0, 3) : null;

export const getIpm = (installs: number, impressions: number) =>
  impressions > 0 ? _.round(installs / (impressions / 1000), 2) : null;

export const getRoas = (revenue: number, spend: number) =>
  spend > 0 ? _.round((revenue / spend) * 100.0, 3) : null;

export const getCpa = (spend: number, actions: number) =>
  actions > 0 ? _.round(spend / actions, 2) : null;

export const getCpc = (spend: number, clicks: number) =>
  clicks > 0 ? _.round(spend / clicks, 2) : null;

export const dimensionObjCompareFn = (
  a: { width: number; height: number; type?: MEImageCreativeType },
  b: { width: number; height: number; type?: MEImageCreativeType },
) => {
  if (a.width - b.width === 0) {
    return a.height - b.height;
  }
  return a.width - b.width;
};
