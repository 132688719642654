import produce from 'immer';
import _ from 'lodash';

import {
  CHANGE_ADVERTISER_CONTEXT,
  LIST_ADVERTISER_SUCCESS,
  LIST_ADVERTISER_REQUEST,
  LIST_ADVERTISER_ERROR,
  GET_ADVERTISER_SUCCESS,
  GET_ADVERTISER_ERROR,
  GET_ADVERTISER_REQUEST,
  SAVE_ADVERTISER_REQUEST,
  SAVE_ADVERTISER_SUCCESS,
  SAVE_ADVERTISER_ERROR,
  DELETE_ADVERTISER_REQUEST,
  DELETE_ADVERTISER_SUCCESS,
  DELETE_ADVERTISER_ERROR,
} from './types';

// The initial state of the Advertisers.
export const initialState = {
  loading: false,
  updating: false,
  loadError: null,
  updateError: null,
  lastListRequestTs: 0,
  lastEntityRequestTs: {},
  entities: {},
  currentAdvertiser: null,
};

function advertisersReducer(state = initialState, action) {
  // eslint-disable-next-line consistent-return
  return produce(state, (draft) => {
    switch (action.type) {
      case CHANGE_ADVERTISER_CONTEXT: {
        draft.currentAdvertiser = action.payload;
        break;
      }
      case LIST_ADVERTISER_REQUEST: {
        draft.loading = true;
        draft.loadError = null;
        draft.lastListRequestTs = Date.now();
        break;
      }
      case LIST_ADVERTISER_SUCCESS: {
        const { advertisers } = action.payload;
        const keyMap = _.keyBy(advertisers, 'id');

        draft.loading = false;
        draft.entities = keyMap;

        if (draft.currentAdvertiser && _.has(keyMap, draft.currentAdvertiser.id)) {
          draft.currentAdvertiser = keyMap[draft.currentAdvertiser.id];
        }
        break;
      }
      case LIST_ADVERTISER_ERROR: {
        const { error } = action.payload;

        draft.loading = false;
        draft.loadError = error;
        break;
      }
      case GET_ADVERTISER_REQUEST: {
        const { advertiserId } = action.payload;

        draft.loading = true;
        draft.loadError = null;
        _.set(draft.lastEntityRequestTs, advertiserId, Date.now());
        break;
      }
      case GET_ADVERTISER_SUCCESS: {
        const { advertiser } = action.payload;

        draft.loading = false;
        draft.entities[advertiser.id] = advertiser;

        if (draft.currentAdvertiser && draft.currentAdvertiser.id === advertiser.id) {
          draft.currentAdvertiser = advertiser;
        }
        break;
      }
      case GET_ADVERTISER_ERROR: {
        const { error } = action.payload;

        draft.loading = false;
        draft.loadError = error;
        break;
      }
      case SAVE_ADVERTISER_REQUEST: {
        const { advertiserId } = action.payload;

        draft.updating = true;
        draft.updateError = null;

        if (advertiserId) {
          draft.entities[advertiserId] = {
            ...draft.entities[advertiserId],
            updating: true,
          };
        }

        break;
      }
      case SAVE_ADVERTISER_SUCCESS: {
        const { advertiser } = action.payload;

        draft.updating = false;
        draft.entities[advertiser.id] = advertiser;

        if (draft.currentAdvertiser && draft.currentAdvertiser.id === advertiser.id) {
          draft.currentAdvertiser = advertiser;
        }
        break;
      }
      case SAVE_ADVERTISER_ERROR: {
        const { error } = action.payload;

        draft.updating = false;
        draft.updateError = error;
        break;
      }
      case DELETE_ADVERTISER_REQUEST: {
        draft.updating = true;
        draft.updateError = null;
        break;
      }
      case DELETE_ADVERTISER_SUCCESS: {
        const { advertiserId } = action.payload;

        draft.updating = false;
        _.unset(draft.entities, advertiserId);

        if (draft.currentAdvertiser && draft.currentAdvertiser.id === advertiserId) {
          draft.currentAdvertiser = null;
        }
        break;
      }
      case DELETE_ADVERTISER_ERROR: {
        const { error } = action.payload;

        draft.updating = false;
        draft.updateError = error;
        break;
      }
      default: {
        break;
      }
    }
  });
}

export default advertisersReducer;
