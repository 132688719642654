import GlobalStore, { STORE_KEY } from './globalStorage';

// eslint-disable-next-line import/prefer-default-export
export function getPlatformFeatures() {
  const platformSetting = GlobalStore.get(STORE_KEY.PLATFORM_SETTING);
  if (platformSetting) {
    return platformSetting.features;
  }

  return [];
}
