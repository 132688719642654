export const ACTION_TYPE_AUTH = 'auth';

export const SET_AUTH_STATE = `${ACTION_TYPE_AUTH}/SET_AUTH_STATE`;
export const SET_AUTH_USER = `${ACTION_TYPE_AUTH}/SET_AUTH_USER`;
export const SET_AUTH_MESSAGE = `${ACTION_TYPE_AUTH}/SET_AUTH_MESSAGE`;
export const SET_AUTH_TOKEN = `${ACTION_TYPE_AUTH}/SET_AUTH_TOKEN`;
export const SET_USER_SPACE = `${ACTION_TYPE_AUTH}/SET_USER_SPACE`;
export const SET_USE_MORSE = `${ACTION_TYPE_AUTH}/SET_USE_MORSE`;
export const SET_PLATFORM = `${ACTION_TYPE_AUTH}/SET_PLATFORM`;
export const SET_AVAILABLE_PLATFORMS = `${ACTION_TYPE_AUTH}/SET_AVAILABLE_PLATFORMS`;
export const SET_MANUAL_LOGOUT = `${ACTION_TYPE_AUTH}/SET_MANUAL_LOGOUT`;

export const SAVE_WORKPLACE_REQUEST = `${ACTION_TYPE_AUTH}/SAVE_WORKPLACE_REQUEST`;
export const SAVE_WORKPLACE_SUCCESS = `${ACTION_TYPE_AUTH}/SAVE_WORKPLACE_SUCCESS`;
export const SAVE_WORKPLACE_ERROR = `${ACTION_TYPE_AUTH}/SAVE_WORKPLACE_ERROR`;

export const AUTH_STATE = {
  NOT_READY: 'NOT_READY',
  INIT: 'INIT',
  LOGGING_IN: 'LOGGING_IN',
  VERIFYING_ACCOUNT: 'VERIFYING_ACCOUNT',
  SELECTING_PLATFORM: 'SELECTING_PLATFORM',
  LOGGED_IN: 'LOGGED_IN',
};
