import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useHistory, useRouteMatch } from 'react-router-dom';
import MUIEditIcon from '@material-ui/icons/Edit';
import MUITooltip from '@material-ui/core/Tooltip';

import MCPlatformSelect from './MCPlatformSelect';
import generatePathForRoute from '../../common-v2/route/generatePathForRoute';
import { MERouteKeys } from '../../common-v2/route/consts';

const SCWrapper = styled.div`
  position: absolute;
  top: 100%;
  right: 0;
  width: 230px;
  box-shadow: 0 4px 9px 0 rgba(0, 0, 0, 0.3);
  background-color: #ffffff;
  z-index: 10;

  & > div:not(:last-child) {
    border-bottom: solid 1px #e0e0e0;
  }
`;

const SCUserInfoWrapper = styled.div`
  display: flex;
  padding: 12px 0;
  margin: 0 12px;
  align-items: center;
  justify-content: space-between;
`;

const SCProfilePhotoWrapper = styled.div`
  flex: 0 0 auto;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  overflow: hidden;
`;

const SCProfilePhoto = styled.img`
  width: 100%;
  height: 100%;
`;

const SCUserNameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 0;
  width: 120px;
  box-sizing: border-box;
  padding: 0 10px;
`;

type MTUserInfoProps = {
  isOverflow: boolean;
};

const SCUserName = styled.div<MTUserInfoProps>`
  width: 100%;
  height: 1rem;

  text-overflow: ${(props) => (props.isOverflow ? 'ellipsis' : 'unset')};
  overflow: ${(props) => (props.isOverflow ? 'hidden' : 'unset')};
  white-space: ${(props) => (props.isOverflow ? 'nowrap' : 'unset')};

  font-size: 13px;
  color: #212121;
  line-height: 17px;
`;

const SCEmail = styled.div<MTUserInfoProps>`
  width: 100%;
  height: 1rem;

  text-overflow: ${(props) => (props.isOverflow ? 'ellipsis' : 'unset')};
  overflow: ${(props) => (props.isOverflow ? 'hidden' : 'unset')};
  white-space: ${(props) => (props.isOverflow ? 'nowrap' : 'unset')};

  font-size: 12px;
  line-height: 16px;
  color: #7d7d7d;
`;

const SCEditIcon = styled(MUIEditIcon)`
  width: 20px;
  color: #aaaaaa;
  cursor: pointer;
`;

const SCToolbar = styled.div`
  display: flex;
  padding: 12px 0;
  margin: 0 12px;
`;

const SCToolbarButton = styled.button`
  flex: 1;
  height: 27px;
  font-size: 12px;
  color: #7d7d7d;
  border-radius: 2px;
  border: solid 1px #bbbbbb;
  background-color: #ffffff;
  cursor: pointer;
  margin-right: 8px;

  &:last-child {
    margin-right: 0;
  }
`;

type MTHeaderPopperProps = {
  open: boolean;
  user: any;
  currentPlatform?: any;
  availablePlatforms?: Array<any>;
  onClose: () => void;
  onPlatformChange: (newAdvertiserId: string) => void;
  onLogout: () => void;
  usePlatformSelect: boolean;
};

const MCHeaderPopper: React.FunctionComponent<MTHeaderPopperProps> = ({
  open,
  user,
  currentPlatform,
  availablePlatforms,
  onClose,
  onPlatformChange,
  onLogout,
  usePlatformSelect,
}: MTHeaderPopperProps) => {
  const match = useRouteMatch();
  const history = useHistory();

  const nameRef = useRef<HTMLDivElement>(null);
  const emailRef = useRef<HTMLDivElement>(null);
  const [emailOverflow, setEmailOverflow] = useState<boolean>(false);
  const [nameOverflow, setNameOverflow] = useState<boolean>(false);

  useEffect(() => {
    const email = emailRef.current;

    if (
      !emailOverflow &&
      email &&
      (email.scrollHeight > email.offsetHeight || email.scrollWidth > email.offsetWidth)
    ) {
      setEmailOverflow(true);
    }

    const name = nameRef.current;
    if (
      !nameOverflow &&
      name &&
      (name.scrollHeight > name.offsetHeight || name.scrollWidth > name.offsetWidth)
    ) {
      setNameOverflow(true);
    }
  }, [emailOverflow, nameOverflow, open]);

  const goToMyInfoEdit = () => {
    onClose();
    history.push(generatePathForRoute(MERouteKeys.USER_SETTINGS_MY_INFO_EDIT, match.params));
  };

  const goToMyAdAccount = () => {
    onClose();
    history.push(generatePathForRoute(MERouteKeys.USER_SETTINGS_MY_AD_ACCOUNT, match.params));
  };

  const goToMyInfo = () => {
    onClose();
    history.push(generatePathForRoute(MERouteKeys.USER_SETTINGS_MY_INFO, match.params));
  };

  if (!open) return <></>;

  return (
    <SCWrapper>
      <SCUserInfoWrapper>
        <SCProfilePhotoWrapper>
          <SCProfilePhoto src={user.photoURL} alt={user.displayName} />
        </SCProfilePhotoWrapper>
        <SCUserNameWrapper>
          <MUITooltip title={user.displayName} arrow disableHoverListener={!nameOverflow}>
            <SCUserName ref={nameRef} isOverflow={nameOverflow}>
              {user.displayName}
            </SCUserName>
          </MUITooltip>
          <MUITooltip title={user.email} arrow disableHoverListener={!emailOverflow}>
            <SCEmail ref={emailRef} isOverflow={emailOverflow}>
              {user.email}
            </SCEmail>
          </MUITooltip>
        </SCUserNameWrapper>
        <SCEditIcon onClick={goToMyInfoEdit} />
      </SCUserInfoWrapper>
      <SCToolbar>
        <SCToolbarButton onClick={goToMyAdAccount}>My Ad Accounts</SCToolbarButton>
      </SCToolbar>
      {usePlatformSelect && currentPlatform && availablePlatforms && (
        <MCPlatformSelect
          currentPlatform={currentPlatform}
          availablePlatforms={availablePlatforms}
          onChange={onPlatformChange}
        />
      )}
      <SCToolbar>
        <SCToolbarButton onClick={goToMyInfo}>My Info</SCToolbarButton>
        <SCToolbarButton onClick={() => onLogout()}>Log out</SCToolbarButton>
      </SCToolbar>
    </SCWrapper>
  );
};

export default MCHeaderPopper;
