import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { AUTH_STATE } from 'redux/auth/types';
import { RootState } from 'redux/types';
import MCLoader from 'components-v2/Common/MCLoader';
import withTracking from 'containers/Common/withTracking';

import { ACTION_MODE, EMAIL_ACTION_QUERY_KEYS } from './consts';
import MCResetPassword from './MCResetPassword';

function MCEmailActionHandler() {
  const authState = useSelector((state: RootState) => state.auth.state);
  const location = useLocation();
  const query: any = new URLSearchParams(location.search);
  const mode = query.get(EMAIL_ACTION_QUERY_KEYS.MODE);

  if (authState === AUTH_STATE.NOT_READY) {
    return <MCLoader />;
  }

  if (mode === ACTION_MODE.RESET_PASSWORD || mode === ACTION_MODE.CREATE_PASSWORD) {
    return (
      <MCResetPassword
        mode={mode}
        token={query.get(EMAIL_ACTION_QUERY_KEYS.TOKEN)}
        email={query.get(EMAIL_ACTION_QUERY_KEYS.EMAIL)}
        oobCode={query.get(EMAIL_ACTION_QUERY_KEYS.OOBCODE)}
      />
    );
  }

  return <MCLoader />;
}

export default withTracking(MCEmailActionHandler);
