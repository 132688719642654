import produce from 'immer';

import {
  CLEAR_IN_APP_ALERTS,
  CLOSE_IN_APP_ALERT,
  ENQUEUE_IN_APP_ALERT,
  REMOVE_IN_APP_ALERT,
} from './types';

const INITIAL_STATE = {
  items: [],
};

const inAppAlertReducer = (state = INITIAL_STATE, action) => {
  // eslint-disable-next-line consistent-return
  return produce(state, (draft) => {
    // eslint-disable-next-line default-case
    switch (action.type) {
      case ENQUEUE_IN_APP_ALERT:
        draft.items.push(action.notification);
        break;
      case CLOSE_IN_APP_ALERT:
        draft.items[draft.items.findIndex((noti) => noti.key === action.key)].dismissed = true;
        break;
      case REMOVE_IN_APP_ALERT:
        draft.items = draft.items.filter((item) => item.key !== action.key);
        break;
      case CLEAR_IN_APP_ALERTS:
        draft.items = [];
        break;
    }
  });
};

export default inAppAlertReducer;
