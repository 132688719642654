import _ from 'lodash';
import { format } from 'date-fns-tz';
import GlobalStore, { STORE_KEY } from '../global/globalStorage';

export const USER_SPACES = {
  GENERAL: 'GENERAL',
  USERSPACE_C: 'USERSPACE_C',
  USERSPACE_MOLOCO: 'USERSPACE_MOLOCO',
};

export const PLATFORMS = {
  MOLOCO: 'MOLOCO',
  SKT: 'SKT',
  SAMSUNG: 'SAMSUNG',
  SKT_TMAP: 'SKT_TMAP',
  SKT_PUB: 'SKT_PUB',
  PLAYGROUND: 'PLAYGROUND',
  MOLOCO_ADCLOUD: 'MOLOCO_ADCLOUD',
  MLC: 'MLC',
};

export const PLATFORM_SERVICE_TYPES = {
  CM_ONLY: 'CM_ONLY',
  IM_ONLY: 'IM_ONLY',
  LIMITED_CM_WITH_IM: 'LIMITED_CM_WITH_IM',
};

export const SERVICES = {
  CAMPAIGN_MANAGER: 'campaign',
  INVENTORY_MANAGER: 'inventory',
};

export const SERVICE_CONFIGS = {
  [SERVICES.CAMPAIGN_MANAGER]: {
    id: SERVICES.CAMPAIGN_MANAGER,
    label: 'Campaign Manager',
    basePath: '/v1/campaign',
  },
  [SERVICES.INVENTORY_MANAGER]: {
    id: SERVICES.INVENTORY_MANAGER,
    label: 'Inventory Manager',
    basePath: '/v1/inventory',
  },
};

export const CAMPAIGN_TYPE_MAP = {
  APP_USER_ACQUISITION: 'APP_USER_ACQUISITION',
  APP_REENGAGEMENT: 'APP_REENGAGEMENT',
  WEBSITE_VISIT: 'WEBSITE_VISIT',
  DIRECT_SOLD_APP: 'DIRECT_SOLD_APP',
  DIRECT_SOLD_WEB: 'DIRECT_SOLD_WEB',
  HOUSE_AD_APP: 'HOUSE_AD_APP',
  HOUSE_AD_WEB: 'HOUSE_AD_WEB',
};

export const CAMPAIGN_TYPE_LABEL = {
  APP_USER_ACQUISITION: 'App Installs',
  APP_REENGAGEMENT: 'Re-engagement',
  WEBSITE_VISIT: 'Web Visits',
  DIRECT_SOLD_APP: 'Direct Sold',
  DIRECT_SOLD_WEB: 'Direct Sold',
  HOUSE_AD_APP: 'House Ad',
  HOUSE_AD_WEB: 'House Ad',
};

export const CAMPAIGN_GOAL_TYPE_MAP = {
  OPTIMIZE_APP_INSTALLS: 'OPTIMIZE_APP_INSTALLS', // will be deprecated
  OPTIMIZE_APP_ROAS: 'OPTIMIZE_APP_ROAS', // will be deprecated
  OPTIMIZE_CLICKS_TO_APP: 'OPTIMIZE_CLICKS_TO_APP', // will be deprecated
  OPTIMIZE_CLICKS_TO_WEB: 'OPTIMIZE_CLICKS_TO_WEB', // will be deprecated
  OPTIMIZE_CPA_FOR_APP_UA: 'OPTIMIZE_CPA_FOR_APP_UA',
  OPTIMIZE_CPI_FOR_APP_UA: 'OPTIMIZE_CPI_FOR_APP_UA',
  OPTIMIZE_CPC_FOR_APP_UA: 'OPTIMIZE_CPC_FOR_APP_UA',
  OPTIMIZE_CPC_FOR_APP_RE: 'OPTIMIZE_CPC_FOR_APP_RE',
  OPTIMIZE_CPC_FOR_WEB_VISIT: 'OPTIMIZE_CPC_FOR_WEB_VISIT',
  OPTIMIZE_ROAS_FOR_APP_UA: 'OPTIMIZE_ROAS_FOR_APP_UA',
  OPTIMIZE_ROAS_FOR_APP_RE: 'OPTIMIZE_ROAS_FOR_APP_RE',
  FIXED_CPM_FOR_APP_UA: 'FIXED_CPM_FOR_APP_UA',
  FIXED_CPM_FOR_APP_RE: 'FIXED_CPM_FOR_APP_RE',
  OPTIMIZE_NONE: 'OPTIMIZE_NONE',
};

export const CAMPAIGN_GOAL_TYPE_LABEL = {
  OPTIMIZE_APP_INSTALLS: 'Install',
  OPTIMIZE_APP_ROAS: 'ROAS',
  OPTIMIZE_CLICKS_TO_APP: 'Click',
  OPTIMIZE_CLICKS_TO_WEB: 'Click',
  OPTIMIZE_CPA_FOR_APP_UA: 'In App Event',
  OPTIMIZE_CPI_FOR_APP_UA: 'Install',
  OPTIMIZE_CPC_FOR_APP_UA: 'Click',
  OPTIMIZE_CPC_FOR_APP_RE: 'Click',
  OPTIMIZE_CPC_FOR_WEB_VISIT: 'Click',
  OPTIMIZE_ROAS_FOR_APP_UA: 'ROAS',
  OPTIMIZE_ROAS_FOR_APP_RE: 'ROAS',
  FIXED_CPM_FOR_APP_UA: 'Fixed CPM',
  FIXED_CPM_FOR_APP_RE: 'Fixed CPM',
  OPTIMIZE_NONE: 'None',
};

export const OS_MAP = {
  ANDROID: 'ANDROID',
  IOS: 'IOS',
};

export const CURRENCY_MAP = {
  USD: 'USD',
  KRW: 'KRW',
  JPY: 'JPY',
  GBP: 'GBP',
  EUR: 'EUR',
};

export const CURRENCY_DATA_MAP = {
  USD: {
    symbol: '$',
    name: 'US Dollar',
    symbol_native: '$',
    decimal_digits: 2,
    rounding: 0,
    code: 'USD',
    name_plural: 'US dollars',
  },
  CAD: {
    symbol: 'CA$',
    name: 'Canadian Dollar',
    symbol_native: '$',
    decimal_digits: 2,
    rounding: 0,
    code: 'CAD',
    name_plural: 'Canadian dollars',
  },
  EUR: {
    symbol: '€',
    name: 'Euro',
    symbol_native: '€',
    decimal_digits: 2,
    rounding: 0,
    code: 'EUR',
    name_plural: 'euros',
  },
  AED: {
    symbol: 'AED',
    name: 'United Arab Emirates Dirham',
    symbol_native: 'د.إ.‏',
    decimal_digits: 2,
    rounding: 0,
    code: 'AED',
    name_plural: 'UAE dirhams',
  },
  AFN: {
    symbol: 'Af',
    name: 'Afghan Afghani',
    symbol_native: '؋',
    decimal_digits: 0,
    rounding: 0,
    code: 'AFN',
    name_plural: 'Afghan Afghanis',
  },
  ALL: {
    symbol: 'ALL',
    name: 'Albanian Lek',
    symbol_native: 'Lek',
    decimal_digits: 0,
    rounding: 0,
    code: 'ALL',
    name_plural: 'Albanian lekë',
  },
  AMD: {
    symbol: 'AMD',
    name: 'Armenian Dram',
    symbol_native: 'դր.',
    decimal_digits: 0,
    rounding: 0,
    code: 'AMD',
    name_plural: 'Armenian drams',
  },
  ARS: {
    symbol: 'AR$',
    name: 'Argentine Peso',
    symbol_native: '$',
    decimal_digits: 2,
    rounding: 0,
    code: 'ARS',
    name_plural: 'Argentine pesos',
  },
  AUD: {
    symbol: 'AU$',
    name: 'Australian Dollar',
    symbol_native: '$',
    decimal_digits: 2,
    rounding: 0,
    code: 'AUD',
    name_plural: 'Australian dollars',
  },
  AZN: {
    symbol: 'man.',
    name: 'Azerbaijani Manat',
    symbol_native: 'ман.',
    decimal_digits: 2,
    rounding: 0,
    code: 'AZN',
    name_plural: 'Azerbaijani manats',
  },
  BAM: {
    symbol: 'KM',
    name: 'Bosnia-Herzegovina Convertible Mark',
    symbol_native: 'KM',
    decimal_digits: 2,
    rounding: 0,
    code: 'BAM',
    name_plural: 'Bosnia-Herzegovina convertible marks',
  },
  BDT: {
    symbol: 'Tk',
    name: 'Bangladeshi Taka',
    symbol_native: '৳',
    decimal_digits: 2,
    rounding: 0,
    code: 'BDT',
    name_plural: 'Bangladeshi takas',
  },
  BGN: {
    symbol: 'BGN',
    name: 'Bulgarian Lev',
    symbol_native: 'лв.',
    decimal_digits: 2,
    rounding: 0,
    code: 'BGN',
    name_plural: 'Bulgarian leva',
  },
  BHD: {
    symbol: 'BD',
    name: 'Bahraini Dinar',
    symbol_native: 'د.ب.‏',
    decimal_digits: 3,
    rounding: 0,
    code: 'BHD',
    name_plural: 'Bahraini dinars',
  },
  BIF: {
    symbol: 'FBu',
    name: 'Burundian Franc',
    symbol_native: 'FBu',
    decimal_digits: 0,
    rounding: 0,
    code: 'BIF',
    name_plural: 'Burundian francs',
  },
  BND: {
    symbol: 'BN$',
    name: 'Brunei Dollar',
    symbol_native: '$',
    decimal_digits: 2,
    rounding: 0,
    code: 'BND',
    name_plural: 'Brunei dollars',
  },
  BOB: {
    symbol: 'Bs',
    name: 'Bolivian Boliviano',
    symbol_native: 'Bs',
    decimal_digits: 2,
    rounding: 0,
    code: 'BOB',
    name_plural: 'Bolivian bolivianos',
  },
  BRL: {
    symbol: 'R$',
    name: 'Brazilian Real',
    symbol_native: 'R$',
    decimal_digits: 2,
    rounding: 0,
    code: 'BRL',
    name_plural: 'Brazilian reals',
  },
  BWP: {
    symbol: 'BWP',
    name: 'Botswanan Pula',
    symbol_native: 'P',
    decimal_digits: 2,
    rounding: 0,
    code: 'BWP',
    name_plural: 'Botswanan pulas',
  },
  BYN: {
    symbol: 'Br',
    name: 'Belarusian Ruble',
    symbol_native: 'руб.',
    decimal_digits: 2,
    rounding: 0,
    code: 'BYN',
    name_plural: 'Belarusian rubles',
  },
  BZD: {
    symbol: 'BZ$',
    name: 'Belize Dollar',
    symbol_native: '$',
    decimal_digits: 2,
    rounding: 0,
    code: 'BZD',
    name_plural: 'Belize dollars',
  },
  CDF: {
    symbol: 'CDF',
    name: 'Congolese Franc',
    symbol_native: 'FrCD',
    decimal_digits: 2,
    rounding: 0,
    code: 'CDF',
    name_plural: 'Congolese francs',
  },
  CHF: {
    symbol: 'CHF',
    name: 'Swiss Franc',
    symbol_native: 'CHF',
    decimal_digits: 2,
    rounding: 0.05,
    code: 'CHF',
    name_plural: 'Swiss francs',
  },
  CLP: {
    symbol: 'CL$',
    name: 'Chilean Peso',
    symbol_native: '$',
    decimal_digits: 0,
    rounding: 0,
    code: 'CLP',
    name_plural: 'Chilean pesos',
  },
  CNY: {
    symbol: 'CN¥',
    name: 'Chinese Yuan',
    symbol_native: 'CN¥',
    decimal_digits: 2,
    rounding: 0,
    code: 'CNY',
    name_plural: 'Chinese yuan',
  },
  COP: {
    symbol: 'CO$',
    name: 'Colombian Peso',
    symbol_native: '$',
    decimal_digits: 0,
    rounding: 0,
    code: 'COP',
    name_plural: 'Colombian pesos',
  },
  CRC: {
    symbol: '₡',
    name: 'Costa Rican Colón',
    symbol_native: '₡',
    decimal_digits: 0,
    rounding: 0,
    code: 'CRC',
    name_plural: 'Costa Rican colóns',
  },
  CVE: {
    symbol: 'CV$',
    name: 'Cape Verdean Escudo',
    symbol_native: 'CV$',
    decimal_digits: 2,
    rounding: 0,
    code: 'CVE',
    name_plural: 'Cape Verdean escudos',
  },
  CZK: {
    symbol: 'Kč',
    name: 'Czech Republic Koruna',
    symbol_native: 'Kč',
    decimal_digits: 2,
    rounding: 0,
    code: 'CZK',
    name_plural: 'Czech Republic korunas',
  },
  DJF: {
    symbol: 'Fdj',
    name: 'Djiboutian Franc',
    symbol_native: 'Fdj',
    decimal_digits: 0,
    rounding: 0,
    code: 'DJF',
    name_plural: 'Djiboutian francs',
  },
  DKK: {
    symbol: 'Dkr',
    name: 'Danish Krone',
    symbol_native: 'kr',
    decimal_digits: 2,
    rounding: 0,
    code: 'DKK',
    name_plural: 'Danish kroner',
  },
  DOP: {
    symbol: 'RD$',
    name: 'Dominican Peso',
    symbol_native: 'RD$',
    decimal_digits: 2,
    rounding: 0,
    code: 'DOP',
    name_plural: 'Dominican pesos',
  },
  DZD: {
    symbol: 'DA',
    name: 'Algerian Dinar',
    symbol_native: 'د.ج.‏',
    decimal_digits: 2,
    rounding: 0,
    code: 'DZD',
    name_plural: 'Algerian dinars',
  },
  EEK: {
    symbol: 'Ekr',
    name: 'Estonian Kroon',
    symbol_native: 'kr',
    decimal_digits: 2,
    rounding: 0,
    code: 'EEK',
    name_plural: 'Estonian kroons',
  },
  EGP: {
    symbol: 'EGP',
    name: 'Egyptian Pound',
    symbol_native: 'ج.م.‏',
    decimal_digits: 2,
    rounding: 0,
    code: 'EGP',
    name_plural: 'Egyptian pounds',
  },
  ERN: {
    symbol: 'Nfk',
    name: 'Eritrean Nakfa',
    symbol_native: 'Nfk',
    decimal_digits: 2,
    rounding: 0,
    code: 'ERN',
    name_plural: 'Eritrean nakfas',
  },
  ETB: {
    symbol: 'Br',
    name: 'Ethiopian Birr',
    symbol_native: 'Br',
    decimal_digits: 2,
    rounding: 0,
    code: 'ETB',
    name_plural: 'Ethiopian birrs',
  },
  GBP: {
    symbol: '£',
    name: 'British Pound Sterling',
    symbol_native: '£',
    decimal_digits: 2,
    rounding: 0,
    code: 'GBP',
    name_plural: 'British pounds sterling',
  },
  GEL: {
    symbol: 'GEL',
    name: 'Georgian Lari',
    symbol_native: 'GEL',
    decimal_digits: 2,
    rounding: 0,
    code: 'GEL',
    name_plural: 'Georgian laris',
  },
  GHS: {
    symbol: 'GH₵',
    name: 'Ghanaian Cedi',
    symbol_native: 'GH₵',
    decimal_digits: 2,
    rounding: 0,
    code: 'GHS',
    name_plural: 'Ghanaian cedis',
  },
  GNF: {
    symbol: 'FG',
    name: 'Guinean Franc',
    symbol_native: 'FG',
    decimal_digits: 0,
    rounding: 0,
    code: 'GNF',
    name_plural: 'Guinean francs',
  },
  GTQ: {
    symbol: 'GTQ',
    name: 'Guatemalan Quetzal',
    symbol_native: 'Q',
    decimal_digits: 2,
    rounding: 0,
    code: 'GTQ',
    name_plural: 'Guatemalan quetzals',
  },
  HKD: {
    symbol: 'HK$',
    name: 'Hong Kong Dollar',
    symbol_native: '$',
    decimal_digits: 2,
    rounding: 0,
    code: 'HKD',
    name_plural: 'Hong Kong dollars',
  },
  HNL: {
    symbol: 'HNL',
    name: 'Honduran Lempira',
    symbol_native: 'L',
    decimal_digits: 2,
    rounding: 0,
    code: 'HNL',
    name_plural: 'Honduran lempiras',
  },
  HRK: {
    symbol: 'kn',
    name: 'Croatian Kuna',
    symbol_native: 'kn',
    decimal_digits: 2,
    rounding: 0,
    code: 'HRK',
    name_plural: 'Croatian kunas',
  },
  HUF: {
    symbol: 'Ft',
    name: 'Hungarian Forint',
    symbol_native: 'Ft',
    decimal_digits: 0,
    rounding: 0,
    code: 'HUF',
    name_plural: 'Hungarian forints',
  },
  IDR: {
    symbol: 'Rp',
    name: 'Indonesian Rupiah',
    symbol_native: 'Rp',
    decimal_digits: 0,
    rounding: 0,
    code: 'IDR',
    name_plural: 'Indonesian rupiahs',
  },
  ILS: {
    symbol: '₪',
    name: 'Israeli New Sheqel',
    symbol_native: '₪',
    decimal_digits: 2,
    rounding: 0,
    code: 'ILS',
    name_plural: 'Israeli new sheqels',
  },
  INR: {
    symbol: 'Rs',
    name: 'Indian Rupee',
    symbol_native: 'টকা',
    decimal_digits: 2,
    rounding: 0,
    code: 'INR',
    name_plural: 'Indian rupees',
  },
  IQD: {
    symbol: 'IQD',
    name: 'Iraqi Dinar',
    symbol_native: 'د.ع.‏',
    decimal_digits: 0,
    rounding: 0,
    code: 'IQD',
    name_plural: 'Iraqi dinars',
  },
  IRR: {
    symbol: 'IRR',
    name: 'Iranian Rial',
    symbol_native: '﷼',
    decimal_digits: 0,
    rounding: 0,
    code: 'IRR',
    name_plural: 'Iranian rials',
  },
  ISK: {
    symbol: 'Ikr',
    name: 'Icelandic Króna',
    symbol_native: 'kr',
    decimal_digits: 0,
    rounding: 0,
    code: 'ISK',
    name_plural: 'Icelandic krónur',
  },
  JMD: {
    symbol: 'J$',
    name: 'Jamaican Dollar',
    symbol_native: '$',
    decimal_digits: 2,
    rounding: 0,
    code: 'JMD',
    name_plural: 'Jamaican dollars',
  },
  JOD: {
    symbol: 'JD',
    name: 'Jordanian Dinar',
    symbol_native: 'د.أ.‏',
    decimal_digits: 3,
    rounding: 0,
    code: 'JOD',
    name_plural: 'Jordanian dinars',
  },
  JPY: {
    symbol: '¥',
    name: 'Japanese Yen',
    symbol_native: '￥',
    decimal_digits: 0,
    rounding: 0,
    code: 'JPY',
    name_plural: 'Japanese yen',
  },
  KES: {
    symbol: 'Ksh',
    name: 'Kenyan Shilling',
    symbol_native: 'Ksh',
    decimal_digits: 2,
    rounding: 0,
    code: 'KES',
    name_plural: 'Kenyan shillings',
  },
  KHR: {
    symbol: 'KHR',
    name: 'Cambodian Riel',
    symbol_native: '៛',
    decimal_digits: 2,
    rounding: 0,
    code: 'KHR',
    name_plural: 'Cambodian riels',
  },
  KMF: {
    symbol: 'CF',
    name: 'Comorian Franc',
    symbol_native: 'FC',
    decimal_digits: 0,
    rounding: 0,
    code: 'KMF',
    name_plural: 'Comorian francs',
  },
  KRW: {
    symbol: '₩',
    name: 'South Korean Won',
    symbol_native: '₩',
    decimal_digits: 0,
    rounding: 0,
    code: 'KRW',
    name_plural: 'South Korean won',
  },
  KWD: {
    symbol: 'KD',
    name: 'Kuwaiti Dinar',
    symbol_native: 'د.ك.‏',
    decimal_digits: 3,
    rounding: 0,
    code: 'KWD',
    name_plural: 'Kuwaiti dinars',
  },
  KZT: {
    symbol: 'KZT',
    name: 'Kazakhstani Tenge',
    symbol_native: 'тңг.',
    decimal_digits: 2,
    rounding: 0,
    code: 'KZT',
    name_plural: 'Kazakhstani tenges',
  },
  LBP: {
    symbol: 'LB£',
    name: 'Lebanese Pound',
    symbol_native: 'ل.ل.‏',
    decimal_digits: 0,
    rounding: 0,
    code: 'LBP',
    name_plural: 'Lebanese pounds',
  },
  LKR: {
    symbol: 'SLRs',
    name: 'Sri Lankan Rupee',
    symbol_native: 'SL Re',
    decimal_digits: 2,
    rounding: 0,
    code: 'LKR',
    name_plural: 'Sri Lankan rupees',
  },
  LTL: {
    symbol: 'Lt',
    name: 'Lithuanian Litas',
    symbol_native: 'Lt',
    decimal_digits: 2,
    rounding: 0,
    code: 'LTL',
    name_plural: 'Lithuanian litai',
  },
  LVL: {
    symbol: 'Ls',
    name: 'Latvian Lats',
    symbol_native: 'Ls',
    decimal_digits: 2,
    rounding: 0,
    code: 'LVL',
    name_plural: 'Latvian lati',
  },
  LYD: {
    symbol: 'LD',
    name: 'Libyan Dinar',
    symbol_native: 'د.ل.‏',
    decimal_digits: 3,
    rounding: 0,
    code: 'LYD',
    name_plural: 'Libyan dinars',
  },
  MAD: {
    symbol: 'MAD',
    name: 'Moroccan Dirham',
    symbol_native: 'د.م.‏',
    decimal_digits: 2,
    rounding: 0,
    code: 'MAD',
    name_plural: 'Moroccan dirhams',
  },
  MDL: {
    symbol: 'MDL',
    name: 'Moldovan Leu',
    symbol_native: 'MDL',
    decimal_digits: 2,
    rounding: 0,
    code: 'MDL',
    name_plural: 'Moldovan lei',
  },
  MGA: {
    symbol: 'MGA',
    name: 'Malagasy Ariary',
    symbol_native: 'MGA',
    decimal_digits: 0,
    rounding: 0,
    code: 'MGA',
    name_plural: 'Malagasy Ariaries',
  },
  MKD: {
    symbol: 'MKD',
    name: 'Macedonian Denar',
    symbol_native: 'MKD',
    decimal_digits: 2,
    rounding: 0,
    code: 'MKD',
    name_plural: 'Macedonian denari',
  },
  MMK: {
    symbol: 'MMK',
    name: 'Myanma Kyat',
    symbol_native: 'K',
    decimal_digits: 0,
    rounding: 0,
    code: 'MMK',
    name_plural: 'Myanma kyats',
  },
  MOP: {
    symbol: 'MOP$',
    name: 'Macanese Pataca',
    symbol_native: 'MOP$',
    decimal_digits: 2,
    rounding: 0,
    code: 'MOP',
    name_plural: 'Macanese patacas',
  },
  MUR: {
    symbol: 'MURs',
    name: 'Mauritian Rupee',
    symbol_native: 'MURs',
    decimal_digits: 0,
    rounding: 0,
    code: 'MUR',
    name_plural: 'Mauritian rupees',
  },
  MXN: {
    symbol: 'MX$',
    name: 'Mexican Peso',
    symbol_native: '$',
    decimal_digits: 2,
    rounding: 0,
    code: 'MXN',
    name_plural: 'Mexican pesos',
  },
  MYR: {
    symbol: 'RM',
    name: 'Malaysian Ringgit',
    symbol_native: 'RM',
    decimal_digits: 2,
    rounding: 0,
    code: 'MYR',
    name_plural: 'Malaysian ringgits',
  },
  MZN: {
    symbol: 'MTn',
    name: 'Mozambican Metical',
    symbol_native: 'MTn',
    decimal_digits: 2,
    rounding: 0,
    code: 'MZN',
    name_plural: 'Mozambican meticals',
  },
  NAD: {
    symbol: 'N$',
    name: 'Namibian Dollar',
    symbol_native: 'N$',
    decimal_digits: 2,
    rounding: 0,
    code: 'NAD',
    name_plural: 'Namibian dollars',
  },
  NGN: {
    symbol: '₦',
    name: 'Nigerian Naira',
    symbol_native: '₦',
    decimal_digits: 2,
    rounding: 0,
    code: 'NGN',
    name_plural: 'Nigerian nairas',
  },
  NIO: {
    symbol: 'C$',
    name: 'Nicaraguan Córdoba',
    symbol_native: 'C$',
    decimal_digits: 2,
    rounding: 0,
    code: 'NIO',
    name_plural: 'Nicaraguan córdobas',
  },
  NOK: {
    symbol: 'Nkr',
    name: 'Norwegian Krone',
    symbol_native: 'kr',
    decimal_digits: 2,
    rounding: 0,
    code: 'NOK',
    name_plural: 'Norwegian kroner',
  },
  NPR: {
    symbol: 'NPRs',
    name: 'Nepalese Rupee',
    symbol_native: 'नेरू',
    decimal_digits: 2,
    rounding: 0,
    code: 'NPR',
    name_plural: 'Nepalese rupees',
  },
  NZD: {
    symbol: 'NZ$',
    name: 'New Zealand Dollar',
    symbol_native: '$',
    decimal_digits: 2,
    rounding: 0,
    code: 'NZD',
    name_plural: 'New Zealand dollars',
  },
  OMR: {
    symbol: 'OMR',
    name: 'Omani Rial',
    symbol_native: 'ر.ع.‏',
    decimal_digits: 3,
    rounding: 0,
    code: 'OMR',
    name_plural: 'Omani rials',
  },
  PAB: {
    symbol: 'B/.',
    name: 'Panamanian Balboa',
    symbol_native: 'B/.',
    decimal_digits: 2,
    rounding: 0,
    code: 'PAB',
    name_plural: 'Panamanian balboas',
  },
  PEN: {
    symbol: 'S/.',
    name: 'Peruvian Nuevo Sol',
    symbol_native: 'S/.',
    decimal_digits: 2,
    rounding: 0,
    code: 'PEN',
    name_plural: 'Peruvian nuevos soles',
  },
  PHP: {
    symbol: '₱',
    name: 'Philippine Peso',
    symbol_native: '₱',
    decimal_digits: 2,
    rounding: 0,
    code: 'PHP',
    name_plural: 'Philippine pesos',
  },
  PKR: {
    symbol: 'PKRs',
    name: 'Pakistani Rupee',
    symbol_native: '₨',
    decimal_digits: 0,
    rounding: 0,
    code: 'PKR',
    name_plural: 'Pakistani rupees',
  },
  PLN: {
    symbol: 'zł',
    name: 'Polish Zloty',
    symbol_native: 'zł',
    decimal_digits: 2,
    rounding: 0,
    code: 'PLN',
    name_plural: 'Polish zlotys',
  },
  PYG: {
    symbol: '₲',
    name: 'Paraguayan Guarani',
    symbol_native: '₲',
    decimal_digits: 0,
    rounding: 0,
    code: 'PYG',
    name_plural: 'Paraguayan guaranis',
  },
  QAR: {
    symbol: 'QR',
    name: 'Qatari Rial',
    symbol_native: 'ر.ق.‏',
    decimal_digits: 2,
    rounding: 0,
    code: 'QAR',
    name_plural: 'Qatari rials',
  },
  RON: {
    symbol: 'RON',
    name: 'Romanian Leu',
    symbol_native: 'RON',
    decimal_digits: 2,
    rounding: 0,
    code: 'RON',
    name_plural: 'Romanian lei',
  },
  RSD: {
    symbol: 'din.',
    name: 'Serbian Dinar',
    symbol_native: 'дин.',
    decimal_digits: 0,
    rounding: 0,
    code: 'RSD',
    name_plural: 'Serbian dinars',
  },
  RUB: {
    symbol: 'RUB',
    name: 'Russian Ruble',
    symbol_native: '₽.',
    decimal_digits: 2,
    rounding: 0,
    code: 'RUB',
    name_plural: 'Russian rubles',
  },
  RWF: {
    symbol: 'RWF',
    name: 'Rwandan Franc',
    symbol_native: 'FR',
    decimal_digits: 0,
    rounding: 0,
    code: 'RWF',
    name_plural: 'Rwandan francs',
  },
  SAR: {
    symbol: 'SR',
    name: 'Saudi Riyal',
    symbol_native: 'ر.س.‏',
    decimal_digits: 2,
    rounding: 0,
    code: 'SAR',
    name_plural: 'Saudi riyals',
  },
  SDG: {
    symbol: 'SDG',
    name: 'Sudanese Pound',
    symbol_native: 'SDG',
    decimal_digits: 2,
    rounding: 0,
    code: 'SDG',
    name_plural: 'Sudanese pounds',
  },
  SEK: {
    symbol: 'Skr',
    name: 'Swedish Krona',
    symbol_native: 'kr',
    decimal_digits: 2,
    rounding: 0,
    code: 'SEK',
    name_plural: 'Swedish kronor',
  },
  SGD: {
    symbol: 'S$',
    name: 'Singapore Dollar',
    symbol_native: '$',
    decimal_digits: 2,
    rounding: 0,
    code: 'SGD',
    name_plural: 'Singapore dollars',
  },
  SOS: {
    symbol: 'Ssh',
    name: 'Somali Shilling',
    symbol_native: 'Ssh',
    decimal_digits: 0,
    rounding: 0,
    code: 'SOS',
    name_plural: 'Somali shillings',
  },
  SYP: {
    symbol: 'SY£',
    name: 'Syrian Pound',
    symbol_native: 'ل.س.‏',
    decimal_digits: 0,
    rounding: 0,
    code: 'SYP',
    name_plural: 'Syrian pounds',
  },
  THB: {
    symbol: '฿',
    name: 'Thai Baht',
    symbol_native: '฿',
    decimal_digits: 2,
    rounding: 0,
    code: 'THB',
    name_plural: 'Thai baht',
  },
  TND: {
    symbol: 'DT',
    name: 'Tunisian Dinar',
    symbol_native: 'د.ت.‏',
    decimal_digits: 3,
    rounding: 0,
    code: 'TND',
    name_plural: 'Tunisian dinars',
  },
  TOP: {
    symbol: 'T$',
    name: 'Tongan Paʻanga',
    symbol_native: 'T$',
    decimal_digits: 2,
    rounding: 0,
    code: 'TOP',
    name_plural: 'Tongan paʻanga',
  },
  TRY: {
    symbol: 'TL',
    name: 'Turkish Lira',
    symbol_native: 'TL',
    decimal_digits: 2,
    rounding: 0,
    code: 'TRY',
    name_plural: 'Turkish Lira',
  },
  TTD: {
    symbol: 'TT$',
    name: 'Trinidad and Tobago Dollar',
    symbol_native: '$',
    decimal_digits: 2,
    rounding: 0,
    code: 'TTD',
    name_plural: 'Trinidad and Tobago dollars',
  },
  TWD: {
    symbol: 'NT$',
    name: 'New Taiwan Dollar',
    symbol_native: 'NT$',
    decimal_digits: 2,
    rounding: 0,
    code: 'TWD',
    name_plural: 'New Taiwan dollars',
  },
  TZS: {
    symbol: 'TSh',
    name: 'Tanzanian Shilling',
    symbol_native: 'TSh',
    decimal_digits: 0,
    rounding: 0,
    code: 'TZS',
    name_plural: 'Tanzanian shillings',
  },
  UAH: {
    symbol: '₴',
    name: 'Ukrainian Hryvnia',
    symbol_native: '₴',
    decimal_digits: 2,
    rounding: 0,
    code: 'UAH',
    name_plural: 'Ukrainian hryvnias',
  },
  UGX: {
    symbol: 'USh',
    name: 'Ugandan Shilling',
    symbol_native: 'USh',
    decimal_digits: 0,
    rounding: 0,
    code: 'UGX',
    name_plural: 'Ugandan shillings',
  },
  UYU: {
    symbol: '$U',
    name: 'Uruguayan Peso',
    symbol_native: '$',
    decimal_digits: 2,
    rounding: 0,
    code: 'UYU',
    name_plural: 'Uruguayan pesos',
  },
  UZS: {
    symbol: 'UZS',
    name: 'Uzbekistan Som',
    symbol_native: 'UZS',
    decimal_digits: 0,
    rounding: 0,
    code: 'UZS',
    name_plural: 'Uzbekistan som',
  },
  VEF: {
    symbol: 'Bs.F.',
    name: 'Venezuelan Bolívar',
    symbol_native: 'Bs.F.',
    decimal_digits: 2,
    rounding: 0,
    code: 'VEF',
    name_plural: 'Venezuelan bolívars',
  },
  VND: {
    symbol: '₫',
    name: 'Vietnamese Dong',
    symbol_native: '₫',
    decimal_digits: 0,
    rounding: 0,
    code: 'VND',
    name_plural: 'Vietnamese dong',
  },
  XAF: {
    symbol: 'FCFA',
    name: 'CFA Franc BEAC',
    symbol_native: 'FCFA',
    decimal_digits: 0,
    rounding: 0,
    code: 'XAF',
    name_plural: 'CFA francs BEAC',
  },
  XOF: {
    symbol: 'CFA',
    name: 'CFA Franc BCEAO',
    symbol_native: 'CFA',
    decimal_digits: 0,
    rounding: 0,
    code: 'XOF',
    name_plural: 'CFA francs BCEAO',
  },
  YER: {
    symbol: 'YR',
    name: 'Yemeni Rial',
    symbol_native: 'ر.ي.‏',
    decimal_digits: 0,
    rounding: 0,
    code: 'YER',
    name_plural: 'Yemeni rials',
  },
  ZAR: {
    symbol: 'R',
    name: 'South African Rand',
    symbol_native: 'R',
    decimal_digits: 2,
    rounding: 0,
    code: 'ZAR',
    name_plural: 'South African rand',
  },
  ZMK: {
    symbol: 'ZK',
    name: 'Zambian Kwacha',
    symbol_native: 'ZK',
    decimal_digits: 0,
    rounding: 0,
    code: 'ZMK',
    name_plural: 'Zambian kwachas',
  },
  ZWL: {
    symbol: 'ZWL$',
    name: 'Zimbabwean Dollar',
    symbol_native: 'ZWL$',
    decimal_digits: 0,
    rounding: 0,
    code: 'ZWL',
    name_plural: 'Zimbabwean Dollar',
  },
};

export const ENABLING_STATE_MAP = {
  UNSPECIFIED: 'UNSPECIFIED',
  ENABLED: 'ENABLED',
  DISABLED: 'DISABLED',
};

export const CAMPAIGN_STATE_MAP = {
  DRAFT: 'DRAFT',
  SUBMITTED: 'SUBMITTED',
  ACTIVE: 'ACTIVE',
  PAUSED: 'PAUSED',
  COMPLETED: 'COMPLETED',
};

export const PRODUCT_TYPE_MAP = {
  APP: 'APP',
  WEB: 'WEB',
};

export const ID_TYPE_MAP = {
  GOOGLE_ADID: 'GOOGLE_ADID',
  APPLE_IDFA: 'APPLE_IDFA',
};

export const CREATIVE_FORMAT_LIST = [
  '1024x768',
  '120x600',
  '160x600',
  '200x200',
  '250x250',
  '300x250',
  '300x600',
  '320x100',
  '320x480',
  '320x50',
  '336x280',
  '375x667',
  '468x60',
  '480x320',
  '600x600',
  '640x100',
  '640x200',
  '667x375',
  '728x90',
  '768x1024',
  'native',
  'video',
];

export const HTTP_TYPES = {
  GET: 'get',
  LIST: 'list',
  CREATE: 'create',
  UPDATE: 'update',
  DELETE: 'delete',
};

export const getPlatformServiceType = (platform) => {
  switch (platform) {
    case PLATFORMS.SKT_TMAP:
    case PLATFORMS.SKT_PUB:
      // Platforms that support publisher service on CM but not normal campaigns.
      return PLATFORM_SERVICE_TYPES.LIMITED_CM_WITH_IM;
    default:
      return PLATFORM_SERVICE_TYPES.CM_ONLY;
  }
};

export const isLimitedCMService = (platform) =>
  getPlatformServiceType(platform) === PLATFORM_SERVICE_TYPES.LIMITED_CM_WITH_IM;

const toSelectFieldOption = (option) => ({
  label: option,
  value: option,
});
const toSelectFieldOptions = (options) => options.map((option) => toSelectFieldOption(option));

export const USER_SPACE_OPTIONS = toSelectFieldOptions(_.values(USER_SPACES));
export const PRODUCT_TYPE_OPTIONS = toSelectFieldOptions(_.values(PRODUCT_TYPE_MAP));
export const OS_OPTIONS = toSelectFieldOptions(_.values(OS_MAP));
export const CURRENCY_OPTIONS = toSelectFieldOptions(_.values(CURRENCY_MAP));
export const CREATIVE_FORMAT_OPTIONS = toSelectFieldOptions(CREATIVE_FORMAT_LIST);

export const getCampaignTypeOptions = (productType) => {
  const platformSettings = GlobalStore.get(STORE_KEY.PLATFORM_SETTING);
  if (platformSettings && platformSettings.supportedCampaignSetups) {
    const campaignTypes = _.keys(platformSettings.supportedCampaignSetups[productType]);
    return campaignTypes.map((type) => ({
      label: CAMPAIGN_TYPE_LABEL[type],
      value: type,
    }));
  }

  return [];
};

export const getCampaignGoalOptions = (productType, campaignType) => {
  const platformSettings = GlobalStore.get(STORE_KEY.PLATFORM_SETTING);
  if (
    platformSettings.supportedCampaignSetups &&
    _.has(platformSettings.supportedCampaignSetups, productType)
  ) {
    const goalTypes = platformSettings.supportedCampaignSetups[productType][campaignType];
    if (goalTypes) {
      return goalTypes.map((type) => ({
        label: CAMPAIGN_GOAL_TYPE_LABEL[type],
        value: type,
      }));
    }
  }

  return [];
};

/*
  CampaignGoalTargetCostRange start
  https://moloco.phacility.com/w/product_policy/adcloud/campaign/
*/
const CURRENCY_EXCHANGE_RATE = {
  USD: 1,
  KRW: 1000,
  JPY: 100,
  GBP: 1,
  EUR: 1,
};

export const getCampaignGoalTargetCostRange = (currency) => {
  return {
    CPI: {
      min: 0.01 * CURRENCY_EXCHANGE_RATE[currency],
      max: 1000 * CURRENCY_EXCHANGE_RATE[currency],
    },
    CPC: {
      min: 0.01 * CURRENCY_EXCHANGE_RATE[currency],
      max: 100 * CURRENCY_EXCHANGE_RATE[currency],
    },
    CPM: {
      min: 0.01 * CURRENCY_EXCHANGE_RATE[currency],
      max: 100 * CURRENCY_EXCHANGE_RATE[currency],
    },
  };
};
/*
  CampaignGoalTargetCostRange end
*/

export const isSupportingCampaignTypes = (askingCampaignTypes) => {
  const platformSettings = GlobalStore.get(STORE_KEY.PLATFORM_SETTING);
  if (platformSettings.supportedCampaignSetups) {
    const productTypes = _.keys(platformSettings.supportedCampaignSetups);
    let found = false;
    productTypes.forEach((productType) => {
      const campaignTypes = _.keys(platformSettings.supportedCampaignSetups[productType]);
      if (_.isArray(campaignTypes) && _.intersection(campaignTypes, askingCampaignTypes).length > 0)
        found = true;
    });

    return found;
  }

  return false;
};

const COUNTRY_CODES = [
  { code: 'AD', name: 'Andorra' },
  { code: 'AE', name: 'United Arab Emirates' },
  { code: 'AF', name: 'Afghanistan' },
  { code: 'AG', name: 'Antigua & Barbuda' },
  { code: 'AI', name: 'Anguilla' },
  { code: 'AL', name: 'Albania' },
  { code: 'AM', name: 'Armenia' },
  { code: 'AO', name: 'Angola' },
  { code: 'AQ', name: 'Antarctica' },
  { code: 'AR', name: 'Argentina' },
  { code: 'AS', name: 'Samoa (American)' },
  { code: 'AT', name: 'Austria' },
  { code: 'AU', name: 'Australia' },
  { code: 'AW', name: 'Aruba' },
  { code: 'AX', name: 'Åland Islands' },
  { code: 'AZ', name: 'Azerbaijan' },
  { code: 'BA', name: 'Bosnia & Herzegovina' },
  { code: 'BB', name: 'Barbados' },
  { code: 'BD', name: 'Bangladesh' },
  { code: 'BE', name: 'Belgium' },
  { code: 'BF', name: 'Burkina Faso' },
  { code: 'BG', name: 'Bulgaria' },
  { code: 'BH', name: 'Bahrain' },
  { code: 'BI', name: 'Burundi' },
  { code: 'BJ', name: 'Benin' },
  { code: 'BL', name: 'St Barthelemy' },
  { code: 'BM', name: 'Bermuda' },
  { code: 'BN', name: 'Brunei' },
  { code: 'BO', name: 'Bolivia' },
  { code: 'BQ', name: 'Caribbean NL' },
  { code: 'BR', name: 'Brazil' },
  { code: 'BS', name: 'Bahamas' },
  { code: 'BT', name: 'Bhutan' },
  { code: 'BV', name: 'Bouvet Island' },
  { code: 'BW', name: 'Botswana' },
  { code: 'BY', name: 'Belarus' },
  { code: 'BZ', name: 'Belize' },
  { code: 'CA', name: 'Canada' },
  { code: 'CC', name: 'Cocos (Keeling) Islands' },
  { code: 'CD', name: 'Congo (Dem. Rep.)' },
  { code: 'CF', name: 'Central African Rep.' },
  { code: 'CG', name: 'Congo (Rep.)' },
  { code: 'CH', name: 'Switzerland' },
  { code: 'CI', name: "Côte d'Ivoire" },
  { code: 'CK', name: 'Cook Islands' },
  { code: 'CL', name: 'Chile' },
  { code: 'CM', name: 'Cameroon' },
  { code: 'CN', name: 'China' },
  { code: 'CO', name: 'Colombia' },
  { code: 'CR', name: 'Costa Rica' },
  { code: 'CU', name: 'Cuba' },
  { code: 'CV', name: 'Cape Verde' },
  { code: 'CW', name: 'Curaçao' },
  { code: 'CX', name: 'Christmas Island' },
  { code: 'CY', name: 'Cyprus' },
  { code: 'CZ', name: 'Czech Republic' },
  { code: 'DE', name: 'Germany' },
  { code: 'DJ', name: 'Djibouti' },
  { code: 'DK', name: 'Denmark' },
  { code: 'DM', name: 'Dominica' },
  { code: 'DO', name: 'Dominican Republic' },
  { code: 'DZ', name: 'Algeria' },
  { code: 'EC', name: 'Ecuador' },
  { code: 'EE', name: 'Estonia' },
  { code: 'EG', name: 'Egypt' },
  { code: 'EH', name: 'Western Sahara' },
  { code: 'ER', name: 'Eritrea' },
  { code: 'ES', name: 'Spain' },
  { code: 'ET', name: 'Ethiopia' },
  { code: 'FI', name: 'Finland' },
  { code: 'FJ', name: 'Fiji' },
  { code: 'FK', name: 'Falkland Islands' },
  { code: 'FM', name: 'Micronesia' },
  { code: 'FO', name: 'Faroe Islands' },
  { code: 'FR', name: 'France' },
  { code: 'GA', name: 'Gabon' },
  { code: 'GB', name: 'Britain (UK)' },
  { code: 'GD', name: 'Grenada' },
  { code: 'GE', name: 'Georgia' },
  { code: 'GF', name: 'French Guiana' },
  { code: 'GG', name: 'Guernsey' },
  { code: 'GH', name: 'Ghana' },
  { code: 'GI', name: 'Gibraltar' },
  { code: 'GL', name: 'Greenland' },
  { code: 'GM', name: 'Gambia' },
  { code: 'GN', name: 'Guinea' },
  { code: 'GP', name: 'Guadeloupe' },
  { code: 'GQ', name: 'Equatorial Guinea' },
  { code: 'GR', name: 'Greece' },
  { code: 'GS', name: 'South Georgia & the South Sandwich Islands' },
  { code: 'GT', name: 'Guatemala' },
  { code: 'GU', name: 'Guam' },
  { code: 'GW', name: 'Guinea-Bissau' },
  { code: 'GY', name: 'Guyana' },
  { code: 'HK', name: 'Hong Kong' },
  { code: 'HM', name: 'Heard Island & McDonald Islands' },
  { code: 'HN', name: 'Honduras' },
  { code: 'HR', name: 'Croatia' },
  { code: 'HT', name: 'Haiti' },
  { code: 'HU', name: 'Hungary' },
  { code: 'ID', name: 'Indonesia' },
  { code: 'IE', name: 'Ireland' },
  { code: 'IL', name: 'Israel' },
  { code: 'IM', name: 'Isle of Man' },
  { code: 'IN', name: 'India' },
  { code: 'IO', name: 'British Indian Ocean Territory' },
  { code: 'IQ', name: 'Iraq' },
  { code: 'IR', name: 'Iran' },
  { code: 'IS', name: 'Iceland' },
  { code: 'IT', name: 'Italy' },
  { code: 'JE', name: 'Jersey' },
  { code: 'JM', name: 'Jamaica' },
  { code: 'JO', name: 'Jordan' },
  { code: 'JP', name: 'Japan' },
  { code: 'KE', name: 'Kenya' },
  { code: 'KG', name: 'Kyrgyzstan' },
  { code: 'KH', name: 'Cambodia' },
  { code: 'KI', name: 'Kiribati' },
  { code: 'KM', name: 'Comoros' },
  { code: 'KN', name: 'St Kitts & Nevis' },
  { code: 'KP', name: 'Korea (North)' },
  { code: 'KR', name: 'Korea (South)' },
  { code: 'KW', name: 'Kuwait' },
  { code: 'KY', name: 'Cayman Islands' },
  { code: 'KZ', name: 'Kazakhstan' },
  { code: 'LA', name: 'Laos' },
  { code: 'LB', name: 'Lebanon' },
  { code: 'LC', name: 'St Lucia' },
  { code: 'LI', name: 'Liechtenstein' },
  { code: 'LK', name: 'Sri Lanka' },
  { code: 'LR', name: 'Liberia' },
  { code: 'LS', name: 'Lesotho' },
  { code: 'LT', name: 'Lithuania' },
  { code: 'LU', name: 'Luxembourg' },
  { code: 'LV', name: 'Latvia' },
  { code: 'LY', name: 'Libya' },
  { code: 'MA', name: 'Morocco' },
  { code: 'MC', name: 'Monaco' },
  { code: 'MD', name: 'Moldova' },
  { code: 'ME', name: 'Montenegro' },
  { code: 'MF', name: 'St Martin (French)' },
  { code: 'MG', name: 'Madagascar' },
  { code: 'MH', name: 'Marshall Islands' },
  { code: 'MK', name: 'North Macedonia' },
  { code: 'ML', name: 'Mali' },
  { code: 'MM', name: 'Myanmar (Burma)' },
  { code: 'MN', name: 'Mongolia' },
  { code: 'MO', name: 'Macau' },
  { code: 'MP', name: 'Northern Mariana Islands' },
  { code: 'MQ', name: 'Martinique' },
  { code: 'MR', name: 'Mauritania' },
  { code: 'MS', name: 'Montserrat' },
  { code: 'MT', name: 'Malta' },
  { code: 'MU', name: 'Mauritius' },
  { code: 'MV', name: 'Maldives' },
  { code: 'MW', name: 'Malawi' },
  { code: 'MX', name: 'Mexico' },
  { code: 'MY', name: 'Malaysia' },
  { code: 'MZ', name: 'Mozambique' },
  { code: 'NA', name: 'Namibia' },
  { code: 'NC', name: 'New Caledonia' },
  { code: 'NE', name: 'Niger' },
  { code: 'NF', name: 'Norfolk Island' },
  { code: 'NG', name: 'Nigeria' },
  { code: 'NI', name: 'Nicaragua' },
  { code: 'NL', name: 'Netherlands' },
  { code: 'NO', name: 'Norway' },
  { code: 'NP', name: 'Nepal' },
  { code: 'NR', name: 'Nauru' },
  { code: 'NU', name: 'Niue' },
  { code: 'NZ', name: 'New Zealand' },
  { code: 'OM', name: 'Oman' },
  { code: 'PA', name: 'Panama' },
  { code: 'PE', name: 'Peru' },
  { code: 'PF', name: 'French Polynesia' },
  { code: 'PG', name: 'Papua New Guinea' },
  { code: 'PH', name: 'Philippines' },
  { code: 'PK', name: 'Pakistan' },
  { code: 'PL', name: 'Poland' },
  { code: 'PM', name: 'St Pierre & Miquelon' },
  { code: 'PN', name: 'Pitcairn' },
  { code: 'PR', name: 'Puerto Rico' },
  { code: 'PS', name: 'Palestine' },
  { code: 'PT', name: 'Portugal' },
  { code: 'PW', name: 'Palau' },
  { code: 'PY', name: 'Paraguay' },
  { code: 'QA', name: 'Qatar' },
  { code: 'RE', name: 'Réunion' },
  { code: 'RO', name: 'Romania' },
  { code: 'RS', name: 'Serbia' },
  { code: 'RU', name: 'Russia' },
  { code: 'RW', name: 'Rwanda' },
  { code: 'SA', name: 'Saudi Arabia' },
  { code: 'SB', name: 'Solomon Islands' },
  { code: 'SC', name: 'Seychelles' },
  { code: 'SD', name: 'Sudan' },
  { code: 'SE', name: 'Sweden' },
  { code: 'SG', name: 'Singapore' },
  { code: 'SH', name: 'St Helena' },
  { code: 'SI', name: 'Slovenia' },
  { code: 'SJ', name: 'Svalbard & Jan Mayen' },
  { code: 'SK', name: 'Slovakia' },
  { code: 'SL', name: 'Sierra Leone' },
  { code: 'SM', name: 'San Marino' },
  { code: 'SN', name: 'Senegal' },
  { code: 'SO', name: 'Somalia' },
  { code: 'SR', name: 'Suriname' },
  { code: 'SS', name: 'South Sudan' },
  { code: 'ST', name: 'Sao Tome & Principe' },
  { code: 'SV', name: 'El Salvador' },
  { code: 'SX', name: 'St Maarten (Dutch)' },
  { code: 'SY', name: 'Syria' },
  { code: 'SZ', name: 'Eswatini (Swaziland)' },
  { code: 'TC', name: 'Turks & Caicos Is' },
  { code: 'TD', name: 'Chad' },
  { code: 'TF', name: 'French Southern & Antarctic Lands' },
  { code: 'TG', name: 'Togo' },
  { code: 'TH', name: 'Thailand' },
  { code: 'TJ', name: 'Tajikistan' },
  { code: 'TK', name: 'Tokelau' },
  { code: 'TL', name: 'East Timor' },
  { code: 'TM', name: 'Turkmenistan' },
  { code: 'TN', name: 'Tunisia' },
  { code: 'TO', name: 'Tonga' },
  { code: 'TR', name: 'Turkey' },
  { code: 'TT', name: 'Trinidad & Tobago' },
  { code: 'TV', name: 'Tuvalu' },
  { code: 'TW', name: 'Taiwan' },
  { code: 'TZ', name: 'Tanzania' },
  { code: 'UA', name: 'Ukraine' },
  { code: 'UG', name: 'Uganda' },
  { code: 'UM', name: 'US minor outlying islands' },
  { code: 'US', name: 'United States' },
  { code: 'UY', name: 'Uruguay' },
  { code: 'UZ', name: 'Uzbekistan' },
  { code: 'VA', name: 'Vatican City' },
  { code: 'VC', name: 'St Vincent' },
  { code: 'VE', name: 'Venezuela' },
  { code: 'VG', name: 'Virgin Islands (UK)' },
  { code: 'VI', name: 'Virgin Islands (US)' },
  { code: 'VN', name: 'Vietnam' },
  { code: 'VU', name: 'Vanuatu' },
  { code: 'WF', name: 'Wallis & Futuna' },
  { code: 'WS', name: 'Samoa (western)' },
  { code: 'YE', name: 'Yemen' },
  { code: 'YT', name: 'Mayotte' },
  { code: 'ZA', name: 'South Africa' },
  { code: 'ZM', name: 'Zambia' },
  { code: 'ZW', name: 'Zimbabwe' },
];

// TODO: Remove deprecated timezone
const TIMEZONE_OPTIONS = [
  // { code: null, value: 'US/Pacific', identifier: 'America/Los_Angeles' },
  { code: null, value: 'UTC' },
  { code: 'AS', value: 'Pacific/Pago_Pago' },
  { code: 'US', value: 'Pacific/Honolulu' },
  { code: 'US', value: 'America/Los_Angeles' },
  { code: 'MX', value: 'America/Tijuana' },
  { code: 'US', value: 'America/Denver' },
  { code: 'US', value: 'America/Phoenix' },
  { code: 'MX', value: 'America/Mazatlan' },
  { code: 'US', value: 'America/Chicago' },
  { code: 'MX', value: 'America/Mexico_City' },
  { code: 'CA', value: 'America/Regina' },
  { code: 'GT', value: 'America/Guatemala' },
  { code: 'CO', value: 'America/Bogota' },
  { code: 'US', value: 'America/New_York' },
  { code: 'PE', value: 'America/Lima' },
  { code: 'CA', value: 'America/Halifax' },
  { code: 'GY', value: 'America/Guyana' },
  { code: 'BO', value: 'America/La_Paz' },
  { code: 'AR', value: 'America/Argentina/Buenos_Aires' },
  { code: 'GL', value: 'America/Godthab' },
  { code: 'UY', value: 'America/Montevideo' },
  { code: 'CL', value: 'America/Santiago' },
  { code: 'BR', value: 'America/Sao_Paulo' },
  { code: 'GS', value: 'Atlantic/South_Georgia' },
  { code: 'PT', value: 'Atlantic/Azores' },
  { code: 'CV', value: 'Atlantic/Cape_Verde' },
  { code: 'MA', value: 'Africa/Casablanca' },
  { code: 'IE', value: 'Europe/Dublin' },
  { code: 'PT', value: 'Europe/Lisbon' },
  { code: 'GB', value: 'Europe/London' },
  { code: 'LR', value: 'Africa/Monrovia' },
  { code: 'DZ', value: 'Africa/Algiers' },
  { code: 'NL', value: 'Europe/Amsterdam' },
  { code: 'DE', value: 'Europe/Berlin' },
  { code: 'BE', value: 'Europe/Brussels' },
  { code: 'HU', value: 'Europe/Budapest' },
  { code: 'DK', value: 'Europe/Copenhagen' },
  { code: 'ES', value: 'Europe/Madrid' },
  { code: 'FR', value: 'Europe/Paris' },
  { code: 'IT', value: 'Europe/Rome' },
  { code: 'SE', value: 'Europe/Stockholm' },
  { code: 'AT', value: 'Europe/Vienna' },
  { code: 'PL', value: 'Europe/Warsaw' },
  { code: 'GR', value: 'Europe/Athens' },
  { code: 'RO', value: 'Europe/Bucharest' },
  { code: 'EG', value: 'Africa/Cairo' },
  { code: 'IL', value: 'Asia/Jerusalem' },
  { code: 'ZA', value: 'Africa/Johannesburg' },
  { code: 'FI', value: 'Europe/Helsinki' },
  { code: 'UA', value: 'Europe/Kiev' },
  { code: 'RU', value: 'Europe/Kaliningrad' },
  { code: 'LV', value: 'Europe/Riga' },
  { code: 'BG', value: 'Europe/Sofia' },
  { code: 'EE', value: 'Europe/Tallinn' },
  { code: 'LT', value: 'Europe/Vilnius' },
  { code: 'TR', value: 'Europe/Istanbul' },
  { code: 'IQ', value: 'Asia/Baghdad' },
  { code: 'KE', value: 'Africa/Nairobi' },
  { code: 'BY', value: 'Europe/Minsk' },
  { code: 'SA', value: 'Asia/Riyadh' },
  { code: 'RU', value: 'Europe/Moscow' },
  { code: 'AZ', value: 'Asia/Baku' },
  { code: 'RU', value: 'Europe/Samara' },
  { code: 'GE', value: 'Asia/Tbilisi' },
  { code: 'AM', value: 'Asia/Yerevan' },
  { code: 'PK', value: 'Asia/Karachi' },
  { code: 'RU', value: 'Asia/Yekaterinburg' },
  { code: 'UZ', value: 'Asia/Tashkent' },
  { code: 'KZ', value: 'Asia/Almaty' },
  { code: 'BD', value: 'Asia/Dhaka' },
  { code: 'TH', value: 'Asia/Bangkok' },
  { code: 'ID', value: 'Asia/Jakarta' },
  { code: 'RU', value: 'Asia/Krasnoyarsk' },
  { code: 'CN', value: 'Asia/Shanghai' },
  { code: 'HK', value: 'Asia/Hong_Kong' },
  { code: 'MY', value: 'Asia/Kuala_Lumpur' },
  { code: 'RU', value: 'Asia/Irkutsk' },
  { code: 'SG', value: 'Asia/Singapore' },
  { code: 'TW', value: 'Asia/Taipei' },
  { code: 'MN', value: 'Asia/Ulaanbaatar' },
  { code: 'AU', value: 'Australia/Perth' },
  { code: 'RU', value: 'Asia/Yakutsk' },
  { code: 'KR', value: 'Asia/Seoul' },
  { code: 'JP', value: 'Asia/Tokyo' },
  { code: 'AU', value: 'Australia/Brisbane' },
  { code: 'GU', value: 'Pacific/Guam' },
  { code: 'RU', value: 'Asia/Magadan' },
  { code: 'RU', value: 'Asia/Vladivostok' },
  { code: 'PG', value: 'Pacific/Port_Moresby' },
  { code: 'AU', value: 'Australia/Hobart' },
  { code: 'AU', value: 'Australia/Sydney' },
  { code: 'SB', value: 'Pacific/Guadalcanal' },
  { code: 'NC', value: 'Pacific/Noumea' },
  { code: 'MH', value: 'Pacific/Majuro' },
  { code: 'RU', value: 'Asia/Kamchatka' },
  { code: 'NZ', value: 'Pacific/Auckland' },
  { code: 'TK', value: 'Pacific/Fakaofo' },
  { code: 'FJ', value: 'Pacific/Fiji' },
  { code: 'TO', value: 'Pacific/Tongatapu' },
  { code: 'WS', value: 'Pacific/Apia' },
];

const TODAY_DATE_OBJECT = new Date();

const UTC_OFFSET = {
  CODE: 'Z',
  OFFSET_STR: '+00:00',
};

const getTimezoneOffsetStr = (timeZone) => {
  let offset;
  try {
    offset = format(TODAY_DATE_OBJECT, 'XXX', { timeZone });
    if (offset === UTC_OFFSET.CODE) return UTC_OFFSET.OFFSET_STR;
  } catch {
    offset = '';
  }
  return offset;
};

export const timezoneLabelFormat = (tz) => {
  const tzObject = _.find(TIMEZONE_OPTIONS, { value: tz });
  const timezone = _.get(tzObject, 'value', null);
  const countryCode = _.get(tzObject, 'code', null);
  const identifier = _.get(tzObject, 'identifier', null);

  if (!timezone) return 'Unknown';

  // timezone name
  const spTimezone = timezone.split('/');
  const tzName =
    spTimezone.length > 1
      ? spTimezone
          .slice(1)
          .join('/')
          .replace('_', ' ')
      : spTimezone[0];

  // country name
  const country = _.get(_.find(COUNTRY_CODES, { code: countryCode }), 'name', '');

  // offset
  const offset = getTimezoneOffsetStr(identifier || timezone);

  return `${tzName}${country && `, ${country}`} (${offset})`;
};

export const getLabeledTimezoneOptions = () =>
  TIMEZONE_OPTIONS.map(({ value: timezone }) => ({
    label: timezoneLabelFormat(timezone),
    value: timezone,
  }));

export const COUNTRY_OPTIONS = [
  {
    label: ' Republic of Korea(KOR)',
    value: 'KOR',
  },
  {
    label: ' United States of America(USA)',
    value: 'USA',
  },
  {
    label: 'Afghanistan(AFG)',
    value: 'AFG',
  },
  {
    label: 'Åland Islands(ALA)',
    value: 'ALA',
  },
  {
    label: 'Albania(ALB)',
    value: 'ALB',
  },
  {
    label: 'Algeria(DZA)',
    value: 'DZA',
  },
  {
    label: 'American Samoa(ASM)',
    value: 'ASM',
  },
  {
    label: 'Andorra(AND)',
    value: 'AND',
  },
  {
    label: 'Angola(AGO)',
    value: 'AGO',
  },
  {
    label: 'Anguilla(AIA)',
    value: 'AIA',
  },
  {
    label: 'Antarctica(ATA)',
    value: 'ATA',
  },
  {
    label: 'Antigua and Barbuda(ATG)',
    value: 'ATG',
  },
  {
    label: 'Argentina(ARG)',
    value: 'ARG',
  },
  {
    label: 'Armenia(ARM)',
    value: 'ARM',
  },
  {
    label: 'Aruba(ABW)',
    value: 'ABW',
  },
  {
    label: 'Australia(AUS)',
    value: 'AUS',
  },
  {
    label: 'Austria(AUT)',
    value: 'AUT',
  },
  {
    label: 'Azerbaijan(AZE)',
    value: 'AZE',
  },
  {
    label: 'Bahamas(BHS)',
    value: 'BHS',
  },
  {
    label: 'Bahrain(BHR)',
    value: 'BHR',
  },
  {
    label: 'Bangladesh(BGD)',
    value: 'BGD',
  },
  {
    label: 'Barbados(BRB)',
    value: 'BRB',
  },
  {
    label: 'Belarus(BLR)',
    value: 'BLR',
  },
  {
    label: 'Belgium(BEL)',
    value: 'BEL',
  },
  {
    label: 'Belize(BLZ)',
    value: 'BLZ',
  },
  {
    label: 'Benin(BEN)',
    value: 'BEN',
  },
  {
    label: 'Bermuda(BMU)',
    value: 'BMU',
  },
  {
    label: 'Bhutan(BTN)',
    value: 'BTN',
  },
  {
    label: 'Bolivia(BOL)',
    value: 'BOL',
  },
  {
    label: 'Bonaire, Sint Eustatius and Saba(BES)',
    value: 'BES',
  },
  {
    label: 'Bosnia and Herzegovina(BIH)',
    value: 'BIH',
  },
  {
    label: 'Botswana(BWA)',
    value: 'BWA',
  },
  {
    label: 'Bouvet Island(BVT)',
    value: 'BVT',
  },
  {
    label: 'Brazil(BRA)',
    value: 'BRA',
  },
  {
    label: 'British Indian Ocean Territory(IOT)',
    value: 'IOT',
  },
  {
    label: 'British Virgin Islands(VGB)',
    value: 'VGB',
  },
  {
    label: 'Brunei Darussalam(BRN)',
    value: 'BRN',
  },
  {
    label: 'Bulgaria(BGR)',
    value: 'BGR',
  },
  {
    label: 'Burkina Faso(BFA)',
    value: 'BFA',
  },
  {
    label: 'Burundi(BDI)',
    value: 'BDI',
  },
  {
    label: 'Cabo Verde(CPV)',
    value: 'CPV',
  },
  {
    label: 'Cambodia(KHM)',
    value: 'KHM',
  },
  {
    label: 'Cameroon(CMR)',
    value: 'CMR',
  },
  {
    label: 'Canada(CAN)',
    value: 'CAN',
  },
  {
    label: 'Cayman Islands(CYM)',
    value: 'CYM',
  },
  {
    label: 'Central African Republic(CAF)',
    value: 'CAF',
  },
  {
    label: 'Chad(TCD)',
    value: 'TCD',
  },
  {
    label: 'Chile(CHL)',
    value: 'CHL',
  },
  {
    label: 'China(CHN)',
    value: 'CHN',
  },
  {
    label: 'Hong Kong(HKG)',
    value: 'HKG',
  },
  {
    label: 'Macao(MAC)',
    value: 'MAC',
  },
  {
    label: 'Christmas Island(CXR)',
    value: 'CXR',
  },
  {
    label: 'Cocos (Keeling) Islands(CCK)',
    value: 'CCK',
  },
  {
    label: 'Colombia(COL)',
    value: 'COL',
  },
  {
    label: 'Comoros(COM)',
    value: 'COM',
  },
  {
    label: 'Congo(COG)',
    value: 'COG',
  },
  {
    label: 'Cook Islands(COK)',
    value: 'COK',
  },
  {
    label: 'Costa Rica(CRI)',
    value: 'CRI',
  },
  {
    label: 'Côte d’Ivoire(CIV)',
    value: 'CIV',
  },
  {
    label: 'Croatia(HRV)',
    value: 'HRV',
  },
  {
    label: 'Cuba(CUB)',
    value: 'CUB',
  },
  {
    label: 'Curaçao(CUW)',
    value: 'CUW',
  },
  {
    label: 'Cyprus(CYP)',
    value: 'CYP',
  },
  {
    label: 'Czechia(CZE)',
    value: 'CZE',
  },
  {
    label: "Democratic People's Republic of Korea(PRK)",
    value: 'PRK',
  },
  {
    label: 'Democratic Republic of the Congo(COD)',
    value: 'COD',
  },
  {
    label: 'Denmark(DNK)',
    value: 'DNK',
  },
  {
    label: 'Djibouti(DJI)',
    value: 'DJI',
  },
  {
    label: 'Dominica(DMA)',
    value: 'DMA',
  },
  {
    label: 'Dominican Republic(DOM)',
    value: 'DOM',
  },
  {
    label: 'Ecuador(ECU)',
    value: 'ECU',
  },
  {
    label: 'Egypt(EGY)',
    value: 'EGY',
  },
  {
    label: 'El Salvador(SLV)',
    value: 'SLV',
  },
  {
    label: 'Equatorial Guinea(GNQ)',
    value: 'GNQ',
  },
  {
    label: 'Eritrea(ERI)',
    value: 'ERI',
  },
  {
    label: 'Estonia(EST)',
    value: 'EST',
  },
  {
    label: 'Eswatini(SWZ)',
    value: 'SWZ',
  },
  {
    label: 'Ethiopia(ETH)',
    value: 'ETH',
  },
  {
    label: 'Falkland Islands (Malvinas)(FLK)',
    value: 'FLK',
  },
  {
    label: 'Faroe Islands(FRO)',
    value: 'FRO',
  },
  {
    label: 'Fiji(FJI)',
    value: 'FJI',
  },
  {
    label: 'Finland(FIN)',
    value: 'FIN',
  },
  {
    label: 'France(FRA)',
    value: 'FRA',
  },
  {
    label: 'French Guiana(GUF)',
    value: 'GUF',
  },
  {
    label: 'French Polynesia(PYF)',
    value: 'PYF',
  },
  {
    label: 'French Southern Territories(ATF)',
    value: 'ATF',
  },
  {
    label: 'Gabon(GAB)',
    value: 'GAB',
  },
  {
    label: 'Gambia(GMB)',
    value: 'GMB',
  },
  {
    label: 'Georgia(GEO)',
    value: 'GEO',
  },
  {
    label: 'Germany(DEU)',
    value: 'DEU',
  },
  {
    label: 'Ghana(GHA)',
    value: 'GHA',
  },
  {
    label: 'Gibraltar(GIB)',
    value: 'GIB',
  },
  {
    label: 'Greece(GRC)',
    value: 'GRC',
  },
  {
    label: 'Greenland(GRL)',
    value: 'GRL',
  },
  {
    label: 'Grenada(GRD)',
    value: 'GRD',
  },
  {
    label: 'Guadeloupe(GLP)',
    value: 'GLP',
  },
  {
    label: 'Guam(GUM)',
    value: 'GUM',
  },
  {
    label: 'Guatemala(GTM)',
    value: 'GTM',
  },
  {
    label: 'Guernsey(GGY)',
    value: 'GGY',
  },
  {
    label: 'Guinea(GIN)',
    value: 'GIN',
  },
  {
    label: 'Guinea-Bissau(GNB)',
    value: 'GNB',
  },
  {
    label: 'Guyana(GUY)',
    value: 'GUY',
  },
  {
    label: 'Haiti(HTI)',
    value: 'HTI',
  },
  {
    label: 'Heard Island and McDonald Islands(HMD)',
    value: 'HMD',
  },
  {
    label: 'Holy See(VAT)',
    value: 'VAT',
  },
  {
    label: 'Honduras(HND)',
    value: 'HND',
  },
  {
    label: 'Hungary(HUN)',
    value: 'HUN',
  },
  {
    label: 'Iceland(ISL)',
    value: 'ISL',
  },
  {
    label: 'India(IND)',
    value: 'IND',
  },
  {
    label: 'Indonesia(IDN)',
    value: 'IDN',
  },
  {
    label: 'Iran(IRN)',
    value: 'IRN',
  },
  {
    label: 'Iraq(IRQ)',
    value: 'IRQ',
  },
  {
    label: 'Ireland(IRL)',
    value: 'IRL',
  },
  {
    label: 'Isle of Man(IMN)',
    value: 'IMN',
  },
  {
    label: 'Israel(ISR)',
    value: 'ISR',
  },
  {
    label: 'Italy(ITA)',
    value: 'ITA',
  },
  {
    label: 'Jamaica(JAM)',
    value: 'JAM',
  },
  {
    label: 'Japan(JPN)',
    value: 'JPN',
  },
  {
    label: 'Jersey(JEY)',
    value: 'JEY',
  },
  {
    label: 'Jordan(JOR)',
    value: 'JOR',
  },
  {
    label: 'Kazakhstan(KAZ)',
    value: 'KAZ',
  },
  {
    label: 'Kenya(KEN)',
    value: 'KEN',
  },
  {
    label: 'Kiribati(KIR)',
    value: 'KIR',
  },
  {
    label: 'Kuwait(KWT)',
    value: 'KWT',
  },
  {
    label: 'Kyrgyzstan(KGZ)',
    value: 'KGZ',
  },
  {
    label: 'Lao(LAO)',
    value: 'LAO',
  },
  {
    label: 'Latvia(LVA)',
    value: 'LVA',
  },
  {
    label: 'Lebanon(LBN)',
    value: 'LBN',
  },
  {
    label: 'Lesotho(LSO)',
    value: 'LSO',
  },
  {
    label: 'Liberia(LBR)',
    value: 'LBR',
  },
  {
    label: 'Libya(LBY)',
    value: 'LBY',
  },
  {
    label: 'Liechtenstein(LIE)',
    value: 'LIE',
  },
  {
    label: 'Lithuania(LTU)',
    value: 'LTU',
  },
  {
    label: 'Luxembourg(LUX)',
    value: 'LUX',
  },
  {
    label: 'Madagascar(MDG)',
    value: 'MDG',
  },
  {
    label: 'Malawi(MWI)',
    value: 'MWI',
  },
  {
    label: 'Malaysia(MYS)',
    value: 'MYS',
  },
  {
    label: 'Maldives(MDV)',
    value: 'MDV',
  },
  {
    label: 'Mali(MLI)',
    value: 'MLI',
  },
  {
    label: 'Malta(MLT)',
    value: 'MLT',
  },
  {
    label: 'Marshall Islands(MHL)',
    value: 'MHL',
  },
  {
    label: 'Martinique(MTQ)',
    value: 'MTQ',
  },
  {
    label: 'Mauritania(MRT)',
    value: 'MRT',
  },
  {
    label: 'Mauritius(MUS)',
    value: 'MUS',
  },
  {
    label: 'Mayotte(MYT)',
    value: 'MYT',
  },
  {
    label: 'Mexico(MEX)',
    value: 'MEX',
  },
  {
    label: 'Micronesia(FSM)',
    value: 'FSM',
  },
  {
    label: 'Monaco(MCO)',
    value: 'MCO',
  },
  {
    label: 'Mongolia(MNG)',
    value: 'MNG',
  },
  {
    label: 'Montenegro(MNE)',
    value: 'MNE',
  },
  {
    label: 'Montserrat(MSR)',
    value: 'MSR',
  },
  {
    label: 'Morocco(MAR)',
    value: 'MAR',
  },
  {
    label: 'Mozambique(MOZ)',
    value: 'MOZ',
  },
  {
    label: 'Myanmar(MMR)',
    value: 'MMR',
  },
  {
    label: 'Namibia(NAM)',
    value: 'NAM',
  },
  {
    label: 'Nauru(NRU)',
    value: 'NRU',
  },
  {
    label: 'Nepal(NPL)',
    value: 'NPL',
  },
  {
    label: 'Netherlands(NLD)',
    value: 'NLD',
  },
  {
    label: 'New Caledonia(NCL)',
    value: 'NCL',
  },
  {
    label: 'New Zealand(NZL)',
    value: 'NZL',
  },
  {
    label: 'Nicaragua(NIC)',
    value: 'NIC',
  },
  {
    label: 'Niger(NER)',
    value: 'NER',
  },
  {
    label: 'Nigeria(NGA)',
    value: 'NGA',
  },
  {
    label: 'Niue(NIU)',
    value: 'NIU',
  },
  {
    label: 'Norfolk Island(NFK)',
    value: 'NFK',
  },
  {
    label: 'North Macedonia(MKD)',
    value: 'MKD',
  },
  {
    label: 'Northern Mariana Islands(MNP)',
    value: 'MNP',
  },
  {
    label: 'Norway(NOR)',
    value: 'NOR',
  },
  {
    label: 'Oman(OMN)',
    value: 'OMN',
  },
  {
    label: 'Pakistan(PAK)',
    value: 'PAK',
  },
  {
    label: 'Palau(PLW)',
    value: 'PLW',
  },
  {
    label: 'Panama(PAN)',
    value: 'PAN',
  },
  {
    label: 'Papua New Guinea(PNG)',
    value: 'PNG',
  },
  {
    label: 'Paraguay(PRY)',
    value: 'PRY',
  },
  {
    label: 'Peru(PER)',
    value: 'PER',
  },
  {
    label: 'Philippines(PHL)',
    value: 'PHL',
  },
  {
    label: 'Pitcairn(PCN)',
    value: 'PCN',
  },
  {
    label: 'Poland(POL)',
    value: 'POL',
  },
  {
    label: 'Portugal(PRT)',
    value: 'PRT',
  },
  {
    label: 'Puerto Rico(PRI)',
    value: 'PRI',
  },
  {
    label: 'Qatar(QAT)',
    value: 'QAT',
  },
  {
    label: 'Republic of Moldova(MDA)',
    value: 'MDA',
  },
  {
    label: 'Réunion(REU)',
    value: 'REU',
  },
  {
    label: 'Romania(ROU)',
    value: 'ROU',
  },
  {
    label: 'Russian Federation(RUS)',
    value: 'RUS',
  },
  {
    label: 'Rwanda(RWA)',
    value: 'RWA',
  },
  {
    label: 'Saint Barthélemy(BLM)',
    value: 'BLM',
  },
  {
    label: 'Saint Helena(SHN)',
    value: 'SHN',
  },
  {
    label: 'Saint Kitts and Nevis(KNA)',
    value: 'KNA',
  },
  {
    label: 'Saint Lucia(LCA)',
    value: 'LCA',
  },
  {
    label: 'Saint Martin(MAF)',
    value: 'MAF',
  },
  {
    label: 'Saint Pierre and Miquelon(SPM)',
    value: 'SPM',
  },
  {
    label: 'Saint Vincent and the Grenadines(VCT)',
    value: 'VCT',
  },
  {
    label: 'Samoa(WSM)',
    value: 'WSM',
  },
  {
    label: 'San Marino(SMR)',
    value: 'SMR',
  },
  {
    label: 'Sao Tome and Principe(STP)',
    value: 'STP',
  },
  {
    label: 'Saudi Arabia(SAU)',
    value: 'SAU',
  },
  {
    label: 'Senegal(SEN)',
    value: 'SEN',
  },
  {
    label: 'Serbia(SRB)',
    value: 'SRB',
  },
  {
    label: 'Seychelles(SYC)',
    value: 'SYC',
  },
  {
    label: 'Sierra Leone(SLE)',
    value: 'SLE',
  },
  {
    label: 'Singapore(SGP)',
    value: 'SGP',
  },
  {
    label: 'Sint Maarten (Dutch part)(SXM)',
    value: 'SXM',
  },
  {
    label: 'Slovakia(SVK)',
    value: 'SVK',
  },
  {
    label: 'Slovenia(SVN)',
    value: 'SVN',
  },
  {
    label: 'Solomon Islands(SLB)',
    value: 'SLB',
  },
  {
    label: 'Somalia(SOM)',
    value: 'SOM',
  },
  {
    label: 'South Africa(ZAF)',
    value: 'ZAF',
  },
  {
    label: 'South Georgia and the South Sandwich Islands(SGS)',
    value: 'SGS',
  },
  {
    label: 'South Sudan(SSD)',
    value: 'SSD',
  },
  {
    label: 'Spain(ESP)',
    value: 'ESP',
  },
  {
    label: 'Sri Lanka(LKA)',
    value: 'LKA',
  },
  {
    label: 'State of Palestine(PSE)',
    value: 'PSE',
  },
  {
    label: 'Sudan(SDN)',
    value: 'SDN',
  },
  {
    label: 'Suriname(SUR)',
    value: 'SUR',
  },
  {
    label: 'Svalbard and Jan Mayen Islands(SJM)',
    value: 'SJM',
  },
  {
    label: 'Sweden(SWE)',
    value: 'SWE',
  },
  {
    label: 'Switzerland(CHE)',
    value: 'CHE',
  },
  {
    label: 'Syrian Arab Republic(SYR)',
    value: 'SYR',
  },
  {
    label: 'Taiwan(TWN)',
    value: 'TWN',
  },
  {
    label: 'Tajikistan(TJK)',
    value: 'TJK',
  },
  {
    label: 'Thailand(THA)',
    value: 'THA',
  },
  {
    label: 'Timor-Leste(TLS)',
    value: 'TLS',
  },
  {
    label: 'Togo(TGO)',
    value: 'TGO',
  },
  {
    label: 'Tokelau(TKL)',
    value: 'TKL',
  },
  {
    label: 'Tonga(TON)',
    value: 'TON',
  },
  {
    label: 'Trinidad and Tobago(TTO)',
    value: 'TTO',
  },
  {
    label: 'Tunisia(TUN)',
    value: 'TUN',
  },
  {
    label: 'Turkey(TUR)',
    value: 'TUR',
  },
  {
    label: 'Turkmenistan(TKM)',
    value: 'TKM',
  },
  {
    label: 'Turks and Caicos Islands(TCA)',
    value: 'TCA',
  },
  {
    label: 'Tuvalu(TUV)',
    value: 'TUV',
  },
  {
    label: 'Uganda(UGA)',
    value: 'UGA',
  },
  {
    label: 'Ukraine(UKR)',
    value: 'UKR',
  },
  {
    label: 'United Arab Emirates(ARE)',
    value: 'ARE',
  },
  {
    label: 'United Kingdom of Great Britain(GBR)',
    value: 'GBR',
  },
  {
    label: 'Tanzania(TZA)',
    value: 'TZA',
  },
  {
    label: 'United States Minor Outlying Islands(UMI)',
    value: 'UMI',
  },
  {
    label: 'United States Virgin Islands(VIR)',
    value: 'VIR',
  },
  {
    label: 'Uruguay(URY)',
    value: 'URY',
  },
  {
    label: 'Uzbekistan(UZB)',
    value: 'UZB',
  },
  {
    label: 'Vanuatu(VUT)',
    value: 'VUT',
  },
  {
    label: 'Venezuela(VEN)',
    value: 'VEN',
  },
  {
    label: 'Viet Nam(VNM)',
    value: 'VNM',
  },
  {
    label: 'Wallis and Futuna Islands(WLF)',
    value: 'WLF',
  },
  {
    label: 'Western Sahara(ESH)',
    value: 'ESH',
  },
  {
    label: 'Yemen(YEM)',
    value: 'YEM',
  },
  {
    label: 'Zambia(ZMB)',
    value: 'ZMB',
  },
  {
    label: 'Zimbabwe(ZWE)',
    value: 'ZWE',
  },
];
export const IAB_OPTIONS = [
  {
    label: 'Arts & Entertainment (IAB1)',
    value: 'IAB1',
  },
  {
    label: 'Books & Literature (IAB1-1)',
    value: 'IAB1-1',
  },
  {
    label: 'Celebrity Fan/Gossip (IAB1-2)',
    value: 'IAB1-2',
  },
  {
    label: 'Fine Art (IAB1-3)',
    value: 'IAB1-3',
  },
  {
    label: 'Humor (IAB1-4)',
    value: 'IAB1-4',
  },
  {
    label: 'Movies (IAB1-5)',
    value: 'IAB1-5',
  },
  {
    label: 'Music (IAB1-6)',
    value: 'IAB1-6',
  },
  {
    label: 'Television (IAB1-7)',
    value: 'IAB1-7',
  },
  {
    label: 'Automotive (IAB2)',
    value: 'IAB2',
  },
  {
    label: 'Auto Parts (IAB2-1)',
    value: 'IAB2-1',
  },
  {
    label: 'Auto Repair (IAB2-2)',
    value: 'IAB2-2',
  },
  {
    label: 'Buying/Selling Cars (IAB2-3)',
    value: 'IAB2-3',
  },
  {
    label: 'Car Culture (IAB2-4)',
    value: 'IAB2-4',
  },
  {
    label: 'Certified Pre-Owned (IAB2-5)',
    value: 'IAB2-5',
  },
  {
    label: 'Convertible (IAB2-6)',
    value: 'IAB2-6',
  },
  {
    label: 'Coupe (IAB2-7)',
    value: 'IAB2-7',
  },
  {
    label: 'Crossover (IAB2-8)',
    value: 'IAB2-8',
  },
  {
    label: 'Diesel (IAB2-9)',
    value: 'IAB2-9',
  },
  {
    label: 'Electric Vehicle (IAB2-10)',
    value: 'IAB2-10',
  },
  {
    label: 'Hatchback (IAB2-11)',
    value: 'IAB2-11',
  },
  {
    label: 'Hybrid (IAB2-12)',
    value: 'IAB2-12',
  },
  {
    label: 'Luxury (IAB2-13)',
    value: 'IAB2-13',
  },
  {
    label: 'MiniVan (IAB2-14)',
    value: 'IAB2-14',
  },
  {
    label: 'Mororcycles (IAB2-15)',
    value: 'IAB2-15',
  },
  {
    label: 'Off-Road Vehicles (IAB2-16)',
    value: 'IAB2-16',
  },
  {
    label: 'Performance Vehicles (IAB2-17)',
    value: 'IAB2-17',
  },
  {
    label: 'Pickup (IAB2-18)',
    value: 'IAB2-18',
  },
  {
    label: 'Road-Side Assistance (IAB2-19)',
    value: 'IAB2-19',
  },
  {
    label: 'Sedan (IAB2-20)',
    value: 'IAB2-20',
  },
  {
    label: 'Trucks & Accessories (IAB2-21)',
    value: 'IAB2-21',
  },
  {
    label: 'Vintage Cars (IAB2-22)',
    value: 'IAB2-22',
  },
  {
    label: 'Wagon (IAB2-23)',
    value: 'IAB2-23',
  },
  {
    label: 'Business (IAB3)',
    value: 'IAB3',
  },
  {
    label: 'Advertising (IAB3-1)',
    value: 'IAB3-1',
  },
  {
    label: 'Agriculture (IAB3-2)',
    value: 'IAB3-2',
  },
  {
    label: 'Biotech/Biomedical (IAB3-3)',
    value: 'IAB3-3',
  },
  {
    label: 'Business Software (IAB3-4)',
    value: 'IAB3-4',
  },
  {
    label: 'Construction (IAB3-5)',
    value: 'IAB3-5',
  },
  {
    label: 'Forestry (IAB3-6)',
    value: 'IAB3-6',
  },
  {
    label: 'Government (IAB3-7)',
    value: 'IAB3-7',
  },
  {
    label: 'Green Solutions (IAB3-8)',
    value: 'IAB3-8',
  },
  {
    label: 'Human Resources (IAB3-9)',
    value: 'IAB3-9',
  },
  {
    label: 'Logistics (IAB3-10)',
    value: 'IAB3-10',
  },
  {
    label: 'Marketing (IAB3-11)',
    value: 'IAB3-11',
  },
  {
    label: 'Metals (IAB3-12)',
    value: 'IAB3-12',
  },
  {
    label: 'Careers (IAB4)',
    value: 'IAB4',
  },
  {
    label: 'Career Planning (IAB4-1)',
    value: 'IAB4-1',
  },
  {
    label: 'College (IAB4-2)',
    value: 'IAB4-2',
  },
  {
    label: 'Financial Aid (IAB4-3)',
    value: 'IAB4-3',
  },
  {
    label: 'Job Fairs (IAB4-4)',
    value: 'IAB4-4',
  },
  {
    label: 'Job Search (IAB4-5)',
    value: 'IAB4-5',
  },
  {
    label: 'Resume Writing/Advice (IAB4-6)',
    value: 'IAB4-6',
  },
  {
    label: 'Nursing (IAB4-7)',
    value: 'IAB4-7',
  },
  {
    label: 'Scholarships (IAB4-8)',
    value: 'IAB4-8',
  },
  {
    label: 'Telecommuting (IAB4-9)',
    value: 'IAB4-9',
  },
  {
    label: 'U.S. Military (IAB4-10)',
    value: 'IAB4-10',
  },
  {
    label: 'Career Advice (IAB4-11)',
    value: 'IAB4-11',
  },
  {
    label: 'Education (IAB5)',
    value: 'IAB5',
  },
  {
    label: '7-12 Education (IAB5-1)',
    value: 'IAB5-1',
  },
  {
    label: 'Adult Education (IAB5-2)',
    value: 'IAB5-2',
  },
  {
    label: 'Art History (IAB5-3)',
    value: 'IAB5-3',
  },
  {
    label: 'College Administration (IAB5-4)',
    value: 'IAB5-4',
  },
  {
    label: 'College Life (IAB5-5)',
    value: 'IAB5-5',
  },
  {
    label: 'Distance Learning (IAB5-6)',
    value: 'IAB5-6',
  },
  {
    label: 'English as a 2nd Language (IAB5-7)',
    value: 'IAB5-7',
  },
  {
    label: 'Language Learning (IAB5-8)',
    value: 'IAB5-8',
  },
  {
    label: 'Graduate School (IAB5-9)',
    value: 'IAB5-9',
  },
  {
    label: 'Homeschooling (IAB5-10)',
    value: 'IAB5-10',
  },
  {
    label: 'Homework/Study Tips (IAB5-11)',
    value: 'IAB5-11',
  },
  {
    label: 'K-6 Educators (IAB5-12)',
    value: 'IAB5-12',
  },
  {
    label: 'Private School (IAB5-13)',
    value: 'IAB5-13',
  },
  {
    label: 'Special Education (IAB5-14)',
    value: 'IAB5-14',
  },
  {
    label: 'Studying Business (IAB5-15)',
    value: 'IAB5-15',
  },
  {
    label: 'Family & Parenting (IAB6)',
    value: 'IAB6',
  },
  {
    label: 'Adoption (IAB6-1)',
    value: 'IAB6-1',
  },
  {
    label: 'Babies & Toddlers (IAB6-2)',
    value: 'IAB6-2',
  },
  {
    label: 'Daycare/Pre School (IAB6-3)',
    value: 'IAB6-3',
  },
  {
    label: 'Family Internet (IAB6-4)',
    value: 'IAB6-4',
  },
  {
    label: 'Parenting - K-6 Kids (IAB6-5)',
    value: 'IAB6-5',
  },
  {
    label: 'Parenting teens (IAB6-6)',
    value: 'IAB6-6',
  },
  {
    label: 'Pregnancy (IAB6-7)',
    value: 'IAB6-7',
  },
  {
    label: 'Special Needs Kids (IAB6-8)',
    value: 'IAB6-8',
  },
  {
    label: 'Eldercare (IAB6-9)',
    value: 'IAB6-9',
  },
  {
    label: 'Health & Fitness (IAB7)',
    value: 'IAB7',
  },
  {
    label: 'Exercise (IAB7-1)',
    value: 'IAB7-1',
  },
  {
    label: 'A.D.D. (IAB7-2)',
    value: 'IAB7-2',
  },
  {
    label: 'AIDS/HIV (IAB7-3)',
    value: 'IAB7-3',
  },
  {
    label: 'Allergies (IAB7-4)',
    value: 'IAB7-4',
  },
  {
    label: 'Alternative Medicine (IAB7-5)',
    value: 'IAB7-5',
  },
  {
    label: 'Arthritis (IAB7-6)',
    value: 'IAB7-6',
  },
  {
    label: 'Asthma (IAB7-7)',
    value: 'IAB7-7',
  },
  {
    label: 'Autism/PDD (IAB7-8)',
    value: 'IAB7-8',
  },
  {
    label: 'Bipolar Disorder (IAB7-9)',
    value: 'IAB7-9',
  },
  {
    label: 'Brain Tumor (IAB7-10)',
    value: 'IAB7-10',
  },
  {
    label: 'Cancer (IAB7-11)',
    value: 'IAB7-11',
  },
  {
    label: 'Cholesterol (IAB7-12)',
    value: 'IAB7-12',
  },
  {
    label: 'Chronic Fatigue Syndrome (IAB7-13)',
    value: 'IAB7-13',
  },
  {
    label: 'Chronic Pain (IAB7-14)',
    value: 'IAB7-14',
  },
  {
    label: 'Cold & Flu (IAB7-15)',
    value: 'IAB7-15',
  },
  {
    label: 'Deafness (IAB7-16)',
    value: 'IAB7-16',
  },
  {
    label: 'Dental Care (IAB7-17)',
    value: 'IAB7-17',
  },
  {
    label: 'Depression (IAB7-18)',
    value: 'IAB7-18',
  },
  {
    label: 'Dermatology (IAB7-19)',
    value: 'IAB7-19',
  },
  {
    label: 'Diabetes (IAB7-20)',
    value: 'IAB7-20',
  },
  {
    label: 'Epilepsy (IAB7-21)',
    value: 'IAB7-21',
  },
  {
    label: 'GERD/Acid Reflux (IAB7-22)',
    value: 'IAB7-22',
  },
  {
    label: 'Headaches/Migraines (IAB7-23)',
    value: 'IAB7-23',
  },
  {
    label: 'Heart Disease (IAB7-24)',
    value: 'IAB7-24',
  },
  {
    label: 'Herbs for Health (IAB7-25)',
    value: 'IAB7-25',
  },
  {
    label: 'Holistic Healing (IAB7-26)',
    value: 'IAB7-26',
  },
  {
    label: 'IBS/Crohn’s Disease (IAB7-27)',
    value: 'IAB7-27',
  },
  {
    label: 'Incest/Abuse Support (IAB7-28)',
    value: 'IAB7-28',
  },
  {
    label: 'Incontinence (IAB7-29)',
    value: 'IAB7-29',
  },
  {
    label: 'Infertility (IAB7-30)',
    value: 'IAB7-30',
  },
  {
    label: 'Men’s Health (IAB7-31)',
    value: 'IAB7-31',
  },
  {
    label: 'Nutrition (IAB7-32)',
    value: 'IAB7-32',
  },
  {
    label: 'Orthopedics (IAB7-33)',
    value: 'IAB7-33',
  },
  {
    label: 'Panic/Anxiety Disorders (IAB7-34)',
    value: 'IAB7-34',
  },
  {
    label: 'Pediatrics (IAB7-35)',
    value: 'IAB7-35',
  },
  {
    label: 'Physical Therapy (IAB7-36)',
    value: 'IAB7-36',
  },
  {
    label: 'Psychology/Psychiatry (IAB7-37)',
    value: 'IAB7-37',
  },
  {
    label: 'Senior Health (IAB7-38)',
    value: 'IAB7-38',
  },
  {
    label: 'Sexuality (IAB7-39)',
    value: 'IAB7-39',
  },
  {
    label: 'Sleep Disorders (IAB7-40)',
    value: 'IAB7-40',
  },
  {
    label: 'Smoking Cessation (IAB7-41)',
    value: 'IAB7-41',
  },
  {
    label: 'Substance Abuse (IAB7-42)',
    value: 'IAB7-42',
  },
  {
    label: 'Thyroid Disease (IAB7-43)',
    value: 'IAB7-43',
  },
  {
    label: 'Weight Loss (IAB7-44)',
    value: 'IAB7-44',
  },
  {
    label: 'Women’s Health (IAB7-45)',
    value: 'IAB7-45',
  },
  {
    label: 'Food & Drink (IAB8)',
    value: 'IAB8',
  },
  {
    label: 'American Cuisine (IAB8-1)',
    value: 'IAB8-1',
  },
  {
    label: 'Barbecues & Grilling (IAB8-2)',
    value: 'IAB8-2',
  },
  {
    label: 'Cajun/Creole (IAB8-3)',
    value: 'IAB8-3',
  },
  {
    label: 'Chinese Cuisine (IAB8-4)',
    value: 'IAB8-4',
  },
  {
    label: 'Cocktails/Beer (IAB8-5)',
    value: 'IAB8-5',
  },
  {
    label: 'Coffee/Tea (IAB8-6)',
    value: 'IAB8-6',
  },
  {
    label: 'Cuisine-Specific (IAB8-7)',
    value: 'IAB8-7',
  },
  {
    label: 'Desserts & Baking (IAB8-8)',
    value: 'IAB8-8',
  },
  {
    label: 'Dining Out (IAB8-9)',
    value: 'IAB8-9',
  },
  {
    label: 'Food Allergies (IAB8-10)',
    value: 'IAB8-10',
  },
  {
    label: 'French Cuisine (IAB8-11)',
    value: 'IAB8-11',
  },
  {
    label: 'Health/Lowfat Cooking (IAB8-12)',
    value: 'IAB8-12',
  },
  {
    label: 'Italian Cuisine (IAB8-13)',
    value: 'IAB8-13',
  },
  {
    label: 'Japanese Cuisine (IAB8-14)',
    value: 'IAB8-14',
  },
  {
    label: 'Mexican Cuisine (IAB8-15)',
    value: 'IAB8-15',
  },
  {
    label: 'Vegan (IAB8-16)',
    value: 'IAB8-16',
  },
  {
    label: 'Vegetarian (IAB8-17)',
    value: 'IAB8-17',
  },
  {
    label: 'Wine (IAB8-18)',
    value: 'IAB8-18',
  },
  {
    label: 'Hobbies & Interests (IAB9)',
    value: 'IAB9',
  },
  {
    label: 'Art/Technology (IAB9-1)',
    value: 'IAB9-1',
  },
  {
    label: 'Arts & Crafts (IAB9-2)',
    value: 'IAB9-2',
  },
  {
    label: 'Beadwork (IAB9-3)',
    value: 'IAB9-3',
  },
  {
    label: 'Birdwatching (IAB9-4)',
    value: 'IAB9-4',
  },
  {
    label: 'Board Games/Puzzles (IAB9-5)',
    value: 'IAB9-5',
  },
  {
    label: 'Candle & Soap Making (IAB9-6)',
    value: 'IAB9-6',
  },
  {
    label: 'Card Games (IAB9-7)',
    value: 'IAB9-7',
  },
  {
    label: 'Chess (IAB9-8)',
    value: 'IAB9-8',
  },
  {
    label: 'Cigars (IAB9-9)',
    value: 'IAB9-9',
  },
  {
    label: 'Collecting (IAB9-10)',
    value: 'IAB9-10',
  },
  {
    label: 'Comic Books (IAB9-11)',
    value: 'IAB9-11',
  },
  {
    label: 'Drawing/Sketching (IAB9-12)',
    value: 'IAB9-12',
  },
  {
    label: 'Freelance Writing (IAB9-13)',
    value: 'IAB9-13',
  },
  {
    label: 'Genealogy (IAB9-14)',
    value: 'IAB9-14',
  },
  {
    label: 'Getting Published (IAB9-15)',
    value: 'IAB9-15',
  },
  {
    label: 'Guitar (IAB9-16)',
    value: 'IAB9-16',
  },
  {
    label: 'Home Recording (IAB9-17)',
    value: 'IAB9-17',
  },
  {
    label: 'Investors & Patents (IAB9-18)',
    value: 'IAB9-18',
  },
  {
    label: 'Jewelry Making (IAB9-19)',
    value: 'IAB9-19',
  },
  {
    label: 'Magic & Illusion (IAB9-20)',
    value: 'IAB9-20',
  },
  {
    label: 'Needlework (IAB9-21)',
    value: 'IAB9-21',
  },
  {
    label: 'Painting (IAB9-22)',
    value: 'IAB9-22',
  },
  {
    label: 'Photography (IAB9-23)',
    value: 'IAB9-23',
  },
  {
    label: 'Radio (IAB9-24)',
    value: 'IAB9-24',
  },
  {
    label: 'Roleplaying Games (IAB9-25)',
    value: 'IAB9-25',
  },
  {
    label: 'Sci-Fi & Fantasy (IAB9-26)',
    value: 'IAB9-26',
  },
  {
    label: 'Scrapbooking (IAB9-27)',
    value: 'IAB9-27',
  },
  {
    label: 'Screenwriting (IAB9-28)',
    value: 'IAB9-28',
  },
  {
    label: 'Stamps & Coins (IAB9-29)',
    value: 'IAB9-29',
  },
  {
    label: 'Video & Computer Games (IAB9-30)',
    value: 'IAB9-30',
  },
  {
    label: 'Woodworking (IAB9-31)',
    value: 'IAB9-31',
  },
  {
    label: 'Home & Garden (IAB10)',
    value: 'IAB10',
  },
  {
    label: 'Appliances (IAB10-1)',
    value: 'IAB10-1',
  },
  {
    label: 'Entertaining (IAB10-2)',
    value: 'IAB10-2',
  },
  {
    label: 'Environmental Safety (IAB10-3)',
    value: 'IAB10-3',
  },
  {
    label: 'Gardening (IAB10-4)',
    value: 'IAB10-4',
  },
  {
    label: 'Home Repair (IAB10-5)',
    value: 'IAB10-5',
  },
  {
    label: 'Home Theater (IAB10-6)',
    value: 'IAB10-6',
  },
  {
    label: 'Interior Decorating (IAB10-7)',
    value: 'IAB10-7',
  },
  {
    label: 'Landscaping (IAB10-8)',
    value: 'IAB10-8',
  },
  {
    label: 'Remodeling & Construction (IAB10-9)',
    value: 'IAB10-9',
  },
  {
    label: 'Law, Gov’t & Politics (IAB11)',
    value: 'IAB11',
  },
  {
    label: 'Immigration (IAB11-1)',
    value: 'IAB11-1',
  },
  {
    label: 'Legal Issues (IAB11-2)',
    value: 'IAB11-2',
  },
  {
    label: 'U.S. Government Resources (IAB11-3)',
    value: 'IAB11-3',
  },
  {
    label: 'Politics (IAB11-4)',
    value: 'IAB11-4',
  },
  {
    label: 'Commentary (IAB11-5)',
    value: 'IAB11-5',
  },
  {
    label: 'News (IAB12)',
    value: 'IAB12',
  },
  {
    label: 'International News (IAB12-1)',
    value: 'IAB12-1',
  },
  {
    label: 'National News (IAB12-2)',
    value: 'IAB12-2',
  },
  {
    label: 'Local News (IAB12-3)',
    value: 'IAB12-3',
  },
  {
    label: 'Personal Finance (IAB13)',
    value: 'IAB13',
  },
  {
    label: 'Beginning Investing (IAB13-1)',
    value: 'IAB13-1',
  },
  {
    label: 'Credit/Debt & Loans (IAB13-2)',
    value: 'IAB13-2',
  },
  {
    label: 'Financial News (IAB13-3)',
    value: 'IAB13-3',
  },
  {
    label: 'Financial Planning (IAB13-4)',
    value: 'IAB13-4',
  },
  {
    label: 'Hedge Fund (IAB13-5)',
    value: 'IAB13-5',
  },
  {
    label: 'Insurance (IAB13-6)',
    value: 'IAB13-6',
  },
  {
    label: 'Investing (IAB13-7)',
    value: 'IAB13-7',
  },
  {
    label: 'Mutual Funds (IAB13-8)',
    value: 'IAB13-8',
  },
  {
    label: 'Options (IAB13-9)',
    value: 'IAB13-9',
  },
  {
    label: 'Retirement Planning (IAB13-10)',
    value: 'IAB13-10',
  },
  {
    label: 'Stocks (IAB13-11)',
    value: 'IAB13-11',
  },
  {
    label: 'Tax Planning (IAB13-12)',
    value: 'IAB13-12',
  },
  {
    label: 'Society (IAB14)',
    value: 'IAB14',
  },
  {
    label: 'Dating (IAB14-1)',
    value: 'IAB14-1',
  },
  {
    label: 'Divorce Support (IAB14-2)',
    value: 'IAB14-2',
  },
  {
    label: 'Gay Life (IAB14-3)',
    value: 'IAB14-3',
  },
  {
    label: 'Marriage (IAB14-4)',
    value: 'IAB14-4',
  },
  {
    label: 'Senior Living (IAB14-5)',
    value: 'IAB14-5',
  },
  {
    label: 'Teens (IAB14-6)',
    value: 'IAB14-6',
  },
  {
    label: 'Weddings (IAB14-7)',
    value: 'IAB14-7',
  },
  {
    label: 'Ethnic Specific (IAB14-8)',
    value: 'IAB14-8',
  },
  {
    label: 'Science (IAB15)',
    value: 'IAB15',
  },
  {
    label: 'Astrology (IAB15-1)',
    value: 'IAB15-1',
  },
  {
    label: 'Biology (IAB15-2)',
    value: 'IAB15-2',
  },
  {
    label: 'Chemistry (IAB15-3)',
    value: 'IAB15-3',
  },
  {
    label: 'Geology (IAB15-4)',
    value: 'IAB15-4',
  },
  {
    label: 'Paranormal Phenomena (IAB15-5)',
    value: 'IAB15-5',
  },
  {
    label: 'Physics (IAB15-6)',
    value: 'IAB15-6',
  },
  {
    label: 'Space/Astronomy (IAB15-7)',
    value: 'IAB15-7',
  },
  {
    label: 'Geography (IAB15-8)',
    value: 'IAB15-8',
  },
  {
    label: 'Botany (IAB15-9)',
    value: 'IAB15-9',
  },
  {
    label: 'Weather (IAB15-10)',
    value: 'IAB15-10',
  },
  {
    label: 'Pets (IAB16)',
    value: 'IAB16',
  },
  {
    label: 'Aquariums (IAB16-1)',
    value: 'IAB16-1',
  },
  {
    label: 'Birds (IAB16-2)',
    value: 'IAB16-2',
  },
  {
    label: 'Cats (IAB16-3)',
    value: 'IAB16-3',
  },
  {
    label: 'Dogs (IAB16-4)',
    value: 'IAB16-4',
  },
  {
    label: 'Large Animals (IAB16-5)',
    value: 'IAB16-5',
  },
  {
    label: 'Reptiles (IAB16-6)',
    value: 'IAB16-6',
  },
  {
    label: 'Veterinary Medicine (IAB16-7)',
    value: 'IAB16-7',
  },
  {
    label: 'Sports (IAB17)',
    value: 'IAB17',
  },
  {
    label: 'Auto Racing (IAB17-1)',
    value: 'IAB17-1',
  },
  {
    label: 'Baseball (IAB17-2)',
    value: 'IAB17-2',
  },
  {
    label: 'Bicycling (IAB17-3)',
    value: 'IAB17-3',
  },
  {
    label: 'Bodybuilding (IAB17-4)',
    value: 'IAB17-4',
  },
  {
    label: 'Boxing (IAB17-5)',
    value: 'IAB17-5',
  },
  {
    label: 'Canoeing/Kayaking (IAB17-6)',
    value: 'IAB17-6',
  },
  {
    label: 'Cheerleading (IAB17-7)',
    value: 'IAB17-7',
  },
  {
    label: 'Climbing (IAB17-8)',
    value: 'IAB17-8',
  },
  {
    label: 'Cricket (IAB17-9)',
    value: 'IAB17-9',
  },
  {
    label: 'Figure Skating (IAB17-10)',
    value: 'IAB17-10',
  },
  {
    label: 'Fly Fishing (IAB17-11)',
    value: 'IAB17-11',
  },
  {
    label: 'Football (IAB17-12)',
    value: 'IAB17-12',
  },
  {
    label: 'Freshwater Fishing (IAB17-13)',
    value: 'IAB17-13',
  },
  {
    label: 'Game & Fish (IAB17-14)',
    value: 'IAB17-14',
  },
  {
    label: 'Golf (IAB17-15)',
    value: 'IAB17-15',
  },
  {
    label: 'Horse Racing (IAB17-16)',
    value: 'IAB17-16',
  },
  {
    label: 'Horses (IAB17-17)',
    value: 'IAB17-17',
  },
  {
    label: 'Hunting/Shooting (IAB17-18)',
    value: 'IAB17-18',
  },
  {
    label: 'Inline Skating (IAB17-19)',
    value: 'IAB17-19',
  },
  {
    label: 'Martial Arts (IAB17-20)',
    value: 'IAB17-20',
  },
  {
    label: 'Mountain Biking (IAB17-21)',
    value: 'IAB17-21',
  },
  {
    label: 'NASCAR Racing (IAB17-22)',
    value: 'IAB17-22',
  },
  {
    label: 'Olympics (IAB17-23)',
    value: 'IAB17-23',
  },
  {
    label: 'Paintball (IAB17-24)',
    value: 'IAB17-24',
  },
  {
    label: 'Power & Motorcycles (IAB17-25)',
    value: 'IAB17-25',
  },
  {
    label: 'Pro Basketball (IAB17-26)',
    value: 'IAB17-26',
  },
  {
    label: 'Pro Ice Hockey (IAB17-27)',
    value: 'IAB17-27',
  },
  {
    label: 'Rodeo (IAB17-28)',
    value: 'IAB17-28',
  },
  {
    label: 'Rugby (IAB17-29)',
    value: 'IAB17-29',
  },
  {
    label: 'Running/Jogging (IAB17-30)',
    value: 'IAB17-30',
  },
  {
    label: 'Sailing (IAB17-31)',
    value: 'IAB17-31',
  },
  {
    label: 'Saltwater Fishing (IAB17-32)',
    value: 'IAB17-32',
  },
  {
    label: 'Scuba Diving (IAB17-33)',
    value: 'IAB17-33',
  },
  {
    label: 'Skateboarding (IAB17-34)',
    value: 'IAB17-34',
  },
  {
    label: 'Skiing (IAB17-35)',
    value: 'IAB17-35',
  },
  {
    label: 'Snowboarding (IAB17-36)',
    value: 'IAB17-36',
  },
  {
    label: 'Surfing/Bodyboarding (IAB17-37)',
    value: 'IAB17-37',
  },
  {
    label: 'Swimming (IAB17-38)',
    value: 'IAB17-38',
  },
  {
    label: 'Table Tennis/Ping-Pong (IAB17-39)',
    value: 'IAB17-39',
  },
  {
    label: 'Tennis (IAB17-40)',
    value: 'IAB17-40',
  },
  {
    label: 'Volleyball (IAB17-41)',
    value: 'IAB17-41',
  },
  {
    label: 'Walking (IAB17-42)',
    value: 'IAB17-42',
  },
  {
    label: 'Waterski/Wakeboard (IAB17-43)',
    value: 'IAB17-43',
  },
  {
    label: 'World Soccer (IAB17-44)',
    value: 'IAB17-44',
  },
  {
    label: 'Style & Fashion (IAB18)',
    value: 'IAB18',
  },
  {
    label: 'Beauty (IAB18-1)',
    value: 'IAB18-1',
  },
  {
    label: 'Body Art (IAB18-2)',
    value: 'IAB18-2',
  },
  {
    label: 'Fashion (IAB18-3)',
    value: 'IAB18-3',
  },
  {
    label: 'Jewelry (IAB18-4)',
    value: 'IAB18-4',
  },
  {
    label: 'Clothing (IAB18-5)',
    value: 'IAB18-5',
  },
  {
    label: 'Accessories (IAB18-6)',
    value: 'IAB18-6',
  },
  {
    label: 'Technology & Computing (IAB19)',
    value: 'IAB19',
  },
  {
    label: '3-D Graphics (IAB19-1)',
    value: 'IAB19-1',
  },
  {
    label: 'Animation (IAB19-2)',
    value: 'IAB19-2',
  },
  {
    label: 'Antivirus Software (IAB19-3)',
    value: 'IAB19-3',
  },
  {
    label: 'C/C++ (IAB19-4)',
    value: 'IAB19-4',
  },
  {
    label: 'Cameras & Camcorders (IAB19-5)',
    value: 'IAB19-5',
  },
  {
    label: 'Cell Phones (IAB19-6)',
    value: 'IAB19-6',
  },
  {
    label: 'Computer Certification (IAB19-7)',
    value: 'IAB19-7',
  },
  {
    label: 'Computer Networking (IAB19-8)',
    value: 'IAB19-8',
  },
  {
    label: 'Computer Peripherals (IAB19-9)',
    value: 'IAB19-9',
  },
  {
    label: 'Computer Reviews (IAB19-10)',
    value: 'IAB19-10',
  },
  {
    label: 'Data Centers (IAB19-11)',
    value: 'IAB19-11',
  },
  {
    label: 'Databases (IAB19-12)',
    value: 'IAB19-12',
  },
  {
    label: 'Desktop Publishing (IAB19-13)',
    value: 'IAB19-13',
  },
  {
    label: 'Desktop Video (IAB19-14)',
    value: 'IAB19-14',
  },
  {
    label: 'Email (IAB19-15)',
    value: 'IAB19-15',
  },
  {
    label: 'Graphics Software (IAB19-16)',
    value: 'IAB19-16',
  },
  {
    label: 'Home Video/DVD (IAB19-17)',
    value: 'IAB19-17',
  },
  {
    label: 'Internet Technology (IAB19-18)',
    value: 'IAB19-18',
  },
  {
    label: 'Java (IAB19-19)',
    value: 'IAB19-19',
  },
  {
    label: 'JavaScript (IAB19-20)',
    value: 'IAB19-20',
  },
  {
    label: 'Mac Support (IAB19-21)',
    value: 'IAB19-21',
  },
  {
    label: 'MP3/MIDI (IAB19-22)',
    value: 'IAB19-22',
  },
  {
    label: 'Net Conferencing (IAB19-23)',
    value: 'IAB19-23',
  },
  {
    label: 'Net for Beginners (IAB19-24)',
    value: 'IAB19-24',
  },
  {
    label: 'Network Security (IAB19-25)',
    value: 'IAB19-25',
  },
  {
    label: 'Palmtops/PDAs (IAB19-26)',
    value: 'IAB19-26',
  },
  {
    label: 'PC Support (IAB19-27)',
    value: 'IAB19-27',
  },
  {
    label: 'Portable (IAB19-28)',
    value: 'IAB19-28',
  },
  {
    label: 'Entertainment (IAB19-29)',
    value: 'IAB19-29',
  },
  {
    label: 'Shareware/Freeware (IAB19-30)',
    value: 'IAB19-30',
  },
  {
    label: 'Unix (IAB19-31)',
    value: 'IAB19-31',
  },
  {
    label: 'Visual Basic (IAB19-32)',
    value: 'IAB19-32',
  },
  {
    label: 'Web Clip Art (IAB19-33)',
    value: 'IAB19-33',
  },
  {
    label: 'Web Design/HTML (IAB19-34)',
    value: 'IAB19-34',
  },
  {
    label: 'Web Search (IAB19-35)',
    value: 'IAB19-35',
  },
  {
    label: 'Windows (IAB19-36)',
    value: 'IAB19-36',
  },
  {
    label: 'Travel (IAB20)',
    value: 'IAB20',
  },
  {
    label: 'Adventure Travel (IAB20-1)',
    value: 'IAB20-1',
  },
  {
    label: 'Africa (IAB20-2)',
    value: 'IAB20-2',
  },
  {
    label: 'Air Travel (IAB20-3)',
    value: 'IAB20-3',
  },
  {
    label: 'Australia & New Zealand (IAB20-4)',
    value: 'IAB20-4',
  },
  {
    label: 'Bed & Breakfasts (IAB20-5)',
    value: 'IAB20-5',
  },
  {
    label: 'Budget Travel (IAB20-6)',
    value: 'IAB20-6',
  },
  {
    label: 'Business Travel (IAB20-7)',
    value: 'IAB20-7',
  },
  {
    label: 'By US Locale (IAB20-8)',
    value: 'IAB20-8',
  },
  {
    label: 'Camping (IAB20-9)',
    value: 'IAB20-9',
  },
  {
    label: 'Canada (IAB20-10)',
    value: 'IAB20-10',
  },
  {
    label: 'Caribbean (IAB20-11)',
    value: 'IAB20-11',
  },
  {
    label: 'Cruises (IAB20-12)',
    value: 'IAB20-12',
  },
  {
    label: 'Eastern Europe (IAB20-13)',
    value: 'IAB20-13',
  },
  {
    label: 'Europe (IAB20-14)',
    value: 'IAB20-14',
  },
  {
    label: 'France (IAB20-15)',
    value: 'IAB20-15',
  },
  {
    label: 'Greece (IAB20-16)',
    value: 'IAB20-16',
  },
  {
    label: 'Honeymoons/Getaways (IAB20-17)',
    value: 'IAB20-17',
  },
  {
    label: 'Hotels (IAB20-18)',
    value: 'IAB20-18',
  },
  {
    label: 'Italy (IAB20-19)',
    value: 'IAB20-19',
  },
  {
    label: 'Japan (IAB20-20)',
    value: 'IAB20-20',
  },
  {
    label: 'Mexico & Central America (IAB20-21)',
    value: 'IAB20-21',
  },
  {
    label: 'National Parks (IAB20-22)',
    value: 'IAB20-22',
  },
  {
    label: 'South America (IAB20-23)',
    value: 'IAB20-23',
  },
  {
    label: 'Spas (IAB20-24)',
    value: 'IAB20-24',
  },
  {
    label: 'Theme Parks (IAB20-25)',
    value: 'IAB20-25',
  },
  {
    label: 'Traveling with Kids (IAB20-26)',
    value: 'IAB20-26',
  },
  {
    label: 'United Kingdom (IAB20-27)',
    value: 'IAB20-27',
  },
  {
    label: 'Real Estate (IAB21)',
    value: 'IAB21',
  },
  {
    label: 'Apartments (IAB21-1)',
    value: 'IAB21-1',
  },
  {
    label: 'Architects (IAB21-2)',
    value: 'IAB21-2',
  },
  {
    label: 'Buying/Selling Homes (IAB21-3)',
    value: 'IAB21-3',
  },
  {
    label: 'Shopping (IAB22)',
    value: 'IAB22',
  },
  {
    label: 'Contests & Freebies (IAB22-1)',
    value: 'IAB22-1',
  },
  {
    label: 'Couponing (IAB22-2)',
    value: 'IAB22-2',
  },
  {
    label: 'Comparison (IAB22-3)',
    value: 'IAB22-3',
  },
  {
    label: 'Engines (IAB22-4)',
    value: 'IAB22-4',
  },
  {
    label: 'Religion & Spirituality (IAB23)',
    value: 'IAB23',
  },
  {
    label: 'Alternative Religions (IAB23-1)',
    value: 'IAB23-1',
  },
  {
    label: 'Atheism/Agnosticism (IAB23-2)',
    value: 'IAB23-2',
  },
  {
    label: 'Buddhism (IAB23-3)',
    value: 'IAB23-3',
  },
  {
    label: 'Catholicism (IAB23-4)',
    value: 'IAB23-4',
  },
  {
    label: 'Christianity (IAB23-5)',
    value: 'IAB23-5',
  },
  {
    label: 'Hinduism (IAB23-6)',
    value: 'IAB23-6',
  },
  {
    label: 'Islam (IAB23-7)',
    value: 'IAB23-7',
  },
  {
    label: 'Judaism (IAB23-8)',
    value: 'IAB23-8',
  },
  {
    label: 'Latter-Day Saints (IAB23-9)',
    value: 'IAB23-9',
  },
  {
    label: 'Pagan/Wiccan (IAB23-10)',
    value: 'IAB23-10',
  },
  {
    label: 'Uncategorized (IAB24)',
    value: 'IAB24',
  },
  {
    label: 'Non-Standard Content (IAB25)',
    value: 'IAB25',
  },
  {
    label: 'Unmoderated UGC (IAB25-1)',
    value: 'IAB25-1',
  },
  {
    label: 'Extreme Graphic/Explicit Violence (IAB25-2)',
    value: 'IAB25-2',
  },
  {
    label: 'Pornography (IAB25-3)',
    value: 'IAB25-3',
  },
  {
    label: 'Profane Content (IAB25-4)',
    value: 'IAB25-4',
  },
  {
    label: 'Hate Content (IAB25-5)',
    value: 'IAB25-5',
  },
  {
    label: 'Under Construction (IAB25-6)',
    value: 'IAB25-6',
  },
  {
    label: 'Incentivized (IAB25-7)',
    value: 'IAB25-7',
  },
  {
    label: 'Illegal Content (IAB26)',
    value: 'IAB26',
  },
  {
    label: 'Illegal Content (IAB26-1)',
    value: 'IAB26-1',
  },
  {
    label: 'Warez (IAB26-2)',
    value: 'IAB26-2',
  },
  {
    label: 'Spyware/Malware (IAB26-3)',
    value: 'IAB26-3',
  },
  {
    label: 'Copyright Infringement (IAB26-4)',
    value: 'IAB26-4',
  },
];
export const IAB_MAP = _.keyBy(IAB_OPTIONS, (IAB_OPTION) => IAB_OPTION.value);

export const DATETIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';
export const DATETIME_FORMAT_DETAIL = 'YYYY-MM-DD HH:mm:ssZ';

export const PRICING_MODEL_TYPE_OPTIONS = [
  {
    label: 'PLATFORM_CUSTOM',
    value: 'PLATFORM_CUSTOM',
  },
];

export const REVIEW_TYPE = {
  UNREVIEWED: 'unreviewed',
  APPROVED: 'approved',
  BLOCKED: 'blocked',
};

export const EXCHANGE_BLOCK_TYPE = {
  ADVERTISERS: 'advertisers',
  CATEGORIES: 'categories',
};

export const APP_DETAIL_TYPE = {
  APP_INFO: 'APP_INFO',
  AD_UNITS: 'AD_UNITS',
};

export const AD_UNIT_DETAIL_TYPE = {
  ADUNIT_INFO: 'ADUNIT_INFO',
};

export const PUBLISHER_BLOCK_TYPE = {
  ADVERTISERS: 'advertisers',
  CATEGORIES: 'categories',
};

export const ENTITY_TYPES = {
  ADVERTISER: 'ADVERTISER',
  AUDIENCE: 'AUDIENCE',
  AD_GROUP: 'AD_GROUP',
  AD_UNIT: 'AD_UNIT',
  CAMPAIGN: 'CAMPAIGN',
  CREATIVE_GROUP: 'CREATIVE_GROUP',
  CUSTOMER_ACTIVITY: 'CUSTOMER_ACTIVITY',
  CUSTOMER_SET: 'CUSTOMER_SET',
  PRODUCT: 'PRODUCT',
  TRACKING_LINK: 'TRACKING_LINK',
};

export const REPORT_PERIOD = {
  TODAY: 'TODAY',
  YESTERDAY: 'YESTERDAY',
  LAST7DAYS: 'LAST7DAYS',
  LAST30DAYS: 'LAST30DAYS',
};

export const REPORT_PERIOD_OPTIONS = [
  {
    id: REPORT_PERIOD.TODAY,
    label: 'Today',
  },
  {
    id: REPORT_PERIOD.YESTERDAY,
    label: 'Yesterday',
  },
  {
    id: REPORT_PERIOD.LAST7DAYS,
    label: 'Last 7days',
  },
  {
    id: REPORT_PERIOD.LAST30DAYS,
    label: 'Last 30days',
  },
];

export const OS_VERSIONS = {
  ALL: 'ALL',
  Latest: 'Latest',
  ANDROID: {
    '2.0': '2.0',
    '2.1': '2.1',
    '2.2': '2.2',
    '2.3': '2.3',
    '3.0': '3.0',
    '3.1': '3.1',
    '4.0': '4.0',
    '4.1': '4.1',
    '4.2': '4.2',
    '4.3': '4.3',
    '4.4': '4.4',
    '5.0': '5.0',
    '5.1': '5.1',
    '6.0': '6.0',
    '7.0': '7.0',
    '7.1': '7.1',
    '8.0': '8.0',
    '8.1': '8.1',
    '9.0': '9.0',
    '10.0': '10.0',
  },
  IOS: {
    '2.0': '2.0',
    '2.1': '2.1',
    '2.2': '2.2',
    '3.0': '3.0',
    '3.1': '3.1',
    '3.2': '3.2',
    '4.0': '4.0',
    '4.1': '4.1',
    '4.2': '4.2',
    '4.3': '4.3',
    '5.0': '5.0',
    '5.1': '5.1',
    '6.0': '6.0',
    '6.1': '6.1',
    '7.0': '7.0',
    '7.1': '7.1',
    '8.0': '8.0',
    '8.1': '8.1',
    '8.2': '8.2',
    '8.3': '8.3',
    '8.4': '8.4',
    '9.0': '9.0',
    '9.1': '9.1',
    '9.2': '9.2',
    '9.3': '9.3',
    '10.0': '10.0',
    '10.1': '10.1',
    '10.2': '10.2',
    '10.3': '10.3',
    '11.0': '11.0',
    '11.1': '11.1',
    '11.2': '11.2',
    '11.3': '11.3',
    '11.4': '11.4',
    '12.0': '12.0',
    '12.1': '12.1',
    '12.2': '12.2',
    '12.3': '12.3',
    '12.4': '12.4',
    '13.0': '13.0',
    '13.1': '13.1',
    '13.2': '13.2',
    '13.3': '13.3',
  },
};
