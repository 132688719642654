import { ImageResource } from './imageResource';
import { MICreativeProto } from '../proto/creative';
import { MECreativeType } from '../types';
import { MICreativeImageSrcProto } from '../proto/creativeSource';

// eslint-disable-next-line import/prefer-default-export
export class ImageCreative {
  public proto: MICreativeProto;
  public id: string;
  public adAccountId: string;
  public title: string;
  public type: MECreativeType;
  public originalFilename: string;
  public sizeInBytes: number;
  public image: ImageResource;
  public createdAt: string;
  public updatedAt: string;

  constructor(proto: MICreativeProto) {
    this.proto = proto;

    // Basic proto message part
    this.id = proto.id ?? '';
    this.adAccountId = proto.ad_account_id ?? '';
    this.title = proto.title ?? '';
    this.type = proto.type ?? MECreativeType.IMAGE;
    this.originalFilename = proto.original_filename ?? '';
    this.sizeInBytes = proto.size_in_bytes ?? 0;
    this.image = ImageResource.fromProto(proto.image ?? ({} as MICreativeImageSrcProto));
    this.image.originalFilename = this.originalFilename;
    this.createdAt = proto.created_at ?? '';
    this.updatedAt = proto.updated_at ?? '';
  }

  static fromProto = (proto: MICreativeProto) => {
    return new ImageCreative(proto);
  };

  static toProto = (uiObject: ImageCreative) => {
    const proto = uiObject.proto;

    proto.title = uiObject.title;
    proto.type = uiObject.type;

    if (uiObject.image) {
      proto.original_filename = uiObject.image.originalFilename; // Put back originalFilename to creative level.
      proto.image = ImageResource.toProto(uiObject.image);
    } else {
      proto.original_filename = '';
      delete proto.image;
    }

    proto.size_in_bytes = uiObject.sizeInBytes;

    return proto;
  };
}
