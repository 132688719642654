import _ from 'lodash';

const globalStorage = {};

export const STORE_KEY = {
  USER_SPACE: 'USER_SPACE',
  PLATFORM: 'PLATFORM',
  AUTH_TOKEN: 'AUTH_TOKEN',
  PLATFORM_SETTING: 'PLATFORM_SETTING',
  FEATURE_CONFIG: 'FEATURE_CONFIG',
};

const GlobalStore = {
  add: (key, item) => _.set(globalStorage, key, item),
  get: (key) => _.get(globalStorage, key, null),
};

Object.freeze(GlobalStore);
export default GlobalStore;
