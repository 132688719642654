import React from 'react';
import bugsnag from '@bugsnag/js';
import bugsnagReact from '@bugsnag/plugin-react';

import deployEnv from '../constants/deployEnv';

export default bugsnag(
  deployEnv.isProd()
    ? {
        apiKey: 'c3aa0a1af3df98c6f00a611f6babce27',
        releaseStage: 'prod',
      }
    : {
        apiKey: '7b8c91d437d4ad6ba7a5b413691cf1d7',
        releaseStage: 'dev',
      },
).use(bugsnagReact, React);
