import { combineReducers } from 'redux';
import adGroupsReducer from './adGroups/reducer';

import advertiserReportSummaryReducer from './advertiserReportSummary/reducer';
import advertisersReducer from './advertisers/reducer';
import audiencesReducer from './audiences/reducer';
import creativeReviewsReducer from './creativeReviews/reducer.ts';
import creativesReducer from './creatives/reducer.ts';
import creativeGroupsReducer from './creativeGroups/reducer';
import customerActivitiesReducer from './customerActivities/reducer';
import customerSetsReducer from './customerSets/reducer';
import entityLogsReducer from './entityLogs/reducer';
import pricingModelsReducer from './pricingModels/reducer';
import productsReducer from './products/reducer';
import campaignsReducer from './campaigns/reducer';
import reportSummaryReducer from './reportSummary/reducer';
import trackingLinksReducer from './trackingLinks/reducer';
import trackingLinkTestsReducer from './trackingLinkTests/reducer';
import logDataReducer from './logData/reducer';
import operationItemReducer from './operationItem/reducer';
import progressReducer from './progress/reducer.ts';
import reportExportReducer from './reportExport/reducer';

const campaignManagerReducer = combineReducers({
  advertiserReportSummary: advertiserReportSummaryReducer,
  adGroups: adGroupsReducer,
  advertisers: advertisersReducer,
  audiences: audiencesReducer,
  campaigns: campaignsReducer,
  creatives: creativesReducer,
  creativeGroups: creativeGroupsReducer,
  creativeReviews: creativeReviewsReducer,
  customerActivities: customerActivitiesReducer,
  customerSets: customerSetsReducer,
  entityLogs: entityLogsReducer,
  logData: logDataReducer,
  pricingModels: pricingModelsReducer,
  products: productsReducer,
  reportSummary: reportSummaryReducer,
  trackingLinks: trackingLinksReducer,
  trackingLinkTests: trackingLinkTestsReducer,
  operationItems: operationItemReducer,
  progress: progressReducer,
  reportExport: reportExportReducer,
});

export default campaignManagerReducer;
