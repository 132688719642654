import _ from 'lodash';
import add from 'date-fns/add';
import isAfter from 'date-fns/isAfter';
import parseISO from 'date-fns/parseISO';

const TOKEN_EXPIRE_TIME = 30 * 60; // seconds;

// eslint-disable-next-line import/prefer-default-export
export class TokenCache {
  constructor(userSpaceId, platformId, userId, email, displayName, token, availablePlatforms) {
    this.userSpaceId = userSpaceId;
    this.platformId = platformId;
    this.userId = userId;
    this.email = email;
    this.displayName = displayName;
    this.token = token;
    this.availablePlatforms = availablePlatforms;
    this.expireTime = add(new Date(), { seconds: TOKEN_EXPIRE_TIME });
  }

  toJson() {
    return {
      userSpaceId: this.userSpaceId,
      platformId: this.platformId,
      userId: this.userId,
      email: this.email,
      displayName: this.displayName,
      token: this.token,
      availablePlatforms: this.availablePlatforms,
      expireTime: this.expireTime.toISOString(),
    };
  }

  isExpired() {
    return isAfter(new Date(), this.expireTime);
  }

  static fromJson(object) {
    const t = new TokenCache();
    t.userSpaceId = _.get(object, 'userSpaceId', '');
    t.platformId = _.get(object, 'platformId', '');
    t.userId = _.get(object, 'userId', '');
    t.token = _.get(object, 'token', '');
    t.email = _.get(object, 'email', '');
    t.displayName = _.get(object, 'displayName', '');
    t.availablePlatforms = _.get(object, 'availablePlatforms', []);
    t.expireTime = parseISO(_.get(object, 'expireTime', new Date().toISOString()));

    if (!_.every([t.userSpaceId, t.platformId, t.userId, t.displayName, t.token, t.email], Boolean))
      return null;

    return t;
  }
}
