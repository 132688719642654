import _ from 'lodash';
import PropTypes from 'prop-types';
import { toUIObjectDefault, fromUIObjectDefault } from '../utils/modelUtils';

export class Exchange {
  constructor(object) {
    this.id = _.get(object, 'id', '');
    this.title = _.get(object, 'title', '');
    this.description = _.get(object, 'description', '');
    this.bcat = _.get(object, 'bcat', []);
    this.badv = _.get(object, 'badv', []);
    this.proto = _.get(object, 'proto', null);
  }

  static toUIObject = (proto) => {
    const object = toUIObjectDefault(proto, Exchange);
    return object;
  };

  static fromUIObject = (object) => {
    const proto = fromUIObjectDefault(object, Exchange);
    return proto;
  };
}

export const ExchangePropTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  bcat: PropTypes.array,
  badv: PropTypes.array,
  bapp: PropTypes.array,
};
