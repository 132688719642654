/*
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here. We prefix them with `componentName` so we avoid
 * reducers accidentally picking up actions they shouldn`t.
 *
 * Follow this format:
 * export const YOUR_ACTION_CONSTANT = `YourContainer/YOUR_ACTION_CONSTANT`;
 */

import { ACTION_TYPE_INVENTORY_MANAGER } from '../types';

const ACTION_TYPE_PUBLISHER_APP = 'publisherApp';

export const LIST_PUBLISHER_APP = `${ACTION_TYPE_INVENTORY_MANAGER}/${ACTION_TYPE_PUBLISHER_APP}/LIST_PUBLISHER_APP`;
export const LIST_PUBLISHER_APP_SUCCESS = `${ACTION_TYPE_INVENTORY_MANAGER}/${ACTION_TYPE_PUBLISHER_APP}/LIST_PUBLISHER_APP_SUCCESS`;
export const LIST_PUBLISHER_APP_ERROR = `${ACTION_TYPE_INVENTORY_MANAGER}/${ACTION_TYPE_PUBLISHER_APP}/LIST_PUBLISHER_APP_ERROR`;

export const GET_PUBLISHER_APP = `${ACTION_TYPE_INVENTORY_MANAGER}/${ACTION_TYPE_PUBLISHER_APP}/GET_PUBLISHER_APP`;
export const GET_PUBLISHER_APP_SUCCESS = `${ACTION_TYPE_INVENTORY_MANAGER}/${ACTION_TYPE_PUBLISHER_APP}/GET_PUBLISHER_APP_SUCCESS`;
export const GET_PUBLISHER_APP_ERROR = `${ACTION_TYPE_INVENTORY_MANAGER}/${ACTION_TYPE_PUBLISHER_APP}/GET_PUBLISHER_APP_ERROR`;

export const SAVE_PUBLISHER_APP = `${ACTION_TYPE_INVENTORY_MANAGER}/${ACTION_TYPE_PUBLISHER_APP}/SAVE_PUBLISHER_APP`;
export const SAVE_PUBLISHER_APP_SUCCESS = `${ACTION_TYPE_INVENTORY_MANAGER}/${ACTION_TYPE_PUBLISHER_APP}/SAVE_PUBLISHER_APP_SUCCESS`;
export const SAVE_PUBLISHER_APP_ERROR = `${ACTION_TYPE_INVENTORY_MANAGER}/${ACTION_TYPE_PUBLISHER_APP}/SAVE_PUBLISHER_APP_ERROR`;

export const DELETE_PUBLISHER_APP = `${ACTION_TYPE_INVENTORY_MANAGER}/${ACTION_TYPE_PUBLISHER_APP}/DELETE_PUBLISHER_APP`;
export const DELETE_PUBLISHER_APP_SUCCESS = `${ACTION_TYPE_INVENTORY_MANAGER}/${ACTION_TYPE_PUBLISHER_APP}/DELETE_PUBLISHER_APP_SUCCESS`;
export const DELETE_PUBLISHER_APP_ERROR = `${ACTION_TYPE_INVENTORY_MANAGER}/${ACTION_TYPE_PUBLISHER_APP}/DELETE_PUBLISHER_APP_ERROR`;
