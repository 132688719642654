/*
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here. We prefix them with 'componentName' so we avoid
 * reducers accidentally picking up actions they shouldn't.
 *
 * Follow this format:
 * export const YOUR_ACTION_CONSTANT = 'YourContainer/YOUR_ACTION_CONSTANT';
 */

export const ACTION_TYPE_AD_ACCOUNT_USERS = 'adAccountUsers';

export const LIST_USERS = `${ACTION_TYPE_AD_ACCOUNT_USERS}/LIST_USERS`;
export const LIST_USERS_SUCCESS = `${ACTION_TYPE_AD_ACCOUNT_USERS}/LIST_USERS_SUCCESS`;
export const LIST_USERS_ERROR = `${ACTION_TYPE_AD_ACCOUNT_USERS}/LIST_USERS_ERROR`;

export const GET_USER = `${ACTION_TYPE_AD_ACCOUNT_USERS}/GET_USER`;
export const GET_USER_SUCCESS = `${ACTION_TYPE_AD_ACCOUNT_USERS}/GET_USER_SUCCESS`;
export const GET_USER_ERROR = `${ACTION_TYPE_AD_ACCOUNT_USERS}/GET_USER_ERROR`;

export const SAVE_USER = `${ACTION_TYPE_AD_ACCOUNT_USERS}/SAVE_USER`;
export const SAVE_USER_SUCCESS = `${ACTION_TYPE_AD_ACCOUNT_USERS}/SAVE_USER_SUCCESS`;
export const SAVE_USER_ERROR = `${ACTION_TYPE_AD_ACCOUNT_USERS}/SAVE_USER_ERROR`;

export const DELETE_USER = `${ACTION_TYPE_AD_ACCOUNT_USERS}/DELETE_USER`;
export const DELETE_USER_SUCCESS = `${ACTION_TYPE_AD_ACCOUNT_USERS}/DELETE_USER_SUCCESS`;
export const DELETE_USER_ERROR = `${ACTION_TYPE_AD_ACCOUNT_USERS}/DELETE_USER_ERROR`;
