import produce from 'immer';
import _ from 'lodash';

import {
  LIST_CAMPAIGN_SUCCESS,
  LIST_CAMPAIGN_REQUEST,
  LIST_CAMPAIGN_ERROR,
  GET_CAMPAIGN_SUCCESS,
  GET_CAMPAIGN_ERROR,
  GET_CAMPAIGN_REQUEST,
  SAVE_CAMPAIGN_REQUEST,
  SAVE_CAMPAIGN_SUCCESS,
  SAVE_CAMPAIGN_ERROR,
  CHANGE_CAMPAIGN_CONTEXT,
} from './types';

// The initial state of the Campaigns
export const initialState = {
  loading: false,
  updating: false,
  loadError: null,
  updateError: null,
  lastListRequestTs: {},
  lastEntityRequestTs: {},
  entities: {},
  currentCampaign: null,
};

function campaignsReducer(state = initialState, action) {
  // eslint-disable-next-line consistent-return
  return produce(state, (draft) => {
    // eslint-disable-next-line default-case
    switch (action.type) {
      case CHANGE_CAMPAIGN_CONTEXT: {
        const { advertiserId, productId, campaignId } = action.payload;

        draft.currentCampaign = _.get(draft.entities, [advertiserId, productId, campaignId], null);
        break;
      }

      case LIST_CAMPAIGN_REQUEST: {
        const { advertiserId, productId } = action.payload;

        draft.loading = true;
        draft.loadError = null;
        _.set(draft.lastListRequestTs, [advertiserId, productId], Date.now());
        break;
      }

      case LIST_CAMPAIGN_SUCCESS: {
        const { advertiserId, productId, campaigns } = action.payload;
        const keyMap = _.keyBy(campaigns, 'id');

        draft.loading = false;
        _.set(draft.entities, [advertiserId, productId], keyMap);

        if (draft.currentCampaign && _.has(keyMap, draft.currentCampaign.id)) {
          draft.currentCampaign = keyMap[draft.currentCampaign.id];
        }
        break;
      }
      case LIST_CAMPAIGN_ERROR: {
        const { error } = action.payload;

        draft.loading = false;
        draft.loadError = error;
        break;
      }
      case GET_CAMPAIGN_REQUEST: {
        const { advertiserId, productId, campaignId } = action.payload;

        draft.loading = true;
        draft.loadError = null;
        _.set(draft.lastEntityRequestTs, [advertiserId, productId, campaignId], Date.now());
        break;
      }
      case GET_CAMPAIGN_SUCCESS: {
        const { advertiserId, productId, campaign } = action.payload;

        draft.loading = false;
        _.set(draft.entities, [advertiserId, productId, campaign.id], campaign);

        if (draft.currentCampaign && draft.currentCampaign.id === campaign.id) {
          draft.currentCampaign = campaign;
        }
        break;
      }
      case GET_CAMPAIGN_ERROR: {
        const { error } = action.payload;

        draft.loading = false;
        draft.loadError = error;
        break;
      }
      case SAVE_CAMPAIGN_REQUEST: {
        draft.updating = true;
        draft.updateError = null;
        break;
      }
      case SAVE_CAMPAIGN_SUCCESS: {
        const { advertiserId, productId, campaign } = action.payload;

        draft.updating = false;
        draft.updateError = null;
        _.set(draft.entities, [advertiserId, productId, campaign.id], campaign);

        if (draft.currentCampaign && draft.currentCampaign.id === campaign.id) {
          draft.currentCampaign = campaign;
        }
        break;
      }
      case SAVE_CAMPAIGN_ERROR: {
        const { error } = action.payload;
        draft.updating = false;
        draft.updateError = error;
        break;
      }
      default:
        break;
    }
  });
}

export default campaignsReducer;
