import React from 'react';
import styled, { css } from 'styled-components';
import MUICheckIcon from '@material-ui/icons/Check';
import MUIEditIcon from '@material-ui/icons/Edit';

import { FORM_WIDTH, MEFormState, MEFormFieldWidth } from './consts';
import MCLoader from '../MCLoader';

// icon that indicates if the form is editing or completed
export type MTFormStateIconProps = {
  state: MEFormState;
  className?: string; // for styled function
};

const SCFormStateIconWrapper = styled.div<MTFormStateIconProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.4em;
  height: 1.4em;
  border-radius: 1em;
  font-size: 1em;
  color: #fff;
  margin-right: 0.6em;
  background-color: ${(props) => (props.state === MEFormState.COMPLETE ? '#4e7fed' : '#a0a0a0')};

  & svg {
    font-size: 1em;
  }
`;

export const MCFormStateIcon = ({ state, ...rest }: MTFormStateIconProps) => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SCFormStateIconWrapper state={state} {...rest}>
      {state === MEFormState.COMPLETE ? <MUICheckIcon /> : <MUIEditIcon />}
    </SCFormStateIconWrapper>
  );
};

// form containers
export const MCFormActionBar = styled.div`
  display: flex;
  justify-content: flex-end;
  max-width: ${FORM_WIDTH};
  padding: ${(props) => props.theme.spacing(1)}px 0 0 0;
`;

export const MCFormPopoverActionBar = styled(MCFormActionBar)`
  padding: ${(props) => props.theme.spacing(2)}px ${(props) => props.theme.spacing(2)}px;
`;

export const MCFormFieldGroupTitle = styled.h3`
  font-size: 14px;
  font-weight: bold;
  margin: 0;
`;

export const MCFormBoxTitle = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  margin: 0;
`;

export const MCFormSection = styled.div`
  border-bottom: 1px solid #bbbbbb;
  &:last-child {
    border-bottom: 0;
  }

  /* action bar inside section */
  & ${MCFormActionBar} {
    padding: ${(props) => props.theme.spacing(2)}px;
  }
`;

export const MCFormBoxHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 6px 0 ${(props) => props.theme.spacing(2)}px;
  height: 50px;

  /* apply border-top to the first MCFormSection with MCFormBoxHeader */
  & + ${MCFormSection}:first-child, & + div ${MCFormSection}:first-child {
    border-top: 1px solid #bbbbbb;
  }
`;

export const MCFormBoxBody = styled.div``;

export const MCFormBox = styled.div`
  width: ${FORM_WIDTH};
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
  color: #606060;
  background-color: #fff;
`;

export const MCFormPanel = styled.div`
  width: 100%;
  padding: 0 ${(props) => props.theme.spacing(2)}px;
  padding-bottom: ${(props) => props.theme.spacing(1.5)}px;

  &:last-child {
    padding-bottom: ${(props) => props.theme.spacing(4)}px;
  }
`;

export const MCFormPopoverPanel = styled.div`
  width: 100%;
  padding: 0;
`;

// label
export type MTFormLabelProps = {
  required?: boolean;
};

export const MCFormLabel = styled.label<MTFormLabelProps>`
  color: #494f58;
  font-size: 14px;
  font-weight: 500;
  padding-bottom: 2px;
  ${(props) =>
    props.required &&
    `
    &::after {
      content: '*';
      color: #ff0000;
    }`}
`;

// wrapper for input/select/textarea...
export const MCFormFieldBody = styled.div`
  display: flex;
  flex-direction: column;
  margin: 5px 0;
`;

const FORM_FIELD_WIDTH_MAP: Record<MEFormFieldWidth, string> = {
  [MEFormFieldWidth.SMALL]: '40%',
  [MEFormFieldWidth.MEDIUM]: '70%',
  [MEFormFieldWidth.FULL]: '100%',
  [MEFormFieldWidth.UNSET]: 'unset',
};

// wrapper for label and control
export const MCFormField = styled.div<{ width?: MEFormFieldWidth }>`
  display: flex;
  padding: ${(props) => props.theme.spacing(1)}px 0;
  font-size: 14px;
  flex-direction: column;
  &:first-child {
    margin-top: 0;
  }
  width: ${(props) =>
    props.width ? FORM_FIELD_WIDTH_MAP[props.width] : FORM_FIELD_WIDTH_MAP[MEFormFieldWidth.FULL]};
`;

export const MCFormFields = styled.div`
  padding: ${(props) => props.theme.spacing(2)}px ${(props) => props.theme.spacing(3)}px;
`;

export const MCBoxFormFields = styled.div`
  margin-top: 5px;
  border: solid 1px #e0e0e0;
  padding: ${(props) => props.theme.spacing(2)}px ${(props) => props.theme.spacing(3)}px;
`;

export type MTFormFieldGroupProps = {
  horizontal?: boolean;
};

export const MCFormFieldGroup = styled.div<MTFormFieldGroupProps>`
  display: flex;
  flex-direction: column;
  padding: ${(props) => props.theme.spacing(3)}px
    calc(${(props) => props.theme.spacing(2)}px + (16px) * 2);

  ${(props) =>
    props.horizontal &&
    `
      & ${MCFormField} {
        flex-direction: row;
      }

      & ${MCFormLabel} {
        flex: 0 1;
        flex-basis: 100px;
        margin: 0 ${props.theme.spacing(1)}px 6px 0;
      }

      & ${MCFormFieldBody} {
        flex: 1;
        margin: 0 0 3px 0;
      }
    }`}
`;

export const MCHorizontalFormFieldGroup = styled.div`
  display: flex;
  flex-direction: row;

  & > div {
    width: 100%;
  }

  & > div:not(:last-child) {
    margin-right: ${(props) => props.theme.spacing(2)}px;
  }
`;

// input control
export const MCFormControl = styled.div`
  display: flex;
  align-items: baseline;
  position: relative;
`;

export const MCFormTextFieldPrefix = styled.div`
  position: absolute;
  width: 1em;
  bottom: 0;
  padding-bottom: 5px;
`;

export const MCFormTextFieldSuffix = styled.div`
  width: 1em;
  padding-bottom: 5px;
  margin-left: -1em;
`;

export const MCFormInput = styled.input<{ hasPrefix?: boolean; hasSuffix?: boolean }>`
  flex: 1;
  color: #212121;
  padding: 0 ${(props) => (props.hasSuffix ? '1.2' : 0)}em 6px
    ${(props) => (props.hasPrefix ? '1.2' : 0)}em;
  border: 0;
  border-bottom: 1px solid #9c9c9c;
  font-size: 14px;

  &:focus {
    outline: none;
  }

  &:hover:enabled {
    border-color: #9c9c9c;
  }

  &:focus:enabled {
    border-color: #9c9c9c;
  }

  &:disabled {
    color: #aaa;
    background: #fff;
    border-color: #ddd;
  }

  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type='number'] {
    -moz-appearance: textfield;
  }

  &::placeholder {
    color: #c2c2c2;
  }
`;

export const MCFormTextArea = styled.textarea<{ hasPrefix?: boolean }>`
  flex: 1;
  color: #212121;
  padding: 10px 10px 10px ${(props) => (props.hasPrefix ? '1em' : '10px')};
  border-radius: 2px;
  border: solid 1px #bbbbbb;
  background-color: #f5f5f5;
  resize: none;
  font-size: 14px;

  &:focus {
    outline: none;
  }

  &:hover:enabled {
    border-color: #9c9c9c;
  }

  &:focus:enabled {
    border-color: #9c9c9c;
  }

  &:disabled {
    color: #aaa;
    background: #fff;
    border-color: #ddd;
  }

  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type='number'] {
    -moz-appearance: textfield;
  }

  &::placeholder {
    color: #c2c2c2;
  }
`;

const SCCheckboxWrapper = styled.label<{ disabled?: boolean }>`
  display: block;
  position: relative;
  padding-left: 16px;
  cursor: ${(props) => (!props.disabled ? 'pointer' : 'default')};
  user-select: none;
`;

const SCCheckboxInput = styled.input`
  display: none;
  &[type='checkbox'] {
    clip-path: polygon(0 0);
  }
`;

const SCCheckMark = styled.span`
  display: inline-block;
  color: #000000;
  vertical-align: text-top;

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 16px;
    height: 16px;
    border: 2px solid #747474;
    border-radius: 2px;
    background-color: #ffffff;
  }
  &::after {
    content: '';
    display: none;
    position: absolute;
    top: 0;
    left: 5px;
    width: 6px;
    height: 12px;
    border-width: 0 2px 2px 0;
    border-style: solid;
    border-color: transparent #ffffff #ffffff transparent;
  }

  &:hover::before {
    // TODO: hover effect
  }

  // checked
  ${SCCheckboxInput}:checked + &::before {
    border-color: transparent;
    background-color: ${(props) => props.theme.palette.primary.main};
  }
  ${SCCheckboxInput}:checked + &::after {
    display: block;
    transform: rotate(40deg);
  }

  //disabled
  ${SCCheckboxInput}:disabled + &::before {
    border-color: #d2d2d2;
    background-color: #ffffff;
  }
  ${SCCheckboxInput}:disabled:checked + &::before {
    background-color: #d2d2d2;
  }
`;

type MTCheckBoxInputProps = {
  label?: string;
  disabled?: boolean;
};

export const MCCheckBoxInput = ({
  label,
  disabled,
  ...rest
}: MTCheckBoxInputProps & React.InputHTMLAttributes<HTMLInputElement>) => {
  return (
    <SCCheckboxWrapper disabled={disabled}>
      {label}
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <SCCheckboxInput type="checkbox" {...rest} disabled={disabled} />
      <SCCheckMark />
    </SCCheckboxWrapper>
  );
};

// character counter
export type MTFormCharacterCounterProps = {
  isOverflow?: boolean;
};

export const MCFormCharacterCounter = styled.div<MTFormCharacterCounterProps>`
  flex: 0 1;
  text-align: right;
  min-width: 50px;
  margin-left: ${(props) => props.theme.spacing(1)}px;
  color: ${(props) => (props.isOverflow ? 'red' : '#898989')};
  font-size: 11px;
`;

// help (hint, error)
export const MCFormFieldHelp = styled.div`
  display: flex;
  flex-direction: column;
`;

export const MCFormHint = styled.div`
  color: #828282;
  font-size: 12px;
  padding: ${(props) => props.theme.spacing(0.5)}px 0 0 0;
`;

export const MCFormError = styled.div`
  color: red;
  font-size: 12px;
  padding: ${(props) => props.theme.spacing(0.5)}px 0 0 0;
`;

export type MTRadioGroupDirection = 'column' | 'row';

export type MTRadioGroupFormControlProps = {
  direction: MTRadioGroupDirection;
};

export const MCRadioGroupFormControl = styled(MCFormControl)<MTRadioGroupFormControlProps>`
  flex-direction: ${(props) => props.direction};
  ${(props) =>
    props.direction === 'column' &&
    css`
      & label {
        margin-bottom: 1em;

        &:last-child {
          margin-bottom: 0;
        }
      }
    `}
`;

export const MCRadioLabelWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const MCRadioIcon = styled.img`
  width: 14px;
  height: 14px;

  margin-right: 3px;
`;

export const MCFormCollapsibleBoxHidden = styled.div<{ expand: boolean }>`
  display: flex;
  height: ${(props) => (props.expand ? 'auto' : 0)};
  overflow: ${(props) => (props.expand ? 'auto' : 'hidden')};
`;

const MCFormLoaderWrapper = styled.div`
  padding: ${(props) => props.theme.spacing(4)}px 0;
`;

export const MCFormLoader = () => (
  <MCFormLoaderWrapper>
    <MCLoader />
  </MCFormLoaderWrapper>
);

export const MCFormHelpMessage = styled.div`
  font-size: 11px;
  color: #838383;
`;

export const MCReadOnlyInputForm = styled.div`
  flex: 1;
  padding: 2px 8px;
  border: 1px solid #f5f5f5;
  background: #f5f5f5;
  height: 32px;
  color: #676767;
  font-size: 14px;
  display: flex;
  align-items: center;
`;
