/*

This reducer saves the progress state for each entity type.
If it is the ID of the target entity type, such as `get` and `update`, ID is used as it is for progressKey.
For the ID of the ancestor type of the target entity, such as `list` and `create`, progressKey must have the ancestor entity type as a prefix.
ex)
 - listAdAccount: progressKey === `WORKPLACE:${workplaceID}`
 - getAdAccount: progressKey === `${adAccountID}`

 */

import produce from 'immer';
import _ from 'lodash';

import { MTProgressType } from '../../../common-v2/types';

const emptyObjectByEntity: Record<MTProgressType, Record<any, any>> = {
  WORKPLACE: {},
  ADVERTISER: {},
  PRODUCT: {},
  CAMPAIGN: {},
  AD_GROUP: {},
  OPERATION_ITEM: {},
  CREATIVE_GROUP: {},
  TRACKING_LINK: {},
  TRACKING_LINK_VERIFICATION: {},
  REPORT_EXPORT: {},
  AUDIENCE: {},
  CUSTOMER_SET: {},
  CUSTOMER_ACTIVITY: {},
  LOG_TRANSFER: {},
  CREATIVE_REVIEW: {},
};

// The initial state of the progress
export const initialState = {
  loading: _.cloneDeep(emptyObjectByEntity),
  loadError: _.cloneDeep(emptyObjectByEntity),
  saving: _.cloneDeep(emptyObjectByEntity),
  saveError: _.cloneDeep(emptyObjectByEntity),
  deleting: _.cloneDeep(emptyObjectByEntity),
  deleteError: _.cloneDeep(emptyObjectByEntity),
};

// TODO: type
function progressReducer(state = initialState, action: any) {
  return produce(state, (draft) => {
    const { type } = action;

    const matches = /(LIST|GET|SAVE|DELETE)_(.*)_(REQUEST|SUCCESS|ERROR)/.exec(type);

    if (matches && action.payload) {
      const { progressKey, error } = action.payload;
      if (progressKey && progressKey.entityId) {
        const [callMethod, entityType, callState] = matches.slice(1);
        const progressKeyText = `${progressKey.entityType}:${progressKey.entityId}`;

        switch (`${callMethod}_${callState}`) {
          case 'LIST_REQUEST':
          case 'GET_REQUEST':
            _.set(draft.loading, [entityType, progressKeyText], true);
            _.unset(draft.loadError, [entityType, progressKeyText]);
            break;
          case 'LIST_SUCCESS':
          case 'GET_SUCCESS':
            _.unset(draft.loading, [entityType, progressKeyText]);
            break;
          case 'LIST_ERROR':
          case 'GET_ERROR':
            _.unset(draft.loading, [entityType, progressKeyText]);
            _.set(draft.loadError, [entityType, progressKeyText], error);
            break;
          case 'SAVE_REQUEST':
            _.set(draft.saving, [entityType, progressKeyText], true);
            _.unset(draft.saveError, [entityType, progressKeyText]);
            break;
          case 'SAVE_SUCCESS':
            _.unset(draft.saving, [entityType, progressKeyText]);
            break;
          case 'SAVE_ERROR':
            _.unset(draft.saving, [entityType, progressKeyText]);
            _.set(draft.saveError, [entityType, progressKeyText], error);
            break;
          case 'DELETE_REQUEST':
            _.set(draft.deleting, [entityType, progressKeyText], true);
            _.unset(draft.deleteError, [entityType, progressKeyText]);
            break;
          case 'DELETE_SUCCESS':
            _.unset(draft.deleting, [entityType, progressKeyText]);
            break;
          case 'DELETE_ERROR':
            _.unset(draft.deleting, [entityType, progressKeyText]);
            _.set(draft.deleteError, [entityType, progressKeyText], error);
            break;
          default:
            break;
        }
      }
    }
  });
}

export default progressReducer;
