import produce from 'immer';

import GlobalStore, { STORE_KEY } from '../../common/global/globalStorage';
import GlobalStoreV2, { STORE_KEY as STORE_KEY_V2 } from '../../common-v2/global/globalStorage';
import PlatformSettings from '../../common-v2/model/platformSettings.ts';
import { SET_PLATFORM_SETTINGS, SET_TABLE_PAGE_SIZE, TRIGGER_SUPER_USER } from './types';
import { TABLE_PAGE_SIZE } from '../../common/utils/browser_cache_keys';

function getTablePageSizeFromLocalStorage() {
  return Number(localStorage.getItem(TABLE_PAGE_SIZE)) || 10;
}

const INITIAL_STATE = {
  tablePageSize: getTablePageSizeFromLocalStorage(),
  platformSettings: new PlatformSettings(),
  superUser: false,
};

const mainReducer = (state = INITIAL_STATE, action) => {
  // eslint-disable-next-line consistent-return
  return produce(state, (draft) => {
    // eslint-disable-next-line default-case
    switch (action.type) {
      case SET_TABLE_PAGE_SIZE: {
        draft.tablePageSize = action.payload;
        break;
      }
      case SET_PLATFORM_SETTINGS: {
        const { platformSettings } = action.payload;
        draft.platformSettings = platformSettings;
        GlobalStore.add(STORE_KEY.PLATFORM_SETTING, platformSettings);
        GlobalStoreV2.add(STORE_KEY_V2.PLATFORM_SETTING, platformSettings);
        break;
      }
      case TRIGGER_SUPER_USER:
        draft.superUser = !draft.superUser;
        break;
    }
  });
};

export default mainReducer;
