/*
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here. We prefix them with 'componentName' so we avoid
 * reducers accidentally picking up actions they shouldn't.
 *
 * Follow this format:
 * export const YOUR_ACTION_CONSTANT = 'YourContainer/YOUR_ACTION_CONSTANT';
 */

import { ACTION_TYPE_CAMPAIGN_MANAGER } from '../consts.ts';

export const ACTION_TYPE_PRODUCT = 'product';

export const CHANGE_PRODUCT_CONTEXT = `${ACTION_TYPE_CAMPAIGN_MANAGER}/${ACTION_TYPE_PRODUCT}/CHANGE_PRODUCT_CONTEXT`;

export const LIST_PRODUCT_REQUEST = `${ACTION_TYPE_CAMPAIGN_MANAGER}/${ACTION_TYPE_PRODUCT}/LIST_PRODUCT_REQUEST`;
export const LIST_PRODUCT_SUCCESS = `${ACTION_TYPE_CAMPAIGN_MANAGER}/${ACTION_TYPE_PRODUCT}/LIST_PRODUCT_SUCCESS`;
export const LIST_PRODUCT_ERROR = `${ACTION_TYPE_CAMPAIGN_MANAGER}/${ACTION_TYPE_PRODUCT}/LIST_PRODUCT_ERROR`;

export const GET_PRODUCT_REQUEST = `${ACTION_TYPE_CAMPAIGN_MANAGER}/${ACTION_TYPE_PRODUCT}/GET_PRODUCT_REQUEST`;
export const GET_PRODUCT_SUCCESS = `${ACTION_TYPE_CAMPAIGN_MANAGER}/${ACTION_TYPE_PRODUCT}/GET_PRODUCT_SUCCESS`;
export const GET_PRODUCT_ERROR = `${ACTION_TYPE_CAMPAIGN_MANAGER}/${ACTION_TYPE_PRODUCT}/GET_PRODUCT_ERROR`;

export const SAVE_PRODUCT_REQUEST = `${ACTION_TYPE_CAMPAIGN_MANAGER}/${ACTION_TYPE_PRODUCT}/SAVE_PRODUCT_REQUEST`;
export const SAVE_PRODUCT_SUCCESS = `${ACTION_TYPE_CAMPAIGN_MANAGER}/${ACTION_TYPE_PRODUCT}/SAVE_PRODUCT_SUCCESS`;
export const SAVE_PRODUCT_ERROR = `${ACTION_TYPE_CAMPAIGN_MANAGER}/${ACTION_TYPE_PRODUCT}/SAVE_PRODUCT_ERROR`;

export const DELETE_PRODUCT_REQUEST = `${ACTION_TYPE_CAMPAIGN_MANAGER}/${ACTION_TYPE_PRODUCT}/DELETE_PRODUCT_REQUEST`;
export const DELETE_PRODUCT_SUCCESS = `${ACTION_TYPE_CAMPAIGN_MANAGER}/${ACTION_TYPE_PRODUCT}/DELETE_PRODUCT_SUCCESS`;
export const DELETE_PRODUCT_ERROR = `${ACTION_TYPE_CAMPAIGN_MANAGER}/${ACTION_TYPE_PRODUCT}/DELETE_PRODUCT_ERROR`;
