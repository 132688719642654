import axios, { AxiosRequestConfig } from 'axios';

import { isMCRHost } from 'common-v2/utils';
import { ADCLOUD_DSP_V1_API_PATH } from '../constants/apiEndpoints';
import GlobalStore, { STORE_KEY } from '../global/globalStorage';
// eslint-disable-next-line import/extensions
import { MEInAppEventTimeWindows, MIInAppEventCondition } from '../model/product';
import { MIAdAccountProto } from '../proto/adAccount';
import { MIAdGroupProto } from '../proto/adGroup';
import { MISharedAudienceTargetProto } from '../proto/audienceCommon';
import { MICampaignProto } from '../proto/campaign';
import { MICreativeProto } from '../proto/creative';
import { MICreativeGroupProto } from '../proto/creativeGroup';
import { MICustomerSetProto } from '../proto/customerSet';
import { MIProductProto } from '../proto/product';
import { MIReportExportProto } from '../proto/reportExport';
import { MITrackingLinkProto } from '../proto/trackingLink';
import { MEProductType } from '../types';
import { MIOperationItemProto } from '../proto/operationItem';
import { MINotificationPreferenceProto } from '../proto/notification';

function getHeader() {
  return {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${GlobalStore.get(STORE_KEY.AUTH_TOKEN)}`,
    ...(isMCRHost() ? { 'Include-Legacy': true } : {}),
  };
}

/*
  Advertiser
*/
function createAdvertiser(adAccountProto: MIAdAccountProto) {
  const config: AxiosRequestConfig = {
    method: 'POST',
    url: `${ADCLOUD_DSP_V1_API_PATH}/ad-accounts`,
    headers: getHeader(),
    data: adAccountProto,
  };
  return axios(config);
}

function getAdvertiser(adAccountId: string) {
  const config: AxiosRequestConfig = {
    method: 'GET',
    url: `${ADCLOUD_DSP_V1_API_PATH}/ad-accounts/${adAccountId}`,
    headers: getHeader(),
  };
  return axios(config);
}

function updateAdvertiser(adAccountId: string, adAccountProto: MIAdAccountProto) {
  const config: AxiosRequestConfig = {
    method: 'PUT',
    url: `${ADCLOUD_DSP_V1_API_PATH}/ad-accounts/${adAccountId}`,
    headers: getHeader(),
    data: adAccountProto,
  };
  return axios(config);
}

function deleteAdvertiser(adAccountId: string) {
  const config: AxiosRequestConfig = {
    method: 'DELETE',
    url: `${ADCLOUD_DSP_V1_API_PATH}/ad-accounts/${adAccountId}`,
    headers: getHeader(),
  };
  return axios(config);
}

function listAdvertisers() {
  const config: AxiosRequestConfig = {
    method: 'GET',
    url: `${ADCLOUD_DSP_V1_API_PATH}/ad-accounts`,
    headers: getHeader(),
  };
  return axios(config);
}

/*
  Product
*/
function listProducts(adAccountId: string) {
  const config: AxiosRequestConfig = {
    method: 'GET',
    url: `${ADCLOUD_DSP_V1_API_PATH}/products`,
    headers: getHeader(),
    params: {
      ad_account_id: adAccountId,
    },
  };
  return axios(config);
}

function getProduct(productId: string, inAppEventCondition?: MIInAppEventCondition) {
  const config: AxiosRequestConfig = {
    method: 'GET',
    url: `${ADCLOUD_DSP_V1_API_PATH}/products/${productId}`,
    headers: getHeader(),
  };

  const params = new URLSearchParams();

  if (inAppEventCondition) {
    params.append(
      'condition.time_window',
      inAppEventCondition.timeWindow || MEInAppEventTimeWindows.D30,
    );
  }
  config.params = params;

  return axios(config);
}

function createProduct(adAccountId: string, product: MIProductProto) {
  const config: AxiosRequestConfig = {
    method: 'POST',
    url: `${ADCLOUD_DSP_V1_API_PATH}/products`,
    headers: getHeader(),
    data: product,
    params: {
      ad_account_id: adAccountId,
    },
  };
  return axios(config);
}

function updateProduct(productId: string, product: MIProductProto) {
  const config: AxiosRequestConfig = {
    method: 'PUT',
    url: `${ADCLOUD_DSP_V1_API_PATH}/products/${productId}`,
    headers: getHeader(),
    data: product,
  };
  return axios(config);
}

function deleteProduct(productId: string) {
  const config: AxiosRequestConfig = {
    method: 'DELETE',
    url: `${ADCLOUD_DSP_V1_API_PATH}/products/${productId}`,
    headers: getHeader(),
  };
  return axios(config);
}

/*
  Campaigns
*/
function listCampaigns(useAbridged: boolean, adAccountId: string, productId?: string) {
  const url = useAbridged
    ? `${ADCLOUD_DSP_V1_API_PATH}/abridged-campaigns`
    : `${ADCLOUD_DSP_V1_API_PATH}/campaigns`;
  const config: AxiosRequestConfig = {
    method: 'GET',
    url,
    headers: getHeader(),
  };

  const params = new URLSearchParams();
  if (adAccountId) params.append('ad_account_id', adAccountId);
  if (productId) params.append('product_id', productId);
  config.params = params;

  return axios(config);
}

function getCampaign(
  useAbridged: boolean,
  adAccountId: string,
  productId: string,
  campaignId: string,
) {
  const url = useAbridged
    ? `${ADCLOUD_DSP_V1_API_PATH}/abridged-campaigns/${campaignId}`
    : `${ADCLOUD_DSP_V1_API_PATH}/campaigns/${campaignId}`;
  const config: AxiosRequestConfig = {
    method: 'GET',
    url,
    headers: getHeader(),
  };

  const params = new URLSearchParams();
  params.append('ad_account_id', adAccountId);
  params.append('product_id', productId);
  config.params = params;

  return axios(config);
}

function createCampaign(
  adAccountId: string,
  productId: string,
  campaign: MICampaignProto,
  adGroup: MIAdGroupProto,
  payoutItem: MIOperationItemProto,
) {
  const config: AxiosRequestConfig = {
    method: 'POST',
    url: `${ADCLOUD_DSP_V1_API_PATH}/campaigns`,
    headers: getHeader(),
    data: {
      campaign,
      ad_group: adGroup,
      payout_item: payoutItem,
    },
  };

  const params = new URLSearchParams();
  if (adAccountId) params.append('ad_account_id', adAccountId);
  if (productId) params.append('product_id', productId);
  config.params = params;

  return axios(config);
}

function updateCampaign(adAccountId: string, productId: string, campaign: MICampaignProto) {
  const config: AxiosRequestConfig = {
    method: 'PUT',
    url: `${ADCLOUD_DSP_V1_API_PATH}/campaigns/${campaign.id}`,
    headers: getHeader(),
    data: campaign,
  };

  const params = new URLSearchParams();
  if (adAccountId) params.append('ad_account_id', adAccountId);
  if (productId) params.append('product_id', productId);
  config.params = params;

  return axios(config);
}

/*
  Ad Group
*/

function getAdGroup(adAccountId: string, productId: string, campaignId: string, adGroupId: string) {
  const config: AxiosRequestConfig = {
    method: 'GET',
    url: `${ADCLOUD_DSP_V1_API_PATH}/ad-groups/${adGroupId}`,
    headers: getHeader(),
  };

  const params = new URLSearchParams();
  if (adAccountId) params.append('ad_account_id', adAccountId);
  if (productId) params.append('product_id', productId);
  if (campaignId) params.append('campaign_id', campaignId);
  config.params = params;

  return axios(config);
}

function listAdGroups(adAccountId: string, productId: string, campaignId: string) {
  const config: AxiosRequestConfig = {
    method: 'GET',
    url: `${ADCLOUD_DSP_V1_API_PATH}/ad-groups`,
    headers: getHeader(),
    params: {},
  };

  const params = new URLSearchParams();
  if (adAccountId) params.append('ad_account_id', adAccountId);
  if (productId) params.append('product_id', productId);
  if (campaignId) params.append('campaign_id', campaignId);
  config.params = params;

  return axios(config);
}

function createAdGroup(
  adAccountId: string,
  productId: string,
  campaignId: string,
  adGroup: MIAdGroupProto,
) {
  const config: AxiosRequestConfig = {
    method: 'POST',
    url: `${ADCLOUD_DSP_V1_API_PATH}/ad-groups`,
    headers: getHeader(),
    data: adGroup,
  };

  const params = new URLSearchParams();
  if (adAccountId) params.append('ad_account_id', adAccountId);
  if (productId) params.append('product_id', productId);
  if (campaignId) params.append('campaign_id', campaignId);
  config.params = params;

  return axios(config);
}

function updateAdGroup(
  adAccountId: string,
  productId: string,
  campaignId: string,
  adGroupId: string,
  adGroup: MIAdGroupProto,
) {
  const config: AxiosRequestConfig = {
    method: 'PUT',
    url: `${ADCLOUD_DSP_V1_API_PATH}/ad-groups/${adGroupId}`,
    headers: getHeader(),
    data: adGroup,
  };

  const params = new URLSearchParams();
  if (adAccountId) params.append('ad_account_id', adAccountId);
  if (productId) params.append('product_id', productId);
  if (campaignId) params.append('campaign_id', campaignId);
  config.params = params;

  return axios(config);
}

function deleteAdGroup(
  adAccountId: string,
  productId: string,
  campaignId: string,
  adGroupId: string,
) {
  const config: AxiosRequestConfig = {
    method: 'DELETE',
    url: `${ADCLOUD_DSP_V1_API_PATH}/ad-groups/${adGroupId}`,
    headers: getHeader(),
  };

  const params = new URLSearchParams();
  if (adAccountId) params.append('ad_account_id', adAccountId);
  if (productId) params.append('product_id', productId);
  if (campaignId) params.append('campaign_id', campaignId);
  config.params = params;

  return axios(config);
}

/*
  Payout Item
*/

function listPayoutItems(adAccountId: string, productId: string, campaignId: string) {
  const config: AxiosRequestConfig = {
    method: 'GET',
    url: `${ADCLOUD_DSP_V1_API_PATH}/payout-items`,
    headers: getHeader(),
  };

  const params = new URLSearchParams();
  if (adAccountId) params.append('ad_account_id', adAccountId);
  if (productId) params.append('product_id', productId);
  if (campaignId) params.append('campaign_id', campaignId);
  config.params = params;

  return axios(config);
}

function getPayoutItem(adAccountId: string, productId: string, campaignId: string, date: string) {
  const config: AxiosRequestConfig = {
    method: 'GET',
    url: `${ADCLOUD_DSP_V1_API_PATH}/payout-items/${date}`,
    headers: getHeader(),
  };

  const params = new URLSearchParams();
  if (adAccountId) params.append('ad_account_id', adAccountId);
  if (productId) params.append('product_id', productId);
  if (campaignId) params.append('campaign_id', campaignId);
  config.params = params;

  return axios(config);
}

function updatePayoutItem(
  adAccountId: string,
  productId: string,
  campaignId: string,
  payoutItem: MIOperationItemProto,
) {
  const config: AxiosRequestConfig = {
    method: 'PUT',
    url: `${ADCLOUD_DSP_V1_API_PATH}/payout-items/${payoutItem.effective_date}`,
    headers: getHeader(),
    data: payoutItem,
  };

  const params = new URLSearchParams();
  if (adAccountId) params.append('ad_account_id', adAccountId);
  if (productId) params.append('product_id', productId);
  if (campaignId) params.append('campaign_id', campaignId);
  config.params = params;

  return axios(config);
}

function createPayoutItem(
  adAccountId: string,
  productId: string,
  campaignId: string,
  payoutItem: MIOperationItemProto,
) {
  const config: AxiosRequestConfig = {
    method: 'POST',
    url: `${ADCLOUD_DSP_V1_API_PATH}/payout-items`,
    headers: getHeader(),
    data: payoutItem,
  };

  const params = new URLSearchParams();
  if (adAccountId) params.append('ad_account_id', adAccountId);
  if (productId) params.append('product_id', productId);
  if (campaignId) params.append('campaign_id', campaignId);
  config.params = params;

  return axios(config);
}

function deletePayoutItem(
  adAccountId: string,
  productId: string,
  campaignId: string,
  date: string,
) {
  const config: AxiosRequestConfig = {
    method: 'DELETE',
    url: `${ADCLOUD_DSP_V1_API_PATH}/payout-items/${date}`,
    headers: getHeader(),
  };

  const params = new URLSearchParams();
  if (adAccountId) params.append('ad_account_id', adAccountId);
  if (productId) params.append('product_id', productId);
  if (campaignId) params.append('campaign_id', campaignId);
  config.params = params;

  return axios(config);
}

/*
  CreativeGroup
 */
function createCreativeGroup(
  adAccountId: string,
  productId: string,
  creativeGroup: MICreativeGroupProto,
) {
  const config: AxiosRequestConfig = {
    method: 'POST',
    url: `${ADCLOUD_DSP_V1_API_PATH}/creative-groups`,
    headers: getHeader(),
    data: creativeGroup,
  };

  const params = new URLSearchParams();
  params.append('ad_account_id', adAccountId);
  params.append('product_id', productId);
  config.params = params;

  return axios(config);
}

function getCreativeGroup(creativeGroupId: string) {
  const config: AxiosRequestConfig = {
    method: 'GET',
    url: `${ADCLOUD_DSP_V1_API_PATH}/creative-groups/${creativeGroupId}`,
    headers: getHeader(),
  };

  return axios(config);
}

function updateCreativeGroup(creativeGroup: MICreativeGroupProto) {
  const config: AxiosRequestConfig = {
    method: 'PUT',
    url: `${ADCLOUD_DSP_V1_API_PATH}/creative-groups/${creativeGroup.id}`,
    headers: getHeader(),
    data: creativeGroup,
  };

  return axios(config);
}

function listCreativeGroups(adAccountId: string, productId: string) {
  const config: AxiosRequestConfig = {
    method: 'GET',
    url: `${ADCLOUD_DSP_V1_API_PATH}/creative-groups`,
    headers: getHeader(),
  };

  const params = new URLSearchParams();
  if (adAccountId) params.append('ad_account_id', adAccountId);
  if (productId) params.append('product_id', productId);
  config.params = params;

  return axios(config);
}

function copyCreativeGroups(
  adAccountId: string,
  creativeGroupIds: Array<string>,
  targetProductId: string,
  targetTrackingLinkIds: Array<string>,
  targetLandingUrls: Array<string> = [], // TODO It's for legacy, It should be removed after deploy CM18
  targetProductType: MEProductType = MEProductType.APP,
) {
  const data =
    targetProductType === MEProductType.APP
      ? {
          ad_account_id: adAccountId,
          creative_group_ids: creativeGroupIds,
          tgt_product_id: targetProductId,
          tgt_trackinglink_ids: targetTrackingLinkIds,
        }
      : {
          ad_account_id: adAccountId,
          creative_group_ids: creativeGroupIds,
          tgt_product_id: targetProductId,
          tgt_landing_urls: targetLandingUrls || [],
        };

  const config: AxiosRequestConfig = {
    method: 'POST',
    url: `${ADCLOUD_DSP_V1_API_PATH}/creative-groups/clones`,
    headers: getHeader(),
    data,
  };

  return axios(config);
}

function getCopyCreativeGroupsBuildStatus(adAccountId: string, buildStatusUrl: string) {
  const config: AxiosRequestConfig = {
    method: 'GET',
    url: buildStatusUrl,
    headers: getHeader(),
    params: {
      ad_account_id: adAccountId,
    },
  };

  return axios(config);
}

/*
  Creative
 */
function getCreative(creativeId: string) {
  const config: AxiosRequestConfig = {
    method: 'GET',
    url: `${ADCLOUD_DSP_V1_API_PATH}/creatives/${creativeId}`,
    headers: getHeader(),
  };

  return axios.request<{ creative: any }>(config);
}

function getCopyCreativesBuildStatus(adAccountId: string, buildStatusUrl: string) {
  const config: AxiosRequestConfig = {
    method: 'GET',
    url: buildStatusUrl,
    headers: getHeader(),
    params: {
      ad_account_id: adAccountId,
    },
  };
  return axios(config);
}

function copyCreatives(adAccountId: string, targetProductId: string, creativeIds: Array<string>) {
  const config: AxiosRequestConfig = {
    method: 'POST',
    url: `${ADCLOUD_DSP_V1_API_PATH}/creatives/clones`,
    headers: getHeader(),
    data: {
      ad_account_id: adAccountId,
      tgt_product_id: targetProductId,
      creative_ids: creativeIds,
    },
  };
  return axios(config);
}

function listCreatives(adAccountId: string, productId: string) {
  const config: AxiosRequestConfig = {
    method: 'GET',
    url: `${ADCLOUD_DSP_V1_API_PATH}/creatives`,
    headers: getHeader(),
    params: {
      ad_account_id: adAccountId,
      product_id: productId,
    },
  };
  return axios(config);
}

function createCreative(adAccountId: string, productId: string, creative: MICreativeProto) {
  const config: AxiosRequestConfig = {
    method: 'POST',
    url: `${ADCLOUD_DSP_V1_API_PATH}/creatives`,
    headers: getHeader(),
    data: creative,
    params: {
      ad_account_id: adAccountId,
      product_id: productId,
    },
  };
  return axios(config);
}

function updateCreative(creative: MICreativeProto) {
  const config: AxiosRequestConfig = {
    method: 'PUT',
    url: `${ADCLOUD_DSP_V1_API_PATH}/creatives/${creative.id}`,
    headers: getHeader(),
    data: creative,
  };
  return axios(config);
}

function deleteCreative(creativeId: string) {
  const config: AxiosRequestConfig = {
    method: 'DELETE',
    url: `${ADCLOUD_DSP_V1_API_PATH}/creatives/${creativeId}`,
    headers: getHeader(),
  };
  return axios(config);
}

/*
  Creative Review
 */

function listCreativeReviewStatus(adAccountId: string) {
  const config: AxiosRequestConfig = {
    method: 'GET',
    url: `${ADCLOUD_DSP_V1_API_PATH}/creative-reviews`,
    headers: getHeader(),
    params: {
      ad_account_id: adAccountId,
    },
  };
  return axios(config);
}

/*
  Tracking Link
 */

function listTrackingLinks(adAccountId: string, productId: string) {
  const config: AxiosRequestConfig = {
    method: 'GET',
    url: `${ADCLOUD_DSP_V1_API_PATH}/tracking-links`,
    headers: getHeader(),
  };

  const params = new URLSearchParams();
  if (adAccountId) params.append('ad_account_id', adAccountId);
  if (productId) params.append('product_id', productId);
  config.params = params;

  return axios(config);
}

function getTrackingLink(
  adAccountId: string | null,
  productId: string | null,
  trackingLinkId: string,
) {
  const config: AxiosRequestConfig = {
    method: 'GET',
    url: `${ADCLOUD_DSP_V1_API_PATH}/tracking-links/${trackingLinkId}`,
    headers: getHeader(),
  };

  const params = new URLSearchParams();
  if (adAccountId) params.append('ad_account_id', adAccountId);
  if (productId) params.append('product_id', productId);
  config.params = params;

  return axios(config);
}

function updateTrackingLink(
  adAccountId: string | null,
  productId: string | null,
  trackingLink: MITrackingLinkProto,
) {
  const config: AxiosRequestConfig = {
    method: 'PUT',
    url: `${ADCLOUD_DSP_V1_API_PATH}/tracking-links/${trackingLink.id}`,
    headers: getHeader(),
    data: trackingLink,
  };

  const params = new URLSearchParams();
  if (adAccountId) params.append('ad_account_id', adAccountId);
  if (productId) params.append('product_id', productId);
  config.params = params;

  return axios(config);
}

function createTrackingLink(
  adAccountId: string,
  productId: string,
  trackingLink: MITrackingLinkProto,
) {
  const config: AxiosRequestConfig = {
    method: 'POST',
    url: `${ADCLOUD_DSP_V1_API_PATH}/tracking-links`,
    headers: getHeader(),
    data: trackingLink,
  };

  const params = new URLSearchParams();
  if (adAccountId) params.append('ad_account_id', adAccountId);
  if (productId) params.append('product_id', productId);
  config.params = params;

  return axios(config);
}

function deleteTrackingLink(trackingLinkId: string) {
  const config: AxiosRequestConfig = {
    method: 'DELETE',
    url: `${ADCLOUD_DSP_V1_API_PATH}/tracking-links/${trackingLinkId}`,
    headers: getHeader(),
  };

  return axios(config);
}

// convertTrackingLink given URL to internal format according per-tracker rule.
// type - should be one of 'CLICK_THROUGH_LINK', 'VIEW_THROUGH_LINK'.
// os - OsType.
function convertTrackingLink(linkUrl: string, type: string, os: string) {
  const config: AxiosRequestConfig = {
    method: 'POST',
    url: `${ADCLOUD_DSP_V1_API_PATH}/tracking-links/convert`,
    headers: getHeader(),
    data: {
      link_type: type,
      device_os: os,
      url: linkUrl,
    },
  };
  return axios(config);
}

function sendTrackingLinkVerificationEmail(trackingLinkId: string, verificationRequest: any) {
  const config: AxiosRequestConfig = {
    method: 'POST',
    url: `${ADCLOUD_DSP_V1_API_PATH}/tracking-links/${trackingLinkId}/sendVerificationEmail`,
    headers: getHeader(),
    data: verificationRequest,
  };
  return axios(config);
}

function listTrackingLinkAttempts(adAccountId: string, productId: string, trackingLinkId: string) {
  const config: AxiosRequestConfig = {
    method: 'GET',
    url: `${ADCLOUD_DSP_V1_API_PATH}/tracking-link-verification-attempts`,
    headers: getHeader(),
  };

  const params = new URLSearchParams();
  if (adAccountId) params.append('ad_account_id', adAccountId);
  if (productId) params.append('product_id', productId);
  if (trackingLinkId) params.append('tracking_link_id', trackingLinkId);
  config.params = params;

  return axios(config);
}

/*
  Custom Target
 */
function listAudiences(advertiserId: string) {
  const config: AxiosRequestConfig = {
    method: 'GET',
    url: `${ADCLOUD_DSP_V1_API_PATH}/audience-targets`,
    headers: getHeader(),
    params: {
      ad_account_id: advertiserId,
    },
  };
  return axios(config);
}

function getAudience(adAccountId: string, audienceId: string) {
  const config: AxiosRequestConfig = {
    method: 'GET',
    url: `${ADCLOUD_DSP_V1_API_PATH}/audience-targets/${audienceId}`,
    headers: getHeader(),
    params: {
      ad_account_id: adAccountId,
    },
  };
  return axios(config);
}

function updateAudience(adAccountId: string, audience: MISharedAudienceTargetProto) {
  const config: AxiosRequestConfig = {
    method: 'PUT',
    url: `${ADCLOUD_DSP_V1_API_PATH}/audience-targets/${audience.id}`,
    headers: getHeader(),
    data: audience,
    params: {
      ad_account_id: adAccountId,
      audience_target_id: audience.id,
    },
  };
  return axios(config);
}

function createAudience(adAccountId: string, audience: MISharedAudienceTargetProto) {
  const config: AxiosRequestConfig = {
    method: 'POST',
    url: `${ADCLOUD_DSP_V1_API_PATH}/audience-targets`,
    headers: getHeader(),
    data: audience,
    params: {
      ad_account_id: adAccountId,
    },
  };
  return axios(config);
}

function deleteAudience(adAccountId: string, audienceId: string) {
  const config: AxiosRequestConfig = {
    method: 'DELETE',
    url: `${ADCLOUD_DSP_V1_API_PATH}/audience-targets/${audienceId}`,
    headers: getHeader(),
    params: {
      ad_account_id: adAccountId,
      audience_target_id: audienceId,
    },
  };
  return axios(config);
}

/*
  Customer Set
 */
function createCustomerSet(adAccountId: string, customerSet: MICustomerSetProto) {
  const config: AxiosRequestConfig = {
    method: 'POST',
    url: `${ADCLOUD_DSP_V1_API_PATH}/customer-sets`,
    headers: getHeader(),
    data: customerSet,
  };

  const params = new URLSearchParams();
  params.append('ad_account_id', adAccountId);
  config.params = params;

  return axios(config);
}

function getCustomerSet(customerSetId: string) {
  const config: AxiosRequestConfig = {
    method: 'GET',
    url: `${ADCLOUD_DSP_V1_API_PATH}/customer-sets/${customerSetId}`,
    headers: getHeader(),
  };

  return axios(config);
}

function updateCustomerSet(customerSet: MICustomerSetProto) {
  const config: AxiosRequestConfig = {
    method: 'PUT',
    url: `${ADCLOUD_DSP_V1_API_PATH}/customer-sets/${customerSet.id}`,
    headers: getHeader(),
    data: customerSet,
  };

  return axios(config);
}

function deleteCustomerSet(customerSetId: string) {
  const config: AxiosRequestConfig = {
    method: 'DELETE',
    url: `${ADCLOUD_DSP_V1_API_PATH}/customer-sets/${customerSetId}`,
    headers: getHeader(),
  };

  return axios(config);
}

function listCustomerSets(adAccountId: string) {
  const config: AxiosRequestConfig = {
    method: 'GET',
    url: `${ADCLOUD_DSP_V1_API_PATH}/customer-sets`,
    headers: getHeader(),
  };

  const params = new URLSearchParams();
  params.append('ad_account_id', adAccountId);
  config.params = params;

  return axios(config);
}

/*
  Customer Activity
*/
// TODO: define MICustomerActivityProto @seonkyu
function createCustomerActivity(adAccountId: string, customerActivity: any) {
  const config: AxiosRequestConfig = {
    method: 'POST',
    url: `${ADCLOUD_DSP_V1_API_PATH}/customer-activities`,
    headers: getHeader(),
    data: customerActivity,
  };

  const params = new URLSearchParams();
  params.append('ad_account_id', adAccountId);
  config.params = params;

  return axios(config);
}

function getCustomerActivity(customerActivityId: string) {
  const config: AxiosRequestConfig = {
    method: 'GET',
    url: `${ADCLOUD_DSP_V1_API_PATH}/customer-activities/${customerActivityId}`,
    headers: getHeader(),
  };

  return axios(config);
}

function updateCustomerActivity(customerActivity: any) {
  const config: AxiosRequestConfig = {
    method: 'PUT',
    url: `${ADCLOUD_DSP_V1_API_PATH}/customer-activities/${customerActivity.id}`,
    headers: getHeader(),
    data: customerActivity,
  };

  return axios(config);
}

function deleteCustomerActivity(customerActivityId: string) {
  const config: AxiosRequestConfig = {
    method: 'DELETE',
    url: `${ADCLOUD_DSP_V1_API_PATH}/customer-activities/${customerActivityId}`,
    headers: getHeader(),
  };

  return axios(config);
}

function listCustomerActivities(adAccountId: string) {
  const config: AxiosRequestConfig = {
    method: 'GET',
    url: `${ADCLOUD_DSP_V1_API_PATH}/customer-activities`,
    headers: getHeader(),
  };

  const params = new URLSearchParams();
  params.append('ad_account_id', adAccountId);
  config.params = params;

  return axios(config);
}

/*
  Upload
 */
function getAssetUploadUrl(
  adAccountId: string,
  assetKind: string,
  filename: string,
  mimeType: string,
) {
  const config: AxiosRequestConfig = {
    method: 'POST',
    url: `${ADCLOUD_DSP_V1_API_PATH}/creative-assets`,
    headers: getHeader(),
    data: {
      asset_kind: assetKind,
      mime_type: mimeType,
      file_name: filename,
    },
  };

  const params = new URLSearchParams();
  params.append('ad_account_id', adAccountId);
  config.params = params;

  return axios(config);
}

function getCreativeUploadUrl(adAccountId: string, filename: string, mimeType: string) {
  return getAssetUploadUrl(adAccountId, 'CREATIVE', filename, mimeType);
}

function getCsvUploadUrl(adAccountId: string, filename: string, mimeType: string) {
  return getAssetUploadUrl(adAccountId, 'CSV', filename, mimeType);
}

/*
  log Data Transfer
 */

function listLogDataTransfers(adAccountId: string) {
  const config: AxiosRequestConfig = {
    method: 'GET',
    url: `${ADCLOUD_DSP_V1_API_PATH}/export-sinks`,
    headers: getHeader(),
    params: {
      ad_account_id: adAccountId,
    },
  };
  return axios(config);
}

// TODO: Define MIExportSinkProto @seungwoo0203
function createLogDataTransfers(adAccountId: string, exportSink: any) {
  const config: AxiosRequestConfig = {
    method: 'POST',
    url: `${ADCLOUD_DSP_V1_API_PATH}/export-sinks`,
    headers: getHeader(),
    data: exportSink,
    params: {
      ad_account_id: adAccountId,
    },
  };

  return axios(config);
}

function updateLogDataTransfers(adAccountId: string, exportSink: any) {
  const config: AxiosRequestConfig = {
    method: 'PUT',
    url: `${ADCLOUD_DSP_V1_API_PATH}/export-sinks/${exportSink.id}`,
    headers: getHeader(),
    data: exportSink,
    params: {
      ad_account_id: adAccountId,
    },
  };

  return axios(config);
}

function deleteLogDataTransfers(adAccountId: string, exportSink: any) {
  const config: AxiosRequestConfig = {
    method: 'DELETE',
    url: `${ADCLOUD_DSP_V1_API_PATH}/export-sinks/${exportSink.id}`,
    headers: getHeader(),
    params: {
      ad_account_id: adAccountId,
    },
  };

  return axios(config);
}

/*
  log Data DownLoad
 */

function listLogs(startDate: string, endDate: string, advertiserId: string) {
  const config: AxiosRequestConfig = {
    method: 'GET',
    url: `${ADCLOUD_DSP_V1_API_PATH}/logs`,
    headers: getHeader(),
  };

  const params = new URLSearchParams();
  params.append('date_range.start', startDate);
  params.append('date_range.end', endDate);
  if (advertiserId) {
    params.append('ad_account_id', advertiserId);
  }
  config.params = params;
  return axios(config);
}

function getLogStatus(logId: string) {
  const config: AxiosRequestConfig = {
    method: 'GET',
    url: `${ADCLOUD_DSP_V1_API_PATH}/logs/${logId}/status`,
    headers: getHeader(),
    params: {
      log_id: logId,
    },
  };

  return axios(config);
}

// TODO: Define MILogProto @seungwoo0203
function createLog(log: any, advertiserId: string) {
  const config: AxiosRequestConfig = {
    method: 'POST',
    url: `${ADCLOUD_DSP_V1_API_PATH}/logs`,
    headers: getHeader(),
    data: log,
  };

  const params = new URLSearchParams();
  if (advertiserId) {
    params.append('ad_account_id', advertiserId);
  }
  config.params = params;

  return axios(config);
}

/*
  Campaign Summary
*/

function getCampaignSummaryWithFilter(
  timezone: string,
  startDate: string,
  endDate: string,
  groupByOptions: Array<string>,
  adAccountId?: string,
  filters?: object,
  orderByOptions?: Array<string>, // for server-side sorting
  limit?: number,
) {
  const config: AxiosRequestConfig = {
    method: 'GET',
    url: `${ADCLOUD_DSP_V1_API_PATH}/campaign-summary`,
    headers: getHeader(),
  };

  const params = new URLSearchParams();
  params.append('timezone', timezone);
  params.append('date_range.start', startDate);
  params.append('date_range.end', endDate);

  if (adAccountId) {
    params.append('ad_account_id', adAccountId);
  }

  // add filters e.g. { product_id: '#pr_id_value',  campaign_id: '#cr_id_value'}
  if (filters) {
    Object.entries(filters).forEach((entry) => {
      const [key, value] = entry;
      params.append(`filter.${key}`, value as string);
    });
  }

  groupByOptions.forEach((opt) => {
    params.append('group_by', opt);
  });

  if (orderByOptions) {
    orderByOptions.forEach((opt) => {
      params.append('order_by', opt);
    });
  }

  if (limit) {
    params.append('limit', `${limit}`);
  }

  config.params = params;
  return axios(config);
}

function getCampaignSummary(
  timezone: string,
  startDate: string,
  endDate: string,
  groupByOptions: Array<string>,
) {
  // call getCampaignSummaryWithFilter without adAccountId:string, filter, orderByOptions
  return getCampaignSummaryWithFilter(
    timezone,
    startDate,
    endDate,
    groupByOptions,
    undefined,
    {},
    [],
  );
}

/*
  Cohort Summary
*/

// TODO: Use appropriate type for parameters @sjhan
function getCohortSummary(
  startDate: string,
  endDate: string,
  adAccountId: string,
  type: string,
  actions: Array<string>,
) {
  const config: AxiosRequestConfig = {
    method: 'GET',
    url: `${ADCLOUD_DSP_V1_API_PATH}/cohort-summary`,
    headers: getHeader(),
  };

  const params = new URLSearchParams();
  params.append('date_range.start', startDate);
  params.append('date_range.end', endDate);
  params.append('ad_account_id', adAccountId);
  params.append('type', type);

  if (actions) {
    actions.forEach((a: string) => {
      params.append('actions', a);
    });
  }

  config.params = params;
  return axios(config);
}

function getDspConfiguration() {
  const config: AxiosRequestConfig = {
    method: 'GET',
    url: `${ADCLOUD_DSP_V1_API_PATH}/configuration`,
    headers: getHeader(),
  };
  return axios(config);
}

/*
  Entity Logs
 */

function listEntityLogs(adAccountId: string, entityId: string) {
  const config: AxiosRequestConfig = {
    method: 'GET',
    url: `${ADCLOUD_DSP_V1_API_PATH}/entity-logs`,
    headers: getHeader(),
  };

  const params = new URLSearchParams();
  params.append('ad_account_id', adAccountId);
  params.append('entity_id', entityId);

  config.params = params;

  return axios(config);
}

/*
  Report Export
 */

function listReportExports(adAccountId: string) {
  const config: AxiosRequestConfig = {
    method: 'GET',
    url: `${ADCLOUD_DSP_V1_API_PATH}/report-exports`,
    headers: getHeader(),
  };

  const params = new URLSearchParams();
  params.append('ad_account_id', adAccountId);
  config.params = params;

  return axios(config);
}

function createReportExport(adAccountId: string, reportExport: MIReportExportProto) {
  const config: AxiosRequestConfig = {
    method: 'POST',
    url: `${ADCLOUD_DSP_V1_API_PATH}/report-exports`,
    headers: getHeader(),
    data: {
      partner: reportExport.partner,
    },
  };

  const params = new URLSearchParams();

  params.append('ad_account_id', adAccountId);
  if (reportExport.product_id) {
    params.append('product_id', reportExport.product_id);
  }
  config.params = params;

  return axios(config);
}

function deleteReportExport(reportExportId: string) {
  const config: AxiosRequestConfig = {
    method: 'DELETE',
    url: `${ADCLOUD_DSP_V1_API_PATH}/report-exports/${reportExportId}`,
    headers: getHeader(),
  };

  return axios(config);
}

function listNotificationPreferences(userId: string) {
  const config: AxiosRequestConfig = {
    method: 'GET',
    url: `${ADCLOUD_DSP_V1_API_PATH}/noti-preferences`,
    headers: getHeader(),
    params: {
      user_id: userId,
    },
  };
  return axios(config);
}

function batchUpdateNotificationPreferences(preferences: Array<MINotificationPreferenceProto>) {
  const config: AxiosRequestConfig = {
    method: 'PUT',
    url: `${ADCLOUD_DSP_V1_API_PATH}/noti-preferences/batch/update`,
    headers: getHeader(),
    data: {
      preferences,
    },
  };
  return axios(config);
}

function listNotificationDeliveries(intervalStart: number, intervalEnd: number) {
  const config: AxiosRequestConfig = {
    method: 'GET',
    url: `${ADCLOUD_DSP_V1_API_PATH}/noti-deliveries`,
    headers: getHeader(),
  };

  const params = new URLSearchParams();
  params.append('interval.start', new Date(intervalStart).toISOString());
  params.append('interval.end', new Date(intervalEnd).toISOString());
  params.append('limit', '300');
  config.params = params;

  return axios(config);
}

function batchUpdateNotificationDeliveriesReadStatus(deliveryIds: Array<string>) {
  const config: AxiosRequestConfig = {
    method: 'PUT',
    url: `${ADCLOUD_DSP_V1_API_PATH}/noti-deliveries/batch/update-read-status`,
    headers: getHeader(),
    data: {
      notification_delivery_ids: deliveryIds,
    },
  };
  return axios(config);
}

export default {
  listAdvertisers,
  createAdvertiser,
  getAdvertiser,
  updateAdvertiser,
  deleteAdvertiser,
  listProducts,
  createProduct,
  getProduct,
  updateProduct,
  deleteProduct,
  listCampaigns,
  getCampaign,
  createCampaign,
  updateCampaign,
  createAdGroup,
  listAdGroups,
  getAdGroup,
  updateAdGroup,
  deleteAdGroup,
  listOperationItems: listPayoutItems,
  getOperationItem: getPayoutItem,
  updateOperationItem: updatePayoutItem,
  createOperationItem: createPayoutItem,
  deleteOperationItem: deletePayoutItem,
  createCreativeGroup,
  getCreativeGroup,
  updateCreativeGroup,
  listCreativeGroups,
  getCopyCreativesBuildStatus,
  copyCreatives,
  copyCreativeGroups,
  getCopyCreativeGroupsBuildStatus,
  listCreatives,
  createCreative,
  getCreative,
  updateCreative,
  deleteCreative,
  listCreativeReviewStatus,
  listTrackingLinks,
  createTrackingLink,
  getTrackingLink,
  updateTrackingLink,
  deleteTrackingLink,
  convertTrackingLink,
  sendTrackingLinkVerificationEmail,
  listTrackingLinkAttempts,
  listAudiences,
  getAudience,
  updateAudience,
  createAudience,
  deleteAudience,
  createCustomerSet,
  getCustomerSet,
  updateCustomerSet,
  deleteCustomerSet,
  listCustomerSets,
  createCustomerActivity,
  getCustomerActivity,
  updateCustomerActivity,
  deleteCustomerActivity,
  listCustomerActivities,
  getCsvUploadUrl,
  getCreativeUploadUrl,
  listLogDataTransfers,
  createLogDataTransfers,
  updateLogDataTransfers,
  deleteLogDataTransfers,
  listLogs,
  getLogStatus,
  createLog,
  getCampaignSummaryWithFilter,
  getCampaignSummary,
  getDspConfiguration,
  listEntityLogs,
  listReportExports,
  createReportExport,
  deleteReportExport,
  getCohortSummary,
  listNotificationPreferences,
  batchUpdateNotificationPreferences,
  listNotificationDeliveries,
  batchUpdateNotificationDeliveriesReadStatus,
};
