import {
  CLEAR_IN_APP_ALERTS,
  CLOSE_IN_APP_ALERT,
  ENQUEUE_IN_APP_ALERT,
  REMOVE_IN_APP_ALERT,
} from './types';

const showInAppAlert = (msg, level, key, options = {}) => {
  const inAppAlertKey = key || new Date().getTime() + Math.random();
  return {
    type: ENQUEUE_IN_APP_ALERT,
    notification: {
      key: inAppAlertKey,
      message: msg,
      options: {
        key: inAppAlertKey,
        variant: level,
        ...options,
      },
    },
  };
};

export const fireDefault = (msg, key, options = {}) => {
  return showInAppAlert(msg, 'default', key, options);
};

export const fireSuccess = (msg, key, options = {}) => {
  return showInAppAlert(msg, 'success', key, options);
};

export const fireInfo = (msg, key, options = {}) => {
  return showInAppAlert(msg, 'info', key, options);
};

export const fireWarning = (msg, key, options = {}) => {
  return showInAppAlert(msg, 'warning', key, options);
};

export const fireError = (msg, key, options = {}) => {
  return showInAppAlert(msg, 'error', key, options);
};

export const closeInAppAlert = (key) => {
  return (dispatch, getState) => {
    const { items } = getState().notification;

    if (items.findIndex((item) => item.key === key) !== -1) {
      dispatch({
        type: CLOSE_IN_APP_ALERT,
        dismissAll: !key, // dismiss all if no key has been defined
        key,
      });
    }
  };
};

export const removeInAppAlert = (key) => ({
  type: REMOVE_IN_APP_ALERT,
  key,
});

export const clearInAppAlerts = () => ({
  type: CLEAR_IN_APP_ALERTS,
});

// TODO: Delete the code below when v1 has been deprecated (@gihyeon)
export const notifyDefault = fireDefault;
export const notifySuccess = fireSuccess;
export const notifyInfo = fireInfo;
export const notifyWarning = fireWarning;
export const notifyError = fireError;
export const closeNotification = closeInAppAlert;
export const removeNotification = removeInAppAlert;
export const clearNotifications = clearInAppAlerts;
