/*
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here. We prefix them with 'componentName' so we avoid
 * reducers accidentally picking up actions they shouldn't.
 *
 * Follow this format:
 * export const YOUR_ACTION_CONSTANT = 'YourContainer/YOUR_ACTION_CONSTANT';
 */

import { ACTION_TYPE_CAMPAIGN_MANAGER } from '../consts.ts';

export const ACTION_TYPE_CAMPAIGN = 'campaign';

export const CHANGE_CAMPAIGN_CONTEXT = `${ACTION_TYPE_CAMPAIGN_MANAGER}/${ACTION_TYPE_CAMPAIGN}/CHANGE_CAMPAIGN_CONTEXT`;

export const LIST_CAMPAIGN_REQUEST = `${ACTION_TYPE_CAMPAIGN_MANAGER}/${ACTION_TYPE_CAMPAIGN}/LIST_CAMPAIGN_REQUEST`;
export const LIST_CAMPAIGN_SUCCESS = `${ACTION_TYPE_CAMPAIGN_MANAGER}/${ACTION_TYPE_CAMPAIGN}/LIST_CAMPAIGN_SUCCESS`;
export const LIST_CAMPAIGN_ERROR = `${ACTION_TYPE_CAMPAIGN_MANAGER}/${ACTION_TYPE_CAMPAIGN}/LIST_CAMPAIGN_ERROR`;

export const GET_CAMPAIGN_REQUEST = `${ACTION_TYPE_CAMPAIGN_MANAGER}/${ACTION_TYPE_CAMPAIGN}/GET_CAMPAIGN_REQUEST`;
export const GET_CAMPAIGN_SUCCESS = `${ACTION_TYPE_CAMPAIGN_MANAGER}/${ACTION_TYPE_CAMPAIGN}/GET_CAMPAIGN_SUCCESS`;
export const GET_CAMPAIGN_ERROR = `${ACTION_TYPE_CAMPAIGN_MANAGER}/${ACTION_TYPE_CAMPAIGN}/GET_CAMPAIGN_ERROR`;

export const SAVE_CAMPAIGN_REQUEST = `${ACTION_TYPE_CAMPAIGN_MANAGER}/${ACTION_TYPE_CAMPAIGN}/SAVE_CAMPAIGN_REQUEST`;
export const SAVE_CAMPAIGN_SUCCESS = `${ACTION_TYPE_CAMPAIGN_MANAGER}/${ACTION_TYPE_CAMPAIGN}/SAVE_CAMPAIGN_SUCCESS`;
export const SAVE_CAMPAIGN_ERROR = `${ACTION_TYPE_CAMPAIGN_MANAGER}/${ACTION_TYPE_CAMPAIGN}/SAVE_CAMPAIGN_ERROR`;
