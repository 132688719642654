import { ImageResource } from './imageResource';
import { MICreativeVideoSrcProto } from '../proto/creativeSource';

// eslint-disable-next-line import/prefer-default-export
export class VideoResource {
  public proto: MICreativeVideoSrcProto;
  public videoUrl: string;
  public width: number;
  public height: number;
  public lengthSeconds: number;
  public sizeInBytes: number;
  public companionImages: Array<ImageResource>;

  public originalFilename: string;
  public resourceUrl: string;

  constructor(proto: MICreativeVideoSrcProto) {
    this.proto = proto;

    // Basic proto message part
    this.videoUrl = proto.video_url ?? '';
    this.width = proto.width ?? 0;
    this.height = proto.height ?? 0;
    this.lengthSeconds = proto.length_seconds ?? 0;
    this.sizeInBytes = proto.size_in_bytes ?? 0;
    this.companionImages = (proto.companion_images ?? []).map((companionImageProto) =>
      ImageResource.fromProto(companionImageProto),
    );

    // Special part
    this.originalFilename = proto.original_filename ?? ''; // We put the originalFilename in the resource level to correct proto structure.

    // For UI part
    this.resourceUrl = this.videoUrl; // Use resourceUrl instead of video_url for modularization.
  }

  static fromProto = (proto: MICreativeVideoSrcProto) => {
    return new VideoResource(proto);
  };

  static toProto = (uiObject: VideoResource) => {
    const proto = uiObject.proto;

    proto.video_url = uiObject.resourceUrl;
    proto.width = uiObject.width;
    proto.height = uiObject.height;
    proto.length_seconds = uiObject.lengthSeconds;
    proto.size_in_bytes = uiObject.sizeInBytes;
    proto.companion_images = uiObject.companionImages.map((companionImage) =>
      ImageResource.toProto(companionImage),
    );

    delete proto.original_filename; // Put back originalFilename to creative level.

    return proto;
  };
}
