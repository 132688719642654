import styled from 'styled-components';
import { ListItem, Typography } from '@material-ui/core';

import { grayColor } from 'assets/jss/rootStyle';
import { MCButton } from 'components-v2/Common/Button/MCButton';
import { MCFormInput } from 'components-v2/Common/Form/FormStyledComponents';

export const SCAuthPageWrapper = styled.div`
  width: 100%;
  height: 100vh;
  padding: 15% 0 0;
  margin: auto;
  background-color: ${grayColor.light};
`;

export const SCAuthFormContainer = styled.div`
  display: flex;
  width: 360px;
  margin: auto;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: ${(props) => props.theme.palette.background.paper};
  border-radius: 4px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
`;

export const SCInputFormContainer = styled.div`
  width: 80%;
  margin: 30px 50px;
  display: flex;
  flex-direction: column;
`;

export const InputField = styled(MCFormInput)`
  margin-top: ${(props) => props.theme.spacing(2)}px;
`;

export const PlatformListItem = styled(ListItem)`
  border: 1px solid #bbbbbb;
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }
`;
export const PlatformListIcon = styled.img`
  width: 32px;
  height: 32px;
  object-fit: contain;
  border: 1px solid #bbbbbb;
  background-color: #ffffff;
  padding: 2px;
`;

export const SwitchWorkplaceHeaderContainer = styled.div`
  width: 80%;
  margin: 30px;
`;

export const SwitchWorkplaceHeader = styled.div`
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #494f58;
`;

export const SCAuthLogoContainer = styled.div`
  text-align: center;
`;

export const SCVersionTextContainer = styled.div`
  position: absolute;
  bottom: 8px;
  height: 10px;
  right: 16px;
  font-size: 6px;
`;

export const SCAuthAdCloudLogo = styled.img`
  width: 133px;
  margin-top: 30px;
`;

export const SwitchBrandLogoContainer = styled.div`
  display: flex;
  float: left;
  width: 54px;
  height: 54px;
  margin-right: 10px;
  border-radius: 2.8px;
  border: solid 1.1px #bbbbbb;
  background-color: #ffffff;
  align-items: center;
  justify-content: center;
`;

export const SwitchBrandLogo = styled.img`
  padding: 3px;
  width: inherit;
  height: auto;
`;

export const SwitchBrandTypo = styled(Typography)`
  font-size: 22px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #454545;
`;

export const SwitchFormContainer = styled.div`
  width: 80%;
  margin: 30px 50px;
  display: flex;
  flex-direction: column;
`;

export const SCPageTitle = styled.div`
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  color: #494f58;
  margin-bottom: ${(props) => props.theme.spacing(2)}px;
`;

export const SCFormWrapper = styled.div`
  margin-bottom: ${(props) => props.theme.spacing(2)}px;
`;

export const SCLabel = styled.div`
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  color: #494f58;
  margin-bottom: ${(props) => props.theme.spacing(2)}px;
`;

export const SCNormalText = styled.div`
  font-size: 14px;
  font-weight: normal;
  text-align: left;
  color: #494f58;
  margin-bottom: ${(props) => props.theme.spacing(2)}px;
`;

export const SCAuthButton = styled(MCButton)`
  width: 100%;
  height: 36px;
  justify-content: center;
`;

export const SCAuthErrorMessage = styled.div`
  color: red;
  font-size: 12px;
  margin-bottom: ${(props) => props.theme.spacing(1)}px;

  &:before {
    content: '';
    display: inline-block;
    background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20aria-hidden%3D%22true%22%20focusable%3D%22false%22%20data-prefix%3D%22fas%22%20data-icon%3D%22exclamation-circle%22%20class%3D%22svg-inline--fa%20fa-exclamation-circle%20fa-w-16%22%20role%3D%22img%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%20512%20512%22%3E%3Cpath%20fill%3D%22red%22%20d%3D%22M504%20256c0%20136.997-111.043%20248-248%20248S8%20392.997%208%20256C8%20119.083%20119.043%208%20256%208s248%20111.083%20248%20248zm-248%2050c-25.405%200-46%2020.595-46%2046s20.595%2046%2046%2046%2046-20.595%2046-46-20.595-46-46-46zm-43.673-165.346l7.418%20136c.347%206.364%205.609%2011.346%2011.982%2011.346h48.546c6.373%200%2011.635-4.982%2011.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884%200-12.356%205.78-11.981%2012.654z%22%3E%3C/path%3E%3C/svg%3E');
    height: 12px;
    width: 12px;
    margin-right: 5px;
    vertical-align: -15%;
  }
`;
