// eslint-disable-next-line import/prefer-default-export
export const FIELD_TYPE = {
  ACTIVITY_CONDITIONS: 'activity-conditions',
  CHECKBOX: 'checkbox',
  CHIP: 'chip',
  SWITCH: 'switch',
  DATE_FIELD: 'date',
  DATETIME_FIELD: 'datetime',
  ENTITY_SELECTOR: 'entitySelector',
  TABLE_ENTITY_SELECTOR: 'tableEntitySelector',
  MULTI_ENTITY_SELECTOR: 'multiEntitySelector',
  KPI_ACTION_SELECTOR: 'kpiActionSelector',
  KEYVALUE_FIELD: 'keyvalue',
  MULTI_SELECT: 'multiSelect',
  NUMBER: 'number',
  SEARCHABLE_SELECT: 'searchableSelect',
  SEARCH_BAR_SELECT: 'searchBarSelect',
  SELECT_FIELD: 'select',
  RICH_SELECT: 'RICH_SELECT',
  TEXT_AREA: 'textarea',
  TEXT_FIELD: 'text',
  TRACKINGLINK_FIELD: 'trackinglink',
  RADIO_GROUP: 'radio-group',
  WEEKLY_TIMETABLE: 'weekly-timetable',
};
