import { ImageResource } from './imageResource';
import { VideoResource } from './videoResource';
import { MICreativeNativeSrcProto } from '../proto/creativeSource';

// eslint-disable-next-line import/prefer-default-export
export class NativeResource {
  public proto: MICreativeNativeSrcProto;

  public iconImage?: ImageResource;
  public mainImage?: ImageResource;
  public video?: VideoResource;
  public title: string;
  public text: string;
  public ctaText: string;

  constructor(proto: MICreativeNativeSrcProto) {
    this.proto = proto;

    this.iconImage = proto.icon_image ? ImageResource.fromProto(proto.icon_image) : undefined;
    this.mainImage = proto.main_image ? ImageResource.fromProto(proto.main_image) : undefined;
    this.video = proto.video ? VideoResource.fromProto(proto.video) : undefined;
    this.title = proto.title ?? '';
    this.text = proto.text ?? '';
    this.ctaText = proto.cta_text ?? '';
  }

  static fromProto = (proto: MICreativeNativeSrcProto) => {
    return new NativeResource(proto);
  };

  static toProto = (uiObject: NativeResource) => {
    const proto = uiObject.proto;

    if (uiObject.iconImage) {
      proto.icon_image = ImageResource.toProto(uiObject.iconImage);
    } else {
      delete proto.icon_image;
    }

    if (uiObject.mainImage) {
      proto.original_filename = uiObject.mainImage.originalFilename;
      proto.main_image = ImageResource.toProto(uiObject.mainImage);
    } else {
      proto.original_filename = '';
      delete proto.main_image;
    }

    if (uiObject.video) {
      proto.original_filename = uiObject.video.originalFilename;
      proto.video = VideoResource.toProto(uiObject.video);
    } else {
      delete proto.video;
    }

    proto.title = uiObject.title;
    proto.text = uiObject.text;
    proto.cta_text = uiObject.ctaText;

    return proto;
  };
}
