import React from 'react';
import { SnackbarProvider } from 'notistack';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';

import { getPlatformFeatures } from '../../../common/global/utils';
import { FEATURES } from '../../../common-v2/constants/features';

// assets
import SnackbarSuccess from '../../../assets/imgs/snackbar_v2/snackbar_v2_success.svg';
import SnackbarError from '../../../assets/imgs/snackbar_v2/snackbar_v2_error.svg';
import SnackbarWarning from '../../../assets/imgs/snackbar_v2/snackbar_v2_warning.svg';
import SnackbarInfo from '../../../assets/imgs/snackbar_v2/snackbar_v2_info.svg';
import SnackbarCopied from '../../../assets/imgs/snackbar_v2/snackbar_v2_copied.svg';

// styles
const useStyles = makeStyles(() => ({
  success: { backgroundColor: '#4caf50' },
  error: { backgroundColor: '#cf2d2d' },
  warning: { backgroundColor: '#ffa400' },
  info: { backgroundColor: '#313131' },
}));

const SCSnackbarWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  margin-right: 8px;
`;

// snackbar icon - v2
type MTSnackbarVariant = 'success' | 'error' | 'warning' | 'info' | 'default';

const ICON_ASSETS: Record<MTSnackbarVariant, string> = {
  success: SnackbarSuccess,
  error: SnackbarError,
  warning: SnackbarWarning,
  info: SnackbarInfo,
  default: SnackbarCopied,
};

type MTSnackbarIcon = {
  variant: MTSnackbarVariant;
};

const MCSnackbarIcon = (props: MTSnackbarIcon): React.ReactElement => {
  const { variant } = props;
  return (
    <SCSnackbarWrapper>
      <img src={ICON_ASSETS[variant]} alt={variant} draggable={false} />
    </SCSnackbarWrapper>
  );
};

// snackbar provider
type MTSnackbarProvider = { children?: React.ReactElement };

function MCInAppAlertProvider({ children }: MTSnackbarProvider): React.ReactElement {
  const classes = useStyles();
  const features = getPlatformFeatures();
  const isOnlyLayoutV1 = features.includes(FEATURES.ONLY_LAYOUT_V1);

  const iconVariant = isOnlyLayoutV1
    ? {}
    : {
        success: <MCSnackbarIcon variant="success" />,
        error: <MCSnackbarIcon variant="error" />,
        warning: <MCSnackbarIcon variant="warning" />,
        info: <MCSnackbarIcon variant="info" />,
        default: <MCSnackbarIcon variant="default" />,
      };

  const snackbarClasses = isOnlyLayoutV1
    ? {}
    : {
        variantSuccess: classes.success,
        variantError: classes.error,
        variantWarning: classes.warning,
        variantInfo: classes.info,
      };

  return (
    <SnackbarProvider iconVariant={iconVariant} classes={snackbarClasses} maxSnack={3}>
      {children}
    </SnackbarProvider>
  );
}

export default MCInAppAlertProvider;
