/*
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here. We prefix them with 'componentName' so we avoid
 * reducers accidentally picking up actions they shouldn't.
 *
 * Follow this format:
 * export const YOUR_ACTION_CONSTANT = 'YourContainer/YOUR_ACTION_CONSTANT';
 */

import { ACTION_TYPE_CAMPAIGN_MANAGER } from '../consts.ts';

export const ACTION_TYPE_TRACKING_LINK = 'tracking_link';

export const SET_CURRENT_TRACKING_LINK = `${ACTION_TYPE_CAMPAIGN_MANAGER}/${ACTION_TYPE_TRACKING_LINK}/SET_CURRENT_TRACKING_LINK`;

export const LIST_TRACKING_LINK_REQUEST = `${ACTION_TYPE_CAMPAIGN_MANAGER}/${ACTION_TYPE_TRACKING_LINK}/LIST_TRACKING_LINK_REQUEST`;
export const LIST_TRACKING_LINK_SUCCESS = `${ACTION_TYPE_CAMPAIGN_MANAGER}/${ACTION_TYPE_TRACKING_LINK}/LIST_TRACKING_LINK_SUCCESS`;
export const LIST_TRACKING_LINK_ERROR = `${ACTION_TYPE_CAMPAIGN_MANAGER}/${ACTION_TYPE_TRACKING_LINK}/LIST_TRACKING_LINK_ERROR`;

export const GET_TRACKING_LINK_REQUEST = `${ACTION_TYPE_CAMPAIGN_MANAGER}/${ACTION_TYPE_TRACKING_LINK}/GET_TRACKING_LINK_REQUEST`;
export const GET_TRACKING_LINK_SUCCESS = `${ACTION_TYPE_CAMPAIGN_MANAGER}/${ACTION_TYPE_TRACKING_LINK}/GET_TRACKING_LINK_SUCCESS`;
export const GET_TRACKING_LINK_ERROR = `${ACTION_TYPE_CAMPAIGN_MANAGER}/${ACTION_TYPE_TRACKING_LINK}/GET_TRACKING_LINK_ERROR`;

export const SAVE_TRACKING_LINK_REQUEST = `${ACTION_TYPE_CAMPAIGN_MANAGER}/${ACTION_TYPE_TRACKING_LINK}/SAVE_TRACKING_LINK_REQUEST`;
export const SAVE_TRACKING_LINK_SUCCESS = `${ACTION_TYPE_CAMPAIGN_MANAGER}/${ACTION_TYPE_TRACKING_LINK}/SAVE_TRACKING_LINK_SUCCESS`;
export const SAVE_TRACKING_LINK_ERROR = `${ACTION_TYPE_CAMPAIGN_MANAGER}/${ACTION_TYPE_TRACKING_LINK}/SAVE_TRACKING_LINK_ERROR`;
