// In VideoCreative, we keep companionImages outside of video.
import { VideoResource } from './videoResource';
import { MICreativeProto } from '../proto/creative';
import { MECreativeType } from '../types';
import { ImageResource } from './imageResource';
import { MICreativeVideoSrcProto } from '../proto/creativeSource';

// eslint-disable-next-line import/prefer-default-export
export class VideoCreative {
  public proto: MICreativeProto;
  public id: string;
  public adAccountId: string;
  public title: string;
  public originalFilename: string;
  public type: MECreativeType;
  public sizeInBytes: number;
  public video: VideoResource;
  public createdAt: string;
  public updatedAt: string;

  public companionImages: Array<ImageResource>;

  constructor(proto: MICreativeProto) {
    this.proto = proto || {};

    // Basic proto message part
    this.id = proto.id ?? '';
    this.adAccountId = proto.ad_account_id ?? '';
    this.title = proto.title ?? '';
    this.originalFilename = proto.original_filename ?? '';
    this.type = proto.type ?? MECreativeType.VIDEO;
    this.sizeInBytes = proto.size_in_bytes ?? 0;
    this.video = VideoResource.fromProto(proto.video ?? ({} as MICreativeVideoSrcProto));
    this.video.originalFilename = this.originalFilename;
    this.createdAt = proto.created_at ?? '';
    this.updatedAt = proto.updated_at ?? '';

    // For UI part
    this.companionImages = this.video.companionImages;
  }

  static fromProto = (proto: MICreativeProto) => {
    return new VideoCreative(proto);
  };

  static toProto = (uiObject: VideoCreative) => {
    const proto = uiObject.proto;

    if (uiObject.video) {
      const uiVideo = uiObject.video;
      proto.original_filename = uiVideo.originalFilename; // Put back originalFilename to creative level.
      uiVideo.companionImages = uiObject.companionImages;
      proto.video = VideoResource.toProto(uiVideo);
    } else {
      proto.original_filename = '';
      delete proto.video;
    }

    proto.title = uiObject.title;

    return proto;
  };
}
