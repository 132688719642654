import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import authReducer from './auth/reducer';
import mainReducer from './main/reducer';
import inAppAlertReducer from './inAppAlert/reducer';
import campaignManagerReducer from './campaignManager/reducer';
import inventoryManagerReducer from './inventoryManager/reducer';
import workplaceUserReducer from './workplaceUsers/reducer';
import adAccountUserReducer from './adAccountUsers/reducer.ts';
import userManagerReducer from './userManager/reducer';
import pollingReducer from './polling/reducer.ts';
import notificationReducer from './notification/reducer.ts';

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    auth: authReducer,
    main: mainReducer,
    inAppAlert: inAppAlertReducer,
    polling: pollingReducer,
    campaignManager: campaignManagerReducer,
    inventoryManager: inventoryManagerReducer,
    userManager: userManagerReducer,
    workplaceUsers: workplaceUserReducer,
    adAccountUsers: adAccountUserReducer,
    notification: notificationReducer,
  });
