import { library } from '@fortawesome/fontawesome-svg-core';

import {
  faBell,
  faCog,
  faCaretDown,
  faCaretUp,
  faCircle,
  faCopy,
  faFilter,
  faHistory,
  faTimesCircle,
  faPlusSquare,
  faPen,
  faPoll,
  faTrash,
  faTrashAlt,
  faQuestionCircle,
  faRedo,
  faFileCode,
  faFileInvoiceDollar,
  faHighlighter,
  faThLarge as fasThLarge,
} from '@fortawesome/free-solid-svg-icons';
import { faPlusSquare as farPlusSquare, faEdit } from '@fortawesome/free-regular-svg-icons';
import {
  faArrowUp,
  faArrowDown,
  faArrowLeft,
  faArrowToBottom,
  faCheck,
  faExternalLinkAlt,
  faCode,
  faPlus,
  faMinus,
  faGreaterThan,
  faGreaterThanEqual,
  faLessThan,
  faLessThanEqual,
  faEquals,
  faSearch,
  faTimes,
} from '@fortawesome/pro-regular-svg-icons';
import {
  faAnalytics,
  faArrowAltFromBottom,
  faDatabase,
  faExclamationCircle as fasExclamationCircle,
  faFileCheck,
  faHomeLgAlt,
  faLink as fasLink,
  faMegaphone as fasMegaphone,
  faTasksAlt,
  faUsersClass,
} from '@fortawesome/pro-solid-svg-icons';
import {
  faCalendarDay,
  faChartLine,
  faClock,
  faExclamationCircle as falExclamationCircle,
  faFolder,
  faInfoCircle,
  faPlusCircle,
  faPlusSquare as falPlusSquare,
  faSortAmountDown,
  faTimes as falTimes,
} from '@fortawesome/pro-light-svg-icons';

import {
  faArchive,
  faBullseyeArrow,
  faCog as fadCog,
  faCogs,
  faExchange,
  faExclamationCircle as fadExclamationCircle,
  faSpinnerThird,
  faIdBadge,
  faIdCard,
  faImage,
  faImages,
  faLink,
  faMegaphone,
  faThLarge,
  faUserFriends,
  faUsers,
} from '@fortawesome/pro-duotone-svg-icons';

library.add(
  faAnalytics,
  faArrowUp,
  faArrowDown,
  faArrowToBottom,
  faArchive,
  faArrowAltFromBottom,
  faArrowLeft,
  faBell,
  faBullseyeArrow,
  faCheck,
  faCaretDown,
  faCaretUp,
  faCode,
  faCog,
  fadCog,
  faCopy,
  faCalendarDay,
  faChartLine,
  faClock,
  faCircle,
  faCogs,
  faDatabase,
  faEdit,
  faEquals,
  faExchange,
  fasExclamationCircle,
  fadExclamationCircle,
  falExclamationCircle,
  faExternalLinkAlt,
  faFileCheck,
  faFileCode,
  faFileInvoiceDollar,
  faFilter,
  faFolder,
  faGreaterThan,
  faGreaterThanEqual,
  faHighlighter,
  faHistory,
  faHomeLgAlt,
  faTasksAlt,
  faIdBadge,
  faIdCard,
  faImage,
  faImages,
  faInfoCircle,
  faLink,
  fasLink,
  falTimes,
  faLessThan,
  faLessThanEqual,
  faMegaphone,
  fasMegaphone,
  faMinus,
  faPen,
  faPlus,
  faPlusCircle,
  faPlusSquare,
  falPlusSquare,
  farPlusSquare,
  faPoll,
  faSearch,
  faSortAmountDown,
  faSpinnerThird,
  faThLarge,
  fasThLarge,
  faTimes,
  faTimesCircle,
  faTrash,
  faTrashAlt,
  faUserFriends,
  faUsers,
  faUsersClass,
  faQuestionCircle,
  faRedo,
);
