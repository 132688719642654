import produce from 'immer';
import _ from 'lodash';
import { Publisher } from '../../../common/model/publisher';

import {
  CHANGE_PUBLISHER,
  LIST_PUBLISHERS,
  LIST_PUBLISHERS_SUCCESS,
  LIST_PUBLISHERS_ERROR,
  GET_PUBLISHER,
  GET_PUBLISHER_SUCCESS,
  GET_PUBLISHER_ERROR,
  SAVE_PUBLISHER,
  SAVE_PUBLISHER_SUCCESS,
  SAVE_PUBLISHER_ERROR,
  DELETE_PUBLISHER,
  DELETE_PUBLISHER_SUCCESS,
  DELETE_PUBLISHER_ERROR,
} from './types';

const initState = {
  loading: false,
  updating: false,
  loadError: null,
  updateError: null,
  lastListRequestTs: {},
  lastEntityRequestTs: {},
  /*
    publishers : {
      "exchange_id1": {
        "publisher_id1: {
            "id": ...,
            "title": ...,
            ...
        }
      }
    }
   */
  entities: {},
  currentPublisher: null,
};

function publishersReducer(state = initState, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case CHANGE_PUBLISHER: {
        draft.currentPublisher = action.payload;
        break;
      }
      case LIST_PUBLISHERS: {
        const { exchangeId } = action.payload;

        draft.loading = true;
        draft.loadError = null;
        _.set(draft.lastListRequestTs, [exchangeId], Date.now());
        break;
      }
      case LIST_PUBLISHERS_SUCCESS: {
        const { exchangeId, publishers, cachedPublisher } = action.payload;

        _.set(
          draft.entities,
          exchangeId,
          _.keyBy(publishers, (publisher) => publisher.id),
        );

        if (_.isEmpty(publishers)) {
          draft.currentPublisher = null;
        } else if (_.isEmpty(draft.currentPublisher)) {
          if (cachedPublisher && _.find(publishers, { id: cachedPublisher.id })) {
            draft.currentPublisher = _.find(publishers, {
              id: cachedPublisher.id,
            });
          } else {
            draft.currentPublisher = new Publisher(publishers[0]);
          }
        } else if (!_.find(publishers, { id: draft.currentPublisher.id })) {
          draft.currentPublisher = new Publisher(publishers[0]);
        }

        draft.loading = false;
        break;
      }
      case LIST_PUBLISHERS_ERROR: {
        draft.loading = false;
        draft.loadError = action.error;
        break;
      }

      case GET_PUBLISHER: {
        const { exchangeId, publisherId } = action.payload;

        draft.loading = true;
        draft.loadError = null;
        _.set(draft.lastEntityRequestTs, [exchangeId, publisherId], Date.now());
        break;
      }
      case GET_PUBLISHER_SUCCESS: {
        const { exchangeId, publisherId, publisher } = action.payload;

        draft.loading = false;
        if (!_.has(draft.entities, exchangeId)) {
          draft.entities[exchangeId] = {};
        }
        if (!_.has(draft.entities[exchangeId], publisherId)) {
          draft.entities[exchangeId][publisherId] = {};
        }
        draft.entities[exchangeId][publisherId] = publisher;

        break;
      }
      case GET_PUBLISHER_ERROR: {
        draft.loading = false;
        draft.loadError = action.error;
        break;
      }
      case SAVE_PUBLISHER: {
        draft.updating = true;
        draft.updateError = null;
        break;
      }
      case SAVE_PUBLISHER_SUCCESS: {
        const { exchangeId, publisher } = action.payload;
        const publisherId = publisher.id;

        draft.updating = false;
        if (_.has(draft.entities, exchangeId)) {
          draft.entities[exchangeId][publisherId] = publisher;
        }
        draft.currentPublisher = publisher;
        break;
      }
      case SAVE_PUBLISHER_ERROR: {
        draft.updating = false;
        draft.updateError = action.error;
        break;
      }
      case DELETE_PUBLISHER: {
        draft.updating = true;
        draft.updateError = null;
        break;
      }
      case DELETE_PUBLISHER_SUCCESS: {
        draft.updating = false;
        if (_.has(draft.entities, action.payload.exchange)) {
          delete draft.entities[action.payload.exchange][action.payload.publisherID];
        }
        break;
      }
      case DELETE_PUBLISHER_ERROR: {
        draft.updating = false;
        draft.updateError = action.error;
        break;
      }
      default: {
        break;
      }
    }
    return draft;
  });
}

export default publishersReducer;
