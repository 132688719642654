import produce from 'immer';
import _ from 'lodash';

import {
  LIST_PRICING_MODELS_SUCCESS,
  LIST_PRICING_MODELS,
  LIST_PRICING_MODELS_ERROR,
  GET_PRICING_MODEL_SUCCESS,
  GET_PRICING_MODEL_ERROR,
  GET_PRICING_MODEL,
  SAVE_PRICING_MODEL,
  SAVE_PRICING_MODEL_SUCCESS,
  SAVE_PRICING_MODEL_ERROR,
} from './types';

// The initial state of the PricingModels.
export const initialState = {
  loading: false,
  updating: false,
  loadError: null,
  updateError: null,
  lastListRequestTs: 0,
  lastEntityRequestTs: {},
  entities: {},
};

function pricingModelsReducer(state = initialState, action) {
  // eslint-disable-next-line consistent-return
  return produce(state, (draft) => {
    switch (action.type) {
      case LIST_PRICING_MODELS: {
        draft.loading = true;
        draft.loadError = null;
        draft.lastListRequestTs = Date.now();
        break;
      }
      case LIST_PRICING_MODELS_SUCCESS: {
        draft.loading = false;
        draft.entities = _.keyBy(action.payload, (pricingModel) => pricingModel.id);
        break;
      }
      case LIST_PRICING_MODELS_ERROR: {
        draft.loading = false;
        draft.loadError = action.error;
        break;
      }
      case GET_PRICING_MODEL: {
        const { pricingModelId } = action.payload;

        draft.loading = true;
        draft.loadError = null;
        _.set(draft.lastEntityRequestTs, [pricingModelId], Date.now());
        break;
      }
      case GET_PRICING_MODEL_SUCCESS: {
        const pricingModelObj = action.payload;
        draft.loading = false;
        draft.entities[pricingModelObj.id] = pricingModelObj;
        break;
      }
      case GET_PRICING_MODEL_ERROR: {
        draft.loading = false;
        draft.loadError = action.error;
        break;
      }
      case SAVE_PRICING_MODEL: {
        draft.updating = true;
        draft.updateError = null;
        break;
      }
      case SAVE_PRICING_MODEL_SUCCESS: {
        const pricingModelObj = action.payload;
        draft.updating = false;
        draft.entities[pricingModelObj.id] = pricingModelObj;
        break;
      }
      case SAVE_PRICING_MODEL_ERROR: {
        draft.updating = false;
        draft.updateError = action.error;
        break;
      }
      default: {
        break;
      }
    }
  });
}

export default pricingModelsReducer;
