import { MICreativeImageSrcProto } from '../proto/creativeSource';

// eslint-disable-next-line import/prefer-default-export
export class ImageResource {
  public proto: MICreativeImageSrcProto;
  public imageUrl: string;
  public width: number;
  public height: number;
  public sizeInBytes: number;

  public originalFilename: string;
  public resourceUrl: string;

  constructor(proto: MICreativeImageSrcProto) {
    this.proto = proto;

    // Basic proto message part
    this.imageUrl = proto.image_url ?? '';
    this.width = proto.width ?? 0;
    this.height = proto.height ?? 0;
    this.sizeInBytes = proto.size_in_bytes ?? 0;

    // Special part
    this.originalFilename = proto.original_filename ?? ''; // We put the originalFilename in the resource level to correct proto structure.

    // For UI part
    this.resourceUrl = this.imageUrl; // Use resourceUrl instead of image_url for modularization.
  }

  static fromProto = (proto: MICreativeImageSrcProto) => {
    return new ImageResource(proto);
  };

  static toProto = (uiObject: ImageResource) => {
    const proto = uiObject.proto;

    proto.image_url = uiObject.resourceUrl;
    proto.width = uiObject.width;
    proto.height = uiObject.height;
    proto.size_in_bytes = uiObject.sizeInBytes;

    delete proto.original_filename; // Put back originalFilename to creative level.

    return proto;
  };
}
