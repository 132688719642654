import produce from 'immer';
import _ from 'lodash';

import {
  CHANGE_PRODUCT_CONTEXT,
  LIST_PRODUCT_SUCCESS,
  LIST_PRODUCT_REQUEST,
  LIST_PRODUCT_ERROR,
  GET_PRODUCT_SUCCESS,
  GET_PRODUCT_ERROR,
  GET_PRODUCT_REQUEST,
  SAVE_PRODUCT_REQUEST,
  SAVE_PRODUCT_SUCCESS,
  SAVE_PRODUCT_ERROR,
  DELETE_PRODUCT_REQUEST,
  DELETE_PRODUCT_SUCCESS,
  DELETE_PRODUCT_ERROR,
} from './types';

// The initial state of the Products.
export const initialState = {
  loading: false,
  updating: false,
  loadError: null,
  updateError: null,
  lastListRequestTs: {},
  lastEntityRequestTs: {},
  entities: {},
  currentProduct: null,
};

function productsReducer(state = initialState, action) {
  // eslint-disable-next-line consistent-return
  return produce(state, (draft) => {
    // eslint-disable-next-line default-case
    switch (action.type) {
      case CHANGE_PRODUCT_CONTEXT: {
        draft.currentProduct = action.payload;
        break;
      }
      case LIST_PRODUCT_REQUEST: {
        const { advertiserId } = action.payload;

        draft.loading = true;
        draft.loadError = null;
        _.set(draft.lastListRequestTs, [advertiserId], Date.now());
        break;
      }
      case LIST_PRODUCT_SUCCESS: {
        const { advertiserId, products } = action.payload;
        const keyMap = _.keyBy(products, 'id');

        draft.loading = false;
        _.set(draft.entities, [advertiserId], keyMap);

        if (draft.currentProduct && _.has(keyMap, draft.currentProduct.id)) {
          draft.currentProduct = keyMap[draft.currentProduct.id];
        }

        break;
      }
      case LIST_PRODUCT_ERROR: {
        const { error } = action.payload;

        draft.loading = false;
        draft.loadError = error;
        break;
      }
      case GET_PRODUCT_REQUEST: {
        const { advertiserId, productId } = action.payload;

        draft.loading = true;
        draft.loadError = null;
        _.set(draft.lastEntityRequestTs, [advertiserId, productId], Date.now());
        break;
      }
      case GET_PRODUCT_SUCCESS: {
        const product = action.payload.product;

        draft.loading = false;
        _.set(
          draft.entities,
          [
            product.adAccountId || product.advertiserId, // use advertiserId if adAccountId does not exists to keep compatibility with v1 model
            product.id,
          ],
          product,
        );

        if (draft.currentProduct && draft.currentProduct.id === product.id) {
          draft.currentProduct = product;
        }
        break;
      }
      case GET_PRODUCT_ERROR: {
        const { error } = action.payload;

        draft.loading = false;
        draft.loadError = error;
        break;
      }
      case SAVE_PRODUCT_REQUEST: {
        draft.updating = true;
        draft.updateError = null;
        break;
      }
      case SAVE_PRODUCT_SUCCESS: {
        const advertiserId = action.payload.advertiserId;
        const product = action.payload.product;
        draft.updating = false;
        _.set(draft.entities, [advertiserId, product.id], product);

        if (draft.currentProduct && draft.currentProduct.id === product.id) {
          draft.currentProduct = product;
        }
        break;
      }
      case SAVE_PRODUCT_ERROR: {
        const { error } = action.payload;

        draft.updating = false;
        draft.updateError = error;
        break;
      }
      case DELETE_PRODUCT_REQUEST: {
        draft.updating = true;
        draft.updateError = null;
        break;
      }
      case DELETE_PRODUCT_SUCCESS: {
        const { advertiserId, productId } = action.payload;

        draft.updating = false;
        _.unset(draft.entities, [advertiserId, productId]);

        if (draft.currentProduct && draft.currentProduct.id === productId) {
          draft.currentProduct = null;
        }
        break;
      }
      case DELETE_PRODUCT_ERROR: {
        const { error } = action.payload;

        draft.updating = false;
        draft.updateError = error;
        break;
      }
      default:
        break;
    }
  });
}

export default productsReducer;
