// eslint-disable-next-line max-classes-per-file
import _ from 'lodash';
import { validateUIObject, Validators } from '../utils/modelUtils';
import {
  APP_MARKET_OPTIONS,
  IAB_OPTIONS_FLATTEN as IAB_OPTIONS,
  OS_OPTIONS,
  PRODUCT_TYPE_OPTIONS,
} from '../constants/common';
import { FIELD_TYPE } from '../../components/Common/Formik/consts';
import { MEAppMarkets, MEOs, MEProductType } from '../types';
import {
  MIAppProto,
  MIMMPIntegrationProto,
  MIPostbackIntegrationEventProto,
  MIPostbackIntegrationEventSummaryProto,
  MIPostbackIntegrationProto,
  MIProductProto,
  MIWebProto,
} from '../proto/product';

export enum MEInAppEventTimeWindows {
  TODAY = 'TIME_WINDOW_TODAY',
  D30 = 'TIME_WINDOW_D30',
  D90 = 'TIME_WINDOW_D90',
}

export enum MEInAppEventRevenue {
  REVENUE_ALL = 'REVENUE_ALL',
  REVENUE_TRUE = 'REVENUE_TRUE',
  REVENUE_FALSE = 'REVENUE_FALSE',
}

export enum MEInAppEventAttribution {
  ATTRIBUTION_ALL = 'ATTRIBUTION_ALL',
  ATTRIBUTED = 'ATTRIBUTED',
  UNATTRIBUTED = 'UNATTRIBUTED',
}

export interface MIInAppEventCondition {
  timeWindow: MEInAppEventTimeWindows;
  revenue: MEInAppEventRevenue;
  attribution: MEInAppEventAttribution;
}

export class Web {
  private readonly proto: MIWebProto;
  public category: string = '';
  public landingUrl: string = ''; // TODO remove after deploy CM18

  constructor(proto: MIWebProto) {
    this.proto = proto;
    if (proto.category) this.category = proto.category;
    if (proto.landing_url) this.landingUrl = proto.landing_url; // TODO remove after deploy CM18
  }

  static fromProto(proto: MIWebProto) {
    return new Web(proto);
  }

  static toProto(uiWeb: Web) {
    const proto = _.cloneDeep<MIWebProto>(uiWeb.proto);
    proto.category = uiWeb.category;
    proto.landing_url = uiWeb.landingUrl; // TODO remove after deploy CM18
    return proto;
  }

  static getValidationSchema() {
    return {
      category: [Validators.required],
    };
  }

  static getUISchema() {
    return {
      category: {
        fieldType: FIELD_TYPE.SEARCHABLE_SELECT,
        label: 'Category',
        required: true,
        placeholder: 'Please select a category',
        options: IAB_OPTIONS,
        isMulti: false,
        hideCatalog: true,
        showCatalogNavigator: false,
        optionHeight: 200,
      },
      // TODO remove after deploy CM18
      landingUrl: {
        fieldType: FIELD_TYPE.TEXT_FIELD,
        label: 'Final Landing URL',
        required: true,
        placeholder: 'Please enter a Final landing URL',
        hint:
          'The final landing URL of this product’s ad. Do not enter a tracker’s link. (E.g. http://example.com)',
      },
    };
  }
}

class PostbackIntegration {
  private readonly proto: MIPostbackIntegrationProto;
  public mmp: string = '';
  public bundleId: string = '';

  constructor(proto: MIPostbackIntegrationProto) {
    this.proto = proto;
    if (proto.mmp) this.mmp = proto.mmp;
    if (proto.bundle_id) this.bundleId = proto.bundle_id;
  }

  static toProto(uiPostbackIntegration: PostbackIntegration) {
    const proto = _.cloneDeep<MIPostbackIntegrationProto>(uiPostbackIntegration.proto);
    proto.mmp = uiPostbackIntegration.mmp;
    proto.bundle_id = uiPostbackIntegration.bundleId;
    return proto;
  }

  static getUISchema(isCreate: boolean) {
    return {
      mmp: {
        fieldType: FIELD_TYPE.SELECT_FIELD,
        label: 'MMP',
        required: true,
        disabled: !isCreate,
      },
      bundleId: {
        fieldType: FIELD_TYPE.TEXT_FIELD,
        label: 'MMP Bundle ID',
        required: true,
        placeholder: 'Please enter a MMP bundle ID',
        hint: 'Bundle registered in MMP.',
        disabled: !isCreate,
      },
    };
  }

  static getValidationSchema() {
    return {
      mmp: [Validators.required],
      bundleId: [Validators.required],
    };
  }
}

class MMPIntegration {
  private readonly proto: MIMMPIntegrationProto = {} as MIMMPIntegrationProto;
  public id: string = '';
  public mmp: string = '';
  public mmpBundleId: string = '';
  public status: string = '';
  public deniedReason: string = '';
  public updatedAt: string = '';

  constructor(proto: MIMMPIntegrationProto) {
    this.proto = proto;
    if (proto.id) this.id = proto.id;
    if (proto.mmp) this.mmp = proto.mmp;
    if (proto.mmp_bundle_id) this.mmpBundleId = proto.mmp_bundle_id;
    if (proto.status) this.status = proto.status;
    if (proto.denied_reason) this.deniedReason = proto.denied_reason;
    if (proto.updated_at) this.updatedAt = proto.updated_at;
  }

  static toProto(uiMMPIntegration: MMPIntegration) {
    // TODO: Update proto field to MMPIntegration object
    return _.cloneDeep<MIMMPIntegrationProto>(uiMMPIntegration.proto);
  }
}

export class App {
  private readonly proto: MIAppProto = {} as MIAppProto;
  public bundleId: string = '';
  public category: string = '';
  public appStoreUrl: string = '';
  public rating: number = 4.5;

  public landingUrl: string = '';

  public postbackIntegration: PostbackIntegration = new PostbackIntegration(
    {} as MIPostbackIntegrationProto,
  );

  public mmpIntegration: MMPIntegration = new MMPIntegration({} as MIMMPIntegrationProto);

  constructor(proto: MIAppProto) {
    this.proto = proto;
    if (proto.bundle_id) this.bundleId = proto.bundle_id;
    if (proto.category) this.category = proto.category;
    if (proto.app_store_url) this.appStoreUrl = proto.app_store_url;
    if (proto.rating) this.rating = proto.rating;
    if (proto.landing_url) this.landingUrl = proto.landing_url;
    if (proto.postback_integration)
      this.postbackIntegration = new PostbackIntegration(proto.postback_integration);
    if (proto.mmp_integration) this.mmpIntegration = new MMPIntegration(proto.mmp_integration);
  }

  static fromProto(proto: MIAppProto) {
    return new App(proto);
  }

  static toProto(uiApp: App) {
    const proto = _.cloneDeep<MIAppProto>(uiApp.proto);

    proto.bundle_id = uiApp.bundleId;
    proto.category = uiApp.category;
    proto.app_store_url = uiApp.appStoreUrl;
    proto.rating = uiApp.rating;

    // Not used yet.
    // proto.landing_url = uiApp.landingUrl;

    proto.postback_integration = PostbackIntegration.toProto(uiApp.postbackIntegration);

    // mmpIntegration fields are currently read-only, so we exclude them from proto
    // proto.mmp_integration = MMPIntegration.toProto(uiApp.mmpIntegration);

    return proto;
  }

  static getValidationSchema(deviceOs: MEOs, storeUrl: string) {
    return {
      bundleId: [
        Validators.required,
        deviceOs === MEOs.ANDROID ? Validators.androidBundleId(storeUrl) : Validators.iosBundleId,
      ],
      appStoreUrl: [Validators.required],
      category: [Validators.required],
      postbackIntegration: PostbackIntegration.getValidationSchema(),
    };
  }

  static getUISchema(deviceOs: MEOs, isCreate: boolean) {
    const isAndroid = deviceOs === MEOs.ANDROID;
    const storeName = isAndroid ? 'market' : 'store';
    return {
      bundleId: {
        fieldType: FIELD_TYPE.TEXT_FIELD,
        label: `App ${storeName} bundle`,
        required: true,
        placeholder: `Please enter the App ${storeName} bundle id`,
        hint: `App bundle in the ${storeName}.`,
      },
      category: {
        fieldType: FIELD_TYPE.SEARCHABLE_SELECT,
        label: 'Category',
        required: true,
        placeholder: 'Please select a category',
        options: IAB_OPTIONS,
        isMulti: false,
        hideCatalog: true,
        showCatalogNavigator: false,
        optionHeight: 200,
      },
      appStoreUrl: {
        fieldType: FIELD_TYPE.TEXT_FIELD,
        label: `App ${storeName} URL`,
        required: true,
        placeholder: `Please enter the App ${storeName} URL`,
        hint: `${
          isAndroid
            ? 'URLs of Google Play, Onestore and Galaxy Store are supported.'
            : 'Apple App Store URL. We consider https:// for the default protocol.'
        } Mainly used for the ADX creative review.`,
      },
      landingUrl: {
        fieldType: FIELD_TYPE.TEXT_FIELD,
        label: 'Landing URL',
        placeholder: 'Please enter a landing URL',
      },
      postbackIntegration: PostbackIntegration.getUISchema(isCreate),
    };
  }
}

export class PostbackIntegrationEvent {
  private readonly proto: MIPostbackIntegrationEventProto = {} as MIPostbackIntegrationEventProto;
  public eventName: string = '';
  public totalCount: number = 0;
  public attributedCount: number = 0;
  public isRevenue: boolean = false;

  constructor(proto: MIPostbackIntegrationEventProto) {
    this.proto = proto;
    if (proto.event_name) this.eventName = proto.event_name;
    if (proto.total_count) this.totalCount = proto.total_count;
    if (proto.attributed_count) this.attributedCount = proto.attributed_count;
    if (proto.is_revenue) this.isRevenue = true;
  }
}

export class PostbackIntegrationEventSummary {
  private readonly proto: MIPostbackIntegrationEventSummaryProto = {} as MIPostbackIntegrationEventSummaryProto;
  public lastModifiedAt: string | null = null;
  public summary: Array<PostbackIntegrationEvent> = [];

  constructor(proto: MIPostbackIntegrationEventSummaryProto) {
    this.proto = proto;
    if (proto.last_modified_at) this.lastModifiedAt = proto.last_modified_at;
    if (proto.summary)
      this.summary = proto.summary.map((event) => new PostbackIntegrationEvent(event));
  }
}

export class Product {
  static TITLE_LENGTH_MAX = 50;
  static DESC_LENGTH_MAX = 200;

  private readonly proto: MIProductProto = {} as MIProductProto;
  public id: string = '';
  public adAccountId: string = '';
  public title: string = '';
  public description: string = '';
  public advertiserDomain: string = '';
  public deviceOs: MEOs = MEOs.ANDROID;
  public type: MEProductType = MEProductType.APP;

  public app: App = new App({} as MIAppProto);
  public web: Web = new Web({} as MIWebProto);
  public market: MEAppMarkets = MEAppMarkets.GOOGLE_PLAY;

  public postbackIntegrationEventSummary: PostbackIntegrationEventSummary = new PostbackIntegrationEventSummary(
    {} as MIPostbackIntegrationEventSummaryProto,
  );

  public createdAt: string = '';
  public updatedAt: string = '';

  constructor(proto: MIProductProto) {
    this.proto = proto;
    if (proto.id) this.id = proto.id;
    if (proto.ad_account_id) this.adAccountId = proto.ad_account_id;
    if (proto.title) this.title = proto.title;
    if (proto.description) this.description = proto.description;
    if (proto.advertiser_domain) this.advertiserDomain = proto.advertiser_domain;
    if (proto.device_os) {
      this.deviceOs = proto.device_os;
      this.market =
        proto.device_os === MEOs.ANDROID ? MEAppMarkets.GOOGLE_PLAY : MEAppMarkets.APP_STORE;
    }
    if (proto.type) this.type = proto.type;
    if (proto.app) this.app = new App(proto.app || ({} as MIAppProto));
    if (proto.web) this.web = new Web(proto.web || ({} as MIWebProto));
    if (proto.created_at) this.createdAt = proto.created_at;
    if (proto.updated_at) this.updatedAt = proto.updated_at;
  }

  static fromProto = (
    productProto: MIProductProto,
    postbackIntegrationEventSummaryProto?: MIPostbackIntegrationEventSummaryProto,
  ) => {
    const object = new Product(productProto);

    if (object.type === MEProductType.APP) {
      if (postbackIntegrationEventSummaryProto) {
        object.postbackIntegrationEventSummary = new PostbackIntegrationEventSummary(
          postbackIntegrationEventSummaryProto,
        );
      }
    }

    return object;
  };

  static toProto = (uiProduct: Product) => {
    const proto = _.cloneDeep<MIProductProto>(uiProduct.proto);

    proto.title = uiProduct.title;
    proto.description = uiProduct.description;
    proto.advertiser_domain = uiProduct.advertiserDomain;
    proto.device_os = uiProduct.deviceOs;
    proto.type = uiProduct.type;

    if (uiProduct.type === MEProductType.APP) {
      proto.app = App.toProto(uiProduct.app);
      delete proto.web;
    } else {
      proto.web = Web.toProto(uiProduct.web);
      delete proto.app;
    }

    return proto;
  };

  static getValidationSchema = (object: Product) => {
    return {
      title: [
        Validators.required,
        Validators.noSpecialChar,
        Validators.maxTextLength(Product.TITLE_LENGTH_MAX),
      ],
      description: [Validators.maxTextLength(Product.DESC_LENGTH_MAX)],
      advertiserDomain: [Validators.required, Validators.advertiserDomain],
      deviceOs: [Validators.required],
      app:
        object.type === MEProductType.APP
          ? App.getValidationSchema(object.deviceOs, object.app.appStoreUrl)
          : [],
      web: object.type === MEProductType.WEB ? Web.getValidationSchema() : [],
    };
  };

  static getUISchema = (deviceOs: MEOs, isCreate: boolean) => {
    return {
      id: {
        fieldType: FIELD_TYPE.TEXT_FIELD,
        label: 'ID(Debugging)',
        disabled: true,
      },
      title: {
        fieldType: FIELD_TYPE.TEXT_FIELD,
        label: 'Title',
        required: true,
        placeholder: 'Please enter a title',
        useCharacterCounter: true,
        totalCharacterLimit: Product.TITLE_LENGTH_MAX,
      },
      description: {
        fieldType: FIELD_TYPE.TEXT_FIELD,
        label: 'Description',
        placeholder: 'Please enter a description (optional)',
        useCharacterCounter: true,
        totalCharacterLimit: Product.DESC_LENGTH_MAX,
      },
      advertiserDomain: {
        fieldType: FIELD_TYPE.TEXT_FIELD,
        label: 'Domain',
        required: true,
        placeholder: 'Please enter domain',
        hint: `The domain part of the URL, except 'http://' and 'www.'. (E.g., google.com, apple.com)`,
      },
      deviceOs: {
        fieldType: FIELD_TYPE.RADIO_GROUP,
        label: 'OS',
        required: true,
        options: OS_OPTIONS,
        disabled: !isCreate,
      },
      type: {
        fieldType: FIELD_TYPE.RADIO_GROUP,
        label: 'Type',
        required: true,
        options: PRODUCT_TYPE_OPTIONS,
        disabled: !isCreate,
      },
      market: {
        fieldType: FIELD_TYPE.RADIO_GROUP,
        label: 'Market',
        required: true,
        options: APP_MARKET_OPTIONS,
        disabled: !isCreate,
      },
      app: App.getUISchema(deviceOs, isCreate),
      web: Web.getUISchema(),
    };
  };

  static validateUIObject = (object: any) => {
    return validateUIObject(object, Product.getValidationSchema(object));
  };
}
