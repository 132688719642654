import produce from 'immer';
import _ from 'lodash';
import {
  LIST_CREATIVE_REVIEWS,
  LIST_CREATIVE_REVIEWS_SUCCESS,
  LIST_CREATIVE_REVIEWS_ERROR,
  SAVE_CREATIVE_REVIEWS,
  SAVE_CREATIVE_REVIEWS_SUCCESS,
  SAVE_CREATIVE_REVIEWS_ERROR,
} from './types';

const initState = {
  loading: false,
  updating: false,
  loadError: null,
  updateError: null,
  lastListRequestTs: {},
  /*
    creativeReviews : {
      "exchange_id1": {
        "creative_review_id1": {
            "id": ...,
            "creative_id": ...,
            "status": ...,
        }
      }
    }


   */
  entities: {},
};

function creativeReviewsReducer(state = initState, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case LIST_CREATIVE_REVIEWS: {
        const { exchangeId } = action.payload;

        draft.loading = true;
        draft.loadError = null;
        _.set(draft.lastListRequestTs, [exchangeId], Date.now());
        break;
      }
      case LIST_CREATIVE_REVIEWS_SUCCESS: {
        const { exchangeId, creativeReviews } = action.payload;

        draft.loading = false;
        _.set(
          draft.entities,
          exchangeId,
          _.keyBy(creativeReviews, (creativeReview) => creativeReview.id),
        );
        break;
      }
      case LIST_CREATIVE_REVIEWS_ERROR: {
        draft.loading = false;
        draft.loadError = action.error;
        break;
      }
      case SAVE_CREATIVE_REVIEWS: {
        draft.updating = true;
        draft.updateError = null;
        break;
      }
      case SAVE_CREATIVE_REVIEWS_ERROR: {
        draft.updating = false;
        draft.updateError = action.error;
        break;
      }
      case SAVE_CREATIVE_REVIEWS_SUCCESS: {
        draft.updating = false;
        break;
      }
      default: {
        break;
      }
    }
    return draft;
  });
}

export default creativeReviewsReducer;
