import React from 'react';
import PropTypes from 'prop-types';

import MCErrorScreen from './MCErrorScreen.tsx';
import bugsnagClient from '../../../common/utils/bugsnag';

class MCErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
    };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    // notify to Bugsang
    bugsnagClient.notify(error, { errorInfo });
  }

  render() {
    const { hasError, error } = this.state;
    const { children } = this.props;

    if (hasError) {
      return <MCErrorScreen error={error} />;
    }

    return children;
  }
}

MCErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
};

export default MCErrorBoundary;
