// eslint-disable-next-line import/prefer-default-export
export enum MERouteKeys {
  // invalid
  INVALID = 'INVALID',

  // root
  PLATFORM_ROOT = 'PLATFORM_ROOT',

  // cm
  CAMPAIGN_MANAGER_MAIN = 'CAMPAIGN_MANAGER_MAIN',

  // overview
  OVERVIEW_MAIN = 'OVERVIEW_MAIN',
  ADVERTISER_OVERVIEW = 'ADVERTISER_OVERVIEW',
  APPLICATION_OVERVIEW = 'APPLICATION_OVERVIEW',
  CAMPAIGN_OVERVIEW = 'CAMPAIGN_OVERVIEW',
  CAMPAIGN_CREATE = 'CAMPAIGN_CREATE',
  CAMPAIGN_CREATE_UNDER_APPLICATION = 'CAMPAIGN_CREATE_UNDER_APPLICATION',
  CAMPAIGN_EDIT = 'CAMPAIGN_EDIT',
  AD_GROUP_OVERVIEW = 'AD_GROUP_OVERVIEW',
  AD_GROUP_CREATE = 'AD_GROUP_CREATE',
  AD_GROUP_EDIT = 'AD_GROUP_EDIT',
  OPERATION_ITEM_OVERVIEW = 'OPERATION_ITEM_OVERVIEW',
  OPERATION_ITEM_CREATE = 'OPERATION_ITEM_CREATE',
  OPERATION_ITEM_EDIT = 'OPERATION_ITEM_EDIT',
  CREATIVE_GROUPS_OVERVIEW = 'CREATIVE_GROUPS_OVERVIEW',
  CREATIVE_GROUP_DETAIL = 'CREATIVE_GROUP_DETAIL',
  CREATIVE_GROUP_CREATE = 'CREATIVE_GROUP_CREATE',
  CREATIVE_GROUP_EDIT = 'CREATIVE_GROUP_EDIT',
  CREATIVES_OVERVIEW = 'CREATIVES_OVERVIEW',

  // reports
  REPORTS_MAIN = 'REPORTS_MAIN',

  // analytics
  ANALYTICS_MAIN = 'ANALYTICS_MAIN',
  ANALYTICS_CREATIVE_ANALYSIS = 'ANALYTICS_CREATIVE_ANALYSIS',
  ANALYTICS_INVENTORY_ANALYSIS = 'ANALYTICS_INVENTORY_ANALYSIS',
  ANALYTICS_EXCHANGE_ANALYSIS = 'ANALYTICS_EXCHANGE_ANALYSIS',
  ANALYTICS_COHORT_ROAS_ANALYSIS_UA = 'ANALYTICS_COHORT_ROAS_ANALYSIS_UA',
  ANALYTICS_COHORT_ACTION_ANALYSIS_UA = 'ANALYTICS_COHORT_ACTION_ANALYSIS_UA',
  ANALYTICS_COHORT_ROAS_ANALYSIS_RE = 'ANALYTICS_COHORT_ROAS_ANALYSIS_RE',
  ANALYTICS_COHORT_ACTION_ANALYSIS_RE = 'ANALYTICS_COHORT_ACTION_ANALYSIS_RE',
  ANALYTICS_REPORT_DATA_DOWNLOAD = 'ANALYTICS_REPORT_DATA_DOWNLOAD',
  ANALYTICS_REPORT_DATA_TRANSFER = 'ANALYTICS_REPORT_DATA_TRANSFER',
  ANALYTICS_REPORT_DATA_TRANSFER_CREATE = 'ANALYTICS_REPORT_DATA_TRANSFER_CREATE',
  ANALYTICS_REPORT_DATA_TRANSFER_EDIT = 'ANALYTICS_REPORT_DATA_TRANSFER_EDIT',
  ANALYTICS_REPORT_PARTNER_INTEGRATION = 'ANALYTICS_REPORT_PARTNER_INTEGRATION',

  // assets
  ASSETS_MAIN = 'ASSETS_MAIN',
  APPLICATIONS = 'APPLICATIONS',
  APPLICATION_DETAIL = 'APPLICATION_DETAIL',
  APPLICATION_CREATE = 'APPLICATION_CREATE',
  APPLICATION_EDIT = 'APPLICATION_EDIT',
  MMP_INTEGRATIONS = 'MMP_INTEGRATIONS',
  TRACKING_LINKS = 'TRACKING_LINKS',
  TRACKING_LINK_DETAIL = 'TRACKING_LINK_DETAIL',
  TRACKING_LINK_CREATE = 'TRACKING_LINK_CREATE',
  TRACKING_LINK_EDIT = 'TRACKING_LINK_EDIT',
  CREATIVE_REVIEW_STATUS = 'CREATIVE_REVIEW_STATUS',

  // targets
  TARGETS_MAIN = 'TARGETS_MAIN',
  CUSTOM_TARGETS = 'CUSTOM_TARGETS',
  CUSTOM_TARGET_DETAIL = 'CUSTOM_TARGET_DETAIL',
  CUSTOM_TARGET_CREATE = 'CUSTOM_TARGET_CREATE',
  CUSTOM_TARGET_EDIT = 'CUSTOM_TARGET_EDIT',
  CUSTOMER_SETS = 'CUSTOMER_SETS',
  CUSTOMER_SET_DETAIL = 'CUSTOMER_SET_DETAIL',
  CUSTOMER_SET_CREATE = 'CUSTOMER_SET_CREATE',
  CUSTOMER_SET_EDIT = 'CUSTOMER_SET_EDIT',
  APP_ACTIVITIES = 'APP_ACTIVITIES',
  APP_ACTIVITY_DETAIL = 'APP_ACTIVITY_DETAIL',
  APP_ACTIVITY_CREATE = 'APP_ACTIVITY_CREATE',
  APP_ACTIVITY_EDIT = 'APP_ACTIVITY_EDIT',

  // log data
  LOG_DATA_MAIN = 'LOG_DATA_MAIN',
  LOG_DATA_DOWNLOAD = 'LOG_DATA_DOWNLOAD',
  LOG_DATA_TRANSFER = 'LOG_DATA_TRANSFER',
  LOG_DATA_TRANSFER_CREATE = 'LOG_DATA_TRANSFER_CREATE',
  LOG_DATA_TRANSFER_EDIT = 'LOG_DATA_TRANSFER_EDIT',

  // settings
  CM_SETTINGS_MAIN = 'SETTINGS_MAIN',
  CM_SETTINGS_ADVERTISER_INFO = 'CM_SETTINGS_ADVERTISER_INFO',
  ADVERTISER_INFO_EDIT = 'ADVERTISER_INFO_EDIT',
  CM_SETTINGS_AD_ACCOUNT_USERS = 'CM_SETTINGS_AD_ACCOUNT_USERS',
  AD_ACCOUNT_USER_CREATE = 'AD_ACCOUNT_USER_CREATE',
  AD_ACCOUNT_USER_EDIT = 'AD_ACCOUNT_USER_EDIT',
  CM_SETTINGS_REPORT_EXPORT = 'CM_SETTINGS_REPORT_EXPORT',
  REPORT_EXPORT_CREATE = 'REPORT_EXPORT_CREATE',

  // user settings
  USER_SETTINGS_MAIN = 'USER_SETTINGS_MAIN',
  USER_SETTINGS_MY_INFO = 'USER_SETTINGS_MY_INFO',
  USER_SETTINGS_MY_INFO_EDIT = 'USER_SETTINGS_MY_INFO_EDIT',
  USER_SETTINGS_MY_AD_ACCOUNT = 'USER_SETTINGS_MY_AD_ACCOUNT',
  USER_SETTINGS_AD_ACCOUNT_CREATE = 'USER_SETTINGS_AD_ACCOUNT_CREATE',
  USER_SETTINGS_CHANGE_PASSWORD = 'USER_SETTINGS_CHANGE_PASSWORD',
  USER_SETTINGS_NOTIFICATION = 'USER_SETTINGS_NOTIFICATION',

  // admin
  ADMIN_LOGS_MAIN = 'ADMIN_LOGS_MAIN',
  ADMIN_REPORT_MAIN = 'ADMIN_REPORT_MAIN',
  ADMIN_SETTINGS_MAIN = 'ADMIN_SETTINGS_MAIN',
  ADMIN_SETTINGS_PLATFORM_INFO = 'ADMIN_SETTINGS_PLATFORM_INFO',
  ADMIN_SETTINGS_PLATFORM_EDIT = 'ADMIN_SETTINGS_PLATFORM_EDIT',
  ADMIN_SETTINGS_AD_ACCOUNTS = 'ADMIN_SETTINGS_AD_ACCOUNTS',
  ADMIN_SETTINGS_AD_ACCOUNT_CREATE = 'ADMIN_SETTINGS_AD_ACCOUNT_CREATE',
  ADMIN_SETTINGS_USERS = 'ADMIN_SETTINGS_USERS',
  PLATFORM_USER_CREATE = 'PLATFORM_USER_CREATE',
  PLATFORM_USER_EDIT = 'PLATFORM_USER_EDIT',
  ADMIN_BILLING_MAIN = 'ADMIN_BILLING_MAIN',
  ADMIN_BILLING_SPENDING_OVERVIEW = 'ADMIN_BILLING_SPENDING_OVERVIEW',

  // switch workplace
  SWITCH_WORKPLACE = 'SWITCH_WORKPLACE',

  // experimental features
  LAB_MAIN = 'LAB_MAIN',
  // TODO: delete temporary route
  LAB_REPORTS = 'LAB_REPORTS', // refactored report menu for MRP implementation
  LAB_OVERVIEW = 'LAB_OVERVIEW', // refactored overview menu
  LAB_ADVERTISER_OVERVIEW = 'LAB_ADVERTISER_OVERVIEW',
  LAB_APPLICATION_OVERVIEW = 'LAB_APPLICATION_OVERVIEW',
  LAB_CAMPAIGN_OVERVIEW = 'LAB_CAMPAIGN_OVERVIEW',
}

export type MTRouteParams = {
  platformId: string;
  advertiserId?: string;
  appId?: string;
  campaignId?: string;
  adGroupId?: string;
};
