import _ from 'lodash';

/*
dispatch({
  ...,
  error,
  bugsnagMeta: { ... },
})
*/

export default (Bugsnag) => () => (next) => (action) => {
  const errorMessage = _.get(action, 'error', null) || _.get(action, ['payload', 'error'], null);
  const bugsnagMeta = _.get(action, 'bugsnagMeta', {});

  if (errorMessage || !_.isEmpty(bugsnagMeta)) {
    const actionType = _.get(action, 'type', null);
    const { error: errorObject, context, ...metaData } = bugsnagMeta;

    const error = errorObject || new Error(errorMessage);
    const opts = {
      context: context || errorMessage,
      metaData: {
        errorMessage,
        actionType,
        ...metaData,
      },
    };

    if (process.env.NODE_ENV === 'development') {
      // eslint-disable-next-line no-console
      console.error(`[Bugsnag Reporter] ${context}: ${errorMessage}`, opts.metaData);
    }
    Bugsnag.notify(error, opts);
  }

  next(action);
};
