import React, { useEffect, useState } from 'react';
import { Form, Formik } from 'formik2';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { confirmPasswordReset, verifyPasswordResetCode } from 'common/firebase';
import { updatePassword } from 'redux/auth/actions';
import { RootState } from 'redux/types';
import * as routes from 'common-v2/constants/routes';
import { validateUIObject, Validators } from 'common-v2/utils/modelUtils';
import { MCFormLabel } from 'components-v2/Common/Form/FormStyledComponents';
import { MEButtonColor, MEButtonVariant } from 'components-v2/Common/Button/MCButton';
import { MEFormFieldWidth } from 'components-v2/Common/Form/consts';
import MCFormTextField from 'components-v2/Common/Form/MCFormTextField';
import MCFormCheckBoxField from 'components-v2/Common/Form/MCFormCheckBoxField';

import {
  SCAuthButton,
  SCAuthErrorMessage,
  SCFormWrapper,
  SCNormalText,
  SCPageTitle,
} from './AuthStyledComponents';
import { ACTION_MODE } from './consts';
import MCAuthLayout from './MCAuthLayout';

const STATUS = {
  READY: 0,
  REQUEST: 1,
  DONE: 2,
  ERROR: 3,
};

const STATUS_MESSAGE = {
  [STATUS.READY]: 'Reset your password.',
  [STATUS.REQUEST]: 'Try sending change password request.',
  [STATUS.DONE]: 'Successfully changed the password. Please login again.',
};

const RESET_PASSWORD_SCHEMA = {
  password: [Validators.required, Validators.password],
  confirmPassword: [Validators.required, Validators.password],
  agreePolicy: [Validators.oneOf([true])],
};

export const SCAgreementText = styled.span`
  padding-left: 7px;
  font-size: 11px;
  font-weight: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: normal;
  color: #999999;
`;

export const SCAgreement = styled.div`
  display: flex;
`;

type MTResetPasswordInfo = {
  password: string;
  confirmPassword: string;
  agreePolicy: boolean;
};

type MTResetPasswordProps = {
  mode?: string;
  oobCode?: string;
  email?: string;
  token?: string;
};

export default function MCResetPassword(props: MTResetPasswordProps): React.ReactElement {
  const { mode, oobCode, email: propEmail = '', token } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const [email, setEmail] = useState(propEmail);
  const [passwordConfirmed, setPasswordConfirmed] = useState(false);
  const [error, setError] = useState('');
  const [paramError, setParamError] = useState('');
  const [status, setStatus] = useState(STATUS.READY);
  const useMorse = useSelector((state: RootState) => state.auth.useMorse);

  useEffect(() => {
    if (!useMorse && oobCode) {
      try {
        verifyPasswordResetCode(oobCode)
          .then((usedEmail) => {
            setEmail(usedEmail);
          })
          .catch((errMsg) => {
            setParamError(errMsg);
          });
      } catch (ex) {
        setParamError(ex.message);
      }
    }
  }, [useMorse, oobCode]);

  const handleRequestBack = () => {
    history.push(routes.SIGN_IN);
  };

  const handleValidation = (
    values: MTResetPasswordInfo,
  ): { password?: string; confirmPassword?: string; agreePolicy?: string } => {
    // reset error message
    if (error) {
      setError('');
    }

    setPasswordConfirmed(values.confirmPassword === values.password);

    return validateUIObject(values, RESET_PASSWORD_SCHEMA);
  };

  const handleRequestResetPassword = async (values: MTResetPasswordInfo) => {
    if (useMorse) {
      setError('');
      setStatus(STATUS.REQUEST);

      dispatch(
        updatePassword(token, email, values.password, (errMsg: string) => {
          if (errMsg) {
            setError(errMsg);
            setStatus(STATUS.READY);
          } else {
            setStatus(STATUS.DONE);
          }
        }),
      );
    } else {
      try {
        setError('');
        setStatus(STATUS.REQUEST);

        await confirmPasswordReset(oobCode, values.password);
        setStatus(STATUS.DONE);
      } catch (ex) {
        setError(ex);
        setStatus(STATUS.READY);
      }
    }
  };

  const getPasswordResetForm = () => {
    return (
      <>
        <SCPageTitle>
          {mode === ACTION_MODE.CREATE_PASSWORD
            ? 'Set a new password for your account.'
            : 'Please enter a new password.'}
        </SCPageTitle>
        <MCFormLabel>Email</MCFormLabel>
        <SCNormalText>{propEmail}</SCNormalText>

        <Formik
          validateOnChange
          enableReinitialize
          initialValues={{
            password: '',
            confirmPassword: '',
            agreePolicy: mode !== ACTION_MODE.CREATE_PASSWORD,
          }}
          validate={handleValidation}
          onSubmit={handleRequestResetPassword}
        >
          {(formikProps) => {
            return (
              <Form>
                <SCFormWrapper>
                  <MCFormTextField
                    type="password"
                    name="password"
                    width={MEFormFieldWidth.FULL}
                    label="New Password"
                    placeholder="******"
                    disabled={status !== STATUS.READY}
                  />
                  <MCFormTextField
                    type="password"
                    name="confirmPassword"
                    width={MEFormFieldWidth.FULL}
                    label="Confirm New Password"
                    placeholder="******"
                    disabled={status !== STATUS.READY}
                  />
                  {mode === ACTION_MODE.CREATE_PASSWORD && (
                    // TODO: e2e test for accessing privacy policy page.
                    <SCAgreement>
                      <MCFormCheckBoxField name="agreePolicy" />
                      <SCAgreementText>
                        I agree to{' '}
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href="https://www.molocoads.com/privacy-policy"
                        >
                          the Privacy Policy
                        </a>{' '}
                        and to receive occasional emails from MOLOCO.
                      </SCAgreementText>
                    </SCAgreement>
                  )}
                  {error && <SCAuthErrorMessage>{error}</SCAuthErrorMessage>}
                  {status !== STATUS.READY && <SCNormalText>{STATUS_MESSAGE[status]}</SCNormalText>}
                </SCFormWrapper>

                {status !== STATUS.DONE ? (
                  <SCAuthButton
                    testId="resetPwSubmit"
                    variant={MEButtonVariant.CONTAINED}
                    color={MEButtonColor.PRIMARY}
                    onClick={formikProps.submitForm}
                    disabled={!formikProps.isValid || !passwordConfirmed || status !== STATUS.READY}
                  >
                    Confirm
                  </SCAuthButton>
                ) : (
                  <SCAuthButton
                    variant={MEButtonVariant.CONTAINED}
                    color={MEButtonColor.PRIMARY}
                    onClick={handleRequestBack}
                  >
                    Back to login page
                  </SCAuthButton>
                )}
              </Form>
            );
          }}
        </Formik>
      </>
    );
  };

  return (
    <MCAuthLayout>
      {paramError ? (
        <>
          <SCPageTitle>Invalid reset password request received.</SCPageTitle>
          <SCAuthButton
            variant={MEButtonVariant.CONTAINED}
            color={MEButtonColor.PRIMARY}
            onClick={handleRequestBack}
          >
            Back to login page
          </SCAuthButton>
        </>
      ) : (
        getPasswordResetForm()
      )}
    </MCAuthLayout>
  );
}
