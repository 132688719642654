import React from 'react';
import { useSelector } from 'react-redux';

import defaultLogo from 'assets/imgs/moloco_cloud_logo.png';
import { RootState } from 'redux/types';
import { CAMPAIGN_MANAGER_VER, INVENTORY_MANAGER_VER } from 'common-v2/constants/version';
import MCHeaderContainer from 'containers-v2/Header/MCHeaderContainer';

import {
  SCAuthLogoContainer,
  SCInputFormContainer,
  SCAuthFormContainer,
  SCAuthPageWrapper,
  SCAuthAdCloudLogo,
  SCVersionTextContainer,
} from './AuthStyledComponents';

function getVersionText(userSpace: string) {
  if (process.env.REACT_APP_VERSION) {
    return `Ver.${
      process.env.REACT_APP_VERSION
    } CM:${CAMPAIGN_MANAGER_VER} IM:${INVENTORY_MANAGER_VER} ${btoa(userSpace)}`;
  }

  return `CM:${CAMPAIGN_MANAGER_VER} IM:${INVENTORY_MANAGER_VER} Build:${
    process.env.REACT_APP_BUILD_ID
  } ${btoa(userSpace)}`;
}

type MCAuthLayoutProps = {
  hideHeader?: boolean;
  children?: React.ReactNode;
};
export default function MCAuthLayout({
  hideHeader,
  children,
}: MCAuthLayoutProps): React.ReactElement {
  const userSpace = useSelector((state: RootState) => state.auth.userSpace);

  return (
    <>
      {!hideHeader && <MCHeaderContainer />}
      <SCAuthPageWrapper>
        <SCAuthFormContainer>
          <SCAuthLogoContainer>
            <SCAuthAdCloudLogo src={defaultLogo} alt="MOLOCO Cloud Logo" />
          </SCAuthLogoContainer>
          <SCInputFormContainer>{children}</SCInputFormContainer>
        </SCAuthFormContainer>
        <SCVersionTextContainer>{getVersionText(userSpace)}</SCVersionTextContainer>
      </SCAuthPageWrapper>
    </>
  );
}
