export const DEPLOY_ENV = {
  PROD: 'prod',
  STAGING: 'staging',
  TEST: 'test',
  DEV: 'dev',
};

const isDev = () => {
  return process.env.REACT_APP_DEPLOY_ENV === DEPLOY_ENV.DEV;
};

const isTest = () => {
  return process.env.REACT_APP_DEPLOY_ENV === DEPLOY_ENV.TEST;
};

const isStaging = () => {
  return process.env.REACT_APP_DEPLOY_ENV === DEPLOY_ENV.STAGING;
};

const isProd = () => {
  return process.env.REACT_APP_DEPLOY_ENV === DEPLOY_ENV.PROD;
};

function getDeployEnv() {
  return process.env.REACT_APP_DEPLOY_ENV;
}

export default {
  isDev,
  isTest,
  isStaging,
  isProd,
  getDeployEnv,
};
