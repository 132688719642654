import React from 'react';
import { useSnackbar, SnackbarKey } from 'notistack';
import styled from 'styled-components';
import { format } from 'date-fns';

import MUIIconButton from '@material-ui/core/IconButton';
import MUICloseIcon from '@material-ui/icons/Close';

import notificationMessage, { MENotificationLevel } from 'common-v2/model/notificationDelivery';

import EmergencyAlertAsset from 'assets/imgs/notificationIcon/emergencyAlert.svg';
import CriticalAlertAsset from 'assets/imgs/notificationIcon/criticalAlert.svg';
import WarningAlertAsset from 'assets/imgs/notificationIcon/warningAlert.svg';
import NormalAlertAsset from 'assets/imgs/notificationIcon/normalAlert.svg';
import InfoAlertAsset from 'assets/imgs/notificationIcon/infoAlert.svg';

const SCWrapper = styled.div`
  display: flex;
  width: 526px;
  min-height: 74px;
  box-shadow: 0 4px 9px 0 rgba(0, 0, 0, 0.3);
  background-color: #ffffff;
`;

const SCIconWrapper = styled.div<{ color: string }>`
  width: 52px;
  text-align: center;
  background-color: ${(props) => props.color};
`;

const SCIcon = styled.img`
  width: 21px;
  height: 100%;
`;

const SCContentWrapper = styled.div`
  padding: 9px 12px;
  width: 474px;
  height: 100%;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  letter-spacing: normal;
`;

const SCHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const SCHeader = styled.div<{ color: string }>`
  font-size: 14px;
  font-weight: 500;
  line-height: 1.21;
  color: ${(props) => props.color};
  margin-bottom: 4px;
`;

// eslint-disable-next-line react/jsx-props-no-spreading
const SCButton = styled(({ ...rest }) => <MUIIconButton {...rest} />)`
  padding: 0;
`;

const SCMessage = styled.div`
  font-size: 13px;
  line-height: 1.31;
  color: #212121;
  margin-bottom: 2px;
`;

const SCReceivedAt = styled.div`
  font-size: 12px;
  color: #a9a9a9;
`;

const NOTIFICATION_RESOURCES: Record<
  MENotificationLevel,
  { asset: string; color: string; label: string }
> = {
  [MENotificationLevel.EMERGENCY]: {
    asset: EmergencyAlertAsset,
    color: '#ff0000',
    label: 'Emergency',
  },
  [MENotificationLevel.CRITICAL]: {
    asset: CriticalAlertAsset,
    color: '#ff6300',
    label: 'Critical',
  },
  [MENotificationLevel.WARNING]: { asset: WarningAlertAsset, color: '#ffb000', label: 'Warning' },
  [MENotificationLevel.NORMAL]: { asset: NormalAlertAsset, color: '#17b597', label: 'Normal' },
  [MENotificationLevel.INFORMATION]: {
    asset: InfoAlertAsset,
    color: '#3794d8',
    label: 'Information',
  },
};

type MTMCWebNotificationInAppAlertProps = {
  id: SnackbarKey;
  notification: notificationMessage;
};

const MCWebNotificationInAppAlert = React.forwardRef<any, MTMCWebNotificationInAppAlertProps>(
  (props: MTMCWebNotificationInAppAlertProps, ref) => {
    const {
      id,
      notification: { level, msg, receivedAtTimestamp },
    } = props;

    const { closeSnackbar } = useSnackbar();

    const { label, asset, color } = NOTIFICATION_RESOURCES[level];

    const handleDismiss = () => {
      closeSnackbar(id);
    };

    return (
      <SCWrapper ref={ref}>
        <SCIconWrapper color={color}>
          <SCIcon draggable="false" src={asset} alt={`level-${level}`} />
        </SCIconWrapper>
        <SCContentWrapper>
          <SCHeaderWrapper>
            <SCHeader color={color}>{label}</SCHeader>
            <SCButton onClick={handleDismiss}>
              <MUICloseIcon />
            </SCButton>
          </SCHeaderWrapper>
          <SCMessage>{msg}</SCMessage>
          <SCReceivedAt>
            {format(new Date(receivedAtTimestamp), 'MMM dd, yyyy, hh:mm')}
          </SCReceivedAt>
        </SCContentWrapper>
      </SCWrapper>
    );
  },
);

export default MCWebNotificationInAppAlert;
