import { combineReducers } from 'redux';

import exchangesReducer from './exchanges/reducer';
import creativeReviewsReducer from './creativeReviews/reducer';
import publishersReducer from './publishers/reducer';
import publisherAppsReducer from './publisherApps/reducer';
import publisherReportsReducer from './publisherReports/reducer';
import adUnitsReducer from './adUnits/reducer';

const inventoryManagerReducer = combineReducers({
  adUnits: adUnitsReducer,
  creativeReviews: creativeReviewsReducer,
  exchanges: exchangesReducer,
  publishers: publishersReducer,
  publisherApps: publisherAppsReducer,
  publisherReports: publisherReportsReducer,
});

export default inventoryManagerReducer;
